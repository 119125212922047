import React, { useState, useEffect, isValidElement } from 'react';
import { useParams } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import { toast } from 'react-toastify'
import axios from 'axios'
import Loading from '../../../components/loading'
import GenericButton from "../../../components/buttons/generic_button"
import RefreshButton from "../../../components/buttons/refresh_button"

import '../../../styles/pages/dashboard/modules/serverstatsContent.css'
import '../../../js/sticky_modules_serverstats_bars.js'
import config from '../../../config'

const DashboardModulesServerstatsContent = () => {
    const [cookies] = useCookies(['access_token'])

    const [moduleServerstatsStatus, setModuleServerstatsStatus] = useState(false)

    const [guildCategoryChannels, setGuildCategoryChannels] = useState([])
    const [guildTextChannels, setGuildTextChannels] = useState([])
    const [guildRoles, setGuildRoles] = useState([])

    const [serverstatsSettings, setServerstatsSettings] = useState({})

    const [createCustomPanelChannel, setCreateCustomPanelChannel] = useState("")
    const [createCustomPanelChannelLinkedRole, setCreateCustomPanelChannelLinkedRole] = useState("")

    const [newCounterPanelEnabled, setNewCounterPanelEnabled] = useState(false)
    const [newCounterPanelMemberCountChannel, setNewCounterPanelMemberCountChannel] = useState("")
    const [newCounterPanelOnlineMemberCountChannel, setNewCounterPanelOnlineMemberCountChannel] = useState("")
    const [newCreateCounterPanelOnlineMemberCountWithRoleChannels, setNewCreateCounterPanelOnlineMemberCountWithRoleChannels] = useState([])
    const [newDeleteCounterPanelOnlineMemberCountWithRoleChannels, setNewDeleteCounterPanelOnlineMemberCountWithRoleChannels] = useState([])
    const [newCounterPanelServerBoostChannel, setNewCounterPanelServerBoostChannel] = useState("")
    const [newCounterPanelCategoryChannel, setNewCounterPanelCategoryChannel] = useState("")
    const [newStarboardEnabled, setNewStarboardEnabled] = useState(false)
    const [newStarboardChannel, setNewStarboardChannel] = useState("")
    const [newStarboardCount, setNewStarboardCount] = useState("")
    const [newStatsCommandUsageEnabled, setNewStatsCommandUsageEnabled] = useState(false)
    const [newStatsMessageStatisticsEnabled, setNewStatsMessageStatisticsEnabled] = useState(false)
    const [newTrackBotMessages, setNewTrackBotMessages] = useState('no')

    const params = useParams()
    const [moduleServerstatsLoadingStatus, setModuleServerstatsLoadingStatus] = useState('loading')
    const [textChannelsLoadingStatus, setTextChannelsLoadingStatus] = useState('loading')
    const [categoryChannelsLoadingStatus, setCategoryChannelsLoadingStatus] = useState('loading')
    const [rolesLoadingStatus, setRolesLoadingStatus] = useState('loading')
    const [moduleServerstatsSettingsLoadingStatus, setModuleServerstatsSettingsLoadingStatus] = useState('loading')

    // FIELD CHANGES
    const on_field_change = async (event) => {
        event.preventDefault();
        if (event.target.name === "create-custom-panel-channel-name") {
            setCreateCustomPanelChannel(event.target.value)
        } else if (event.target.name === "create-custom-panel-channel-linked-role") {
            setCreateCustomPanelChannelLinkedRole(event.target.value)
        } else if (event.target.name === "new-serverstats-module-counter-panel-enabled-input") {
            if (event.target.id === "on") {
                setNewCounterPanelEnabled(true)
            } else if (event.target.id === "off") {
                setNewCounterPanelEnabled(false)
            }
        } else if (event.target.name === "new-dashboard-modules-serverstats-counter-panel-category-channel") {
            setNewCounterPanelCategoryChannel(event.target.value)
        } else if (event.target.name === "new-dashboard-modules-serverstats-member-count-channel") {
            setNewCounterPanelMemberCountChannel(event.target.value)
        } else if (event.target.name === "new-dashboard-modules-serverstats-online-member-count-channel") {
            setNewCounterPanelOnlineMemberCountChannel(event.target.value)
        } else if (event.target.name === "new-dashboard-modules-serverstats-server-boost-number-channel") {
            setNewCounterPanelServerBoostChannel(event.target.value)
        } else if (event.target.name === "new-serverstats-module-starboard-enabled-input") {
            if (event.target.id === "on") {
                setNewStarboardEnabled(true)
            } else if (event.target.id === "off") {
                setNewStarboardEnabled(false)
            }
        } else if (event.target.name === "new-dashboard-modules-serverstats-starboard-channel") {
            setNewStarboardChannel(event.target.value)
        } else if (event.target.name === "new-dashboard-modules-serverstats-starboard-count") {
            setNewStarboardCount(event.target.value)
        } else if (event.target.name === "new-serverstats-module-stats-command-usage-enabled-input") {
            if (event.target.id === "on") {
                setNewStatsCommandUsageEnabled(true)
            } else if (event.target.id === "off") {
                setNewStatsCommandUsageEnabled(false)
            }
        } else if (event.target.name === "new-serverstats-module-statistics-messages-enabled-input") {
            if (event.target.id === "on") {
                setNewStatsMessageStatisticsEnabled(true)
            } else if (event.target.id === "off") {
                setNewStatsMessageStatisticsEnabled(false)
            }
        } else if (event.target.name === 'new-dashboard-modules-serverstats-statistics-messages') {
            setNewTrackBotMessages(event.target.value)
        }
    }

    // Refresh data
    const refresh_data_table = async (event) => {
        event.preventDefault();
        window.location.reload()
    }

    // Module Status
    const open_create_custom_panel_sidebar = async (event) => {
        event.preventDefault()
        var bar = document.getElementById('create-custom-panel-sidebar')
        bar.style.transform = "translateX(0px)"
    }
    const close_create_custom_panel_sidebar = async (event) => {
        event.preventDefault()
        var bar = document.getElementById('create-custom-panel-sidebar')
        bar.style.transform = "translateX(+502px)"
    }

    const open_enable_serverstats_module_sidebar = async (event) => {
        event.preventDefault()
        var bar = document.getElementById('enable-serverstats-module-sidebar')
        bar.style.transform = "translateX(0px)"
    }
    const close_enable_serverstats_module_sidebar = async (event) => {
        event.preventDefault()
        var bar = document.getElementById('enable-serverstats-module-sidebar')
        bar.style.transform = "translateX(+502px)"
    }

    const get_module_status = async () => {
        setModuleServerstatsLoadingStatus("loading")
        const options = {
            url: `${config["api_base_url"]}/guilds/${params.guild_id}/modules?module_names=serverstats`,
            method: 'GET',
            headers: {
                access_token: cookies["access_token"]
            }
          };
        axios(options)
        .then(response => {
            if (response.status === 200) {
                    setModuleServerstatsStatus(response["data"]["data"]["module_serverstats"])
                    if (response["data"]["data"]["module_serverstats"] === true) {
                        get_module_settings()
                    }
                }
                setModuleServerstatsLoadingStatus("success")
        })
        .catch(error => {
            console.log(error)
            if (error.response.status === 429) {
                toast.error("Rate limit reached! Please slow down!")
                return
            }
            setModuleServerstatsLoadingStatus("error")
            toast.error("Something went wrong while fetching the module Serverstats status!")
        });
    }

    const set_module_status = async (event) => {
        var new_status
        if (event.target.id === "enable") {
            new_status = true
        } else if (event.target.id === "disable") {
            new_status = false
        }
        
        var data = {}
        data.serverstats = {}
        data.serverstats.new_status = new_status
        data.serverstats.guild_id = params.guild_id

        const options = {
            url: `${config["api_base_url"]}/guilds/${params.guild_id}/modules`,
            method: 'POST',
            headers: {
                access_token: cookies["access_token"]
            },
            data: data
          };

        axios(options)
        .then(response => {
            if (response.status === 202) {
                if (response["data"]["data"]["serverstats"]["code"] === 200) {
                    window.location.reload()
                }
            } else {
                toast.error("Something went wrong while updating the Serverstats module!")
            }
        })
        .catch(error => {
            console.log(error)
            if (error.response.status === 429) {
                toast.error("Rate limit reached! Please slow down!")
                return
            }
            toast.error("Something went wrong while updating the Serverstats module!")
        });
    }

    // Get data
    const get_text_channels = async () => {
        setTextChannelsLoadingStatus("loading")
        const options = {
            url: `${config["api_base_url"]}/guilds/${params.guild_id}/channels?types=text`,
            method: 'GET',
            headers: {
                access_token: cookies["access_token"]
            },
          };

          axios(options)
          .then(response => {
              if (response.status === 200) {
                  setGuildTextChannels(response["data"][0]["data"])
                  setTextChannelsLoadingStatus("success")
              }
          })
          .catch(error => {
              console.log(error)
              setTextChannelsLoadingStatus("error")
              if (error.response.status === 429) {
                toast.error("Rate limit reached! Please slow down!")
                return
            }
              toast.error("Something went wrong while getting the servers text channels!")
          });
    }

    const get_category_channels = async () => {
        setCategoryChannelsLoadingStatus("loading")
        const options = {
            url: `${config["api_base_url"]}/guilds/${params.guild_id}/channels?types=category`,
            method: 'GET',
            headers: {
                access_token: cookies["access_token"]
            },
          };

          axios(options)
          .then(response => {
              if (response.status === 200) {
                  setGuildCategoryChannels(response["data"][0]["data"])
                  setCategoryChannelsLoadingStatus("success")
              }
          })
          .catch(error => {
              console.log(error)
              setCategoryChannelsLoadingStatus("error")
              if (error.response.status === 429) {
                toast.error("Rate limit reached! Please slow down!")
                return
            }
              toast.error("Something went wrong while getting the servers category channels!")
          });
    }

    const get_roles = async () => {
        setRolesLoadingStatus("loading")
        const options = {
            url: `${config["api_base_url"]}/guilds/${params.guild_id}/roles`,
            method: 'GET',
            headers: {
                access_token: cookies["access_token"]
            },
          };

        axios(options)
          .then(response => {
              if (response.status === 200) {
                  setGuildRoles(response["data"]["data"])
                  setRolesLoadingStatus("success")
              }
          })
          .catch(error => {
              console.log(error)
              setRolesLoadingStatus("error")
              if (error.response.status === 429) {
                toast.error("Rate limit reached! Please slow down!")
                return
            }
              toast.error("Something went wrong while getting the servers roles!")
          });
    }

    const get_module_settings = async () => {
        setModuleServerstatsSettingsLoadingStatus("loading")
        const options = {
            url: `${config["api_base_url"]}/guilds/${params.guild_id}/modules/serverstats`,
            method: 'GET',
            headers: {
                access_token: cookies["access_token"]
            },
          };

          axios(options)
          .then(response => {
              if (response.status === 200) {
                  setServerstatsSettings(response["data"]["data"])
                  setNewCounterPanelEnabled(response["data"]["data"]["counter_panel_enabled"])
                  if (response["data"]["data"]["counter_panel_member_count_channel"]["id"] === null) {
                    setNewCounterPanelMemberCountChannel("none")
                  } else {
                    setNewCounterPanelMemberCountChannel("enabled")
                  }
                  if (response["data"]["data"]["counter_panel_online_member_count_channel"]["id"] === null) {
                    setNewCounterPanelOnlineMemberCountChannel("none")
                  } else {
                    setNewCounterPanelOnlineMemberCountChannel("enabled")
                  }
                  if (response["data"]["data"]["counter_panel_server_boost_number_channel"]["id"] === null) {
                    setNewCounterPanelServerBoostChannel("none")
                  } else {
                    setNewCounterPanelServerBoostChannel("enabled")
                  }
                  if (response["data"]["data"]["counter_panel_category_channel"]["id"] === null) {
                    setNewCounterPanelCategoryChannel("none")
                  } else {
                    setNewCounterPanelCategoryChannel(response["data"]["data"]["counter_panel_category_channel"]["id"])
                  }
                  setNewStarboardEnabled(response["data"]["data"]["starboard_enabled"])
                  if (response["data"]["data"]["starboard_channel"]["id"] === null) {
                    setNewStarboardChannel("none")
                  } else {
                    setNewStarboardChannel(response["data"]["data"]["starboard_channel"]["id"])
                  }
                  setNewStarboardCount(response["data"]["data"]["starboard_count"])
                  setNewStatsCommandUsageEnabled(response["data"]["data"]["stats_command_usage_enabled"])
                  setNewStatsMessageStatisticsEnabled(response["data"]["data"]["stats_message_statistics_enabled"])
                  if (response["data"]["data"]["track_bot_messages"] === true) {
                    setNewTrackBotMessages("yes")
                  } else {
                    setNewTrackBotMessages("no")
                  }
                  setModuleServerstatsSettingsLoadingStatus("success")
              }
          })
          .catch(error => {
              console.log(error)
              setModuleServerstatsSettingsLoadingStatus("error")
              if (error.response.status === 429) {
                toast.error("Rate limit reached! Please slow down!")
                return
            }
              toast.error("Something went wrong while getting the settings of the Serverstats module!")
          });
    }

    const reset_module_settings = async () => {
        setNewCounterPanelEnabled(serverstatsSettings.counter_panel_enabled)
        if (serverstatsSettings.counter_panel_member_count_channel.id === null) {
            setNewCounterPanelMemberCountChannel("none")
        } else {
            setNewCounterPanelMemberCountChannel("enabled")
        }
        if (serverstatsSettings.counter_panel_online_member_count_channel.id === null) {
            setNewCounterPanelOnlineMemberCountChannel("none")
        } else {
            setNewCounterPanelOnlineMemberCountChannel("enabled")
        }
        if (serverstatsSettings.counter_panel_server_boost_number_channel.id === null) {
            setNewCounterPanelServerBoostChannel("none")
        } else {
            setNewCounterPanelServerBoostChannel("enabled")
        }
        setNewCreateCounterPanelOnlineMemberCountWithRoleChannels([])
        setNewDeleteCounterPanelOnlineMemberCountWithRoleChannels([])
        if (serverstatsSettings.counter_panel_category_channel.id === null) {
            setNewCounterPanelCategoryChannel("none")
        } else {
            setNewCounterPanelCategoryChannel("enabled")
        }
        setNewStarboardEnabled(serverstatsSettings.starboard_enabled)
        if (serverstatsSettings.starboard_channel.id === null) {
            setNewStarboardChannel("none")
        } else {
            setNewStarboardChannel(serverstatsSettings.starboard_channel.id)
        }
        setNewStarboardCount(serverstatsSettings.starboard_count)
        setNewStatsCommandUsageEnabled(serverstatsSettings.stats_command_usage_enabled)
        setNewStatsMessageStatisticsEnabled(serverstatsSettings.stats_message_statistics_enabled)
        setNewTrackBotMessages(serverstatsSettings.track_bot_messages)
    }

    const reset_new_custom_panels = async () => {
        setNewCreateCounterPanelOnlineMemberCountWithRoleChannels([])
        setNewDeleteCounterPanelOnlineMemberCountWithRoleChannels([])
    }

    const set_module_settings = async () => {
        var data = {}
        data.guild_id = params.guild_id
        if (newCounterPanelEnabled === true) {
            if (newCounterPanelCategoryChannel === "none") {
                toast.error("You need to select the category you want to use for the counter panels!")
                return
            }
        }
        if (newStarboardEnabled === true) {
            if (newStarboardChannel === "none") {
                toast.error("You need to select the new starboard channel!")
                return
            }
            if (newStarboardCount === "" || newStarboardCount < 0 || newStarboardCount === 0) {
                toast.error("You need to insert the number of stars before a message is posted to the starboard!")
                return
            }
        }
        
        if (newCounterPanelEnabled === true) {
            data.counter_panel_enabled = newCounterPanelEnabled
            data.counter_panel_member_count_channel = newCounterPanelMemberCountChannel
            data.counter_panel_online_member_count_channel = newCounterPanelOnlineMemberCountChannel
            data.counter_panel_server_boost_number_channel = newCounterPanelServerBoostChannel
            data.counter_panel_custom_panels = {}
            data.counter_panel_custom_panels.create = newCreateCounterPanelOnlineMemberCountWithRoleChannels
            data.counter_panel_custom_panels.delete = newDeleteCounterPanelOnlineMemberCountWithRoleChannels
            data.counter_panel_category_channel = newCounterPanelCategoryChannel
        } else {
            data.counter_panel_enabled = newCounterPanelEnabled
            data.counter_panel_member_count_channel = "none"
            data.counter_panel_online_member_count_channel = "none"
            data.counter_panel_server_boost_number_channel = "none"
            data.counter_panel_custom_panels = {}
            data.counter_panel_custom_panels.create = []
            data.counter_panel_custom_panels.delete = []
            data.counter_panel_category_channel = "none"
        }
        if (newStarboardEnabled === true) {
            data.starboard_enabled = newStarboardEnabled
            data.starboard_channel = newStarboardChannel
            data.starboard_count = newStarboardCount
        } else {
            data.starboard_enabled = newStarboardEnabled
            data.starboard_channel = "none"
            data.starboard_count = 0
        }

        data.stats_message_statistics_enabled = newStatsMessageStatisticsEnabled
        if (newTrackBotMessages === 'yes') {
            data.track_bot_messages = true
        } else {
            data.track_bot_messages = false
        }
        data.stats_command_usage_enabled = newStatsCommandUsageEnabled

        const options = {
            url: `${config["api_base_url"]}/guilds/${params.guild_id}/modules/serverstats`,
            method: 'PUT',
            headers: {
                access_token: cookies["access_token"]
            },
            data: data
          };

          axios(options)
          .then(response => {
              if (response.status === 202) {
                  window.location.reload()
              }
          })
          .catch(error => {
              console.log(error)
              if (error.response.status === 429) {
                toast.error("Rate limit reached! Please slow down!")
                return
            }
              toast.error("Something went wrong while changing the settings of the Serverstats module!")
          });
    }

    const create_custom_panel = async (event) => {
        event.preventDefault()
        if (createCustomPanelChannel === "") {
            toast.error("You must insert the name to use for a new custom panel!")
            return
        }
        if (createCustomPanelChannelLinkedRole === "") {
            toast.error("You must select the role to link to the new custom panel!")
            return
        }

        var data = {}
        data.channel_name = createCustomPanelChannel
        data.role_id = createCustomPanelChannelLinkedRole
        setNewCreateCounterPanelOnlineMemberCountWithRoleChannels(CreateCounterPanelOnlineMemberCountWithRoleChannels => CreateCounterPanelOnlineMemberCountWithRoleChannels.concat(data))
        setCreateCustomPanelChannel("")
        setCreateCustomPanelChannelLinkedRole("")
        close_create_custom_panel_sidebar(event)
        toast.success("The custom panel will be added on the next save! This can be canceled using the cancel button!")
    }

    const delete_custom_panel = async (event) => {
        event.preventDefault()
        setNewDeleteCounterPanelOnlineMemberCountWithRoleChannels(prevDeleteCounterPanelOnlineMemberCountWithRoleChannels => prevDeleteCounterPanelOnlineMemberCountWithRoleChannels.concat(event.target.id))
        toast.success("The custom panel will be deleted on the next save! This can be canceled using the cancel button!")
    }
    
    useEffect(() => {
        get_module_status()
        get_text_channels()
        get_category_channels()
        get_roles()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return(
        <div className='dashboard-modules-serverstats-content-inner-wrapper'>
            { moduleServerstatsLoadingStatus === 'success' && textChannelsLoadingStatus === 'success' && categoryChannelsLoadingStatus === 'success' && rolesLoadingStatus === 'success' ?
                moduleServerstatsStatus === true ?
                    moduleServerstatsSettingsLoadingStatus === 'success' ?  
                        <div className='dashboard-modules-serverstats-enabled-container'> 
                            <div className='dashboard-modules-serverstats-enabled-disable-wrapper'>
                                Click <label id="disable" onClick={set_module_status} className='dashboard-modules-serverstats-enabled-disable-button'>here</label> if you want to disable this module!
                            </div>
                            <div className='dashboard-modules-serverstats-enabled-settings-container'>
                                <div className='dashboard-modules-serverstats-enabled-settings-reminder'>Don't forget to save your changes!</div>
                                <div className='dashboard-modules-serverstats-enabled-setting'>
                                    <div className='dashboard-modules-serverstats-enabled-setting-label-container'>
                                        <div className='dashboard-modules-serverstats-enabled-setting-label'>
                                            Counter Panel
                                        </div>
                                        { newCounterPanelEnabled ?
                                            <div key="off">
                                                <div className='new-serverstats-module-counter-panel-enabled-wrapper'>
                                                    <label className="toggler-wrapper style-1">
                                                        <input id="off" type="checkbox" name="new-serverstats-module-counter-panel-enabled-input" defaultChecked onChange={on_field_change}></input>
                                                        <div className="toggler-slider">
                                                            <div className="toggler-knob"></div>
                                                        </div>
                                                    </label>
                                                </div>
                                            </div> 
                                            :
                                            <div key="on">
                                                <div className='new-serverstats-module-counter-panel-enabled-wrapper'>
                                                    <label className="toggler-wrapper style-1">
                                                        <input id="on" type="checkbox" name="new-serverstats-module-counter-panel-enabled-input" onChange={on_field_change}></input>
                                                        <div className="toggler-slider">
                                                            <div className="toggler-knob"></div>
                                                        </div>
                                                    </label>
                                                </div> 
                                            </div>
                                        }
                                    </div>
                                    <div className='dashboard-modules-serverstats-enabled-setting-description'>
                                        Add a counter panel to your server.&nbsp;
                                        { serverstatsSettings.counter_panel_enabled === true ?
                                            <a className='serverstats-entry-field-channel-link' href={"https://discord.com/channels/"+params.guild_id} target="_blank" rel="noreferrer">
                                                View counter panels in Discord!
                                            </a>
                                            :
                                            <div></div>
                                        }
                                    </div>
                                    { newCounterPanelEnabled ?
                                        <div>
                                            <hr  className='dashboard-modules-serverstats-enabled-setting-devider' />
                                            <div className='dashboard-modules-serverstats-enabled-internal-setting-wrapper'>
                                                <div className='dashboard-modules-serverstats-enabled-internal-setting dashboard-modules-serverstats-enabled-internal-setting-top'>
                                                    <div className='dashboard-modules-serverstats-enabled-internal-setting-label-wrapper'>
                                                        <div className='dashboard-modules-serverstats-enabled-internal-setting-label'>Counter panel category</div>
                                                    </div>
                                                    <select className='dashboard-modules-serverstats-counter-panel-category-channel-input dashboard-modules-serverstats-enabled-internal-setting-input-top' id='dashboard-modules-serverstats-counter-panel-category-channel-input' name='new-dashboard-modules-serverstats-counter-panel-category-channel' onChange={on_field_change} value={newCounterPanelCategoryChannel}>
                                                        { guildCategoryChannels.map(guild_category_channel => 
                                                            <option key={ String(guild_category_channel.id) } value={ String(guild_category_channel.id) }>
                                                                { guild_category_channel.name }
                                                            </option>
                                                        )}
                                                        <option key="serverstats-counter-panel-category-channel-none" value="none">
                                                            No channel selected!
                                                        </option>
                                                    </select>
                                                </div>
                                                <div className='dashboard-modules-serverstats-enabled-internal-setting dashboard-modules-serverstats-enabled-internal-setting-top'>
                                                    <div className='dashboard-modules-serverstats-enabled-internal-setting-label-wrapper'>
                                                        <div className='dashboard-modules-serverstats-enabled-internal-setting-label'>Member count panel</div>
                                                        { serverstatsSettings.counter_panel_member_count_channel.id !== null ?
                                                            <div className='dashboard-modules-serverstats-enabled-internal-setting-description'>Current: {serverstatsSettings.counter_panel_member_count_channel.name} ({serverstatsSettings.counter_panel_member_count_channel.id})</div>
                                                            :
                                                            <div className='dashboard-modules-serverstats-enabled-internal-setting-description'>Currently disabled</div>
                                                        }
                                                    </div>
                                                    <select className='dashboard-modules-serverstats-member-count-channel-input dashboard-modules-serverstats-enabled-internal-setting-input-top' id='dashboard-modules-serverstats-member-count-channel-input' name='new-dashboard-modules-serverstats-member-count-channel' onChange={on_field_change} value={newCounterPanelMemberCountChannel}>
                                                        <option key="serverstats-member-count-channel-enabled" value="Enabled">
                                                            Enabled
                                                        </option>
                                                        <option key="serverstats-member-count-channel-disabled" value="none">
                                                            Disabled
                                                        </option>
                                                    </select>
                                                </div>
                                                <div className='dashboard-modules-serverstats-enabled-internal-setting dashboard-modules-serverstats-enabled-internal-setting-top'>
                                                    <div className='dashboard-modules-serverstats-enabled-internal-setting-label-wrapper'>
                                                        <div className='dashboard-modules-serverstats-enabled-internal-setting-label'>Online member count panel</div>
                                                        { serverstatsSettings.counter_panel_online_member_count_channel.id !== null ?
                                                            <div className='dashboard-modules-serverstats-enabled-internal-setting-description'>Current: {serverstatsSettings.counter_panel_online_member_count_channel.name} ({serverstatsSettings.counter_panel_online_member_count_channel.id})</div>
                                                            :
                                                            <div className='dashboard-modules-serverstats-enabled-internal-setting-description'>Currently disabled</div>
                                                        }
                                                    </div>
                                                    <select className='dashboard-modules-serverstats-online-member-count-channel-input dashboard-modules-serverstats-enabled-internal-setting-input-top' id='dashboard-modules-serverstats-online-member-count-channel-input' name='new-dashboard-modules-serverstats-online-member-count-channel' onChange={on_field_change} value={newCounterPanelOnlineMemberCountChannel}>
                                                        <option key="serverstats-online-member-count-channel-enabled" value="Enabled">
                                                            Enabled
                                                        </option>
                                                        <option key="serverstats-online-member-count-channel-disabled" value="none">
                                                            Disabled
                                                        </option>
                                                    </select>
                                                </div>
                                                <div className='dashboard-modules-serverstats-enabled-internal-setting dashboard-modules-serverstats-enabled-internal-setting-top'>
                                                    <div className='dashboard-modules-serverstats-enabled-internal-setting-label-wrapper'>
                                                        <div className='dashboard-modules-serverstats-enabled-internal-setting-label'>Server boost number</div>
                                                        { serverstatsSettings.counter_panel_server_boost_number_channel.id !== null ?
                                                            <div className='dashboard-modules-serverstats-enabled-internal-setting-description'>Current: {serverstatsSettings.counter_panel_server_boost_number_channel.name} ({serverstatsSettings.counter_panel_server_boost_number_channel.id})</div>
                                                            :
                                                            <div className='dashboard-modules-serverstats-enabled-internal-setting-description'>Currently disabled</div>
                                                        }
                                                    </div>
                                                    <select className='dashboard-modules-serverstats-server-boost-number-channel-input dashboard-modules-serverstats-enabled-internal-setting-input-top' id='dashboard-modules-serverstats-server-boost-number-channel-input' name='new-dashboard-modules-serverstats-server-boost-number-channel' onChange={on_field_change} value={newCounterPanelServerBoostChannel}>
                                                        <option key="serverstats-server-boost-number-channel-enabled" value="Enabled">
                                                            Enabled
                                                        </option>
                                                        <option key="serverstats-server-boost-number-channel-disabled" value="none">
                                                            Disabled
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className='dashboard-modules-serverstats-enabled-internal-setting'>
                                                <div className='dashboard-modules-serverstats-enabled-internal-setting-label-wrapper'>
                                                    <div className='dashboard-modules-serverstats-enabled-internal-setting-label'>Online members with role</div>
                                                </div>
                                                { serverstatsSettings.counter_panel_online_member_count_with_role_id.length === 0 ?
                                                    <div className='dashboard-modules-serverstats-enabled-internal-setting-custom-panels-empty'>No custom panels</div> 
                                                    :
                                                    <div className='dashboard-modules-serverstats-enabled-internal-setting-custom-panels-table'>
                                                        <div className='dashboard-modules-serverstats-enabled-internal-setting-custom-panels-table-titles'>
                                                            <div className='dashboard-modules-serverstats-enabled-internal-setting-custom-panels-table-titles-wrapper'>
                                                                <div className="dashboard-modules-serverstats-enabled-internal-setting-custom-panels-table-titles-name-wrapper">
                                                                    <p className="dashboard-modules-serverstats-enabled-internal-setting-custom-panels-table-titles-name">Custom panel channel</p>
                                                                </div>
                                                                <div className="dashboard-modules-serverstats-enabled-internal-setting-custom-panels-table-titles-content-wrapper">
                                                                    <p className="dashboard-modules-serverstats-enabled-internal-setting-custom-panels-table-titles-content">Linked role</p>
                                                                </div>
                                                                <div className="dashboard-modules-serverstats-enabled-internal-setting-custom-panels-table-interactions-wrapper">
                                                                    <p className="dashboard-modules-serverstats-enabled-internal-setting-custom-panels-table-interactions">Interactions</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="dashboard-modules-serverstats-enabled-internal-setting-custom-panels-table-content">
                                                            { serverstatsSettings.counter_panel_online_member_count_with_role_id.map(entry =>
                                                                <div className="custom-panel-entry-wrapper" key={entry.id}>
                                                                    <div className="custom-panel-entry-name-wrapper">
                                                                        <p className="custom-panel-entry-name">{ entry.name } ({ entry.id })</p>
                                                                    </div>
                                                                    <div className="custom-panel-entry-content-wrapper">
                                                                        <p className="custom-panel-entry-content">{ entry.role.name } ({ entry.role.id })</p>
                                                                    </div>
                                                                    <div className="custom-panel-entry-interactions-wrapper">
                                                                        <div className="custom-panel-entry-interactions-inner-wrapper">
                                                                            <div className="custom-panel-entry-interactions-form-delete">
                                                                                <span className="custom-panel-entry-interactions-delete-text" id={entry.id} onClick={delete_custom_panel}>Delete</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                }
                                                <div className='dashboard-modules-serverstats-enabled-internal-setting-custom-panels-buttons'>
                                                    <div className='dashboard-modules-serverstats-enabled-internal-setting-custom-panels-add-button' onClick={open_create_custom_panel_sidebar}>Add panel</div>
                                                    <div className='dashboard-modules-serverstats-enabled-internal-setting-custom-panels-add-button' onClick={reset_new_custom_panels}>Reset all changes to custom panels</div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div></div>
                                    }
                                </div>
                                <div className='dashboard-modules-serverstats-enabled-setting'>
                                    <div className='dashboard-modules-serverstats-enabled-setting-label-container'>
                                        <div className='dashboard-modules-serverstats-enabled-setting-label'>
                                            Starboard
                                        </div>
                                        { newStarboardEnabled ?
                                            <div key="off">
                                                <div className='new-serverstats-module-starboard-enabled-wrapper'>
                                                    <label className="toggler-wrapper style-1">
                                                        <input id="off" type="checkbox" name="new-serverstats-module-starboard-enabled-input" defaultChecked onChange={on_field_change}></input>
                                                        <div className="toggler-slider">
                                                            <div className="toggler-knob"></div>
                                                        </div>
                                                    </label>
                                                </div>
                                            </div> 
                                            :
                                            <div key="on">
                                                <div className='new-serverstats-module-starboard-enabled-wrapper'>
                                                    <label className="toggler-wrapper style-1">
                                                        <input id="on" type="checkbox" name="new-serverstats-module-starboard-enabled-input" onChange={on_field_change}></input>
                                                        <div className="toggler-slider">
                                                            <div className="toggler-knob"></div>
                                                        </div>
                                                    </label>
                                                </div> 
                                            </div>
                                        }
                                    </div>
                                    <div className='dashboard-modules-serverstats-enabled-setting-description'>
                                        Add a starboard to your server.&nbsp;
                                        { serverstatsSettings.starboard_channel.id !== null ?
                                            <a className='serverstats-entry-field-channel-link' href={"https://discord.com/channels/"+params.guild_id+"/"+serverstatsSettings.starboard_channel.id} target="_blank" rel="noreferrer">
                                                View starboard in Discord!
                                            </a>
                                            :
                                            <div></div>
                                        }
                                    </div>
                                    { newStarboardEnabled ?
                                        <div>
                                            <hr  className='dashboard-modules-serverstats-enabled-setting-devider' />
                                            <div className='dashboard-modules-serverstats-enabled-internal-setting dashboard-modules-serverstats-enabled-internal-setting'>
                                                <div className='dashboard-modules-serverstats-enabled-internal-setting-label-wrapper'>
                                                    <div className='dashboard-modules-serverstats-enabled-internal-setting-label'>Starboard channel</div>
                                                </div>
                                                <select className='dashboard-modules-serverstats-starboard-channel-input dashboard-modules-serverstats-enabled-internal-setting-input' id='new-dashboard-modules-serverstats-starboard-channel-input' name='new-dashboard-modules-serverstats-starboard-channel' onChange={on_field_change} value={newStarboardChannel}>
                                                    { guildTextChannels.map(guild_text_channel => 
                                                        <option key={ String(guild_text_channel.id) } value={ String(guild_text_channel.id) }>
                                                            { guild_text_channel.name }
                                                        </option>
                                                    )}
                                                    <option key="serverstats-starboard-channel-none" value="none">
                                                        No channel selected!
                                                    </option>
                                                </select>
                                                <div className='dashboard-modules-serverstats-enabled-internal-setting-label-wrapper'>
                                                    <div className='dashboard-modules-serverstats-enabled-internal-setting-label'>Starboard channel</div>
                                                </div>
                                                <input type='numeric' className='dashboard-modules-greetings-enabled-internal-setting-input dashboard-modules-serverstats-enabled-internal-setting-input' id='new-dashboard-modules-serverstats-starboard-count-input' name='new-dashboard-modules-serverstats-starboard-count' placeholder="1" onChange={on_field_change} value={newStarboardCount}></input>
                                            </div>
                                        </div>
                                        :
                                        <div></div>
                                    }
                                </div>
                                <div className='dashboard-modules-serverstats-enabled-setting'>
                                    <div className='dashboard-modules-serverstats-enabled-setting-label-container'>
                                        <div className='dashboard-modules-serverstats-enabled-setting-label'>
                                            Statistics messages
                                        </div>
                                        { newStatsMessageStatisticsEnabled ?
                                            <div key="off">
                                                <div className='new-serverstats-module-statistics-messages-enabled-wrapper'>
                                                    <label className="toggler-wrapper style-1">
                                                        <input id="off" type="checkbox" name="new-serverstats-module-statistics-messages-enabled-input" defaultChecked onChange={on_field_change}></input>
                                                        <div className="toggler-slider">
                                                            <div className="toggler-knob"></div>
                                                        </div>
                                                    </label>
                                                </div>
                                            </div> 
                                            :
                                            <div key="on">
                                                <div className='new-serverstats-module-statistics-messages-enabled-wrapper'>
                                                    <label className="toggler-wrapper style-1">
                                                        <input id="on" type="checkbox" name="new-serverstats-module-statistics-messages-enabled-input" onChange={on_field_change}></input>
                                                        <div className="toggler-slider">
                                                            <div className="toggler-knob"></div>
                                                        </div>
                                                    </label>
                                                </div> 
                                            </div>
                                        }
                                    </div>
                                    <div className='dashboard-modules-serverstats-enabled-setting-description'>Keep track of the number of messages sent by users.</div>
                                    { newStatsMessageStatisticsEnabled ?
                                        <div>
                                            <hr  className='dashboard-modules-serverstats-enabled-setting-devider' />
                                            <div className='dashboard-modules-serverstats-enabled-internal-setting dashboard-modules-serverstats-enabled-internal-setting'>
                                                <div className='dashboard-modules-serverstats-enabled-internal-setting-label-wrapper'>
                                                    <div className='dashboard-modules-serverstats-enabled-internal-setting-label'>Keep track of bot messages</div>
                                                </div>
                                                <select className='dashboard-modules-serverstats-statistics-messages-input dashboard-modules-serverstats-enabled-internal-setting-input' id='new-dashboard-modules-serverstats-statistics-messages-input' name='new-dashboard-modules-serverstats-statistics-messages' onChange={on_field_change} value={newTrackBotMessages}>
                                                    <option key="serverstats-statistics-messages-no" value="no">
                                                        No
                                                    </option>
                                                    <option key="serverstats-statistics-messages-yes" value="yes">
                                                        Yes
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        :
                                        <div></div>
                                    }
                                </div>
                                <div className='dashboard-modules-serverstats-enabled-setting'>
                                    <div className='dashboard-modules-serverstats-enabled-setting-label-container'>
                                        <div className='dashboard-modules-serverstats-enabled-setting-label'>
                                            Statistics command usage
                                        </div>
                                        { newStatsCommandUsageEnabled ?
                                            <div key="off">
                                                <div className='new-serverstats-module-stats-command-usage-enabled-wrapper'>
                                                    <label className="toggler-wrapper style-1">
                                                        <input id="off" type="checkbox" name="new-serverstats-module-stats-command-usage-enabled-input" defaultChecked onChange={on_field_change}></input>
                                                        <div className="toggler-slider">
                                                            <div className="toggler-knob"></div>
                                                        </div>
                                                    </label>
                                                </div>
                                            </div> 
                                            :
                                            <div key="on">
                                                <div className='new-serverstats-module-stats-command-usage-enabled-wrapper'>
                                                    <label className="toggler-wrapper style-1">
                                                        <input id="on" type="checkbox" name="new-serverstats-module-stats-command-usage-enabled-input" onChange={on_field_change}></input>
                                                        <div className="toggler-slider">
                                                            <div className="toggler-knob"></div>
                                                        </div>
                                                    </label>
                                                </div> 
                                            </div>
                                        }
                                    </div>
                                    <div className='dashboard-modules-serverstats-enabled-setting-description'>Keep track of the commands used by server members.</div>
                                </div>
                                <div className="dashboard-modules-serverstats-settings-interactions-wrapper">
                                    <div className="dashboard-modules-serverstats-settings-interactions-inner-wrapper">
                                        <GenericButton type="danger" on_click_action={reset_module_settings} button_text="Cancel" />
                                        <GenericButton button_text="Save" on_click_action={set_module_settings} />
                                    </div>
                                    <div className="dashboard-modules-serverstats-settings-interactions-refresh">
                                        <RefreshButton on_click_action={refresh_data_table} />
                                    </div>
                                </div>
                            </div>
                            <div className='create-custom-panel-sidebar' id="create-custom-panel-sidebar">
                                <div className='create-custom-panel-wrapper'>
                                    <div className='create-custom-panel-cancel'>
                                        <div className="create-custom-panel-back-button" onClick={close_create_custom_panel_sidebar}>
                                            <div className="create-scustom-panel-button-icon"><span className="material-symbols-outlined" id="serverstats-close-icon">close</span></div>
                                            <div className="create-custom-panel-button-text-wrapper"><span className="create-custom-panel-button-text">Cancel & Reset</span></div>
                                        </div>
                                        <h2 className='create-custom-panel-title'>
                                            Create a custom counter panel
                                        </h2>
                                    </div>
                                    <div className='create-custom-panel-channel-name'>
                                        <h3 className='create-custom-panel-channel-name-label'>
                                            Channel name*
                                        </h3>
                                        <input className='create-custom-panel-channel-name-input' id='create-custom-panel-channel-name-input' name='create-custom-panel-channel-name' placeholder='example' onChange={on_field_change} value={createCustomPanelChannel}/>
                                    </div>
                                    <div className='create-custom-panel-channel-linked-role'>
                                        <h3 className='create-custom-panel-channel-linked-role-label'>
                                            Linked role*
                                        </h3>
                                        <select className='create-custom-panel-channel-linked-role-input' id='create-custom-panel-channel-linked-role-input' name='create-custom-panel-channel-linked-role' onChange={on_field_change} value={createCustomPanelChannelLinkedRole}>
                                            { guildRoles.map(guild_role => 
                                                <option key={ String(guild_role.id) } value={ String(guild_role.id) }>
                                                    { guild_role.name }
                                                </option>
                                            )}
                                            <option key="no-role" value="">
                                                No role selected yet!
                                            </option>
                                        </select>
                                    </div> 
                                </div>
                                <div className='create-custom-panel-interactions-wrapper'>
                                    <div className='submit'>
                                        <GenericButton button_text="Create" id="create" on_click_action={create_custom_panel} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <Loading loading_size='s' />
                    :
                    <div className='dashboard-modules-serverstats-disabled-container'>
                        The Serverstats module is disabled, click <label onClick={open_enable_serverstats_module_sidebar} className='dashboard-modules-serverstats-disabled-enable-button'>here</label> if you want to enabled this module!
                        <div className='enable-serverstats-module-sidebar' id="enable-serverstats-module-sidebar">
                            <div className='enable-serverstats-module-wrapper'>
                                <div className='enable-serverstats-module-cancel'>
                                    <div className="enable-serverstats-module-back-button" onClick={close_enable_serverstats_module_sidebar}>
                                        <div className="enable-serverstats-module-button-icon"><span className="material-symbols-outlined" id="serverstats-close-icon">close</span></div>
                                        <div className="enable-serverstats-module-button-text-wrapper"><span className="enable-serverstats-module-button-text">Cancel & Reset</span></div>
                                    </div>
                                    <h2 className='enable-serverstats-module-title'>
                                        Enable the Serverstats Module
                                    </h2>
                                </div>
                            </div>
                            <div className='enable-serverstats-module-interactions-wrapper'>
                                <div className='submit'>
                                    <GenericButton button_text="Enable" id="enable" on_click_action={set_module_status} />
                                </div>
                            </div>
                        </div>
                    </div>
                :
                <Loading loading_size='s' />
            }
        </div>
    );
};

export default DashboardModulesServerstatsContent;