import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import { toast } from 'react-toastify'
import axios from 'axios'
import Loading from '../../../components/loading'
import GenericButton from "../../../components/buttons/generic_button"
import RefreshButton from "../../../components/buttons/refresh_button"

import '../../../styles/pages/dashboard/functions/tagsContent.css'
import '../../../js/sticky_tag_bars.js'
import config from '../../../config'

const DashboardFunctionsTagsContent = () => {
    const [cookies] = useCookies(['access_token'])

    const [serverTags, setServerTags] = useState([])
    const [currentPage, setCurrentPage] = useState(1)

    const [newTagName, setNewTagName] = useState("")
    const [newTagContent, setNewTagContent] = useState("")
    const [editTagName, setEditTagName] = useState("")
    const [editTagContent, setEditTagContent] = useState("")

    const params = useParams()
    const [tagsLoadingStatus, setTagsLoadingStatus] = useState('loading')

    // Popups
    const open_create_tag_popup = async (event) => {
        event.preventDefault()
        var bar = document.getElementById('create-tag-sidebar')
        bar.style.transform = "translateX(0px)"
    }
    const close_create_tag_popup = async (event) => {
        event.preventDefault()
        setNewTagName("")
        setNewTagContent("")
        var bar = document.getElementById('create-tag-sidebar')
        bar.style.transform = "translateX(+502px)"
    }
    const open_edit_tag_popup = async (event) => {
        event.preventDefault()
        setEditTagName(event.target.id)
        var bar = document.getElementById('edit-tag-sidebar')
        bar.style.transform = "translateX(0px)"
    }
    const close_edit_tag_popup = async (event) => {
        event.preventDefault()
        setEditTagContent("")
        setEditTagName("")
        var bar = document.getElementById('edit-tag-sidebar')
        bar.style.transform = "translateX(+502px)"
    }

    // FIELD CHANGES
    const on_field_change = async (event) => {
        event.preventDefault();
        if (event.target.name === "new-tag-name") {
            setNewTagName(event.target.value)
        } else if (event.target.name === "new-tag-content") {
            setNewTagContent(event.target.value)
        } else if (event.target.name === "edit-tag-content") {
            setEditTagContent(event.target.value)
        }
    }

    // Button interactions
    const create_new_tag = async (event) => {
        event.preventDefault();
        var new_tag = {}

        if (newTagName === "") {
            toast.error("You must insert a name for the new tag!")
            return
        }
        if (newTagContent === "") {
            toast.error("You must insert content for the new tag!")
            return
        }
        
        new_tag.guild_id = params.guild_id
        new_tag.action_type = "create"
        new_tag.name = newTagName
        new_tag.content = newTagContent

        const options = {
            url: `${config["api_base_url"]}/guilds/${params.guild_id}/functions/tags`,
            method: 'POST',
            headers: {
                access_token: cookies["access_token"]
            },
            data: new_tag
          };

          axios(options)
          .then(response => {
              if (response.status === 202) {
                  window.location.reload()
              } else if (response.status === 409) {
                  toast.error("A tag with that name already exists!")
              }
          })
          .catch(error => {
              console.log(error)
              if (error.response.status === 429) {
                toast.error("Rate limit reached! Please slow down!")
                return
            }
              toast.error("Something went wrong while creating the new tag!")
          });
    }

    const edit_tag = async (event) => {
        event.preventDefault()
        
        var edit_tag = {}

        if (editTagContent === "") {
            toast.error("You must insert new content for the tag!")
            return
        }

        edit_tag.content = editTagContent
        edit_tag.guild_id = params.guild_id
        edit_tag.action_type = "edit"
        edit_tag.name = editTagName

        const options = {
            url: `${config["api_base_url"]}/guilds/${params.guild_id}/functions/tags/${editTagName}`,
            method: 'PUT',
            headers: {
                access_token: cookies["access_token"]
            },
            data: edit_tag
          };

          axios(options)
          .then(response => {
              if (response.status === 202) {
                  window.location.reload()
              }
          })
          .catch(error => {
              console.log(error)
              if (error.response.status === 429) {
                toast.error("Rate limit reached! Please slow down!")
                return
            }
              toast.error("Something went wrong while edit the tag!")
          });
    }

    const delete_tag = async (event) => {
        event.preventDefault()
        
        var delete_tag = {}
        delete_tag.name = event.target.id
        delete_tag.guild_id = params.guild_id
        delete_tag.action_type = "delete"

        const options = {
            url: `${config["api_base_url"]}/guilds/${params.guild_id}/functions/tags/${event.target.id}`,
            method: 'DELETE',
            headers: {
                access_token: cookies["access_token"]
            },
            data: delete_tag
          };

        axios(options)
          .then(response => {
              if (response.status === 202) {
                  window.location.reload()
              } else if (response.status === 500) {
                  toast.error("Something went wrong while trying to delete the tag!")
              }
          })
          .catch(error => {
              console.log(error)
              if (error.response.status === 429) {
                toast.error("Rate limit reached! Please slow down!")
                return
            }
              toast.error("Something went wrong while deleting the tag!")
          });
    }

    // Refresh data
    const refresh_data_table = async (event) => {
        event.preventDefault();
        setCurrentPage(1)
        setServerTags([])
        window.location.reload()
    }

    const get_extra_data = async (event) => {
        event.preventDefault();
        get_tags_paged()
    }

    // Get data
    const get_tags_paged = async () => {
        setTagsLoadingStatus("loading")
        const options = {
            url: `${config["api_base_url"]}/guilds/${params.guild_id}/functions/tags?page=${currentPage}`,
            method: 'GET',
            headers: {
                access_token: cookies["access_token"]
            }
          };

        axios(options)
        .then(response => {
            if (response.status === 200) {
                if (response["data"]["data"].length === 0) {
                    setCurrentPage(currentPage)
                } else {
                    setCurrentPage(currentPage+1)
                    if (serverTags.length === 0 ) {
                        setServerTags(response["data"]["data"])
                    } else {
                        var newServerTags = serverTags.concat(response["data"]["data"])
                        setServerTags(newServerTags)
                    }
                }
                setTagsLoadingStatus("success")
            }
        })
        .catch(error => {
            console.log(error)
            setTagsLoadingStatus("error")
            if (error.response.status === 429) {
                toast.error("Rate limit reached! Please slow down!")
                return
            }
            toast.error("Something went wrong while fetching the servers tags!")
        });
    }

    useEffect(() => {
        get_tags_paged()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return(
        <div className='dashboard-functions-tags-content-inner-wrapper'>
            { tagsLoadingStatus === 'success' ? 
                <div className='dashboard-functions-tags-table-container'>
                    <div className='dashboard-functions-tags-table-general-interactions-wrapper'>
                        <div className='dashboard-functions-tags-table-data'>
                            <RefreshButton on_click_action={refresh_data_table} />
                        </div>
                        <div className='dashboard-functions-tags-table-tag'>
                            <GenericButton button_text="Create tag" on_click_action={open_create_tag_popup} />
                        </div>
                    </div>
                    { serverTags.length === 0 ? 
                        <div className='dashboard-functions-tags-table-no-entries-wrapper'>
                            This server does not have any entries yet!
                        </div>
                        :
                        <div className='dashboard-functions-tags-table-table-wrapper'>
                            <div className="dashboard-functions-tags-table">
                                <div className="dashboard-functions-tags-table-titles">
                                    <div className="dashboard-functions-tags-table-titles-wrapper">
                                        <div className="dashboard-functions-tags-table-titles-name-wrapper">
                                            <p className="dashboard-functions-tags-table-titles-name">Tag name</p>
                                        </div>
                                        <div className="dashboard-functions-tags-table-titles-content-wrapper">
                                            <p className="dashboard-functions-tags-table-titles-content">Tag content</p>
                                        </div>
                                        <div className="dashboard-functions-tags-table-titles-interactions-wrapper">
                                            <p className="dashboard-functions-tags-table-titles-interactions">Interactions</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="dashboard-functions-tags-table-content">
                                    { serverTags.map(tag => 
                                        <div className="tag-entry-wrapper" key={tag.name}>
                                            <div className="tag-entry-name-wrapper">
                                                <p className="tag-entry-name">{ tag.name }</p>
                                            </div>
                                            <div className="tag-entry-content-wrapper">
                                                <p className="tag-entry-content">{ tag.content }</p>
                                            </div>
                                            <div className="tag-entry-interactions-wrapper">
                                                <div className="tag-entry-interactions-inner-wrapper">
                                                    <div className="tag-entry-interactions-form-edit">
                                                        <span className="tag-entry-interactions-edit-text" id={tag.name} onClick={open_edit_tag_popup}>Edit</span>
                                                    </div>
                                                    <div className="tag-entry-interactions-edit-text-splitter">|</div>
                                                    <div className="tag-entry-interactions-form-delete">
                                                        <span className="tag-entry-interactions-delete-text" id={tag.name} onClick={delete_tag}>Delete</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className='dashboard-functions-tags-table-load-extra'>
                                <GenericButton button_text="Load extra tags" on_click_action={get_extra_data} />
                            </div>
                        </div>
                    }
                    <div className='create-tag-sidebar' id="create-tag-sidebar">
                        <div className='create-tag-wrapper'>
                            <div className='create-tag-cancel'>
                                <div className="create-tag-back-button" onClick={close_create_tag_popup}>
                                    <div className="create-tag-button-icon"><span className="material-symbols-outlined" id="tag-close-icon">close</span></div>
                                    <div className="create-tag-button-text-wrapper"><span className="create-tag-button-text">Cancel & Reset</span></div>
                                </div>
                                <h2 className='create-tag-title'>
                                    New tag
                                </h2>
                            </div>
                            <div className='create-tag-name'>
                                <h3 className='create-tag-name-label'>
                                    Tag name
                                </h3>
                                <input className='create-tag-name-input' id='create-tag-name-input' name='new-tag-name' placeholder='test' onChange={on_field_change} value={newTagName}/>
                            </div>
                            <div className='create-tag-content'>
                                <h3 className='create-tag-content-label'>
                                    Tag content
                                </h3>
                                <textarea rows={15} className='create-tag-content-input' id='create-tag-content-input' name='new-tag-content' placeholder='**markdown content**' onChange={on_field_change} value={newTagContent}/>
                            </div>
                        </div>
                        <div className='create-tag-interactions-wrapper'>
                            <div className='submit'>
                                <GenericButton button_text="Create tag" on_click_action={create_new_tag} />
                            </div>
                        </div>
                    </div>
                    <div className='edit-tag-sidebar' id="edit-tag-sidebar">
                        <div className='edit-tag-wrapper'>
                            <div className='edit-tag-cancel'>
                                <div className="edit-tag-back-button" onClick={close_edit_tag_popup}>
                                    <div className="edit-tag-button-icon"><span className="material-symbols-outlined" id="tag-close-icon">close</span></div>
                                    <div className="edit-tag-button-text-wrapper"><span className="edit-tag-button-text">Cancel & Reset</span></div>
                                </div>
                                <h2 className='edit-tag-title'>
                                    Edit tag
                                </h2>
                            </div>
                            <div className='edit-tag-content'>
                                <h3 className='edit-tag-content-label'>
                                    Tag content
                                </h3>
                                <textarea rows={15} className='edit-tag-content-input' id='edit-tag-content-input' name='edit-tag-content' onChange={on_field_change} value={editTagContent}/>
                            </div>
                        </div>
                        <div className='edit-tag-interactions-wrapper'>
                            <div className='submit'>
                                <GenericButton button_text="Edit tag" on_click_action={edit_tag} />
                            </div>
                        </div>
                    </div>
                </div>            
                :
                <Loading loading_size='s' />
            }
        </div>
    );
};

export default DashboardFunctionsTagsContent;