import '../styles/components/loading.css'

function Loading(props) {
    var type = props.loading_size
    
    if (type === "xs") {
        return(
            <div className='loading-container'>
                <div className='loading-xs'></div>
            </div>
        )
    }
    if (type === "s") {
        return(
            <div className='loading-container'>
                <div className='loading-s'></div>
            </div>
        )
    }
    if (type === "m") {
        return(
            <div className='loading-container'>
                <div className='loading-m'></div>
            </div>
        )
    }
    if (type === "l") {
        return(
            <div className='loading-container'>
                <div className='loading-l'></div>
            </div>
        )
    }
}

export default Loading