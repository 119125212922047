import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import { toast } from 'react-toastify'
import axios from 'axios'
import Loading from '../../../components/loading'
import GenericButton from "../../../components/buttons/generic_button"
import RefreshButton from "../../../components/buttons/refresh_button"
import ReminderEntry from "../../../components/dashboard/functions/ReminderEntry"

import '../../../styles/pages/dashboard/functions/remindersContent.css'
import '../../../js/sticky_reminders_bars.js'
import config from '../../../config'

const DashboardFunctionsRemindersContent = () => {
    const [cookies] = useCookies(['access_token'])

    const [serverReminders, setServerReminders] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [guildSettings, setGuildSettings] = useState({})
    const [guildChannels, setGuildChannels] = useState([])
    const [guildMembers, setGuildMembers] = useState([])

    const [newReminderEndtime, setNewReminderEndtime] = useState("")
    const [newReminderDestinationId, setNewReminderDestinationId] = useState("")
    const [newReminderDescription, setNewReminderDescription] = useState("")
    const [newReminderTargetuserId, setNewReminderTargetuserId] = useState("")
    const [newReminderIsChannel, setNewReminderIsChannel] = useState(true)
    const [newReminderLinkedMessageLink, setNewReminderLinkedMessageLink] = useState("")

    const params = useParams()
    const [remindersLoadingStatus, setRemindersLoadingStatus] = useState('loading')
    const [settingsLoadingStatus, setSettingsLoadingStatus] = useState('loading')
    const [channelsLoadingStatus, setChannelsLoadingStatus] = useState('loading')
    const [membersLoadingStatus, setMembersLoadingStatus] = useState('loading')

    // Popups
    const open_create_reminder_popup = async (event) => {
        event.preventDefault()
        var bar = document.getElementById('create-reminder-sidebar')
        bar.style.transform = "translateX(0px)"
    }
    const close_create_reminder_popup = async (event) => {
        event.preventDefault()
        setNewReminderEndtime("")
        setNewReminderDestinationId("")
        setNewReminderDescription("")
        setNewReminderTargetuserId("")
        setNewReminderLinkedMessageLink("")
        setNewReminderIsChannel(true)
        var bar = document.getElementById('create-reminder-sidebar')
        bar.style.transform = "translateX(+502px)"
    }

    // FIELD CHANGES
    const on_field_change = async (event) => {
        event.preventDefault();
        if (event.target.name === "new-reminder-duration") {
            setNewReminderEndtime(event.target.value)
        } else if (event.target.name === "new-reminder-destination-id") {
            setNewReminderDestinationId(event.target.value)
        } else if (event.target.name === "new-reminder-description") {
            setNewReminderDescription(event.target.value)
        } else if (event.target.name === "new-reminder-target-user-id") {
            setNewReminderTargetuserId(event.target.value)
        } else if (event.target.name === "new-reminder-linked-message-link") {
            setNewReminderLinkedMessageLink(event.target.value)
        } else if (event.target.name === "create-reminder-is-channel") {
            var create_reminder_is_channel_input = document.getElementById("guild-settings-item-checkbox-create-reminder-is-channel")
            var is_channel = create_reminder_is_channel_input.checked
            setNewReminderIsChannel(is_channel)
        }
    }

    // Buttons presses
    const create_reminder = async (event) => {
        event.preventDefault()
        if (newReminderEndtime === "") {
            toast.error("You need to fill in a wait duration!")
            return
        } else {
            const reminderEndtimeRegex = /^(?!.*[dhmsDHMS]{2})\d+[dhmsDHMS]$/;
            const valid = reminderEndtimeRegex.test(String(newReminderEndtime));
            if (!valid) {
                toast.error("The provided wait duration is not valid. Please provide in format: {delay}{d/h/m/s}! It does not support 2d3m!")
                return
            }
        }
        if (newReminderDestinationId === "") {
            toast.error("You need to select a destination!")
            return
        }
        if (newReminderTargetuserId === "") {
            toast.error("You need to select a target user!")
            return
        }
        
        var new_reminder = {}
        new_reminder.action_type = "create"
        new_reminder.guild_id = params.guild_id
        new_reminder.endtime = newReminderEndtime
        new_reminder.destination_id = newReminderDestinationId
        new_reminder.description = newReminderDescription
        new_reminder.target_user_id = newReminderTargetuserId
        new_reminder.linked_message_link = newReminderLinkedMessageLink

        const options = {
            url: `${config["api_base_url"]}/guilds/${params.guild_id}/functions/reminders`,
            method: 'POST',
            headers: {
                access_token: cookies["access_token"]
            },
            data: new_reminder,
          };

          axios(options)
          .then(response => {
              if (response.status === 202) {
                window.location.reload()
              }
          })
          .catch(error => {
              console.log(error)
              if (error.response.status === 429) {
                toast.error("Rate limit reached! Please slow down!")
                return
            }
              toast.error("Something went wrong while creating the reminder!")
          });
    }

    // Refresh data
    const refresh_data = async (event) => {
        event.preventDefault();
        setServerReminders([])
        setCurrentPage(1)
        window.location.reload()
    }

    // Get data
    const get_settings = async () => {
        setSettingsLoadingStatus("loading")
        const options = {
            url: `${config["api_base_url"]}/guilds/${params.guild_id}/settings`,
            method: 'GET',
            headers: {
                access_token: cookies["access_token"]
            }
          };

          axios(options)
          .then(response => {
              if (response.status === 200) {
                  setGuildSettings(response["data"]["data"])
                  setSettingsLoadingStatus("success")
              }
          })
          .catch(error => {
              console.log(error)
              setSettingsLoadingStatus("error")
              if (error.response.status === 429) {
                toast.error("Rate limit reached! Please slow down!")
                return
            }
              toast.error("Something went wrong while getting the servers settings!")
          });
    }

    const get_extra_data = async (event) => {
        event.preventDefault();
        get_reminders_paged()
    }

    const get_reminders_paged = async () => {
        setRemindersLoadingStatus("loading")
        const options = {
            url: `${config["api_base_url"]}/guilds/${params.guild_id}/functions/reminders?page=${currentPage}`,
            method: 'GET',
            headers: {
                access_token: cookies["access_token"]
            }
          };

        axios(options)
        .then(response => {
            if (response.status === 200) {
                if (response["data"]["data"].length === 0) {
                    setCurrentPage(currentPage)
                } else {
                    setCurrentPage(currentPage+1)
                    if (serverReminders.length === 0 ) {
                        setServerReminders(response["data"]["data"])
                    } else {
                        var newServerReminders = serverReminders.concat(response["data"]["data"])
                        setServerReminders(newServerReminders)
                    }
                }
                setRemindersLoadingStatus("success")
            }
        })
        .catch(error => {
            console.log(error)
            setRemindersLoadingStatus("error")
            if (error.response.status === 429) {
                toast.error("Rate limit reached! Please slow down!")
                return
            }
            toast.error("Something went wrong while fetching the servers reminders!")
        });
    }

    const get_channels = async () => {
        setChannelsLoadingStatus("loading")
        const options = {
            url: `${config["api_base_url"]}/guilds/${params.guild_id}/channels?types=text`,
            method: 'GET',
            headers: {
                access_token: cookies["access_token"]
            },
          };

          axios(options)
          .then(response => {
              if (response.status === 200) {
                  setGuildChannels(response["data"][0]["data"])
                  setChannelsLoadingStatus("success")
              }
          })
          .catch(error => {
              console.log(error)
              setChannelsLoadingStatus("error")
              if (error.response.status === 429) {
                toast.error("Rate limit reached! Please slow down!")
                return
            }
              toast.error("Something went wrong while getting the servers channels!")
          });
    }

    const get_members = async () => {
        setMembersLoadingStatus("loading")
        const options = {
            url: `${config["api_base_url"]}/guilds/${params.guild_id}/members`,
            method: 'GET',
            headers: {
                access_token: cookies["access_token"]
            },
          };

          axios(options)
          .then(response => {
              if (response.status === 200) {
                  setGuildMembers(response["data"]["data"])
                  setMembersLoadingStatus("success")
              }
          })
          .catch(error => {
              console.log(error)
              setMembersLoadingStatus("error")
              if (error.response.status === 429) {
                toast.error("Rate limit reached! Please slow down!")
                return
            }
              toast.error("Something went wrong while getting the servers channels!")
          });
    }

    useEffect(() => {
        get_settings()
        get_reminders_paged()
        get_channels()
        get_members()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return(
        <div className='dashboard-functions-reminders-content-inner-wrapper'>
            { remindersLoadingStatus === 'success' && settingsLoadingStatus === 'success' && channelsLoadingStatus === 'success' && membersLoadingStatus === 'success' ? 
                <div className='dashboard-functions-reminder-items-container'>
                    <div className='dashboard-functions-reminder-general-interactions-wrapper'>
                        <div className='dashboard-functions-reminder-data'>
                            <RefreshButton on_click_action={refresh_data} />
                        </div>
                        <div className='dashboard-functions-reminder'>
                            <GenericButton button_text="Create reminder" on_click_action={open_create_reminder_popup} />
                        </div>
                    </div>
                    <div className='reminders-container'>
                        { serverReminders.length === 0 ? 
                            <div className='dashboard-functions-reminders-no-entries-wrapper'>
                                This server does not have any active reminders yet!
                            </div>
                            :
                            <div className='reminders-entry-wrapper'>
                                <div className='reminders-entry-inner-wrapper'>
                                    { serverReminders.map(reminder =>
                                        <ReminderEntry key={reminder.id} reminder={reminder} guild_settings={guildSettings} />
                                    )}
                                </div>
                                <div className='dashboard-functions-reminders-load-extra'>
                                    <GenericButton type="secondary" button_text="Load extra reminders" on_click_action={get_extra_data} />
                                </div>
                            </div>
                        }
                        <div className='create-reminder-sidebar' id="create-reminder-sidebar">
                            <div className='create-reminder-wrapper'>
                                <div className='create-reminder-cancel'>
                                    <div className="create-reminder-back-button" onClick={close_create_reminder_popup}>
                                        <div className="create-reminder-button-icon"><span className="material-symbols-outlined" id="reminder-close-icon">close</span></div>
                                        <div className="create-reminder-button-text-wrapper"><span className="create-tag-button-text">Cancel & Reset</span></div>
                                    </div>
                                    <h2 className='create-reminder-title'>
                                        New reminder
                                    </h2>
                                </div>
                                <div className='create-reminder-duration'>
                                    <h3 className='create-reminder-duration-label'>
                                        Wait duration (d/h/m/s)*
                                    </h3>
                                    <input className='create-reminder-duration-input' id='create-reminder-duration-input' name='new-reminder-duration' placeholder='2h' onChange={on_field_change} value={newReminderEndtime} />
                                </div>
                                <div className='create-reminder-destination-id'>
                                    <h3 className='create-reminder-destination-id-label'>
                                        Destination*
                                    </h3>
                                    { newReminderIsChannel ?
                                        <div key="channel">
                                            <div className='guild-settings-item-checkbox-create-reminder-is-channel-wrapper'>
                                                <label className='guild-settings-item-checkbox-create-reminder-is-channel-label-user'>User</label>
                                                <label className="toggler-wrapper style-1">
                                                    <input id="guild-settings-item-checkbox-create-reminder-is-channel" type="checkbox" name="create-reminder-is-channel" defaultChecked onChange={on_field_change}></input>
                                                    <div className="toggler-slider">
                                                    <div className="toggler-knob"></div>
                                                    </div>
                                                </label>
                                                <label className='guild-settings-item-checkbox-create-reminder-is-channel-label-channel'>Channel</label>
                                            </div>
                                            <select className='create-reminder-destination-id-input' placeholder="channel" id='create-reminder-destination-id-input' name='new-reminder-destination-id' onChange={on_field_change} value={newReminderDestinationId}>
                                                { guildChannels.map(guild_channel => 
                                                    <option key={ String(guild_channel.id) } value={ String(guild_channel.id) }>
                                                        { guild_channel.name }
                                                    </option>
                                                )}
                                                <option key="no-destination" value="">
                                                    No destination selected yet!
                                                </option>
                                            </select>
                                        </div>
                                        :
                                        <div key="user">
                                            <div className='guild-settings-item-checkbox-create-reminder-is-channel-wrapper'>
                                                <label className='guild-settings-item-checkbox-create-reminder-is-channel-label-user'>User</label>
                                                <label className="toggler-wrapper style-1">
                                                    <input id="guild-settings-item-checkbox-create-reminder-is-channel" type="checkbox" name="create-reminder-is-channel" onChange={on_field_change}></input>
                                                    <div className="toggler-slider">
                                                    <div className="toggler-knob"></div>
                                                    </div>
                                                </label>
                                                <label className='guild-settings-item-checkbox-create-reminder-is-channel-label-channel'>Channel</label>
                                            </div>
                                            <select className='create-reminder-destination-id-input' placeholder="user" id='create-reminder-destination-id-input' name='new-reminder-destination-id' onChange={on_field_change} value={newReminderDestinationId}>
                                                { guildMembers.map(guild_member => 
                                                    <option key={ String(guild_member.id) } value={ String(guild_member.id) }>
                                                        { guild_member.name }
                                                    </option>
                                                )}
                                                <option key="no-destination" value="">
                                                    No destination selected yet!
                                                </option>
                                            </select>
                                        </div>
                                    }
                                </div>
                                <div className='create-reminder-target-user-id'>
                                    <h3 className='create-reminder-target-user-id-label'>
                                        Target User*
                                    </h3>
                                    <select className='create-reminder-target-user-id-input' id='create-reminder-target-user-id-input' name='new-reminder-target-user-id' onChange={on_field_change} value={newReminderTargetuserId}>
                                        { guildMembers.map(guild_member => 
                                            <option key={ String(guild_member.id) } value={ String(guild_member.id) }>
                                                { guild_member.name }
                                            </option>
                                        )}
                                        <option key="no-destination" value="">
                                            No target user selected yet!
                                        </option>
                                    </select>
                                </div>
                                <div className='create-reminder-linked-message-link'>
                                    <h3 className='create-reminder-linked-message-link-label'>
                                        Reminder linked message
                                    </h3>
                                    <input className='create-reminder-linked-message-link-input' id='create-reminder-linked-message-link-input' name='new-reminder-linked-message-link' placeholder='https://example.com/' onChange={on_field_change} value={newReminderLinkedMessageLink} />
                                </div>
                                <div className='create-reminder-description'>
                                    <h3 className='create-reminder-description-label'>
                                        Reminder description
                                    </h3>
                                    <textarea rows={15} className='create-reminder-description-input' id='create-reminder-description-input' name='new-reminder-description' placeholder='Some clear explanation' onChange={on_field_change} value={newReminderDescription}/>
                                </div>
                            </div>
                            <div className='create-reminder-interactions-wrapper'>
                                <div className='submit'>
                                    <GenericButton button_text="Create reminder" on_click_action={create_reminder} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <Loading loading_size='s' />
            }
        </div>
    );
};

export default DashboardFunctionsRemindersContent;