import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import { toast } from 'react-toastify'
import axios from 'axios'
import Loading from '../../../components/loading'
import GenericButton from "../../../components/buttons/generic_button"
import RefreshButton from "../../../components/buttons/refresh_button"
import GiveawayEntry from "../../../components/dashboard/functions/GiveawayEntry"

import '../../../styles/pages/dashboard/functions/giveawaysContent.css'
import '../../../js/sticky_giveaways_bars.js'
import config from '../../../config'

const DashboardFunctionsGiveawaysContent = () => {
    const [cookies] = useCookies(['access_token'])

    const [serverGiveaways, setServerGiveaways] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [guildChannels, setGuildChannels] = useState([])
    const [guildSettings, setGuildSettings] = useState({})
    const [guildMembers, setGuildMembers] = useState([])

    const [newGiveawayEndtime, setNewGiveawayEndtime] = useState("")
    const [newGiveawayOwner, setNewGiveawayOwner] = useState("")
    const [newGiveawayChannel, setNewGiveawayChannel] = useState("")
    const [newGiveawayWinnerCount, setNewGiveawayWinnerCount] = useState(0)
    const [newGiveawayPrize, setNewGiveawayPrize] = useState("")
    const [newGiveawayDescription, setNewGiveawayDescription] = useState("")

    const params = useParams()
    const [channelsLoadingStatus, setChannelsLoadingStatus] = useState('loading')
    const [giveawaysLoadingStatus, setGiveawaysLoadingStatus] = useState('loading')
    const [settingsLoadingStatus, setSettingsLoadingStatus] = useState('loading')
    const [membersLoadingStatus, setMembersLoadingStatus] = useState('loading')

    // Popups
    const open_create_giveaway_popup = async (event) => {
        event.preventDefault()
        var bar = document.getElementById('create-giveaway-sidebar')
        bar.style.transform = "translateX(0px)"
    }
    const close_create_giveaway_popup = async (event) => {
        event.preventDefault()
        setNewGiveawayEndtime("")
        setNewGiveawayOwner("")
        setNewGiveawayChannel("")
        setNewGiveawayWinnerCount(0)
        setNewGiveawayPrize("")
        setNewGiveawayDescription("")
        var bar = document.getElementById('create-giveaway-sidebar')
        bar.style.transform = "translateX(+502px)"
    }

    // FIELD CHANGES
    const on_field_change = async (event) => {
        event.preventDefault();
        if (event.target.name === "new-giveaway-duration") {
            setNewGiveawayEndtime(event.target.value)
        } else if (event.target.name === "new-giveaway-owner-id") {
            setNewGiveawayOwner(event.target.value)
        } else if (event.target.name === "new-giveaway-channel-id") {
            setNewGiveawayChannel(event.target.value)
        } else if (event.target.name === "new-giveaway-winner-count") {
            setNewGiveawayWinnerCount(event.target.value)
        } else if (event.target.name === "new-giveaway-prize") {
            setNewGiveawayPrize(event.target.value)
        } else if (event.target.name === "new-giveaway-description") {
            setNewGiveawayDescription(event.target.value)
        }
    }

    // Buttons presses
    const create_giveaway = async (event) => {
        event.preventDefault()
        var new_giveaway = {}
        new_giveaway.action_type = "create"
        if (newGiveawayEndtime === "") {
            toast.error("You need to fill in a wait duration!")
            return
        } else {
            const giveawayEndtimeRegex = /^(?!.*[dhmsDHMS]{2})\d+[dhmsDHMS]$/;
            const valid = giveawayEndtimeRegex.test(String(newGiveawayEndtime));
            if (!valid) {
                toast.error("The provided wait duration is not valid. Please provide in format: {delay}{d/h/m/s}! It does not support 2d3m!")
                return
            } else {
                new_giveaway.endtime = newGiveawayEndtime
            }
        }

        if (newGiveawayOwner === "") {
            toast.error("You must select a owner for this giveaway!")
            return
        }
        new_giveaway.owner_id = String(newGiveawayOwner)

        if (newGiveawayChannel === "") {
            toast.error("You must select a target channel for this giveaway!")
            return
        }
        new_giveaway.channel_id = String(newGiveawayChannel)

        if (newGiveawayWinnerCount === 0 || newGiveawayWinnerCount < 0 || newGiveawayWinnerCount === "") {
            toast.error("You must insert a winner count for this giveaway!")
            return
        }
        new_giveaway.winner_count = String(newGiveawayWinnerCount)
    
        if (newGiveawayPrize === "") {
            toast.error("You must insert a prize for this giveaway!")
            return
        }
        new_giveaway.prize = String(newGiveawayPrize)
        new_giveaway.description = String(newGiveawayDescription)
        new_giveaway.guild_id = params.guild_id

        const options = {
            url: `${config["api_base_url"]}/guilds/${params.guild_id}/functions/giveaways`,
            method: 'POST',
            headers: {
                access_token: cookies["access_token"]
            },
            data: new_giveaway,
          };

          axios(options)
          .then(response => {
              if (response.status === 202) {
                window.location.reload()
              }
          })
          .catch(error => {
              console.log(error)
              if (error.response.status === 429) {
                toast.error("Rate limit reached! Please slow down!")
                return
            }
              toast.error("Something went wrong while creating the giveaway!")
          });
    }

    // Refresh data
    const refresh_data = async (event) => {
        event.preventDefault();
        setServerGiveaways([])
        setCurrentPage(1)
        window.location.reload()
    }

    // Get data
    const get_extra_data = async (event) => {
        event.preventDefault();
        get_giveaways_paged()
    }

    const get_giveaways_paged = async () => {
        setGiveawaysLoadingStatus("loading")
        const options = {
            url: `${config["api_base_url"]}/guilds/${params.guild_id}/functions/giveaways?page=${currentPage}`,
            method: 'GET',
            headers: {
                access_token: cookies["access_token"]
            }
          };

        axios(options)
        .then(response => {
            if (response.status === 200) {
                if (response["data"]["data"].length === 0) {
                    setCurrentPage(currentPage)
                } else {
                    setCurrentPage(currentPage+1)
                    if (serverGiveaways.length === 0 ) {
                        setServerGiveaways(response["data"]["data"])
                    } else {
                        var newServerGiveaways = serverGiveaways.concat(response["data"]["data"])
                        setServerGiveaways(newServerGiveaways)
                    }
                }
                setGiveawaysLoadingStatus("success")
            }
        })
        .catch(error => {
            console.log(error)
            setGiveawaysLoadingStatus("error")
            if (error.response.status === 429) {
                toast.error("Rate limit reached! Please slow down!")
                return
            }
            toast.error("Something went wrong while fetching the servers giveaways!")
        });
    }

    const get_channels = async () => {
        setChannelsLoadingStatus("loading")
        const options = {
            url: `${config["api_base_url"]}/guilds/${params.guild_id}/channels?types=text`,
            method: 'GET',
            headers: {
                access_token: cookies["access_token"]
            },
          };

          axios(options)
          .then(response => {
              if (response.status === 200) {
                  setGuildChannels(response["data"][0]["data"])
                  setChannelsLoadingStatus("success")
              }
          })
          .catch(error => {
              console.log(error)
              setChannelsLoadingStatus("error")
              if (error.response.status === 429) {
                toast.error("Rate limit reached! Please slow down!")
                return
            }
              toast.error("Something went wrong while getting the servers channels!")
          });
    }

    const get_settings = async () => {
        setSettingsLoadingStatus("loading")
        const options = {
            url: `${config["api_base_url"]}/guilds/${params.guild_id}/settings`,
            method: 'GET',
            headers: {
                access_token: cookies["access_token"]
            }
          };

          axios(options)
          .then(response => {
              if (response.status === 200) {
                  setGuildSettings(response["data"]["data"])
                  setSettingsLoadingStatus("success")
              }
          })
          .catch(error => {
              console.log(error)
              setSettingsLoadingStatus("error")
              if (error.response.status === 429) {
                toast.error("Rate limit reached! Please slow down!")
                return
            }
              toast.error("Something went wrong while getting the servers settings!")
          });
    }

    const get_members = async () => {
        setMembersLoadingStatus("loading")
        const options = {
            url: `${config["api_base_url"]}/guilds/${params.guild_id}/members`,
            method: 'GET',
            headers: {
                access_token: cookies["access_token"]
            },
          };

          axios(options)
          .then(response => {
              if (response.status === 200) {
                  setGuildMembers(response["data"]["data"])
                  setMembersLoadingStatus("success")
              }
          })
          .catch(error => {
              console.log(error)
              setMembersLoadingStatus("error")
              if (error.response.status === 429) {
                toast.error("Rate limit reached! Please slow down!")
                return
            }
              toast.error("Something went wrong while getting the servers channels!")
          });
    }

    useEffect(() => {
        get_giveaways_paged()
        get_channels()
        get_settings()
        get_members()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return(
        <div className='dashboard-functions-giveaways-content-inner-wrapper'>
            { channelsLoadingStatus === 'success' && giveawaysLoadingStatus === 'success' && membersLoadingStatus === 'success' && settingsLoadingStatus === 'success' ? 
                <div className='dashboard-functions-giveaways-items-container'>
                    <div className='dashboard-functions-giveaways-general-interactions-wrapper'>
                        <div className='dashboard-functions-giveaways-data'>
                            <RefreshButton on_click_action={refresh_data} />
                        </div>
                        <div className='dashboard-functions-giveaways'>
                            <GenericButton button_text="Create giveaway" on_click_action={open_create_giveaway_popup} />
                        </div>
                    </div>
                    <div className='giveaways-container'>
                        { serverGiveaways.length === 0 ? 
                            <div className='dashboard-functions-giveaways-no-entries-wrapper'>
                                This server does not have any active giveaways yet!
                            </div>
                            :
                            <div className='giveaways-entry-wrapper'>
                                <div className='giveaways-entry-inner-wrapper'>
                                    { serverGiveaways.map(giveaway =>
                                        <GiveawayEntry key={giveaway.id} giveaway={giveaway} guild_settings={guildSettings} />
                                    )}
                                </div>
                                <div className='dashboard-functions-giveaways-load-extra'>
                                    <GenericButton type="secondary" button_text="Load extra giveaways" on_click_action={get_extra_data} />
                                </div>
                            </div>
                        }
                        <div className='create-giveaway-sidebar' id="create-giveaway-sidebar">
                            <div className='create-giveaway-wrapper'>
                                <div className='create-giveaway-cancel'>
                                    <div className="create-giveaway-back-button" onClick={close_create_giveaway_popup}>
                                        <div className="create-giveaway-button-icon"><span className="material-symbols-outlined" id="giveaway-close-icon">close</span></div>
                                        <div className="create-giveaway-button-text-wrapper"><span className="create-giveaway-button-text">Cancel & Reset</span></div>
                                    </div>
                                    <h2 className='create-giveaway-title'>
                                        New giveaway
                                    </h2>
                                </div>
                                <div className='create-giveaway-duration'>
                                    <h3 className='create-giveaway-duration-label'>
                                        Giveaway duration (d/h/m/s)*
                                    </h3>
                                    <input className='create-giveaway-duration-input' id='create-giveaway-duration-input' name='new-giveaway-duration' placeholder='2h' onChange={on_field_change} value={newGiveawayEndtime} />
                                </div>
                                <div className='create-giveaway-owner-id'>
                                    <h3 className='create-giveaway-owner-id-label'>
                                        Owner*
                                    </h3>
                                    <select className='create-giveaway-owner-id-input' id='create-giveaway-owner-id-input' name='new-giveaway-owner-id' onChange={on_field_change} value={newGiveawayOwner}>
                                        { guildMembers.map(guild_member => 
                                            <option key={ String(guild_member.id) } value={ String(guild_member.id) }>
                                                { guild_member.name }
                                            </option>
                                        )}
                                        <option key="no-owner" value="">
                                            No owner selected yet!
                                        </option>
                                    </select>
                                </div> 
                                <div className='create-giveaway-channel-id'>
                                    <h3 className='create-giveaway-channel-id-label'>
                                        Channel*
                                    </h3>
                                    <select className='create-giveaway-channel-id-input' id='create-giveaway-channel-id-input' name='new-giveaway-channel-id' onChange={on_field_change} value={newGiveawayChannel}>
                                        { guildChannels.map(guild_channel => 
                                            <option key={ String(guild_channel.id) } value={ String(guild_channel.id) }>
                                                { guild_channel.name }
                                            </option>
                                        )}
                                        <option key="no-channel" value="">
                                            No channel selected yet!
                                        </option>
                                    </select>
                                </div>
                                <div className='create-giveaway-winner-count'>
                                    <h3 className='create-giveaway-winner-count-label'>
                                        Winner count*
                                    </h3>
                                    <input type="number" className='create-giveaway-winner-count-input' id='create-giveaway-winner-count-input' name='new-giveaway-winner-count' placeholder='1' onChange={on_field_change} value={newGiveawayWinnerCount}/>
                                </div>
                                <div className='create-giveaway-prize'>
                                    <h3 className='create-giveaway-prize-label'>
                                        Prize*
                                    </h3>
                                    <textarea row={2} className='create-giveaway-prize-input' id='create-giveaway-prize-input' name='new-giveaway-prize' placeholder='A prize' onChange={on_field_change} value={newGiveawayPrize}/>
                                </div>
                                <div className='create-giveaway-description'>
                                    <h3 className='create-giveaway-description-label'>
                                        Description
                                    </h3>
                                    <textarea row={2} className='create-giveaway-description-input' id='create-giveaway-description-input' name='new-giveaway-description' placeholder='A description' onChange={on_field_change} value={newGiveawayDescription}/>
                                </div>
                            </div>
                            <div className='create-giveaway-interactions-wrapper'>
                                <div className='submit'>
                                    <GenericButton button_text="Create giveaway" on_click_action={create_giveaway} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <Loading loading_size='s' />
            }
        </div>
    );
};

export default DashboardFunctionsGiveawaysContent;