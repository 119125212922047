import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import { toast } from 'react-toastify'
import axios from 'axios'
import Loading from '../../../components/loading'
import GenericButton from "../../../components/buttons/generic_button"
import RefreshButton from "../../../components/buttons/refresh_button"

import '../../../styles/pages/dashboard/modules/tempchannelsContent.css'
import '../../../js/sticky_modules_tempchannels_bars.js'
import config from '../../../config'

const DashboardModulesTempchannelsContent = () => {
    const [cookies] = useCookies(['access_token'])

    const [moduleTempchannelsStatus, setModuleTempchannelsStatus] = useState(false)

    const [guildCategoryChannels, setGuildCategoryChannels] = useState([])
    const [tempchannelSettings, setTempchannelSettings] = useState({})

    const [enableTempchannelsVoiceCategory, setEnableTempchannelsVoiceCategory] = useState("")
    const [enableTempchannelsVoiceName, setEnableTempchannelsVoiceName] = useState("")
    const [enableTempchannelsTextCreation, setEnableTempchannelsTextCreation] = useState(false)
    const [enableTempchannelsTextCategory, setEnableTempchannelsTextCategory] = useState("")
    const [enableTempchannelsTextName, setEnableTempchannelsTextName] = useState("")

    const [newTempchannelsVoiceCategory, setNewTempchannelsVoiceCategory] = useState("")
    const [newTempchannelsVoiceName, setNewTempchannelsVoiceName] = useState("")
    const [newTempchannelsTextCreation, setNewTempchannelsTextCreation] = useState(false)
    const [newTempchannelsTextCategory, setNewTempchannelsTextCategory] = useState("")
    const [newTempchannelsTextName, setNewTempchannelsTextName] = useState("")

    const params = useParams()
    const [moduleTempchannelsLoadingStatus, setModuleTempchannelsLoadingStatus] = useState('loading')
    const [channelsLoadingStatus, setChannelsLoadingStatus] = useState('loading')
    const [moduleTempchannelsSettingsLoadingStatus, setModuleTempchannelsSettingsLoadingStatus] = useState('loading')

    // FIELD CHANGES
    const on_field_change = async (event) => {
        event.preventDefault();
        if (event.target.name === "enable-tempchannels-voice-category-id") {
            setEnableTempchannelsVoiceCategory(event.target.value)
        }else if (event.target.name === "enable-tempchannels-voice-name") {
            setEnableTempchannelsVoiceName(event.target.value)
        } else if (event.target.name === "enable-tempchannels-module-text-creation-input") {
            if (event.target.id === "on") {
                setEnableTempchannelsTextCreation(true)
            } else if (event.target.id === "off") {
                setEnableTempchannelsTextCreation(false)
            }
        } else if (event.target.name === "enable-tempchannels-text-category-id") {
            setEnableTempchannelsTextCategory(event.target.value)
        } else if (event.target.name === "enable-tempchannels-text-name") {
            setEnableTempchannelsTextName(event.target.value)
        } else if (event.target.name === "new-tempchannels-voice-category-id") {
            setNewTempchannelsVoiceCategory(event.target.value)
        } else if (event.target.name === "new-tempchannels-voice-name") {
            setNewTempchannelsVoiceName(event.target.value)
        } else if (event.target.name === "new-tempchannels-module-text-creation-input") {
            if (event.target.id === "on") {
                setNewTempchannelsTextCreation(true)
            } else if (event.target.id === "off") {
                setNewTempchannelsTextCreation(false)
            }
        } else if (event.target.name === "new-tempchannels-text-category-id") {
            setNewTempchannelsTextCategory(event.target.value)
        } else if (event.target.name === "new-tempchannels-text-name") {
            setNewTempchannelsTextName(event.target.value)
        } 
        
    }

    // Refresh data
    const refresh_data_table = async (event) => {
        event.preventDefault();
        window.location.reload()
    }

    // Module Status
    const open_enable_tempchannels_module_sidebar = async (event) => {
        event.preventDefault()
        var bar = document.getElementById('enable-tempchannels-module-sidebar')
        bar.style.transform = "translateX(0px)"
    }
    const close_enable_tempchannels_module_sidebar = async (event) => {
        event.preventDefault()
        setEnableTempchannelsVoiceCategory("")
        setEnableTempchannelsVoiceName("")
        setEnableTempchannelsTextCreation(false)
        setEnableTempchannelsTextCategory("")
        setEnableTempchannelsTextName("")
        var bar = document.getElementById('enable-tempchannels-module-sidebar')
        bar.style.transform = "translateX(+502px)"
    }

    const get_module_status = async () => {
        setModuleTempchannelsLoadingStatus("loading")
        const options = {
            url: `${config["api_base_url"]}/guilds/${params.guild_id}/modules?module_names=tempchannel`,
            method: 'GET',
            headers: {
                access_token: cookies["access_token"]
            }
          };
        axios(options)
        .then(response => {
            if (response.status === 200) {
                    setModuleTempchannelsStatus(response["data"]["data"]["module_tempchannel"])
                    if (response["data"]["data"]["module_tempchannel"] === true) {
                        get_module_settings()
                    }
                }
                setModuleTempchannelsLoadingStatus("success")
        })
        .catch(error => {
            console.log(error)
            setModuleTempchannelsLoadingStatus("error")
            if (error.response.status === 429) {
                toast.error("Rate limit reached! Please slow down!")
                return
            }
            toast.error("Something went wrong while fetching the module Tempchannels status!")
        });
    }

    const set_module_status = async (event) => {
        var new_status
        if (event.target.id === "enable") {
            new_status = true
        } else if (event.target.id === "disable") {
            new_status = false
        }
        
        if (new_status === true) {
            if (enableTempchannelsVoiceCategory === "") {
                toast.error("You must select a category channel for the temporary voice channels!")
                return
            }
            if (enableTempchannelsVoiceName === "") {
                toast.error("You must insert a name to use for temporary voice channels!")
                return
            }
            if (enableTempchannelsTextCreation === true) {
                if (enableTempchannelsTextCategory === "") {
                    toast.error("You must select a category channel for the temporary text channels!")
                    return
                }
                if (enableTempchannelsTextName === "") {
                    toast.error("You must insert a name to use for temporary text channels!")
                    return
                }
            }
        }

        var data = {}
        data.tempchannel = {}
        data.tempchannel.new_status = new_status
        data.tempchannel.guild_id = params.guild_id
        if (new_status === true) {
            data.tempchannel.voice_category = enableTempchannelsVoiceCategory
            data.tempchannel.voice_name = enableTempchannelsVoiceName
            data.tempchannel.create_text = enableTempchannelsTextCreation
            data.tempchannel.text_category = enableTempchannelsTextCategory
            data.tempchannel.text_name = enableTempchannelsTextName
        }

        const options = {
            url: `${config["api_base_url"]}/guilds/${params.guild_id}/modules`,
            method: 'POST',
            headers: {
                access_token: cookies["access_token"]
            },
            data: data
          };

        axios(options)
        .then(response => {
            if (response.status === 202) {
                if (response["data"]["data"]["tempchannel"]["code"] === 200) {
                    window.location.reload()
                }
            } else {
                toast.error("Something went wrong while updating the Tempchannels module!")
            }
        })
        .catch(error => {
            console.log(error)
            if (error.response.status === 429) {
                toast.error("Rate limit reached! Please slow down!")
                return
            }
            toast.error("Something went wrong while updating the Tempchannels module!")
        });
    }

    // Get data
    const get_category_channels = async () => {
        setChannelsLoadingStatus("loading")
        const options = {
            url: `${config["api_base_url"]}/guilds/${params.guild_id}/channels?types=category`,
            method: 'GET',
            headers: {
                access_token: cookies["access_token"]
            },
          };

          axios(options)
          .then(response => {
              if (response.status === 200) {
                  setGuildCategoryChannels(response["data"][0]["data"])
                  setChannelsLoadingStatus("success")
              }
          })
          .catch(error => {
              console.log(error)
              setChannelsLoadingStatus("error")
              if (error.response.status === 429) {
                toast.error("Rate limit reached! Please slow down!")
                return
            }
              toast.error("Something went wrong while getting the servers channels!")
          });
    }

    const get_module_settings = async () => {
        setModuleTempchannelsSettingsLoadingStatus("loading")
        const options = {
            url: `${config["api_base_url"]}/guilds/${params.guild_id}/modules/tempchannels`,
            method: 'GET',
            headers: {
                access_token: cookies["access_token"]
            },
          };

          axios(options)
          .then(response => {
              if (response.status === 200) {
                  setTempchannelSettings(response["data"]["data"])
                  if (response["data"]["data"]["voice_category"]["id"] === null) {
                    setNewTempchannelsVoiceCategory("None")
                  } else {
                    setNewTempchannelsVoiceCategory(response["data"]["data"]["voice_category"]["id"])
                  }
                  setNewTempchannelsVoiceName(response["data"]["data"]["voice_channel_name"])
                  setNewTempchannelsTextCreation(response["data"]["data"]["create_text"])
                  if (response["data"]["data"]["text_category"]["id"] === null) {
                    setNewTempchannelsTextCategory("None")
                  } else {
                    setNewTempchannelsTextCategory(response["data"]["data"]["text_category"]["id"])
                  }
                  setNewTempchannelsTextName(response["data"]["data"]["text_channel_name"])
                  setModuleTempchannelsSettingsLoadingStatus("success")
              }
          })
          .catch(error => {
              console.log(error)
              setModuleTempchannelsSettingsLoadingStatus("error")
              if (error.response.status === 429) {
                toast.error("Rate limit reached! Please slow down!")
                return
            }
              toast.error("Something went wrong while getting the settings of the Tempchannel module!")
          });
    }

    const reset_module_settings = async () => {
        setNewTempchannelsVoiceCategory(tempchannelSettings.voice_category)
        setNewTempchannelsVoiceName(tempchannelSettings.voice_channel_name)
        setNewTempchannelsTextCreation(tempchannelSettings.create_text)
        setNewTempchannelsTextCategory(tempchannelSettings.text_category)
        setNewTempchannelsTextName(tempchannelSettings.text_channel_name)
    }

    const set_module_settings = async () => {
        var data = {}
        data.guild_id = params.guild_id
        data.new_voice_category = newTempchannelsVoiceCategory
        data.new_voice_name = newTempchannelsVoiceName
        data.new_create_text = newTempchannelsTextCreation
        if (newTempchannelsTextCreation === false) {
            data.new_text_category = null
            data.new_text_name = null
        } else {
            if (newTempchannelsTextCategory === "None") {
                toast.error("You must select a category channel for the temporary text channels!")
                return
            }
            if (newTempchannelsTextName === "None") {
                toast.error("You must insert a name to use for temporary text channels!")
                return
            }
            data.new_text_category = newTempchannelsTextCategory
            data.new_text_name = newTempchannelsTextName
        }

        const options = {
            url: `${config["api_base_url"]}/guilds/${params.guild_id}/modules/tempchannels`,
            method: 'PUT',
            headers: {
                access_token: cookies["access_token"]
            },
            data: data,
          };

          axios(options)
          .then(response => {
              if (response.status === 202) {
                  window.location.reload()
              }
          })
          .catch(error => {
              console.log(error)
              if (error.response.status === 429) {
                toast.error("Rate limit reached! Please slow down!")
                return
            }
              toast.error("Something went wrong while changing the settings of the Tempchannel module!")
          });
    }

    useEffect(() => {
        get_module_status()
        get_category_channels()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return(
        <div className='dashboard-modules-tempchannels-content-inner-wrapper'>
            { moduleTempchannelsLoadingStatus === 'success' && channelsLoadingStatus === 'success' ?
                moduleTempchannelsStatus === true ?
                    moduleTempchannelsSettingsLoadingStatus === 'success' ?  
                        <div className='dashboard-modules-tempchannels-enabled-container'> 
                            <div className='dashboard-modules-tempchannels-enabled-disable-wrapper'>
                                Click <label id="disable" onClick={set_module_status} className='dashboard-modules-tempchannels-enabled-disable-button'>here</label> if you want to disable this module!
                            </div>
                            <div className='dashboard-modules-tempchannels-enabled-settings-container'>
                                <div className='dashboard-modules-tempchannels-enabled-settings-reminder'>Don't forget to save your changes!</div>
                                <div className='dashboard-modules-tempchannels-enabled-setting'>
                                    <div className='dashboard-modules-tempchannels-enabled-setting-label'>
                                        Voice category
                                    </div>
                                    <div className='dashboard-modules-tempchannels-enabled-setting-description'>The category where temporary voice channels are created in.</div>
                                    <hr  className='dashboard-modules-tempchannels-enabled-setting-devider' />
                                    <select className='dashboard-modules-tempchannels-enabled-setting-input' id='dashboard-modules-tempchannels-voice-category-id-input' name='new-tempchannels-voice-category-id' onChange={on_field_change} value={newTempchannelsVoiceCategory}>
                                        { guildCategoryChannels.map(guild_channel => 
                                            <option key={ String(guild_channel.id) } value={ String(guild_channel.id) }>
                                                { guild_channel.name }
                                            </option>
                                        )}
                                        <option key="no-voice-category" value="None">
                                            No voice category selected yet!
                                        </option>
                                    </select>
                                </div>
                                <div className='dashboard-modules-tempchannels-enabled-setting'>
                                    <div className='dashboard-modules-tempchannels-enabled-setting-label'>
                                        Voice channel name
                                    </div>
                                    <div className='dashboard-modules-tempchannels-enabled-setting-description'>The name used for temporary voice channels.</div>
                                    <hr  className='dashboard-modules-tempchannels-enabled-setting-devider' />
                                    <input className='dashboard-modules-tempchannels-enabled-setting-input' id='dashboard-modules-tempchannels-voice-name-input' name='new-tempchannels-voice-name' onChange={on_field_change} value={newTempchannelsVoiceName}></input>
                                </div>
                                <div className='dashboard-modules-tempchannels-enabled-setting'>
                                    <div className='dashboard-modules-tempchannels-enabled-setting-label'>
                                        Link a text channel
                                    </div>
                                    <div className='dashboard-modules-tempchannels-enabled-setting-description'>If a temporary text channel should be created when a temporary voice channel is created.</div>
                                    <hr  className='dashboard-modules-tempchannels-enabled-setting-devider' />
                                    { newTempchannelsTextCreation ?
                                        <div key="off">
                                            <div className='new-tempchannels-module-text-creation-wrapper'>
                                                <label className="toggler-wrapper style-1">
                                                    <input id="off" type="checkbox" name="new-tempchannels-module-text-creation-input" defaultChecked onChange={on_field_change}></input>
                                                    <div className="toggler-slider">
                                                        <div className="toggler-knob"></div>
                                                    </div>
                                                </label>
                                            </div>
                                        </div> 
                                        :
                                        <div key="on">
                                            <div className='new-tempchannels-module-text-creation-wrapper'>
                                                <label className="toggler-wrapper style-1">
                                                    <input id="on" type="checkbox" name="new-tempchannels-module-text-creation-input" onChange={on_field_change}></input>
                                                    <div className="toggler-slider">
                                                        <div className="toggler-knob"></div>
                                                    </div>
                                                </label>
                                            </div> 
                                        </div>
                                    }
                                </div>
                                { newTempchannelsTextCreation ? 
                                    <div>
                                        <div className='dashboard-modules-tempchannels-enabled-setting'>
                                            <div className='dashboard-modules-tempchannels-enabled-setting-label'>
                                                Text category
                                            </div>
                                            <div className='dashboard-modules-tempchannels-enabled-setting-description'>The category where temporary text channels are created in.</div>
                                            <hr  className='dashboard-modules-tempchannels-enabled-setting-devider' />
                                            <select className='dashboard-modules-tempchannels-enabled-setting-input' id='dashboard-modules-tempchannels-Text-category-id-input' name='new-tempchannels-text-category-id' onChange={on_field_change} value={newTempchannelsTextCategory}>
                                                { guildCategoryChannels.map(guild_channel => 
                                                    <option key={ String(guild_channel.id) } value={ String(guild_channel.id) }>
                                                        { guild_channel.name }
                                                    </option>
                                                )}
                                                <option key="no-text-category" value="None">
                                                    No text category selected yet!
                                                </option>
                                            </select>
                                        </div>
                                        <div className='dashboard-modules-tempchannels-enabled-setting'>
                                            <div className='dashboard-modules-tempchannels-enabled-setting-label'>
                                                Text channel name
                                            </div>
                                            <div className='dashboard-modules-tempchannels-enabled-setting-description'>The name used for temporary text channels.</div>
                                            <hr  className='dashboard-modules-tempchannels-enabled-setting-devider' />
                                            <input className='dashboard-modules-tempchannels-enabled-setting-input' id='dashboard-modules-tempchannels-Text-name-input' name='new-tempchannels-text-name' onChange={on_field_change} value={newTempchannelsTextName}></input>
                                        </div>
                                    </div>
                                    :
                                    <div></div>
                                }
                                <div className="dashboard-modules-tempchannels-settings-interactions-wrapper">
                                    <div className="dashboard-modules-tempchannels-settings-interactions-inner-wrapper">
                                        <GenericButton type="danger" on_click_action={reset_module_settings} button_text="Cancel" />
                                        <GenericButton button_text="Save" on_click_action={set_module_settings} />
                                    </div>
                                    <div className="dashboard-modules-tempchannels-settings-interactions-refresh">
                                        <RefreshButton on_click_action={refresh_data_table} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <Loading loading_size='s' />
                    :
                    <div className='dashboard-modules-tempchannels-disabled-container'>
                        The Tempchannels module is disabled, click <label onClick={open_enable_tempchannels_module_sidebar} className='dashboard-modules-tempchannels-disabled-enable-button'>here</label> if you want to enabled this module!
                        <div className='enable-tempchannels-module-sidebar' id="enable-tempchannels-module-sidebar">
                            <div className='enable-tempchannels-module-wrapper'>
                                <div className='enable-tempchannels-module-cancel'>
                                    <div className="enable-tempchannels-module-back-button" onClick={close_enable_tempchannels_module_sidebar}>
                                        <div className="enable-tempchannels-module-button-icon"><span className="material-symbols-outlined" id="tempchannels-close-icon">close</span></div>
                                        <div className="enable-tempchannels-module-button-text-wrapper"><span className="enable-tempchannels-module-button-text">Cancel & Reset</span></div>
                                    </div>
                                    <h2 className='enable-tempchannels-module-title'>
                                        Enable the Tempchannels Module
                                    </h2>
                                </div>
                                <div className='enable-tempchannels-module-voice-category-id'>
                                    <h3 className='enable-tempchannels-module-voice-category-id-label'>
                                        Category channel for voice channels*
                                    </h3>
                                    <select className='enable-tempchannels-module-voice-category-id-input' id='enable-tempchannels-module-voice-category-id-input' name='enable-tempchannels-voice-category-id' onChange={on_field_change} value={enableTempchannelsVoiceCategory}>
                                        { guildCategoryChannels.map(channel => 
                                            <option key={ String(channel.id) } value={ String(channel.id) }>
                                                { channel.name }
                                            </option>
                                        )}
                                        <option key="create-channel" value="create">
                                            Create a default category channel!
                                        </option>
                                        <option key="no-channel" value="">
                                            No channel selected yet!
                                        </option>
                                    </select>
                                </div>
                                <div className='enable-tempchannels-module-voice-name'>
                                    <h3 className='enable-tempchannels-module-voice-name-label'>
                                        Name of the voice channel*
                                    </h3>
                                    <div className='enable-tempchannels-module-voice-name-description'>
                                        Supports user variables!
                                    </div>
                                    <input className='enable-tempchannels-module-voice-name-input' id='enable-tempchannels-module-voice-name-input' name='enable-tempchannels-voice-name' placeholder="<user_id>'s-channel" onChange={on_field_change} value={enableTempchannelsVoiceName} />
                                </div>
                                <div className='enable-tempchannels-module-text-creation'>
                                    <h3 className='enable-tempchannels-module-text-creation-label'>
                                        Link a text channel*
                                    </h3>
                                    { enableTempchannelsTextCreation ?
                                        <div key="off">
                                            <div className='enable-tempchannels-module-text-creation-wrapper'>
                                                <label className="toggler-wrapper style-1">
                                                    <input id="off" type="checkbox" name="enable-tempchannels-module-text-creation-input" defaultChecked onChange={on_field_change}></input>
                                                    <div className="toggler-slider">
                                                        <div className="toggler-knob"></div>
                                                    </div>
                                                </label>
                                            </div>
                                        </div> 
                                        :
                                        <div key="on">
                                            <div className='enable-tempchannels-module-text-creation-wrapper'>
                                                <label className="toggler-wrapper style-1">
                                                    <input id="on" type="checkbox" name="enable-tempchannels-module-text-creation-input" onChange={on_field_change}></input>
                                                    <div className="toggler-slider">
                                                        <div className="toggler-knob"></div>
                                                    </div>
                                                </label>
                                            </div> 
                                        </div>
                                    }
                                </div>
                                { enableTempchannelsTextCreation ? 
                                    <div>
                                        <div className='enable-tempchannels-module-text-category-id'>
                                            <h3 className='enable-tempchannels-module-text-category-id-label'>
                                                Category channel for text channels*
                                            </h3>
                                            <select className='enable-tempchannels-module-text-category-id-input' id='enable-tempchannels-module-text-category-id-input' name='enable-tempchannels-text-category-id' onChange={on_field_change} value={enableTempchannelsTextCategory}>
                                                { guildCategoryChannels.map(channel => 
                                                    <option key={ String(channel.id) } value={ String(channel.id) }>
                                                        { channel.name }
                                                    </option>
                                                )}
                                                <option key="create-channel" value="same">
                                                    Use same as voice!
                                                </option>
                                                <option key="no-channel" value="">
                                                    No channel selected yet!
                                                </option>
                                            </select>
                                        </div>
                                        <div className='enable-tempchannels-module-text-name'>
                                            <h3 className='enable-tempchannels-module-text-name-label'>
                                                Name of the text channel*
                                            </h3>
                                            <div className='enable-tempchannels-module-text-name-description'>
                                                Supports user variables!
                                            </div>
                                            <input className='enable-tempchannels-module-text-name-input' id='enable-tempchannels-module-text-name-input' name='enable-tempchannels-text-name' placeholder="<user_id>'s-text" onChange={on_field_change} value={enableTempchannelsTextName} />
                                        </div>
                                    </div>
                                    :
                                    <div></div>
                                }
                            </div>
                            <div className='enable-tempchannels-module-interactions-wrapper'>
                                <div className='submit'>
                                    <GenericButton button_text="Enable" id="enable" on_click_action={set_module_status} />
                                </div>
                            </div>
                        </div>
                    </div>
                :
                <Loading loading_size='s' />
            }
        </div>
    );
};

export default DashboardModulesTempchannelsContent;