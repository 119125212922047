import React from 'react';
import { useParams } from 'react-router-dom'
import { CarouselProvider, Slider, Slide } from 'pure-react-carousel';

import 'pure-react-carousel/dist/react-carousel.es.css';

import '../../styles/pages/dashboard/overviewContent.css'

const DashboardFunctionsTagsContent = () => {
    const params = useParams()

    return(
        <div className='dashboard-overview-content-inner-wrapper'>
            <div className='dashboard-overview-carousel'>
                <CarouselProvider
                    naturalSlideWidth={100}
                    naturalSlideHeight={200}
                    totalSlides={1}
                    interval={7000}
                    isPlaying={true}
                    playDirection={"forward"}
                    infinite={true}
                >
                    <Slider>
                        <Slide index={0}>
                            <div className='dashboard-overview-carousel-item'>
                                <div className='dashboard-overview-carousel-item-title-wrapper'>
                                    <div className='dashboard-overview-carousel-item-title'>Welcome to the Husqy Dashboard!</div>
                                    <div className='dashboard-overview-carousel-item-subtitle'>A new way to configure Husqy!</div>
                                </div>
                                <div className='dashboard-overview-carousel-item-description'>
                                    The Husqy Dashboard gives you the ability to configure Husqy just the way you need it and change it whenever, wherever!
                                    Combined with the tools provided by Husqy in your Discord server it allows you to change and manage your Discord server. 
                                    As you might notice, some pages are still being worked on! Please bear with us, these pages will be added soon! 
                                    <br/><br/>
                                    Thanks for using and trusting Husqy!
                                    <br/><br/>
                                </div>
                                <div className='dashboard-overview-carousel-item-disclaimer'>
                                    * Husqy is still in active development! Experiencing any issues with Husqy (website, dashboard, API (comming soon!) or the bot itself), please visit: <a href='https://husqy.xyz/help' target='_blank' rel='noreferrer' className='dashboard-overview-carousel-item-description-disclaimer-link'>https://husqy.xyz/help</a>!
                                </div>
                            </div>
                        </Slide>
                    </Slider>
                </CarouselProvider>
            </div>
            <div className='dashboard-overview-functions-wrapper'>
                <div className='dashboard-overview-functions-title'>
                    Husqy Functions
                </div>
                <div className='dashboard-overview-functions-entries-wrapper'>
                    <div className='dashboard-overview-functions-entry'>
                        <div className='dashboard-overview-functions-entry-title-wrapper'>
                            <div className='dashboard-overview-functions-entry-title'>
                                Settings
                            </div>
                            <div className='dashboard-overview-functions-entry-description'>
                                Change settings of Husqy and his functions.
                            </div>
                        </div>
                        <div className='dashboard-overview-functions-entry-interactions-wrapper'>
                            <a href="https://husqy.xyz/help/documentation/settings" target='_blank' rel='noreferrer' className='dashboard-overview-functions-entry-interactions-documentation'>
                                Documentation
                            </a>
                            <a href={"/" + params.guild_id + "/settings"} className='dashboard-overview-functions-entry-interactions-documentation'>
                                Configure
                            </a>
                        </div>
                    </div>
                    <div className='dashboard-overview-functions-entry'>
                        <div className='dashboard-overview-functions-entry-title-wrapper'>
                            <div className='dashboard-overview-functions-entry-title'>
                                Giveaways
                            </div>
                            <div className='dashboard-overview-functions-entry-description'>
                                The giveaway features in Husqy provide you the ability to organize server wide giveaways for your server members and keep the interaction between you, your staff and your members.
                            </div>
                        </div>
                        <div className='dashboard-overview-functions-entry-interactions-wrapper'>
                            <a href="https://husqy.xyz/help/documentation/giveaway" target='_blank' rel='noreferrer' className='dashboard-overview-functions-entry-interactions-documentation'>
                                Documentation
                            </a>
                            <a href={"/" + params.guild_id + "/functions/giveaways"} className='dashboard-overview-functions-entry-interactions-documentation'>
                                Configure
                            </a>
                        </div>
                    </div>
                    <div className='dashboard-overview-functions-entry'>
                        <div className='dashboard-overview-functions-entry-title-wrapper'>
                            <div className='dashboard-overview-functions-entry-title'>
                                Polls
                            </div>
                            <div className='dashboard-overview-functions-entry-description'>
                                The polls features in Husqy provide you the ability to organize server wide polls and get the opinions of your server members on different topics.
                            </div>
                        </div>
                        <div className='dashboard-overview-functions-entry-interactions-wrapper'>
                            <a href="https://husqy.xyz/help/documentation/polls" target='_blank' rel='noreferrer' className='dashboard-overview-functions-entry-interactions-documentation'>
                                Documentation
                            </a>
                            <a href={"/" + params.guild_id + "/functions/polls"} className='dashboard-overview-functions-entry-interactions-documentation'>
                                Configure
                            </a>
                        </div>
                    </div>
                    <div className='dashboard-overview-functions-entry'>
                        <div className='dashboard-overview-functions-entry-title-wrapper'>
                            <div className='dashboard-overview-functions-entry-title'>
                                Reminders
                            </div>
                            <div className='dashboard-overview-functions-entry-description'>
                                Husqy reminders allows you to get reminded about tasks you have to do.
                            </div>
                        </div>
                        <div className='dashboard-overview-functions-entry-interactions-wrapper'>
                            <a href="https://husqy.xyz/help/documentation/reminders" target='_blank' rel='noreferrer' className='dashboard-overview-functions-entry-interactions-documentation'>
                                Documentation
                            </a>
                            <a href={"/" + params.guild_id + "/functions/reminders"} className='dashboard-overview-functions-entry-interactions-documentation'>
                                Configure
                            </a>
                        </div>
                    </div>
                    <div className='dashboard-overview-functions-entry'>
                        <div className='dashboard-overview-functions-entry-title-wrapper'>
                            <div className='dashboard-overview-functions-entry-title'>
                                Tags
                            </div>
                            <div className='dashboard-overview-functions-entry-description'>
                            Husqy tags are a way to create default response (based on tags) for your users to create and send. This can be useful for messages or responses that are very repeatable, f.e. an explanation of a function or the explanation of channel usage.
                            </div>
                        </div>
                        <div className='dashboard-overview-functions-entry-interactions-wrapper'>
                            <a href="https://husqy.xyz/help/documentation/tags" target='_blank' rel='noreferrer' className='dashboard-overview-functions-entry-interactions-documentation'>
                                Documentation
                            </a>
                            <a href={"/" + params.guild_id + "/functions/tags"} className='dashboard-overview-functions-entry-interactions-documentation'>
                                Configure
                            </a>
                        </div>
                    </div>
                    <div className='dashboard-overview-functions-entry'>
                        <div className='dashboard-overview-functions-entry-title-wrapper'>
                            <div className='dashboard-overview-functions-entry-title'>
                                Games
                            </div>
                            <div className='dashboard-overview-functions-entry-description'>
                                Play different games in Discord.
                            </div>
                        </div>
                        <div className='dashboard-overview-functions-entry-interactions-wrapper'>
                            <a href="https://husqy.xyz/help/documentation/games" target='_blank' rel='noreferrer' className='dashboard-overview-functions-entry-interactions-documentation'>
                                Documentation
                            </a>
                            {/* <a href={"/" + params.guild_id + "/settings"} className='dashboard-overview-functions-entry-interactions-documentation'>
                                Configure
                            </a> */}
                        </div>
                    </div>
                    <div className='dashboard-overview-functions-entry'>
                        <div className='dashboard-overview-functions-entry-title-wrapper'>
                            <div className='dashboard-overview-functions-entry-title'>
                                Music & Radio
                            </div>
                            <div className='dashboard-overview-functions-entry-description'>
                                Husqy gives you and your server members the ability to listen to music, radio or other sources while connected to a voice channel and having fun.
                            </div>
                        </div>
                        <div className='dashboard-overview-functions-entry-interactions-wrapper'>
                            <a href="https://husqy.xyz/help/documentation/music-radio" target='_blank' rel='noreferrer' className='dashboard-overview-functions-entry-interactions-documentation'>
                                Documentation
                            </a>
                            {/* <a href={"/" + params.guild_id + "/settings"} className='dashboard-overview-functions-entry-interactions-documentation'>
                                Configure
                            </a> */}
                        </div>
                    </div>
                    <div className='dashboard-overview-functions-entry'>
                        <div className='dashboard-overview-functions-entry-title-wrapper'>
                            <div className='dashboard-overview-functions-entry-title'>
                                Moderation
                            </div>
                            <div className='dashboard-overview-functions-entry-description'>
                                Husqy moderation provides you and your server admins with a powefull moderation tool for your server. It allows you to, f.e. ban, kick and temporarily mute members. In combination with the Logging module it gives you complete insight with all moderation actions done by your admins.
                            </div>
                        </div>
                        <div className='dashboard-overview-functions-entry-interactions-wrapper'>
                            <a href="https://husqy.xyz/help/documentation/moderation" target='_blank' rel='noreferrer' className='dashboard-overview-functions-entry-interactions-documentation'>
                                Documentation
                            </a>
                            {/* <a href={"/" + params.guild_id + "/settings"} className='dashboard-overview-functions-entry-interactions-documentation'>
                                Configure
                            </a> */}
                        </div>
                    </div>
                    <div className='dashboard-overview-functions-entry'>
                        <div className='dashboard-overview-functions-entry-title-wrapper'>
                            <div className='dashboard-overview-functions-entry-title'>
                                Utils
                            </div>
                            <div className='dashboard-overview-functions-entry-description'>
                                Husqy utils provide you with different utilities or tools to help you configure Husqy or customize your server.
                            </div>
                        </div>
                        <div className='dashboard-overview-functions-entry-interactions-wrapper'>
                            <a href="https://husqy.xyz/help/documentation/utils" target='_blank' rel='noreferrer' className='dashboard-overview-functions-entry-interactions-documentation'>
                                Documentation
                            </a>
                            {/* <a href={"/" + params.guild_id + "/settings"} className='dashboard-overview-functions-entry-interactions-documentation'>
                                Configure
                            </a> */}
                        </div>
                    </div>
                    <div className='dashboard-overview-functions-entry'>
                        <div className='dashboard-overview-functions-entry-title-wrapper'>
                            <div className='dashboard-overview-functions-entry-title'>
                                Miscellaneous
                            </div>
                            <div className='dashboard-overview-functions-entry-description'>
                                Miscellaneous commands are commands that do not necessarily belong to a category, these commands primarly work on their own.
                            </div>
                        </div>
                        <div className='dashboard-overview-functions-entry-interactions-wrapper'>
                            <a href="https://husqy.xyz/help/documentation/utils" target='_blank' rel='noreferrer' className='dashboard-overview-functions-entry-interactions-documentation'>
                                Documentation
                            </a>
                            {/* <a href={"/" + params.guild_id + "/settings"} className='dashboard-overview-functions-entry-interactions-documentation'>
                                Configure
                            </a> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className='dashboard-overview-modules-wrapper'>
                <div className='dashboard-overview-modules-title'>
                    Husqy Modules
                </div>
                <div className='dashboard-overview-modules-entries-wrapper'>
                    <div className='dashboard-overview-modules-entry'>
                        <div className='dashboard-overview-modules-entry-title-wrapper'>
                            <div className='dashboard-overview-modules-entry-title'>
                                Logging
                            </div>
                            <div className='dashboard-overview-modules-entry-description'>
                                The Logging Module is an Husqy module which enables you to keep track of different activities in your server nd interactions with Husqy by your server members. It  allows you to select the logging options based on your servers needs and posts them in a selected channel.
                            </div>
                        </div>
                        <div className='dashboard-overview-modules-entry-interactions-wrapper'>
                            <a href="https://husqy.xyz/help/documentation/logging-module" target='_blank' rel='noreferrer' className='dashboard-overview-functions-entry-interactions-documentation'>
                                Documentation
                            </a>
                            <a href={"/" + params.guild_id + "/modules/logging"} className='dashboard-overview-functions-entry-interactions-documentation'>
                                Configure
                            </a>
                        </div>
                    </div>
                    <div className='dashboard-overview-modules-entry'>
                        <div className='dashboard-overview-modules-entry-title-wrapper'>
                            <div className='dashboard-overview-modules-entry-title'>
                                Greetings
                            </div>
                            <div className='dashboard-overview-modules-entry-description'>
                                The Greetings module provides you with the ability to automate some task regarding new or left users. It enables a server to automatically greet new users (via DM and/or in a servers text channel), give one role to a new user or wave them goodbye (in a servers text channel). 
                            </div>
                        </div>
                        <div className='dashboard-overview-modules-entry-interactions-wrapper'>
                            <a href="https://husqy.xyz/help/documentation/greetings-module" target='_blank' rel='noreferrer' className='dashboard-overview-functions-entry-interactions-documentation'>
                                Documentation
                            </a>
                            <a href={"/" + params.guild_id + "/modules/greetings"} className='dashboard-overview-functions-entry-interactions-documentation'>
                                Configure
                            </a>
                        </div>
                    </div>
                    <div className='dashboard-overview-modules-entry'>
                        <div className='dashboard-overview-modules-entry-title-wrapper'>
                            <div className='dashboard-overview-modules-entry-title'>
                                Tempchannels
                            </div>
                            <div className='dashboard-overview-modules-entry-description'>
                                The Tempchannels module allows your server members to join a specified channel and creates tempchannels for them which will automatically be deleted after use.
                            </div>
                        </div>
                        <div className='dashboard-overview-modules-entry-interactions-wrapper'>
                            <a href="https://husqy.xyz/help/documentation/tempchannels-module" target='_blank' rel='noreferrer' className='dashboard-overview-functions-entry-interactions-documentation'>
                                Documentation
                            </a>
                            <a href={"/" + params.guild_id + "/modules/tempchannels"} className='dashboard-overview-functions-entry-interactions-documentation'>
                                Configure
                            </a>
                        </div>
                    </div>
                    <div className='dashboard-overview-modules-entry'>
                        <div className='dashboard-overview-modules-entry-title-wrapper'>
                            <div className='dashboard-overview-modules-entry-title'>
                                Socials
                            </div>
                            <div className='dashboard-overview-modules-entry-description'>
                                The Socials module allows you to keep track of you favorite celebrities or websites within your Discord Server!
                            </div>
                        </div>
                        <div className='dashboard-overview-modules-entry-interactions-wrapper'>
                            <a href="https://husqy.xyz/help/documentation/socials-module" target='_blank' rel='noreferrer' className='dashboard-overview-functions-entry-interactions-documentation'>
                                Documentation
                            </a>
                            <a href={"/" + params.guild_id + "/modules/socials"} className='dashboard-overview-functions-entry-interactions-documentation'>
                                Configure
                            </a>
                        </div>
                    </div>
                    <div className='dashboard-overview-modules-entry'>
                        <div className='dashboard-overview-modules-entry-title-wrapper'>
                            <div className='dashboard-overview-modules-entry-title'>
                                Reaction Roles
                            </div>
                            <div className='dashboard-overview-modules-entry-description'>
                                The ReactionRole module provides the ability to create these reactions in your server and allows you to offload some tasks.
                            </div>
                        </div>
                        <div className='dashboard-overview-modules-entry-interactions-wrapper'>
                            <a href="https://husqy.xyz/help/documentation/reactionroles-module" target='_blank' rel='noreferrer' className='dashboard-overview-functions-entry-interactions-documentation'>
                                Documentation
                            </a>
                            <a href={"/" + params.guild_id + "/modules/reaction-roles"} className='dashboard-overview-functions-entry-interactions-documentation'>
                                Configure
                            </a>
                        </div>
                    </div>
                    <div className='dashboard-overview-modules-entry'>
                        <div className='dashboard-overview-modules-entry-title-wrapper'>
                            <div className='dashboard-overview-modules-entry-title'>
                                Autoresponder
                            </div>
                            <div className='dashboard-overview-modules-entry-description'>
                                The Autoresponder Module allows you to automatically respond, in one of three ways, to a message with a specified word. The Autoresponder allows the use of messages, embeds or reactions.
                            </div>
                        </div>
                        <div className='dashboard-overview-modules-entry-interactions-wrapper'>
                            <a href="https://husqy.xyz/help/documentation/autoresponder-module" target='_blank' rel='noreferrer' className='dashboard-overview-functions-entry-interactions-documentation'>
                                Documentation
                            </a>
                            <a href={"/" + params.guild_id + "/modules/autoresponder"} className='dashboard-overview-functions-entry-interactions-documentation'>
                                Configure
                            </a>
                        </div>
                    </div>
                    <div className='dashboard-overview-modules-entry'>
                        <div className='dashboard-overview-modules-entry-title-wrapper'>
                            <div className='dashboard-overview-modules-entry-title'>
                                Tickets
                            </div>
                            <div className='dashboard-overview-modules-entry-description'>
                                The Tickets Module is the way for you to get direct feedback from you users and help users with existing problems. It allows you server member to create a ticket and get the help they need.
                            </div>
                        </div>
                        <div className='dashboard-overview-modules-entry-interactions-wrapper'>
                            <a href="https://husqy.xyz/help/documentation/tickets-module" target='_blank' rel='noreferrer' className='dashboard-overview-functions-entry-interactions-documentation'>
                                Documentation
                            </a>
                            <a href={"/" + params.guild_id + "/modules/tickets"} className='dashboard-overview-functions-entry-interactions-documentation'>
                                Configure
                            </a>
                        </div>
                    </div>
                    <div className='dashboard-overview-modules-entry'>
                        <div className='dashboard-overview-modules-entry-title-wrapper'>
                            <div className='dashboard-overview-modules-entry-title'>
                                ServerStats
                            </div>
                            <div className='dashboard-overview-modules-entry-description'>
                                The ServerStats module keeps track of different statistics and allows you to incorporate a starboard and a Counter panel into your Discord server.
                            </div>
                        </div>
                        <div className='dashboard-overview-modules-entry-interactions-wrapper'>
                            <a href="https://husqy.xyz/help/documentation/serverstats-module" target='_blank' rel='noreferrer' className='dashboard-overview-functions-entry-interactions-documentation'>
                                Documentation
                            </a>
                            <a href={"/" + params.guild_id + "/modules/serverstats"} className='dashboard-overview-functions-entry-interactions-documentation'>
                                Configure
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DashboardFunctionsTagsContent;