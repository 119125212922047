import React, { useState } from 'react';
import { useParams } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import { toast } from 'react-toastify'
import axios from 'axios'
import GenericButton from '../../buttons/generic_button'

import '../../../styles/components/dashboard/modules/LoggingEventForm.css'
import config from '../../../config'

function LoggingEventForm(props) {
    const [cookies] = useCookies(['access_token'])
        
    const [moduleLoggingEventData, setModuleLoggingEventData] = useState(props.eventData)
    const [newModuleLoggingEventData, setNewModuleLoggingEventData] = useState(props.eventData)
    
    const params = useParams()

    async function on_field_change(event) {
        event.preventDefault()
        var new_state
        if (event.target.id === "on") {
            new_state = true
        } else if (event.target.id === "off") {
            new_state = false
        }
        var key_name = event.target.name
        setNewModuleLoggingEventData(prevNewModuleLoggingEventData => ({...prevNewModuleLoggingEventData, [key_name]: new_state}))
    }

    async function update_logging_event_settings(event) {
        event.preventDefault()
        newModuleLoggingEventData.guild_id = params.guild_id
        const options = {
            url: `${config["api_base_url"]}/guilds/${params.guild_id}/modules/logging`,
            method: 'PUT',
            headers: {
                access_token: cookies["access_token"]
            },
            data: newModuleLoggingEventData
          };

        axios(options)
        .then(response => {
            if (response.status === 202) {
                window.location.reload()
            } else {
                toast.error("Something went wrong while updating the Logging settings!")
            }
        })
        .catch(error => {
            console.log(error)
            if (error.response.status === 429) {
                toast.error("Rate limit reached! Please slow down!")
                return
            } 
            toast.error("Something went wrong while updating the Logging settings!")
        });
    }

    async function reset_logging_events_settings(event) {
        event.preventDefault()
        setNewModuleLoggingEventData(moduleLoggingEventData)
    }

    return(
        <div className='module-logging-event-form-wrapper'>
            <div className='module-logging-event-form-container'>
                <div className='module-logging-event-form-events-info'>
                    <div className='module-logging-event-form-events-group-title'>
                        {props.title}
                    </div>
                    <div className='module-logging-event-form-events-group-description'>
                        {props.description}
                    </div>
                </div>
                {Object.entries(newModuleLoggingEventData).map(([key, value]) => (
                    <div key={ key } className='module-logging-event-form-entry'>
                        <div className='module-logging-event-form-entry-title'>{key}:</div>
                        <div className='module-logging-event-form-entry-value-selector'>
                            { value ?
                                <div key={ key + "off" }>
                                    <div className='module-logging-event-form-entry-value-wrapper'>
                                        <label className="toggler-wrapper style-1">
                                            <input id="off" type="checkbox" name={ key } defaultChecked onChange={on_field_change}></input>
                                            <div className="toggler-slider">
                                                <div className="toggler-knob"></div>
                                            </div>
                                        </label>
                                    </div>
                                </div> 
                                :
                                <div key={ key + "on"}>
                                    <div className='module-logging-event-form-entry-value-wrapper'>
                                        <label className="toggler-wrapper style-1">
                                            <input id="on" type="checkbox" name={ key } onChange={on_field_change}></input>
                                            <div className="toggler-slider">
                                                <div className="toggler-knob"></div>
                                            </div>
                                        </label>
                                    </div> 
                                </div>
                            }
                        </div>
                    </div>
                ))}
            </div>
            <div className='module-logging-event-form-interactions-container'>
                <GenericButton button_text="Reset" type="danger" action_type="reset" on_click_action={reset_logging_events_settings} />
                <GenericButton button_text="Save" on_click_action={update_logging_event_settings} />
            </div>
        </div>
    )
}

export default LoggingEventForm