import '../../styles/components/buttons/logout_button_float.css'

function LogoutButtonFloat() {
    return(
        <div className="logout-button">
            <a href="/logout"><span className="material-symbols-outlined" id="logout-icon">Logout</span></a>
        </div>
    )
}

export default LogoutButtonFloat