import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import axios from 'axios'
import Loading from '../../../components/loading'
import { toast } from 'react-toastify'
import GenericButton from "../../../components/buttons/generic_button"
import RefreshButton from "../../../components/buttons/refresh_button"

import '../../../styles/pages/dashboard/security_privacy/userDataCollectionOverviewContent.css'
import config from '../../../config'

const DashboardPrivacyUserDataCollectionOverviewContent = () => {
    const [cookies] = useCookies(['access_token'])

    const params = useParams()
    const [guildMembers, setGuildMembers] = useState([])
    const [usersDataCollectionStatus, setUsersDataCollectionStatus] = useState([])

    const [userDataCollectionLoadingStatus, setUserDataCollectionLoadingStatus] = useState('loading')
    const [membersLoadingStatus, setMembersLoadingStatus] = useState('loading')

    // Refresh data
    const refresh_data = async (event) => {
        event.preventDefault();
        window.location.reload()
    }

    // Get data
    const get_members = async () => {
        setMembersLoadingStatus("loading")
        const options = {
            url: `${config["api_base_url"]}/guilds/${params.guild_id}/members`,
            method: 'GET',
            headers: {
                access_token: cookies["access_token"]
            },
          };

          axios(options)
          .then(response => {
              if (response.status === 200) {
                  setGuildMembers(response["data"]["data"])
                  setMembersLoadingStatus("success")
              }
          })
          .catch(error => {
              console.log(error)
              setMembersLoadingStatus("error")
              if (error.response.status === 429) {
                toast.error("Rate limit reached! Please slow down!")
                return
            }
              toast.error("Something went wrong while getting the servers members!")
          });
    }

    const get_user_data_collection_status = async () => {
        setUserDataCollectionLoadingStatus("loading")
        const options = {
            url: `${config["api_base_url"]}/guilds/${params.guild_id}/privacy/user-data-collection-status`,
            method: 'GET',
            headers: {
                access_token: cookies["access_token"]
            },
          };

          axios(options)
          .then(response => {
              console.log(response["data"])
              if (response.status === 200) {
                  setUsersDataCollectionStatus(response["data"]["data"])
                  setUserDataCollectionLoadingStatus("success")
              }
          })
          .catch(error => {
              console.log(error)
              setUserDataCollectionLoadingStatus("error")
              if (error.response.status === 429) {
                toast.error("Rate limit reached! Please slow down!")
                return
            }
              toast.error("Something went wrong while getting the servers members data collection status!")
          });
    }

    useEffect(() => {
        get_members()
        get_user_data_collection_status()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return(
        <div className='dashboard-privacy-user-data-collection-overview-inner-wrapper'>
            { userDataCollectionLoadingStatus === 'success' && membersLoadingStatus === 'success' ? 
                <div className='dashboard-privacy-user-data-collection-overview-container'>
                    <div className='dashboard-privacy-user-data-collection-overview-interactions-wrapper'>
                        <div className='dashboard-privacy-user-data-collection-overview-data'>
                            <RefreshButton on_click_action={refresh_data} />
                        </div>
                    </div>
                    <div className='dashboard-privacy-user-data-collection-overview-denied-container'>
                        <div className='dashboard-privacy-user-data-collection-overview-denied-title'>Data collection disabled</div>
                        <div className='dashboard-privacy-user-data-collection-overview-denied-description'>The users in the table below have denied Husqy to collect and use their data! For these users, some functionality works different or is restricted, this can also impact the whole server!</div>
                        { usersDataCollectionStatus.filter(entry => entry.prevent_data_collection === true).length === 0 ?
                            <div className='dashboard-privacy-user-data-collection-overview-table-no-entries-wrapper'>
                                No users have denied Husqy from collecting and using their data!
                            </div>
                            :
                            <div className='dashboard-privacy-user-data-collection-overview-table-table-wrapper'>
                                <div className="dashboard-privacy-user-data-collection-overview-table">
                                    <div className="dashboard-privacy-user-data-collection-overview-table-content">
                                        <div>
                                            <div className="dashboard-privacy-user-data-collection-overview-table-titles">
                                                <div className="dashboard-privacy-user-data-collection-overview-table-titles-wrapper">
                                                    <div className="dashboard-privacy-user-data-collection-overview-table-titles-name-wrapper">
                                                        <p className="dashboard-privacy-user-data-collection-overview-table-titles-name">User</p>
                                                    </div>
                                                    <div className="dashboard-privacy-user-data-collection-overview-table-titles-interactions-wrapper">
                                                        <p className="dashboard-privacy-user-data-collection-overview-table-titles-interactions">Interactions</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='dashboard-privacy-user-data-collection-overview-table-content'>
                                                { usersDataCollectionStatus.filter(entry => entry.prevent_data_collection === true).map(entry =>
                                                    <div className="privacy-user-data-entry-wrapper" key={entry.id}>
                                                        <div className="privacy-user-data-entry-name-wrapper">
                                                            <p className="privacy-user-data-entry-name">{entry.name} - {entry.id}</p>
                                                        </div>
                                                        <div className="privacy-user-data-entry-interactions-wrapper">
                                                            <div className="privacy-user-data-entry-interactions-inner-wrapper">
                                                                <div className="privacy-user-data-entry-interactions-form-delete">
                                                                    <a className="privacy-user-data-entry-interactions-delete-text" href={"https://discord.com/users/"+entry.id} target='_blank' rel='noreferrer' id={entry.id}>Message user</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    <div className='dashboard-privacy-user-data-collection-overview-allowed-container'>
                        <div className='dashboard-privacy-user-data-collection-overview-allowed-title'>Data collection enabled</div>
                        <div className='dashboard-privacy-user-data-collection-overview-allowed-description'>The users in the table below have allowed Husqy to collect and use their data! All functionality works as intended for these users!</div>
                        { usersDataCollectionStatus.filter(entry => entry.prevent_data_collection === false).length === 0 ?
                            <div className='dashboard-privacy-user-data-collection-overview-table-no-entries-wrapper'>
                                No users have allowed Husqy from collecting and using their data!
                            </div>
                            :
                            <div className='dashboard-privacy-user-data-collection-overview-table-table-wrapper'>
                                <div className="dashboard-privacy-user-data-collection-overview-table">
                                    <div className="dashboard-privacy-user-data-collection-overview-table-content">
                                        <div>
                                            <div className="dashboard-privacy-user-data-collection-overview-table-titles">
                                                <div className="dashboard-privacy-user-data-collection-overview-table-titles-wrapper">
                                                    <div className="dashboard-privacy-user-data-collection-overview-table-titles-name-wrapper">
                                                        <p className="dashboard-privacy-user-data-collection-overview-table-titles-name">User</p>
                                                    </div>
                                                    <div className="dashboard-privacy-user-data-collection-overview-table-titles-interactions-wrapper">
                                                        <p className="dashboard-privacy-user-data-collection-overview-table-titles-interactions">Interactions</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='dashboard-privacy-user-data-collection-overview-table-content'>
                                                { usersDataCollectionStatus.filter(entry => entry.prevent_data_collection === false).map(entry =>
                                                    <div className="privacy-user-data-entry-wrapper" key={entry.id}>
                                                        <div className="privacy-user-data-entry-name-wrapper">
                                                            <p className="privacy-user-data-entry-name">{entry.name} - {entry.id}</p>
                                                        </div>
                                                        <div className="privacy-user-data-entry-interactions-wrapper">
                                                            <div className="privacy-user-data-entry-interactions-inner-wrapper">
                                                                <div className="privacy-user-data-entry-interactions-form-delete">
                                                                    <a className="privacy-user-data-entry-interactions-delete-text" href={"https://discord.com/users/"+entry.id} target='_blank' rel='noreferrer' id={entry.id}>Message user</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                :
                <Loading loading_size='s' />
            }
        </div>
    );
};

export default DashboardPrivacyUserDataCollectionOverviewContent;