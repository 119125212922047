import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import { toast } from 'react-toastify'
import axios from 'axios'
import Loading from '../../../components/loading'
import GenericButton from "../../../components/buttons/generic_button"
import RefreshButton from "../../../components/buttons/refresh_button"

import '../../../styles/pages/dashboard/modules/ticketsContent.css'
import '../../../js/sticky_modules_tickets_bars.js'
import config from '../../../config'

const DashboardModulesTicketsContent = () => {
    const [cookies] = useCookies(['access_token'])

    const [moduleTicketsStatus, setModuleTicketsStatus] = useState(false)
    const [ticketsSettings, setTicketsSettings] = useState({})

    const [guildTextChannels, setGuildTextChannels] = useState([])
    const [guildCategoryChannels, setGuildCategoryChannels] = useState([])
    const [guildRoles, setGuildRoles] = useState([])

    const [newTicketsRole, setNewTicketsRole] = useState("")
    const [newTicketsThreadMode, setNewTicketsThreadMode] = useState("")
    const [newTicketsPostChannel, setNewTicketsPostChannel] = useState("")
    const [newTicketsCustomContent, setNewTicketsCustomContent] = useState("")
    const [newTicketsCustomMessage, setNewTicketsCustomMessage] = useState("")
    const [newTicketsCustomModal, setNewTicketsCustomModal] = useState("")
    const [newCreateTicketsDefaultTicketTypes, setNewCreateTicketsDefaultTicketTypes] = useState([])
    const [newDeleteTicketsDefaultTicketTypes, setNewDeleteTicketsDefaultTicketTypes] = useState([])
    const [newTicketsDefaultTicketTypeToAdd, setNewTicketsDefaultTicketTypeToAdd] = useState("")
    const [newCreateTicketsCustomTicketTypes, setNewCreateTicketsCustomTicketTypes] = useState([])
    const [newDeleteTicketsCustomTicketTypes, setNewDeleteTicketsCustomTicketTypes] = useState([])
    const [newTicketsCustomTicketTypeToAdd, setNewTicketsCustomTicketTypeToAdd] = useState("")

    const [enableTicketsRole, setEnableTicketsRole] = useState("")
    const [enableTicketsCategoryChannel, setEnableTicketsCategoryChannel] = useState("")
    const [enableTicketsCreationChannel, setEnableTicketsCreationChannel] = useState("")
    const [enableTicketsPostChannel, setEnableTicketsPostChannel] = useState("")
    const [enableTicketsThreadMode, setEnableTicketsThreadMode] = useState(true)
    const [enableTicketsSetupMode, setEnableTicketsSetupMode] = useState("")
    const [enableTicketsCustomContent, setEnableTicketsCustomContent] = useState("")
    const [enableTicketsCustomModal, setEnableTicketsCustomModal] = useState("")
    const [enableTicketsDefaultTicketTypes, setEnableTicketsDefaultTicketTypes] = useState([])
    const [enableTicketsCustomTicketTypes, setEnableTicketsCustomTicketTypes] = useState([])
    const [enableTicketsCustomTicketTypesTemp, setEnableTicketsCustomTicketTypesTemp] = useState("")
    const [enableTicketsCustomMessage, setEnableTicketsCustomMessage] = useState("default")
    const [enableTicketsCustomModalType, setEnableTicketsCustomModalType] = useState("default")

    const params = useParams()
    const [moduleTicketsLoadingStatus, setModuleTicketsLoadingStatus] = useState('loading')
    const [channelsLoadingStatus, setChannelsLoadingStatus] = useState('loading')
    const [rolesLoadingStatus, setRolesLoadingStatus] = useState('loading')
    const [moduleTicketsSettingsLoadingStatus, setModuleTicketsSettingsLoadingStatus] = useState('loading')

    // FIELD CHANGES
    const on_field_change = async (event) => {
        event.preventDefault();
        if (event.target.name === "enable-tickets-module-role") {
            setEnableTicketsRole(event.target.value)
        } else if (event.target.name === "enable-tickets-module-category-channel") {
            setEnableTicketsCategoryChannel(event.target.value)
        } else if (event.target.name === "enable-tickets-module-creation-channel") {
            setEnableTicketsCreationChannel(event.target.value)
        } else if (event.target.name === "enable-tickets-module-thread-mode-input") {
            if (event.target.id === "on") {
                setEnableTicketsThreadMode(true)
            } else if (event.target.id === "off") {
                setEnableTicketsThreadMode(false)
            }
        } else if (event.target.name === "enable-tickets-module-post-channel") {
            setEnableTicketsPostChannel(event.target.value)
        } else if (event.target.name === "enable-tickets-module-setup-mode") {
            setEnableTicketsSetupMode(event.target.value)
        } else if (event.target.name === "enable-tickets-module-content") {
            setEnableTicketsCustomContent(event.target.value)
        }  else if (event.target.name === "enable-tickets-module-modal") {
            setEnableTicketsCustomModal(event.target.value)
        } else if (event.target.name === "enable-tickets-module-default-ticket-types") {
            var new_enable_default_ticket_types = enableTicketsDefaultTicketTypes 
            if (event.target.value === "clear") {
                setEnableTicketsDefaultTicketTypes([])
            } else {
                if (new_enable_default_ticket_types.find(ticket_type => ticket_type === event.target.value)) {
                    return
                }
                new_enable_default_ticket_types = new_enable_default_ticket_types.concat(event.target.value)
                setEnableTicketsDefaultTicketTypes(new_enable_default_ticket_types)
            }
        } else if (event.target.name === "enable-tickets-module-custom-ticket-types") {
            setEnableTicketsCustomTicketTypesTemp(event.target.value)
        } else if (event.target.name === "enable-tickets-module-custom-ticket-types-save") {
            var new_enable_custom_ticket_types = enableTicketsCustomTicketTypes
            if (enableTicketsCustomTicketTypesTemp === "clear") {
                setEnableTicketsCustomTicketTypes([])
            } else {
                if (new_enable_custom_ticket_types.find(ticket_type => ticket_type === enableTicketsCustomTicketTypesTemp)) {
                    setEnableTicketsCustomTicketTypesTemp("")
                    return
                }
                new_enable_custom_ticket_types = new_enable_custom_ticket_types.concat(enableTicketsCustomTicketTypesTemp)
                setEnableTicketsCustomTicketTypes(new_enable_custom_ticket_types)
            }
            setEnableTicketsCustomTicketTypesTemp("")
        } else if (event.target.name === "enable-tickets-module-tickets-message") {
            setEnableTicketsCustomMessage(event.target.value)
        } else if (event.target.name === "enable-tickets-module-tickets-modal-type") {
            setEnableTicketsCustomModalType(event.target.value)
        } else if (event.target.name === "new-tickets-role") {
            setNewTicketsRole(event.target.value)
        } else if (event.target.name === "new-tickets-module-thread-mode-input") {
            if (event.target.id === "on") {
                setNewTicketsThreadMode(true)
            } else if (event.target.id === "off") {
                setNewTicketsThreadMode(false)
            }
        } else if (event.target.name === "new-tickets-post-channel") {
            setNewTicketsPostChannel(event.target.value)
        } else if (event.target.name === "new-tickets-message-type") {
            setNewTicketsCustomMessage(event.target.value)
        } else if (event.target.name === "new-tickets-message") {
            setNewTicketsCustomContent(event.target.value)
        } else if (event.target.name === "new-tickets-modal") {
            setNewTicketsCustomModal(event.target.value)
        } else if (event.target.name === "add-default-ticket-type") {
            setNewTicketsDefaultTicketTypeToAdd(event.target.value)
        } else if (event.target.name === "add-custom-ticket-type") {
            setNewTicketsCustomTicketTypeToAdd(event.target.value)
        }
    }

    const open_default_ticket_type_sidebar = async (event) => {
        event.preventDefault()
        var bar = document.getElementById('add-default-ticket-type-sidebar')
        bar.style.transform = "translateX(0px)"
    }
    const close_default_ticket_type_sidebar = async (event) => {
        event.preventDefault()
        setNewTicketsDefaultTicketTypeToAdd("")
        var bar = document.getElementById('add-default-ticket-type-sidebar')
        bar.style.transform = "translateX(+502px)"
    }
    const open_custom_ticket_type_sidebar = async (event) => {
        event.preventDefault()
        var bar = document.getElementById('add-custom-ticket-type-sidebar')
        bar.style.transform = "translateX(0px)"
    }
    const close_custom_ticket_type_sidebar = async (event) => {
        event.preventDefault()
        setNewTicketsCustomTicketTypeToAdd("")
        var bar = document.getElementById('add-custom-ticket-type-sidebar')
        bar.style.transform = "translateX(+502px)"
    }

    // Refresh data
    const refresh_data_table = async (event) => {
        event.preventDefault();
        window.location.reload()
    }

    // Module Status
    const open_enable_tickets_module_sidebar = async (event) => {
        event.preventDefault()
        var bar = document.getElementById('enable-tickets-module-sidebar')
        bar.style.transform = "translateX(0px)"
    }
    const close_enable_tickets_module_sidebar = async (event) => {
        event.preventDefault()
        setEnableTicketsRole("")
        setEnableTicketsCategoryChannel("")
        setEnableTicketsCreationChannel("")
        setEnableTicketsThreadMode(true)
        setEnableTicketsPostChannel("")
        setEnableTicketsSetupMode("")
        setEnableTicketsCustomContent("")
        setEnableTicketsCustomModal("")
        setEnableTicketsDefaultTicketTypes([])
        setEnableTicketsCustomTicketTypes([])
        setEnableTicketsCustomTicketTypesTemp("")
        setEnableTicketsCustomMessage("")
        var bar = document.getElementById('enable-tickets-module-sidebar')
        bar.style.transform = "translateX(+502px)"
    }

    const get_module_status = async () => {
        setModuleTicketsLoadingStatus("loading")
        const options = {
            url: `${config["api_base_url"]}/guilds/${params.guild_id}/modules?module_names=tickets`,
            method: 'GET',
            headers: {
                access_token: cookies["access_token"]
            }
          };
        axios(options)
        .then(response => {
            if (response.status === 200) {
                    setModuleTicketsStatus(response["data"]["data"]["module_tickets"])
                    if (response["data"]["data"]["module_tickets"] === true) {
                        get_module_settings()
                    }
                }
                setModuleTicketsLoadingStatus("success")
        })
        .catch(error => {
            console.log(error)
            setModuleTicketsLoadingStatus("error")
            if (error.response.status === 429) {
                toast.error("Rate limit reached! Please slow down!")
                return
            }
            toast.error("Something went wrong while fetching the module Tickets status!")
        });
    }

    const set_module_status = async (event) => {
        var new_status
        if (event.target.id === "enable") {
            new_status = true
        } else if (event.target.id === "disable") {
            new_status = false
        }

        if (new_status === true ) {
            if (enableTicketsRole === "") {
                toast.error("You need to select a role you want to use as the support role!")
                return
            }
            if (enableTicketsCategoryChannel === "") {
                toast.error("You need to select a category channel for the ticket channels!")
                return
            }
            if (enableTicketsCreationChannel === "") {
                toast.error("You need to select a text channel for the ticket creation!")
                return
            }
            if (enableTicketsThreadMode === false) {
                if (enableTicketsPostChannel === "") {
                    toast.error("You need to select a text channel where all tickets will be posted!")
                    return
                }
            }
            if (enableTicketsSetupMode === "") {
                toast.error("You need to select the desired setup mode!")
                return
            }
            if (enableTicketsSetupMode === 'custom') {
                if (enableTicketsCustomMessage === "") {
                    toast.error("You need to select the type of tickets message!")
                    return
                }
                if (enableTicketsCustomMessage !== "default") {
                    if (enableTicketsCustomContent === "") {
                        toast.error("You need to insert the JSON for the custom content to use!")
                        return
                    }
                }
                if (enableTicketsCustomModalType === "") {
                    toast.error("You need to select the type of modal you want to use!")
                    return
                }
                if (enableTicketsCustomModalType !== "default") {
                    if (enableTicketsCustomModal === "") {
                        toast.error("You need to insert the JSON for the custom modal to use!")
                        return
                    }
                }
            }
        }

        var data = {}
        data.tickets = {}
        data.tickets.new_status = new_status
        data.tickets.guild_id = params.guild_id
        if (new_status === true) {
            data.tickets.ticket_support_role = enableTicketsRole
            data.tickets.ticket_creation_channel = enableTicketsCreationChannel
            data.tickets.ticket_creation_category = enableTicketsCategoryChannel
            data.tickets.thread_mode = enableTicketsThreadMode
            if (enableTicketsThreadMode === false) {
                data.tickets.ticket_post_channel = enableTicketsPostChannel
            } else {
                data.tickets.ticket_post_channel = null
            }
            data.tickets.setup_mode = enableTicketsSetupMode
            data.tickets.used_default_ticket_types = enableTicketsDefaultTicketTypes
            data.tickets.used_custom_ticket_types = enableTicketsCustomTicketTypes
            if (enableTicketsCustomMessage === "message") {
                data.tickets.custom_creation_message = true
                data.tickets.custom_creation_embed = false
                data.tickets.custom_creation_content = enableTicketsCustomContent
            } else if (enableTicketsCustomMessage === "embed") {
                data.tickets.custom_creation_message = false
                data.tickets.custom_creation_embed = true
                data.tickets.custom_creation_content = enableTicketsCustomContent
            } else if (enableTicketsCustomMessage === "default") {
                data.tickets.custom_creation_message = false
                data.tickets.custom_creation_embed = true
                data.tickets.custom_creation_content = "default"
            }
            if (enableTicketsCustomModalType === "default") {
                data.tickets.custom_creation_modal = "default"
            } else {
                data.tickets.custom_creation_modal = enableTicketsCustomModal
            }
            
        }
        
        const options = {
            url: `${config["api_base_url"]}/guilds/${params.guild_id}/modules`,
            method: 'POST',
            headers: {
                access_token: cookies["access_token"]
            },
            data: data
          };

        axios(options)
        .then(response => {
            if (response.status === 202) {
                if (response["data"]["data"]["tickets"]["code"] === 200) {
                    window.location.reload()
                }
            } else {
                toast.error("Something went wrong while updating the Tickets module!")
            }
        })
        .catch(error => {
            console.log(error)
            if (error.response.status === 429) {
                toast.error("Rate limit reached! Please slow down!")
                return
            }
            toast.error("Something went wrong while updating the Tickets module!")
        });
    }

    // Get data
    const get_text_channels = async () => {
        setChannelsLoadingStatus("loading")
        const options = {
            url: `${config["api_base_url"]}/guilds/${params.guild_id}/channels?types=text`,
            method: 'GET',
            headers: {
                access_token: cookies["access_token"]
            },
          };

          axios(options)
          .then(response => {
              if (response.status === 200) {
                  setGuildTextChannels(response["data"][0]["data"])
                  setChannelsLoadingStatus("success")
              }
          })
          .catch(error => {
              console.log(error)
              setChannelsLoadingStatus("error")
              if (error.response.status === 429) {
                toast.error("Rate limit reached! Please slow down!")
                return
            }
              toast.error("Something went wrong while getting the servers channels!")
          });
    }

    const get_category_channels = async () => {
        setChannelsLoadingStatus("loading")
        const options = {
            url: `${config["api_base_url"]}/guilds/${params.guild_id}/channels?types=category`,
            method: 'GET',
            headers: {
                access_token: cookies["access_token"]
            },
          };

          axios(options)
          .then(response => {
              if (response.status === 200) {
                  setGuildCategoryChannels(response["data"][0]["data"])
                  setChannelsLoadingStatus("success")
              }
          })
          .catch(error => {
              console.log(error)
              setChannelsLoadingStatus("error")
              if (error.response.status === 429) {
                toast.error("Rate limit reached! Please slow down!")
                return
            }
              toast.error("Something went wrong while getting the servers channels!")
          });
    }

    const get_roles = async () => {
        setRolesLoadingStatus("loading")
        const options = {
            url: `${config["api_base_url"]}/guilds/${params.guild_id}/roles`,
            method: 'GET',
            headers: {
                access_token: cookies["access_token"]
            },
          };

        axios(options)
          .then(response => {
              if (response.status === 200) {
                  setGuildRoles(response["data"]["data"])
                  setRolesLoadingStatus("success")
              }
          })
          .catch(error => {
              console.log(error)
              setRolesLoadingStatus("error")
              if (error.response.status === 429) {
                toast.error("Rate limit reached! Please slow down!")
                return
            }
              toast.error("Something went wrong while getting the servers roles!")
          });
    }

    const get_module_settings = async () => {
        setModuleTicketsSettingsLoadingStatus("loading")
        const options = {
            url: `${config["api_base_url"]}/guilds/${params.guild_id}/modules/tickets`,
            method: 'GET',
            headers: {
                access_token: cookies["access_token"]
            },
          };

          axios(options)
          .then(response => {
              if (response.status === 200) {
                  setTicketsSettings(response["data"]["data"])
                  setNewTicketsRole(response["data"]["data"]["ticket_support_role"]["id"])
                  if (response["data"]["data"]["ticket_post_channel"]["id"] === null) {
                    setNewTicketsPostChannel("none")
                  } else {
                    setNewTicketsPostChannel(response["data"]["data"]["ticket_post_channel"]["id"])
                  }
                  setNewTicketsThreadMode(response["data"]["data"]["thread_mode"])
                  setNewTicketsCustomContent(response["data"]["data"]["custom_creation_content"])
                  if (response["data"]["data"]["custom_creation_embed"] === true) {
                    setNewTicketsCustomMessage("embed")
                  } else {
                    setNewTicketsCustomMessage("message")
                  }
                  setNewTicketsCustomModal(response["data"]["data"]["custom_creation_modal"])
                  setModuleTicketsSettingsLoadingStatus("success")
              }
          })
          .catch(error => {
              console.log(error)
              setModuleTicketsSettingsLoadingStatus("error")
              if (error.response.status === 429) {
                toast.error("Rate limit reached! Please slow down!")
                return
            }
              toast.error("Something went wrong while getting the settings of the Tickets module!")
          });
    }

    const reset_module_settings = async () => {
        setNewTicketsRole(ticketsSettings.ticket_support_role.id)
        if (ticketsSettings.ticket_post_channel.id === null) {
            setNewTicketsPostChannel("none")
          } else {
            setNewTicketsPostChannel(ticketsSettings.ticket_post_channel.id)
          }
        setNewTicketsThreadMode(ticketsSettings.thread_mode)
        setNewTicketsCustomContent(ticketsSettings.custom_creation_content)
        if (ticketsSettings.custom_creation_embed === true) {
          setNewTicketsCustomMessage("embed")
        } else {
          setNewTicketsCustomMessage("message")
        }
        setNewTicketsCustomModal(ticketsSettings.custom_creation_modal)
        setNewCreateTicketsDefaultTicketTypes([])
        setNewDeleteTicketsDefaultTicketTypes([])
        setNewCreateTicketsCustomTicketTypes([])
        setNewDeleteTicketsCustomTicketTypes([])
    }

    const set_module_settings = async () => {
        if (newTicketsThreadMode === false) {
            if (newTicketsPostChannel === "none") {
                toast.error("You need to select the channel where new tickets are posted!")
                return
            }
        }
        if (newTicketsCustomMessage !== "default") {
            if (newTicketsCustomContent === "") {
                toast.error("You need to insert the custom content of the Ticket create message!")
                return
            }
        }

        var data = {}
        data.guild_id = params.guild_id
        data.ticket_support_role = newTicketsRole
        data.thread_mode = newTicketsThreadMode
        if (newTicketsThreadMode === true) {
            data.ticket_post_channel = null
        } else {
            data.ticket_post_channel = newTicketsPostChannel
        }
        if (newTicketsCustomMessage === "default") {
            data.custom_creation_message = false
            data.custom_creation_embed = true
            data.custom_creation_content = "default"
        } else if (newTicketsCustomMessage === "embed") {
            data.custom_creation_message = false
            data.custom_creation_embed = true
            data.custom_creation_content = newTicketsCustomContent
        } else if (newTicketsCustomMessage === "message") {
            data.custom_creation_message = true
            data.custom_creation_embed = false
            data.custom_creation_content = newTicketsCustomContent
        }
        data.custom_creation_modal = newTicketsCustomModal
        data.used_default_ticket_types = {}
        data.used_default_ticket_types.create = newCreateTicketsDefaultTicketTypes
        data.used_default_ticket_types.delete = newDeleteTicketsDefaultTicketTypes
        data.used_custom_ticket_types = {}
        data.used_custom_ticket_types.create = newCreateTicketsCustomTicketTypes
        data.used_custom_ticket_types.delete = newDeleteTicketsCustomTicketTypes

        const options = {
            url: `${config["api_base_url"]}/guilds/${params.guild_id}/modules/tickets`,
            method: 'PUT',
            headers: {
                access_token: cookies["access_token"]
            },
            data: data
          };

        axios(options)
          .then(response => {
              if (response.status === 202) {
                  window.location.reload()
              }
          })
          .catch(error => {
              console.log(error)
              if (error.response.status === 429) {
                toast.error("Rate limit reached! Please slow down!")
                return
            }
              toast.error("Something went wrong while changing the settings of the Tickets module!")
          });
    }

    const create_default_ticket_type = async (event) => {
        event.preventDefault()
        if (newTicketsDefaultTicketTypeToAdd === "") {
            toast.error("You must select the default ticket type to add!")
            return
        }
        if (ticketsSettings.used_default_ticket_types.find(ticket_type => ticket_type === newTicketsDefaultTicketTypeToAdd)) {
            toast.error("This default ticket type is already in use!")
            return
        }
        if (newCreateTicketsDefaultTicketTypes.find(ticket_type => ticket_type === newTicketsDefaultTicketTypeToAdd)) {
            toast.error("This default ticket type is being added on the next save!")
            return
        }

        setNewCreateTicketsDefaultTicketTypes(CreateTicketsDefaultTicketTypes => CreateTicketsDefaultTicketTypes.concat(newTicketsDefaultTicketTypeToAdd))
        setNewTicketsDefaultTicketTypeToAdd("")
        close_default_ticket_type_sidebar(event)
        toast.success("The default ticket type will be added on the next save! This can be canceled using the cancel button!")
    }

    const delete_default_ticket_type = async (event) => {
        event.preventDefault()
        if (newDeleteTicketsDefaultTicketTypes.find(ticket_type => ticket_type === event.target.id)) {
            toast.error("This default ticket type is being deleted on the next save!")
            return
        }

        setNewDeleteTicketsDefaultTicketTypes(prevDeleteTicketsDefaultTicketTypes => prevDeleteTicketsDefaultTicketTypes.concat(event.target.id))
        toast.success("The custom panel will be deleted on the next save! This can be canceled using the cancel button!")
    }

    const reset_new_default_ticket_types = async () => {
        setNewCreateTicketsDefaultTicketTypes([])
        setNewDeleteTicketsDefaultTicketTypes([])
    }

    const create_custom_ticket_type = async (event) => {
        event.preventDefault()
        if (newTicketsCustomTicketTypeToAdd === "") {
            toast.error("You must insert the custom ticket type to add!")
            return
        }
        if (ticketsSettings.used_custom_ticket_types.find(ticket_type => ticket_type === newTicketsCustomTicketTypeToAdd)) {
            toast.error("This custom ticket type is already in use!")
            return
        }
        if (newCreateTicketsCustomTicketTypes.find(ticket_type => ticket_type === newTicketsCustomTicketTypeToAdd)) {
            toast.error("This custom ticket type is being added on the next save!")
            return
        }

        setNewCreateTicketsCustomTicketTypes(CreateTicketsCustomTicketTypes => CreateTicketsCustomTicketTypes.concat(newTicketsCustomTicketTypeToAdd))
        setNewTicketsCustomTicketTypeToAdd("")
        close_custom_ticket_type_sidebar(event)
        toast.success("The custom ticket type will be added on the next save! This can be canceled using the cancel button!")
    }

    const delete_custom_ticket_type = async (event) => {
        event.preventDefault()
        if (newDeleteTicketsCustomTicketTypes.find(ticket_type => ticket_type === event.target.id)) {
            toast.error("This custom ticket type is being deleted on the next save!")
            return
        }

        setNewDeleteTicketsCustomTicketTypes(prevDeleteTicketsCustomTicketTypes => prevDeleteTicketsCustomTicketTypes.concat(event.target.id))
        toast.success("The custom panel will be deleted on the next save! This can be canceled using the cancel button!")
    }

    const reset_new_custom_ticket_types = async () => {
        setNewCreateTicketsCustomTicketTypes([])
        setNewDeleteTicketsCustomTicketTypes([])
    }

    useEffect(() => {
        get_module_status()
        get_text_channels()
        get_category_channels()
        get_roles()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return(
        <div className='dashboard-modules-tickets-content-inner-wrapper'>
            { moduleTicketsLoadingStatus === 'success' && channelsLoadingStatus === 'success' && rolesLoadingStatus === 'success' ?
                moduleTicketsStatus === true ?
                    moduleTicketsSettingsLoadingStatus === 'success' ?  
                        <div className='dashboard-modules-tickets-enabled-container'> 
                            <div className='dashboard-modules-tickets-enabled-disable-wrapper'>
                                Click <label id="disable" onClick={set_module_status} className='dashboard-modules-tickets-enabled-disable-button'>here</label> if you want to disable this module!
                            </div>
                            <div className='dashboard-modules-tickets-enabled-details-reminder'>These details can't be changed!</div>
                            <div className='dashboard-modules-tickets-enabled-details-container'>
                                <div className='dashboard-modules-tickets-enabled-details'>
                                    <div className='dashboard-modules-tickets-enabled-details-label'>
                                        Tickets Category
                                    </div>
                                    <div className='dashboard-modules-tickets-enabled-details-description'>The tickets category.</div>
                                    <hr  className='dashboard-modules-tickets-enabled-details-devider' />
                                    <input disabled className='dashboard-modules-tickets-enabled-details-input' id='dashboard-modules-tickets-enabled-details-input' placeholder='' value={ticketsSettings.ticket_creation_category.name + " (" + ticketsSettings.ticket_creation_category.id + ")"} />
                                </div>
                                <div className='dashboard-modules-tickets-enabled-details'>
                                    <div className='dashboard-modules-tickets-enabled-details-label'>
                                        Tickets creation channel
                                    </div>
                                    <div className='dashboard-modules-tickets-enabled-details-description'>
                                        The channel where users can create tickets.&nbsp;
                                        <a href={"https://discord.com/channels/"+params.guild_id+"/"+ticketsSettings.ticket_creation_channel.id} target='_blank' rel="noreferrer" className='dashboard-modules-tickets-enabled-details-view-channel'>View channel!</a>
                                    </div>
                                    <hr  className='dashboard-modules-tickets-enabled-details-devider' />
                                    <input disabled className='dashboard-modules-tickets-enabled-details-input' id='dashboard-modules-tickets-enabled-details-input' placeholder='' value={ticketsSettings.ticket_creation_channel.name + " (" + ticketsSettings.ticket_creation_channel.id + ")"} />
                                </div>
                            </div>
                            <hr className='dashboard-modules-tickets-enabled-details-settings-devider' />
                            <div className='dashboard-modules-tickets-enabled-settings-container'>
                                <div className='dashboard-modules-tickets-enabled-settings-reminder'>Don't forget to save your changes!</div>
                                <div className='dashboard-modules-tickets-enabled-setting'>
                                    <div className='dashboard-modules-tickets-enabled-setting-label'>
                                        Thread mode
                                    </div>
                                    <div className='dashboard-modules-tickets-enabled-setting-description'>If new tickets should be posted in a designated thread or in a specified channel.</div>
                                    <hr  className='dashboard-modules-tickets-enabled-setting-devider' />
                                    { newTicketsThreadMode ?
                                        <div key="off">
                                            <div className='new-tickets-module-text-creation-wrapper'>
                                                <label className="toggler-wrapper style-1">
                                                    <input id="off" type="checkbox" name="new-tickets-module-thread-mode-input" defaultChecked onChange={on_field_change}></input>
                                                    <div className="toggler-slider">
                                                        <div className="toggler-knob"></div>
                                                    </div>
                                                </label>
                                            </div>
                                        </div> 
                                        :
                                        <div key="on">
                                            <div className='new-tickets-module-text-creation-wrapper'>
                                                <label className="toggler-wrapper style-1">
                                                    <input id="on" type="checkbox" name="new-tickets-module-thread-mode-input" onChange={on_field_change}></input>
                                                    <div className="toggler-slider">
                                                        <div className="toggler-knob"></div>
                                                    </div>
                                                </label>
                                            </div> 
                                        </div>
                                    }
                                    { newTicketsThreadMode === true ?
                                        <div></div>
                                        :
                                        <div>
                                            <div className='dashboard-modules-tickets-enabled-setting-description' id='dashboard-modules-tickets-post-channel-input-label'>When thread mode is disabled, you must select the channel where new tickets will be posted.</div>
                                            <select className='dashboard-modules-tickets-enabled-setting-input' id='dashboard-modules-tickets-post-channel-input' name='new-tickets-post-channel' onChange={on_field_change} value={newTicketsPostChannel}>
                                                { guildTextChannels.map(guild_channel => 
                                                    <option key={ String(guild_channel.id) } value={ String(guild_channel.id) }>
                                                        { guild_channel.name }
                                                    </option>
                                                )}
                                                <option key="create-default" value="create">
                                                    Create default
                                                </option>
                                                <option key="none" value="none">
                                                    No ticket post channel selected
                                                </option>
                                            </select>
                                        </div>
                                    }
                                </div>
                                <div className='dashboard-modules-tickets-enabled-setting'>
                                    <div className='dashboard-modules-tickets-enabled-setting-label'>
                                        Support Role
                                    </div>
                                    <div className='dashboard-modules-tickets-enabled-setting-description'>The role which will be used for assigning tickets to users and making clear which members offer support.</div>
                                    <hr  className='dashboard-modules-tickets-enabled-setting-devider' />
                                    <select className='dashboard-modules-tickets-enabled-setting-input' id='dashboard-modules-tickets-role-input' name='new-tickets-role' onChange={on_field_change} value={newTicketsRole}>
                                        { guildRoles.map(guild_role => 
                                            <option key={ String(guild_role.id) } value={ String(guild_role.id) }>
                                                { guild_role.name }
                                            </option>
                                        )}
                                        <option key="create-default" value="create">
                                            Create default
                                        </option>
                                    </select>
                                </div>
                                <div className='dashboard-modules-tickets-enabled-setting'>
                                    <div className='dashboard-modules-tickets-enabled-setting-label'>
                                        Custom ticket message type
                                    </div>
                                    <div className='dashboard-modules-tickets-enabled-setting-description'>The type of the ticket message.</div>
                                    <hr  className='dashboard-modules-tickets-enabled-setting-devider' />
                                    <select className='dashboard-modules-tickets-enabled-setting-input' id='dashboard-modules-tickets-message-type-input' name='new-tickets-message-type' onChange={on_field_change} value={newTicketsCustomMessage}>
                                        <option key="embed" value="embed">
                                            Embed
                                        </option>
                                        <option key="message" value="message">
                                            Message
                                        </option>
                                        <option key="default" value="default">
                                            Use default message
                                        </option>
                                    </select>
                                </div>
                                { newTicketsCustomMessage === "default" ?
                                    <div></div>
                                    :
                                    <div className='dashboard-modules-tickets-enabled-setting'>
                                        <div className='dashboard-modules-tickets-enabled-setting-label'>
                                            Custom ticket message
                                        </div>
                                        <div className='dashboard-modules-tickets-enabled-setting-description'>The configuration of the ticket message (Please use the Husqy Embed utility!).</div>
                                        <hr  className='dashboard-modules-tickets-enabled-setting-devider' />
                                        <textarea row={3} className='dashboard-modules-tickets-enabled-setting-input' id='dashboard-modules-tickets-message-input' name='new-tickets-message' placeholder='{json}' onChange={on_field_change} value={newTicketsCustomContent}/>
                                    </div>
                                }
                                <div className='dashboard-modules-tickets-enabled-setting'>
                                    <div className='dashboard-modules-tickets-enabled-setting-label'>
                                        Custom ticket modal
                                    </div>
                                    <div className='dashboard-modules-tickets-enabled-setting-description'>The configuration of the ticket modal (Please use the Husqy model utility!). When changing this, please test this function properly!</div>
                                    <hr  className='dashboard-modules-tickets-enabled-setting-devider' />
                                    <textarea row={3} className='dashboard-modules-tickets-enabled-setting-input' id='dashboard-modules-tickets-modal-input' name='new-tickets-modal' placeholder='{json}' onChange={on_field_change} value={newTicketsCustomModal}/>
                                </div>
                                
                                <div className='dashboard-modules-tickets-enabled-setting'>
                                    <div className='dashboard-modules-tickets-enabled-setting-label'>
                                        Default ticket types
                                    </div>
                                    <div className='dashboard-modules-tickets-enabled-setting-description'>The default ticket types users can use to create tickets.</div>
                                    <hr  className='dashboard-modules-tickets-enabled-setting-devider' />
                                    { ticketsSettings.used_default_ticket_types.length === 0 ?
                                        <div className='dashboard-modules-tickets-enabled-internal-setting-ticket-types-empty'>No default ticket types used</div> 
                                        :
                                        <div className='dashboard-modules-tickets-enabled-internal-setting-ticket-types-table'>
                                            <div className='dashboard-modules-tickets-enabled-internal-setting-ticket-types-table-titles'>
                                                <div className='dashboard-modules-tickets-enabled-internal-setting-ticket-types-table-titles-wrapper'>
                                                    <div className="dashboard-modules-tickets-enabled-internal-setting-ticket-types-table-titles-name-wrapper">
                                                        <p className="dashboard-modules-tickets-enabled-internal-setting-ticket-types-table-titles-name">Default ticket types</p>
                                                    </div>
                                                    <div className="dashboard-modules-tickets-enabled-internal-setting-ticket-types-table-interactions-wrapper">
                                                        <p className="dashboard-modules-tickets-enabled-internal-setting-ticket-types-table-interactions">Interactions</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="dashboard-modules-tickets-enabled-internal-setting-ticket-types-table-content">
                                                { ticketsSettings.used_default_ticket_types.map(ticket_types =>
                                                    <div className="ticket-types-entry-wrapper" key={ticket_types}>
                                                        <div className="ticket-types-entry-name-wrapper">
                                                            <p className="ticket-types-entry-name">{ ticket_types }</p>
                                                        </div>
                                                        <div className="ticket-types-entry-interactions-wrapper">
                                                            <div className="ticket-types-entry-interactions-inner-wrapper">
                                                                <div className="ticket-types-entry-interactions-form-delete">
                                                                    <span className="ticket-types-entry-interactions-delete-text" id={ticket_types} onClick={delete_default_ticket_type}>Delete</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    }
                                    <div className='dashboard-modules-tickets-enabled-internal-setting-ticket-types-buttons'>
                                        <div className='dashboard-modules-tickets-enabled-internal-setting-ticket-types-add-button' onClick={open_default_ticket_type_sidebar}>Add default ticket type</div>
                                        <div className='dashboard-modules-tickets-enabled-internal-setting-ticket-types-add-button' onClick={reset_new_default_ticket_types}>Reset all changes to the default ticket types</div>
                                    </div>
                                </div>
                                <div className='dashboard-modules-tickets-enabled-setting'>
                                    <div className='dashboard-modules-tickets-enabled-setting-label'>
                                        Custom ticket types
                                    </div>
                                    <div className='dashboard-modules-tickets-enabled-setting-description'>The custom ticket types users can use to create tickets.</div>
                                    <hr  className='dashboard-modules-tickets-enabled-setting-devider' />
                                    { ticketsSettings.used_custom_ticket_types.length === 0 ?
                                        <div className='dashboard-modules-tickets-enabled-internal-setting-ticket-types-empty'>No custom ticket types used</div> 
                                        :
                                        <div className='dashboard-modules-tickets-enabled-internal-setting-ticket-types-table'>
                                            <div className='dashboard-modules-tickets-enabled-internal-setting-ticket-types-table-titles'>
                                                <div className='dashboard-modules-tickets-enabled-internal-setting-ticket-types-table-titles-wrapper'>
                                                    <div className="dashboard-modules-tickets-enabled-internal-setting-ticket-types-table-titles-name-wrapper">
                                                        <p className="dashboard-modules-tickets-enabled-internal-setting-ticket-types-table-titles-name">Custom ticket types</p>
                                                    </div>
                                                    <div className="dashboard-modules-tickets-enabled-internal-setting-ticket-types-table-interactions-wrapper">
                                                        <p className="dashboard-modules-tickets-enabled-internal-setting-ticket-types-table-interactions">Interactions</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="dashboard-modules-tickets-enabled-internal-setting-ticket-types-table-content">
                                                { ticketsSettings.used_custom_ticket_types.map(ticket_types =>
                                                    <div className="ticket-types-entry-wrapper" key={ticket_types}>
                                                        <div className="ticket-types-entry-name-wrapper">
                                                            <p className="ticket-types-entry-name">{ ticket_types }</p>
                                                        </div>
                                                        <div className="ticket-types-entry-interactions-wrapper">
                                                            <div className="ticket-types-entry-interactions-inner-wrapper">
                                                                <div className="ticket-types-entry-interactions-form-delete">
                                                                    <span className="ticket-types-entry-interactions-delete-text" id={ticket_types} onClick={delete_custom_ticket_type}>Delete</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    }
                                    <div className='dashboard-modules-tickets-enabled-internal-setting-ticket-types-buttons'>
                                        <div className='dashboard-modules-tickets-enabled-internal-setting-ticket-types-add-button' onClick={open_custom_ticket_type_sidebar}>Add custom ticket type</div>
                                        <div className='dashboard-modules-tickets-enabled-internal-setting-ticket-types-add-button' onClick={reset_new_custom_ticket_types}>Reset all changes to the custom ticket types</div>
                                    </div>
                                </div>

                                <div className="dashboard-modules-tickets-settings-interactions-wrapper">
                                    <div className="dashboard-modules-tickets-settings-interactions-inner-wrapper">
                                        <GenericButton type="danger" on_click_action={reset_module_settings} button_text="Cancel" />
                                        <GenericButton button_text="Save" on_click_action={set_module_settings} />
                                    </div>
                                    <div className="dashboard-modules-tickets-settings-interactions-refresh">
                                        <RefreshButton on_click_action={refresh_data_table} />
                                    </div>
                                </div>
                            </div>
                            <div className='add-default-ticket-type-sidebar' id="add-default-ticket-type-sidebar">
                                <div className='add-default-ticket-type-wrapper'>
                                    <div className='add-default-ticket-type-cancel'>
                                        <div className="add-default-ticket-type-back-button" onClick={close_default_ticket_type_sidebar}>
                                            <div className="add-default-ticket-type-button-icon"><span className="material-symbols-outlined" id="tickets-close-icon">close</span></div>
                                            <div className="add-default-ticket-type-button-text-wrapper"><span className="add-default-ticket-type-button-text">Cancel & Reset</span></div>
                                        </div>
                                        <h2 className='add-default-ticket-type-title'>
                                            Add a default ticket type
                                        </h2>
                                    </div>
                                    <div className='add-default-ticket-type-selector'>
                                        <h3 className='add-default-ticket-type-label'>
                                            Default ticket types
                                        </h3>
                                        <select className='add-default-ticket-type-input' id='add-default-ticket-type-input' name='add-default-ticket-type' onChange={on_field_change} value={newTicketsDefaultTicketTypeToAdd}>
                                            <option key="no-selection" value="">
                                                No type selected yet
                                            </option>
                                            <option key="general_support" value="general_support">
                                                general_support
                                            </option>
                                            <option key="account_support" value="account_support">
                                                account_support
                                            </option>
                                            <option key="sales_billing_support" value="sales_billing_support">
                                                sales_billing_support
                                            </option>
                                            <option key="hardware_support" value="hardware_support">
                                                hardware_support
                                            </option>
                                            <option key="product_availability_support" value="product_availability_support">
                                                product_availability_support
                                            </option>
                                            <option key="bug_support" value="bug_support">
                                                bug_support
                                            </option>
                                            <option key="feature_request" value="feature_request">
                                                feature_request
                                            </option>
                                            <option key="feature_improvement" value="feature_improvement">
                                                feature_improvement
                                            </option>
                                            <option key="clear" value="clear">
                                                Clear ticket types and restart
                                            </option>
                                        </select>
                                    </div> 
                                </div>
                                <div className='add-default-ticket-type-interactions-wrapper'>
                                    <div className='submit'>
                                        <GenericButton button_text="Add" id="add" on_click_action={create_default_ticket_type} />
                                    </div>
                                </div>
                            </div>
                            <div className='add-custom-ticket-type-sidebar' id="add-custom-ticket-type-sidebar">
                                <div className='add-custom-ticket-type-wrapper'>
                                    <div className='add-custom-ticket-type-cancel'>
                                        <div className="add-custom-ticket-type-back-button" onClick={close_custom_ticket_type_sidebar}>
                                            <div className="add-custom-ticket-type-button-icon"><span className="material-symbols-outlined" id="tickets-close-icon">close</span></div>
                                            <div className="add-custom-ticket-type-button-text-wrapper"><span className="add-custom-ticket-type-button-text">Cancel & Reset</span></div>
                                        </div>
                                        <h2 className='add-custom-ticket-type-title'>
                                            Add a custom ticket type
                                        </h2>
                                    </div>
                                    <div className='add-custom-ticket-type-selector'>
                                        <h3 className='add-custom-ticket-type-label'>
                                            Custom ticket types
                                        </h3>
                                        <input className='add-custom-ticket-type-input' id='add-custom-ticket-type-input' name='add-custom-ticket-type' placeholder='' onChange={on_field_change} value={newTicketsCustomTicketTypeToAdd} />
                                    </div> 
                                </div>
                                <div className='add-custom-ticket-type-interactions-wrapper'>
                                    <div className='submit'>
                                        <GenericButton button_text="Add" id="add" on_click_action={create_custom_ticket_type} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <Loading loading_size='s' />
                    :
                    <div className='dashboard-modules-tickets-disabled-container'>
                        The Tickets module is disabled, click <label onClick={open_enable_tickets_module_sidebar} className='dashboard-modules-tickets-disabled-enable-button'>here</label> if you want to enabled this module!
                        <div className='enable-tickets-module-sidebar' id="enable-tickets-module-sidebar">
                            <div className='enable-tickets-module-wrapper'>
                                <div className='enable-tickets-module-cancel'>
                                    <div className="enable-tickets-module-back-button" onClick={close_enable_tickets_module_sidebar}>
                                        <div className="enable-tickets-module-button-icon"><span className="material-symbols-outlined" id="giveaway-close-icon">close</span></div>
                                        <div className="enable-tickets-module-button-text-wrapper"><span className="enable-tickets-module-button-text">Cancel & Reset</span></div>
                                    </div>
                                    <h2 className='enable-tickets-module-title'>
                                        Enable the Tickets Module
                                    </h2>
                                </div>
                                <div className='enable-tickets-module-role'>
                                    <h3 className='enable-tickets-module-role-label'>
                                        Support role*
                                    </h3>
                                    <select className='enable-tickets-module-role-input' id='enable-tickets-module-role-input' name='enable-tickets-module-role' onChange={on_field_change} value={enableTicketsRole}>
                                        { guildRoles.map(guild_role => 
                                            <option key={ String(guild_role.id) } value={ String(guild_role.id) }>
                                                { guild_role.name }
                                            </option>
                                        )}
                                        <option key="create-default" value="create">
                                            Create default
                                        </option>
                                        <option key="none-selected" value="">
                                            No role selected yet!
                                        </option>
                                    </select>
                                </div>
                                <div className='enable-tickets-module-category-channel'>
                                    <h3 className='enable-tickets-module-category-channel-label'>
                                        Ticket category channel*
                                    </h3>
                                    <select className='enable-tickets-module-category-channel-input' id='enable-tickets-module-category-channel-input' name='enable-tickets-module-category-channel' onChange={on_field_change} value={enableTicketsCategoryChannel}>
                                        { guildCategoryChannels.map(guild_channel => 
                                            <option key={ String(guild_channel.id) } value={ String(guild_channel.id) }>
                                                { guild_channel.name }
                                            </option>
                                        )}
                                        <option key="create-default" value="create">
                                            Create default
                                        </option>
                                        <option key="none-selected" value="">
                                            No channel selected yet!
                                        </option>
                                    </select>
                                </div>
                                <div className='enable-tickets-module-creation-channel'>
                                    <h3 className='enable-tickets-module-creation-channel-label'>
                                        Ticket creation channel*
                                    </h3>
                                    <select className='enable-tickets-module-creation-channel-input' id='enable-tickets-module-creation-channel-input' name='enable-tickets-module-creation-channel' onChange={on_field_change} value={enableTicketsCreationChannel}>
                                        { guildTextChannels.map(guild_channel => 
                                            <option key={ String(guild_channel.id) } value={ String(guild_channel.id) }>
                                                { guild_channel.name }
                                            </option>
                                        )}
                                        <option key="create-default" value="create">
                                            Create default
                                        </option>
                                        <option key="none-selected" value="">
                                            No channel selected yet!
                                        </option>
                                    </select>
                                </div>
                                <div className='enable-tickets-module-thread-mode'>
                                    <h3 className='enable-tickets-module-thread-mode-label'>
                                        Thread mode*
                                    </h3>
                                    { enableTicketsThreadMode ?
                                        <div key="off">
                                            <div className='enable-tickets-module-thread-mode-wrapper'>
                                                <label className="toggler-wrapper style-1">
                                                    <input id="off" type="checkbox" name="enable-tickets-module-thread-mode-input" defaultChecked onChange={on_field_change}></input>
                                                    <div className="toggler-slider">
                                                        <div className="toggler-knob"></div>
                                                    </div>
                                                </label>
                                            </div>
                                        </div> 
                                        :
                                        <div key="on">
                                            <div className='enable-tickets-module-thread-mode-wrapper'>
                                                <label className="toggler-wrapper style-1">
                                                    <input id="on" type="checkbox" name="enable-tickets-module-thread-mode-input" onChange={on_field_change}></input>
                                                    <div className="toggler-slider">
                                                        <div className="toggler-knob"></div>
                                                    </div>
                                                </label>
                                            </div> 
                                        </div>
                                    }
                                </div>
                                { enableTicketsThreadMode === false ?
                                    <div className='enable-tickets-module-post-channel'>
                                        <h3 className='enable-tickets-module-post-channel-label'>
                                            Ticket post channel*
                                        </h3>
                                        <select className='enable-tickets-module-post-channel-input' id='enable-tickets-module-post-channel-input' name='enable-tickets-module-post-channel' onChange={on_field_change} value={enableTicketsPostChannel}>
                                            { guildTextChannels.map(guild_channel => 
                                                <option key={ String(guild_channel.id) } value={ String(guild_channel.id) }>
                                                    { guild_channel.name }
                                                </option>
                                            )}
                                            <option key="create-default" value="create">
                                                Create default
                                            </option>
                                            <option key="none-selected" value="">
                                                No channel selected yet!
                                            </option>
                                        </select>
                                    </div>
                                    :
                                    <div></div>
                                }
                                <div className='enable-tickets-module-setup-mode'>
                                    <h3 className='enable-tickets-module-setup-mode-label'>
                                        Setup mode*
                                    </h3>
                                    <select className='enable-tickets-module-setup-mode-input' id='enable-tickets-module-setup-mode-input' name='enable-tickets-module-setup-mode' onChange={on_field_change} value={enableTicketsSetupMode}>
                                        <option key="setup-minimal" value="minimal">
                                            Minimal
                                        </option>
                                        <option key="setup-complete" value="complete">
                                            Complete
                                        </option>
                                        <option key="setup-custom" value="custom">
                                            Custom
                                        </option>
                                        <option key="none-selected" value="">
                                            No mode selected yet!
                                        </option>
                                    </select>
                                </div>
                                { enableTicketsSetupMode === "custom" ?
                                    <div>
                                        <div className='enable-tickets-module-tickets-message'>
                                            <h3 className='enable-tickets-module-tickets-message-label'>
                                                Custom ticket message type*
                                            </h3>
                                            <select className='enable-tickets-module-tickets-message-input' id='enable-tickets-module-tickets-message-input' name='enable-tickets-module-tickets-message' onChange={on_field_change} value={enableTicketsCustomMessage}>
                                                <option key="embed" value="embed">
                                                    Embed
                                                </option>
                                                <option key="message" value="message">
                                                    Message
                                                </option>
                                                <option key="default" value="default">
                                                    Use default message
                                                </option>
                                            </select>
                                        </div>
                                        { enableTicketsCustomMessage !== "default" ?
                                            <div className='enable-tickets-module-content'>
                                                <h3 className='enable-tickets-module-content-label'>
                                                    Custom content (Note: If Custom ticket message type is Embed, this needs to be JSON!)*
                                                </h3>
                                                <textarea row={3} className='enable-tickets-module-content-input' id='enable-tickets-module-content-input' name='enable-tickets-module-content' placeholder='{json}' onChange={on_field_change} value={enableTicketsCustomContent}/>
                                            </div>
                                            :
                                            <div></div>
                                        }
                                        <div className='enable-tickets-module-tickets-modal-type'>
                                            <h3 className='enable-tickets-module-tickets-modal-type-label'>
                                                Custom modal type*
                                            </h3>
                                            <select className='enable-tickets-module-tickets-modal-type-input' id='enable-tickets-module-tickets-modal-type-input' name='enable-tickets-module-tickets-modal-type' onChange={on_field_change} value={enableTicketsCustomModalType}>
                                                <option key="custom" value="custom">
                                                    Custom
                                                </option>
                                                <option key="default" value="default">
                                                    Use default message
                                                </option>
                                            </select>
                                        </div>
                                        { enableTicketsCustomModalType !== 'default' ?
                                            <div className='enable-tickets-module-modal'>
                                                <h3 className='enable-tickets-module-modal-label'>
                                                    Custom modal (JSON)*
                                                </h3>
                                                <textarea row={3} className='enable-tickets-module-modal-input' id='enable-tickets-module-modal-input' name='enable-tickets-module-modal' placeholder='{json}' onChange={on_field_change} value={enableTicketsCustomModal}/>
                                            </div>
                                            :
                                            <div></div>
                                        }
                                        <div className='enable-tickets-module-default-ticket-types'>
                                            <h3 className='enable-tickets-module-default-ticket-types-label'>
                                                Default ticket types
                                            </h3>
                                            <select className='enable-tickets-module-default-ticket-types-input' id='enable-tickets-module-default-ticket-types-input' name='enable-tickets-module-default-ticket-types' onChange={on_field_change} value="">
                                                <option key="no-selection" value="">
                                                    Make selection
                                                </option>
                                                <option key="general_support" value="general_support">
                                                    general_support
                                                </option>
                                                <option key="account_support" value="account_support">
                                                    account_support
                                                </option>
                                                <option key="sales_billing_support" value="sales_billing_support">
                                                    sales_billing_support
                                                </option>
                                                <option key="hardware_support" value="hardware_support">
                                                    hardware_support
                                                </option>
                                                <option key="product_availability_support" value="product_availability_support">
                                                    product_availability_support
                                                </option>
                                                <option key="bug_support" value="bug_support">
                                                    bug_support
                                                </option>
                                                <option key="feature_request" value="feature_request">
                                                    feature_request
                                                </option>
                                                <option key="feature_improvement" value="feature_improvement">
                                                    feature_improvement
                                                </option>
                                                <option key="clear" value="clear">
                                                    Clear ticket types and restart
                                                </option>
                                            </select>
                                            <div className='enable-tickets-module-default-ticket-types-overview'>
                                                { enableTicketsDefaultTicketTypes.length === 0 ?
                                                    <div className='enable-tickets-module-default-ticket-types-overview-item'>No ticket types added yet!</div>
                                                    :
                                                    enableTicketsDefaultTicketTypes.map(ticket_type => 
                                                        <div key={ ticket_type } value={ String(ticket_type) } className='enable-tickets-module-default-ticket-types-overview-item'>
                                                            <div className='enable-tickets-module-default-ticket-types-item-title'>{ ticket_type },&nbsp;</div>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                        <div className='enable-tickets-module-custom-ticket-types'>
                                            <h3 className='enable-tickets-module-custom-ticket-types-label'>
                                                Custom ticket types
                                            </h3>
                                            <div className='enable-tickets-module-custom-ticket-types-input-wrapper'>
                                                <input className='enable-tickets-module-custom-ticket-types-input' id='enable-tickets-module-custom-ticket-types-input' name='enable-tickets-module-custom-ticket-types' placeholder='' onChange={on_field_change} value={enableTicketsCustomTicketTypesTemp} />
                                                <GenericButton type="secondary" id="enable-tickets-module-custom-ticket-types-save" name="enable-tickets-module-custom-ticket-types-save" icon_name='add' on_click_action={on_field_change} />
                                            </div>
                                            <div className='enable-tickets-module-default-ticket-types-overview'>
                                                { enableTicketsCustomTicketTypes.length === 0 ?
                                                    <div className='enable-tickets-module-default-ticket-types-overview-item'>No ticket types added yet!</div>
                                                    :
                                                    enableTicketsCustomTicketTypes.map(ticket_type => 
                                                        <div key={ ticket_type } value={ String(ticket_type) } className='enable-tickets-module-default-ticket-types-overview-item'>
                                                            <div className='enable-tickets-module-default-ticket-types-item-title'>{ ticket_type },&nbsp;</div>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div></div>
                                }
                            </div>
                            <div className='enable-tickets-module-interactions-wrapper'>
                                <div className='submit'>
                                    <GenericButton button_text="Enable" id="enable" on_click_action={set_module_status} />
                                </div>
                            </div>
                        </div>
                    </div>
                :
                <Loading loading_size='s' />
            }
        </div>
    );
};

export default DashboardModulesTicketsContent;