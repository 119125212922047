import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import { toast } from 'react-toastify'
import axios from 'axios'
import Loading from '../../../components/loading'
import GenericButton from "../../../components/buttons/generic_button"
import RefreshButton from "../../../components/buttons/refresh_button"

import '../../../styles/pages/dashboard/modules/autoresponderContent.css'
import '../../../js/sticky_modules_autoresponder_bars.js'
import config from '../../../config'

const DashboardModulesAutoresponderContent = () => {
    const [cookies] = useCookies(['access_token'])

    const [moduleAutoresponderStatus, setModuleAutoresponderStatus] = useState(false)
    const [moduleAutoresponderEntries, setModuleAutoresponderEntries] = useState([])
    const [currentPage, setCurrentPage] = useState(1)

    const [guildChannels, setGuildChannels] = useState([])
    const [guildRoles, setGuildRoles] = useState([])
    const [newAutoresponderEntryTrigger, setNewAutoresponderEntryTrigger] = useState("")
    const [newAutoresponderEntryType, setNewAutoresponderEntryType] = useState("")
    const [newAutoresponderEntryContent, setNewAutoresponderEntryContent] = useState("")
    const [newAutoresponderEntryAllowedChannels, setNewAutoresponderEntryAllowedChannels] = useState([])
    const [newAutoresponderEntryIgnoredChannels, setNewAutoresponderEntryIgnoredChannels] = useState([])
    const [newAutoresponderEntryAllowedRoles, setNewAutoresponderEntryAllowedRoles] = useState([])
    const [newAutoresponderEntryIgnoredRoles, setNewAutoresponderEntryIgnoredRoles] = useState([])

    const params = useParams()
    const [moduleAutoresponderLoadingStatus, setModuleAutoresponderLoadingStatus] = useState('loading')
    const [channelsLoadingStatus, setChannelsLoadingStatus] = useState('loading')
    const [rolesLoadingStatus, setRolesLoadingStatus] = useState('loading')
    const [moduleAutoresponderSettingsLoadingStatus, setModuleAutoresponderSettingsLoadingStatus] = useState('loading')

    // FIELD CHANGES
    const on_field_change = async (event) => {
        event.preventDefault();
        if (event.target.id === "create-autoresponder-entry-trigger-input") {
            setNewAutoresponderEntryTrigger(event.target.value)
        } else if (event.target.id === "create-autoresponder-entry-type-input") {
            setNewAutoresponderEntryType(event.target.value)
        } else if (event.target.id === "create-autoresponder-entry-content-input") {
            setNewAutoresponderEntryContent(event.target.value)
        } else if (event.target.id === "create-autoresponder-entry-allowed-channel-id-input") {
            var new_autoresponder_entry_allowed_channels = newAutoresponderEntryAllowedChannels 
            if (event.target.value === "all") {
                new_autoresponder_entry_allowed_channels = []
                guildChannels.forEach(channel => {
                    new_autoresponder_entry_allowed_channels = new_autoresponder_entry_allowed_channels.concat({
                        "name": channel.name,
                        "id": String(channel.id)
                    })
                });
                setNewAutoresponderEntryAllowedChannels(new_autoresponder_entry_allowed_channels)
            } else if (event.target.value === "clear") {
                setNewAutoresponderEntryAllowedChannels([])
                setNewAutoresponderEntryIgnoredChannels([])
            } else {
                if (new_autoresponder_entry_allowed_channels.find(channel => channel.id === event.target.value)) {
                    return
                }
                var new_channel_allowed_entry = guildChannels.find(channel => channel.id === event.target.value)
                new_autoresponder_entry_allowed_channels = new_autoresponder_entry_allowed_channels.concat({
                    "name": new_channel_allowed_entry.name,
                    "id": String(new_channel_allowed_entry.id)
                })
                setNewAutoresponderEntryAllowedChannels(new_autoresponder_entry_allowed_channels)
            }
        } else if (event.target.id === "create-autoresponder-entry-ignored-channel-id-input") {
            var new_autoresponder_entry_ignored_channels = newAutoresponderEntryIgnoredChannels 
            if (event.target.value === "all") {
                new_autoresponder_entry_ignored_channels = []
                guildChannels.forEach(channel => {
                    new_autoresponder_entry_ignored_channels = new_autoresponder_entry_ignored_channels.concat({
                        "name": channel.name,
                        "id": String(channel.id)
                    })
                });
                setNewAutoresponderEntryIgnoredChannels(new_autoresponder_entry_ignored_channels)
            } else if (event.target.value === "clear") {
                setNewAutoresponderEntryIgnoredChannels([])
            } else {
                if (new_autoresponder_entry_ignored_channels.find(channel => channel.id === event.target.value)) {
                    return
                }
                var new_channel_ignored_entry = guildChannels.find(channel => channel.id === event.target.value)
                new_autoresponder_entry_ignored_channels = new_autoresponder_entry_ignored_channels.concat({
                    "name": new_channel_ignored_entry.name,
                    "id": String(new_channel_ignored_entry.id)
                })
                setNewAutoresponderEntryIgnoredChannels(new_autoresponder_entry_ignored_channels)
            }
        } else if (event.target.id === "create-autoresponder-entry-allowed-role-id-input") {
            var new_autoresponder_entry_allowed_roles = newAutoresponderEntryAllowedRoles 
            if (event.target.value === "all") {
                new_autoresponder_entry_allowed_roles = []
                guildRoles.forEach(role => {
                    new_autoresponder_entry_allowed_roles = new_autoresponder_entry_allowed_roles.concat({
                        "name": role.name,
                        "id": String(role.id)
                    })
                });
                setNewAutoresponderEntryAllowedRoles(new_autoresponder_entry_allowed_roles)
            } else if (event.target.value === "clear") {
                setNewAutoresponderEntryAllowedRoles([])
                setNewAutoresponderEntryIgnoredRoles([])
            } else {
                if (new_autoresponder_entry_allowed_roles.find(role => role.id === event.target.value)) {
                    return
                }
                var new_role_allowed_entry = guildRoles.find(role => role.id === event.target.value)
                new_autoresponder_entry_allowed_roles = new_autoresponder_entry_allowed_roles.concat({
                    "name": new_role_allowed_entry.name,
                    "id": String(new_role_allowed_entry.id)
                })
                setNewAutoresponderEntryAllowedRoles(new_autoresponder_entry_allowed_roles)
            }
        } else if (event.target.id === "create-autoresponder-entry-ignored-role-id-input") {
            var new_autoresponder_entry_ignored_roles = newAutoresponderEntryIgnoredRoles
            if (event.target.value === "all") {
                new_autoresponder_entry_ignored_roles = []
                guildRoles.forEach(role => {
                    new_autoresponder_entry_ignored_roles = new_autoresponder_entry_ignored_roles.concat({
                        "name": role.name,
                        "id": String(role.id)
                    })
                });
                setNewAutoresponderEntryIgnoredRoles(new_autoresponder_entry_ignored_roles)
            } else if (event.target.value === "clear") {
                setNewAutoresponderEntryIgnoredRoles([])
            } else {
                if (new_autoresponder_entry_ignored_roles.find(role => role.id === event.target.value)) {
                    return
                }
                var new_role_ignored_entry = guildRoles.find(role => role.id === event.target.value)
                new_autoresponder_entry_ignored_roles = new_autoresponder_entry_ignored_roles.concat({
                    "name": new_role_ignored_entry.name,
                    "id": String(new_role_ignored_entry.id)
                })
                setNewAutoresponderEntryIgnoredRoles(new_autoresponder_entry_ignored_roles)
            }
        }
    }
    
    const open_create_autoresponder_entry_popup = async (event) => {
        event.preventDefault()
        var bar = document.getElementById('create-autoresponder-entry-sidebar')
        bar.style.transform = "translateX(0px)"
    }
    const close_create_autoresponder_entry_popup = async (event) => {
        event.preventDefault()
        setNewAutoresponderEntryTrigger("")
        setNewAutoresponderEntryType("")
        setNewAutoresponderEntryContent("")
        setNewAutoresponderEntryAllowedChannels([])
        setNewAutoresponderEntryIgnoredChannels([])
        setNewAutoresponderEntryAllowedRoles([])
        setNewAutoresponderEntryIgnoredRoles([])
        var bar = document.getElementById('create-autoresponder-entry-sidebar')
        bar.style.transform = "translateX(+502px)"
    }

    const create_autoresponder_entry = async (event) => {
        event.preventDefault()
        var new_autoresponder_entry = {}
        if (newAutoresponderEntryTrigger === "") {
            toast.error("You need to fill in an trigger!")
            return
        }
        if (newAutoresponderEntryType === "") {
            toast.error("You need to select in an type!")
            return
        }
        if (newAutoresponderEntryContent === "") {
            toast.error("You need to fill in the content to respond with!")
            return
        }
        if (newAutoresponderEntryAllowedChannels.length === 0) {
            toast.error("You need to select the allowed channels!")
            return
        }
        if (newAutoresponderEntryAllowedRoles.length === 0) {
            toast.error("You need to select the allowed roles!")
            return
        }
        
        new_autoresponder_entry.trigger = newAutoresponderEntryTrigger
        new_autoresponder_entry.type = newAutoresponderEntryType
        new_autoresponder_entry.content = newAutoresponderEntryContent
        if (newAutoresponderEntryAllowedChannels.length === guildChannels.length) {
            new_autoresponder_entry.allowed_channels = "all"
        } else {
            new_autoresponder_entry.allowed_channels = newAutoresponderEntryAllowedChannels
        }
        new_autoresponder_entry.ignored_channels = newAutoresponderEntryIgnoredChannels
        if (newAutoresponderEntryAllowedRoles.length === guildRoles.length) {
            new_autoresponder_entry.allowed_roles = "all"
        } else {
            new_autoresponder_entry.allowed_roles = newAutoresponderEntryAllowedRoles
        }
        new_autoresponder_entry.ignored_roles = newAutoresponderEntryIgnoredRoles
        new_autoresponder_entry.guild_id = params.guild_id
        new_autoresponder_entry.action_type = "create"

        const options = {
            url: `${config["api_base_url"]}/guilds/${params.guild_id}/modules/autoresponder/entries`,
            method: 'POST',
            headers: {
                access_token: cookies["access_token"]
            },
            data: new_autoresponder_entry
          };

        axios(options)
          .then(response => {
              if (response.status === 202) {
                  window.location.reload()
              } else if (response.status === 500) {
                  toast.error("An autoresponder entry with that trigger already exists!")
              }
          })
          .catch(error => {
              console.log(error)
              if (error.response.status === 500) {
                toast.error("An autoresponder entry with that trigger already exists!")
              } else if (error.response.status === 429) {
                toast.error("Rate limit reached! Please slow down!")
              }else {
                toast.error("Something went wrong while creating the autoresponder entry!")
              }
          });
    }

    const delete_autoresponder_entry = async (event) => {
        event.preventDefault()
        
        var old_autoresponder_entry = {}
        old_autoresponder_entry.guild_id = params.guild_id
        old_autoresponder_entry.action_type = "delete"
        old_autoresponder_entry.autoresponder_entry_id = event.target.id
    
        const options = {
            url: `${config["api_base_url"]}/guilds/${params.guild_id}/modules/autoresponder/entries/${event.target.id}`,
            method: 'DELETE',
            headers: {
                access_token: cookies["access_token"]
            },
            data: old_autoresponder_entry,
          };

        axios(options)
          .then(response => {
              if (response.status === 202) {
                  window.location.reload()
              } else if (response.status === 500) {
                  toast.error("Something went wrong while trying to delete the autoresponder entry!")
              }
          })
          .catch(error => {
              console.log(error)
              if (error.response.status === 429) {
                toast.error("Rate limit reached! Please slow down!")
                return
            }
              toast.error("Something went wrong while deleting the autoresponder entry!")
          });
    }

    const view_allowed_channels = async (event) => {
        event.preventDefault()
        var popup = document.getElementById(event.target.id+"-allowed-channels-popup");
        popup.style.visibility = "visible"
        popup.style.opacity = 1
    }
    const view_ignored_channels = async (event) => {
        event.preventDefault()
        var popup = document.getElementById(event.target.id+"-ignored-channels-popup");
        popup.style.visibility = "visible"
        popup.style.opacity = 1
    }
    const view_allowed_roles = async (event) => {
        event.preventDefault()
        var popup = document.getElementById(event.target.id+"-allowed-roles-popup");
        popup.style.visibility = "visible"
        popup.style.opacity = 1
    }
    const view_ignored_roles = async (event) => {
        event.preventDefault()
        var popup = document.getElementById(event.target.id+"-ignored-roles-popup");
        popup.style.visibility = "visible"
        popup.style.opacity = 1
    }

    const close_allowed_channels = async (event) => {
        event.preventDefault()
        var popup = document.getElementById(event.target.id+"-allowed-channels-popup");
        popup.style.visibility = "hidden"
        popup.style.opacity = 0
    }
    const close_ignored_channels = async (event) => {
        event.preventDefault()
        var popup = document.getElementById(event.target.id+"-ignored-channels-popup");
        popup.style.visibility = "hidden"
        popup.style.opacity = 0
    }
    const close_allowed_roles = async (event) => {
        event.preventDefault()
        var popup = document.getElementById(event.target.id+"-allowed-roles-popup");
        popup.style.visibility = "hidden"
        popup.style.opacity = 0
    }
    const close_ignored_roles = async (event) => {
        event.preventDefault()
        var popup = document.getElementById(event.target.id+"-ignored-roles-popup");
        popup.style.visibility = "hidden"
        popup.style.opacity = 0
    }

    // Refresh data
    const refresh_data_table = async (event) => {
        event.preventDefault();
        setCurrentPage(1)
        setModuleAutoresponderEntries([])
        window.location.reload()
    }

    // Module Status
    const open_enable_autoresponder_module_sidebar = async (event) => {
        event.preventDefault()
        var bar = document.getElementById('enable-autoresponder-module-sidebar')
        bar.style.transform = "translateX(0px)"
    }
    const close_enable_autoresponder_module_sidebar = async (event) => {
        event.preventDefault()
        var bar = document.getElementById('enable-autoresponder-module-sidebar')
        bar.style.transform = "translateX(+502px)"
    }

    const get_module_status = async () => {
        setModuleAutoresponderLoadingStatus("loading")
        const options = {
            url: `${config["api_base_url"]}/guilds/${params.guild_id}/modules?module_names=autoresponder`,
            method: 'GET',
            headers: {
                access_token: cookies["access_token"]
            }
          };
        axios(options)
        .then(response => {
            if (response.status === 200) {
                    setModuleAutoresponderStatus(response["data"]["data"]["module_autoresponder"])
                    if (response["data"]["data"]["module_autoresponder"] === true) {
                        get_module_settings()
                    }
                }
                setModuleAutoresponderLoadingStatus("success")
        })
        .catch(error => {
            console.log(error)
            if (error.response.status === 429) {
                toast.error("Rate limit reached! Please slow down!")
                return
            }
            setModuleAutoresponderLoadingStatus("error")
            toast.error("Something went wrong while fetching the module Autoresponder status!")
        });
    }

    const set_module_status = async (event) => {
        var new_status
        if (event.target.id === "enable") {
            new_status = true
        } else if (event.target.id === "disable") {
            new_status = false
        }

        var data = {}
        data.autoresponder = {}
        data.autoresponder.new_status = new_status
        data.autoresponder.guild_id = params.guild_id
        
        const options = {
            url: `${config["api_base_url"]}/guilds/${params.guild_id}/modules`,
            method: 'POST',
            headers: {
                access_token: cookies["access_token"]
            },
            data: data
          };

        axios(options)
        .then(response => {
            if (response.status === 202) {
                if (response["data"]["data"]["autoresponder"]["code"] === 200) {
                    window.location.reload()
                }
            } else {
                toast.error("Something went wrong while updating the Autoresponder module!")
            }
        })
        .catch(error => {
            console.log(error)
            if (error.response.status === 429) {
                toast.error("Rate limit reached! Please slow down!")
                return
            }
            toast.error("Something went wrong while updating the Autoresponder module!")
        });
    }

    // Get data
    const get_channels = async () => {
        setChannelsLoadingStatus("loading")
        const options = {
            url: `${config["api_base_url"]}/guilds/${params.guild_id}/channels?types=text`,
            method: 'GET',
            headers: {
                access_token: cookies["access_token"]
            },
          };

          axios(options)
          .then(response => {
              if (response.status === 200) {
                  setGuildChannels(response["data"][0]["data"])
                  setChannelsLoadingStatus("success")
              }
          })
          .catch(error => {
              console.log(error)
              setChannelsLoadingStatus("error")
              if (error.response.status === 429) {
                toast.error("Rate limit reached! Please slow down!")
                return
            }
              toast.error("Something went wrong while getting the servers channels!")
          });
    }

    const get_roles = async () => {
        setRolesLoadingStatus("loading")
        const options = {
            url: `${config["api_base_url"]}/guilds/${params.guild_id}/roles`,
            method: 'GET',
            headers: {
                access_token: cookies["access_token"]
            },
          };

        axios(options)
          .then(response => {
              if (response.status === 200) {
                  setGuildRoles(response["data"]["data"])
                  setRolesLoadingStatus("success")
              }
          })
          .catch(error => {
              console.log(error)
              setRolesLoadingStatus("error")
              if (error.response.status === 429) {
                toast.error("Rate limit reached! Please slow down!")
                return
            }
              toast.error("Something went wrong while getting the servers roles!")
          });
    }

    const get_extra_data = async (event) => {
        event.preventDefault();
        get_module_settings()
    }

    const get_module_settings = async () => {
        setModuleAutoresponderSettingsLoadingStatus("loading")
        const options = {
            url: `${config["api_base_url"]}/guilds/${params.guild_id}/modules/autoresponder/entries?page=${currentPage}`,
            method: 'GET',
            headers: {
                access_token: cookies["access_token"]
            },
          };
          axios(options)
          .then(response => {
              if (response.status === 200) {
                if (response["data"]["data"].length === 0) {
                    setCurrentPage(currentPage)
                } else {
                    setCurrentPage(currentPage+1)
                    if (moduleAutoresponderEntries.length === 0 ) {
                        setModuleAutoresponderEntries(response["data"]["data"])
                    } else {
                        var newModuleAutoresponderEntries = moduleAutoresponderEntries.concat(response["data"]["data"])
                        setModuleAutoresponderEntries(newModuleAutoresponderEntries)
                    }
                }
                setModuleAutoresponderSettingsLoadingStatus("success")
              }
          })
          .catch(error => {
              console.log(error)
              setModuleAutoresponderSettingsLoadingStatus("error")
              if (error.response.status === 429) {
                toast.error("Rate limit reached! Please slow down!")
                return
            }
              toast.error("Something went wrong while getting the entries for the Autoresponder module!")
          });
    }

    useEffect(() => {
        get_module_status()
        get_channels()
        get_roles()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return(
        <div className='dashboard-modules-autoresponder-content-inner-wrapper'>
            { moduleAutoresponderLoadingStatus === 'success' && channelsLoadingStatus === 'success' && rolesLoadingStatus === 'success' ?
                moduleAutoresponderStatus === true ?
                    moduleAutoresponderSettingsLoadingStatus === 'success' ?  
                        <div className='dashboard-modules-autoresponder-enabled-container'> 
                            <div className='dashboard-modules-autoresponder-enabled-disable-wrapper'>
                                Click <label id="disable" onClick={set_module_status} className='dashboard-modules-autoresponder-enabled-disable-button'>here</label> if you want to disable this module!
                            </div>
                            <div className='dashboard-modules-autoresponder-table-general-interactions-wrapper'>
                                <div className='dashboard-modules-autoresponder-table-data'>
                                    <RefreshButton on_click_action={refresh_data_table} />
                                </div>
                                <div className='dashboard-modules-autoresponder-table-tag'>
                                    <GenericButton button_text="Create entry" on_click_action={open_create_autoresponder_entry_popup} />
                                </div>
                            </div>
                            <div className='dashboard-modules-autoresponder-table-table-wrapper'>
                                { moduleAutoresponderEntries.length === 0 ?
                                    <div className='dashboard-modules-autoresponder-table-no-entries-wrapper'>
                                        This server does not have any autoresponder entries yet!
                                    </div>
                                    :
                                    <div>
                                        <div className="dashboard-modules-autoresponder-table">
                                            <div className="dashboard-modules-autoresponder-table-titles">
                                                <div className="dashboard-modules-autoresponder-table-titles-wrapper">
                                                    <div className="dashboard-modules-autoresponder-table-titles-id-wrapper">
                                                        <p className="dashboard-modules-autoresponder-table-titles-id">Entry ID</p>
                                                    </div>
                                                    <div className="dashboard-modules-autoresponder-table-titles-type-wrapper">
                                                        <p className="dashboard-modules-autoresponder-table-titles-type">Entry type</p>
                                                    </div>
                                                    <div className="dashboard-modules-autoresponder-table-titles-trigger-wrapper">
                                                        <p className="dashboard-modules-autoresponder-table-titles-trigger">Entry trigger</p>
                                                    </div>
                                                    <div className="dashboard-modules-autoresponder-table-titles-response-wrapper">
                                                        <p className="dashboard-modules-autoresponder-table-titles-response">Entry response</p>
                                                    </div>
                                                    <div className="dashboard-modules-autoresponder-table-titles-channels-wrapper">
                                                        <p className="dashboard-modules-autoresponder-table-titles-channels">Channels</p>
                                                    </div>
                                                    <div className="dashboard-modules-autoresponder-table-titles-roles-wrapper">
                                                        <p className="dashboard-modules-autoresponder-table-titles-roles">Roles</p>
                                                    </div>
                                                    <div className="dashboard-modules-autoresponder-table-titles-interactions-wrapper">
                                                        <p className="dashboard-modules-autoresponder-table-titles-interactions">Interactions</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="dashboard-modules-autoresponder-table-content">
                                                { moduleAutoresponderEntries.map(entry => 
                                                    <div className="autoresponder-entry-wrapper" key={entry.name}>
                                                        <div className="autoresponder-entry-id-wrapper">
                                                            <p className="autoresponder-entry-id">{ entry.autoresponder_response_id }</p>
                                                        </div>
                                                        <div className="autoresponder-entry-type-wrapper">
                                                            <p className="autoresponder-entry-type">{ entry.response_type }</p>
                                                        </div>
                                                        <div className="autoresponder-entry-trigger-wrapper">
                                                            <p className="autoresponder-entry-trigger">{ entry.responding_to }</p>
                                                        </div>
                                                        <div className="autoresponder-entry-response-wrapper">
                                                            <p className="autoresponder-entry-response">{ entry.respond_with_content }</p>
                                                        </div>
                                                        <div className="autoresponder-entry-channels-wrapper">
                                                            <p className="autoresponder-entry-channels-allowed" id={entry.autoresponder_response_id} onClick={view_allowed_channels}>Allowed</p>
                                                            <p className="autoresponder-entry-channels-ignored" id={entry.autoresponder_response_id} onClick={view_ignored_channels}>Ignored</p>
                                                        </div>
                                                        <div className="autoresponder-entry-channels-allowed-popup-container" key={entry.autoresponder_response_id} id={entry.autoresponder_response_id + "-allowed-channels-popup"}>
                                                            <div className="autoresponder-entry-channels-allowed-popup-background"></div>
                                                            <div className="autoresponder-entry-channels-allowed-popup-wrapper">
                                                                <div>
                                                                    <div className="autoresponder-entry-channels-allowed-popup-channels-title">
                                                                        Allowed channels
                                                                    </div>
                                                                    <div className="autoresponder-entry-channels-allowed-popup-channels">
                                                                        { entry.allowed_channels.map(channel =>
                                                                            <div>{channel.name} ({channel.id})</div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <div className="autoresponder-entry-channels-allowed-popup-close" id={entry.autoresponder_response_id}>
                                                                    <GenericButton button_text="Close" key={entry.autoresponder_response_id} id={entry.autoresponder_response_id} on_click_action={close_allowed_channels} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="autoresponder-entry-channels-ignored-popup-container" key={entry.autoresponder_response_id} id={entry.autoresponder_response_id + "-ignored-channels-popup"}>
                                                            <div className="autoresponder-entry-channels-ignored-popup-background"></div>
                                                            <div className="autoresponder-entry-channels-ignored-popup-wrapper">
                                                                <div>
                                                                    <div className="autoresponder-entry-channels-ignored-popup-channels-title">
                                                                        Ignored channels
                                                                    </div>
                                                                    <div className="autoresponder-entry-channels-ignored-popup-channels">
                                                                        { entry.ignored_channels.map(channel =>
                                                                            <div>{channel.name} ({channel.id})</div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <div className="autoresponder-entry-channels-ignored-popup-close">
                                                                    <GenericButton button_text="Close" key={entry.autoresponder_response_id} id={entry.autoresponder_response_id} on_click_action={close_ignored_channels} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="autoresponder-entry-roles-wrapper">
                                                            <p className="autoresponder-entry-roles-allowed" id={entry.autoresponder_response_id} onClick={view_allowed_roles}>Allowed</p>
                                                            <p className="autoresponder-entry-roles-ignored" id={entry.autoresponder_response_id} onClick={view_ignored_roles}>Ignored</p>
                                                        </div>
                                                        <div className="autoresponder-entry-roles-allowed-popup-container" key={entry.autoresponder_response_id} id={entry.autoresponder_response_id + "-allowed-roles-popup"}>
                                                            <div className="autoresponder-entry-roles-allowed-popup-background"></div>
                                                            <div className="autoresponder-entry-roles-allowed-popup-wrapper">
                                                                <div>
                                                                    <div className="autoresponder-entry-roles-allowed-popup-roles-title">
                                                                        Allowed roles
                                                                    </div>
                                                                    <div className="autoresponder-entry-roles-allowed-popup-roles">
                                                                        { entry.allowed_roles.map(role =>
                                                                            <div>{role.name} ({role.id})</div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <div className="autoresponder-entry-roles-allowed-popup-close">
                                                                    <GenericButton button_text="Close" key={entry.autoresponder_response_id} id={entry.autoresponder_response_id} on_click_action={close_allowed_roles} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="autoresponder-entry-roles-ignored-popup-container" key={entry.autoresponder_response_id} id={entry.autoresponder_response_id + "-ignored-roles-popup"}>
                                                            <div className="autoresponder-entry-roles-ignored-popup-background"></div>
                                                            <div className="autoresponder-entry-roles-ignored-popup-wrapper">
                                                                <div>
                                                                    <div className="autoresponder-entry-roles-ignored-popup-roles-title">
                                                                        Ignored roles
                                                                    </div>
                                                                    <div className="autoresponder-entry-roles-ignored-popup-roles">
                                                                        { entry.ignored_roles.map(role =>
                                                                            <div>{role.name} ({role.id})</div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <div className="autoresponder-entry-roles-ignored-popup-close">
                                                                    <GenericButton button_text="Close" key={entry.autoresponder_response_id} id={entry.autoresponder_response_id} on_click_action={close_ignored_roles} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="autoresponder-entry-interactions-wrapper">
                                                            <div className="autoresponder-entry-interactions-inner-wrapper">
                                                                <div className="autoresponder-entry-interactions-form-delete">
                                                                    <span className="autoresponder-entry-interactions-delete-text" id={entry.autoresponder_response_id} onClick={delete_autoresponder_entry}>Delete</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className='dashboard-modules-autoresponder-table-load-extra'>
                                            <GenericButton button_text="Load extra entries" on_click_action={get_extra_data} />
                                        </div>
                                    </div>
                                }
                                <div className='create-autoresponder-entry-sidebar' id="create-autoresponder-entry-sidebar">
                                    <div className='create-autoresponder-entry-wrapper'>
                                        <div className='create-autoresponder-entry-cancel'>
                                            <div className="create-autoresponder-entry-back-button" onClick={close_create_autoresponder_entry_popup}>
                                                <div className="create-autoresponder-entry-button-icon"><span className="material-symbols-outlined" id="autoresponder-entry-close-icon">close</span></div>
                                                <div className="create-autoresponder-entry-button-text-wrapper"><span className="create-autoresponder-entry-button-text">Cancel & Reset</span></div>
                                            </div>
                                            <h2 className='create-autoresponder-entry-title'>
                                                New Autoresponder entry
                                            </h2>
                                        </div>
                                        <div className='create-autoresponder-entry-duration'>
                                            <h3 className='create-autoresponder-entry-trigger-label'>
                                                Trigger*
                                            </h3>
                                            <input className='create-autoresponder-entry-trigger-input' id='create-autoresponder-entry-trigger-input' name='new-autoresponder-entry-trigger' placeholder='test' onChange={on_field_change} value={newAutoresponderEntryTrigger} />
                                        </div>
                                        <div className='create-autoresponder-entry-type'>
                                            <h3 className='create-autoresponder-entry-type-label'>
                                                Type*
                                            </h3>
                                            <select className='create-autoresponder-entry-type-input' id='create-autoresponder-entry-type-input' name='new-autoresponder-entry-type' onChange={on_field_change} value={newAutoresponderEntryType}>
                                                <option key="message" value="message">
                                                    Message
                                                </option>
                                                <option key="embed" value="embed">
                                                    Embed
                                                </option>
                                                <option key="reaction" value="reaction">
                                                    Reaction
                                                </option>
                                                <option key="no-selection" value="">
                                                    No selection made
                                                </option>
                                            </select>
                                        </div> 
                                        <div className='create-autoresponder-entry-content'>
                                            <h3 className='create-autoresponder-entry-content-label'>
                                                Response content*
                                            </h3>
                                            <textarea row={2} className='create-autoresponder-entry-content-input' id='create-autoresponder-entry-content-input' name='new-autoresponder-entry-content' placeholder='Content' onChange={on_field_change} value={newAutoresponderEntryContent}/>
                                        </div>
                                        <div className='create-autoresponder-entry-allowed-channel-id'>
                                            <h3 className='create-autoresponder-entry-allowed-channel-id-label'>
                                                Allowed Channels*
                                            </h3>
                                            <select className='create-autoresponder-entry-allowed-channel-id-input' id='create-autoresponder-entry-allowed-channel-id-input' name='new-autoresponder-entry-allowed-channel-id' onChange={on_field_change} value="">
                                                <option key="no-selection" value="">
                                                    Make selection
                                                </option>
                                                { guildChannels.map(guild_channel => 
                                                    <option key={ String(guild_channel.id) } value={ String(guild_channel.id) }>
                                                        { guild_channel.name }
                                                    </option>
                                                )}
                                                <option key="all-channel" value="all">
                                                    All channels allowed
                                                </option>
                                                <option key="clear" value="clear">
                                                    Clear channels and restart
                                                </option>
                                            </select>
                                            <div className='create-autoresponder-entry-allowed-channel-overview'>
                                                { newAutoresponderEntryAllowedChannels.length === 0 ?
                                                    <div className='create-autoresponder-entry-allowed-channel-overview-item'>No allowed channels yet!</div>
                                                    :
                                                    newAutoresponderEntryAllowedChannels.map(guild_channel => 
                                                        <div key={ String(guild_channel.id) } value={ String(guild_channel.id) } className='create-autoresponder-entry-allowed-channel-overview-item'>
                                                            <div className='create-autoresponder-entry-allowed-channel-overview-item-title'>{ guild_channel.name },&nbsp;</div>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                        { newAutoresponderEntryAllowedChannels.length === guildChannels.length ?
                                            <div className='create-autoresponder-entry-ignored-channel-id'>
                                                <h3 className='create-autoresponder-entry-ignored-channel-id-label'>
                                                    Ignored Channels
                                                </h3>
                                                <select className='create-autoresponder-entry-ignored-channel-id-input' id='create-autoresponder-entry-ignored-channel-id-input' name='new-autoresponder-entry-ignored-channel-id' onChange={on_field_change} value="">
                                                    <option key="no-selection" value="">
                                                        Make selection
                                                    </option>
                                                    { guildChannels.map(guild_channel => 
                                                        <option key={ String(guild_channel.id) } value={ String(guild_channel.id) }>
                                                            { guild_channel.name }
                                                        </option>
                                                    )}
                                                    <option key="clear" value="clear">
                                                        Clear channels and restart
                                                    </option>
                                                </select>
                                                <div className='create-autoresponder-entry-ignored-channel-overview'>
                                                    { newAutoresponderEntryIgnoredChannels.length === 0 ?
                                                        <div className='create-autoresponder-entry-ignored-channel-overview-item'>No ignored channels yet!</div>
                                                        :
                                                        newAutoresponderEntryIgnoredChannels.map(guild_channel => 
                                                            <div key={ String(guild_channel.id) } value={ String(guild_channel.id) } className='create-autoresponder-entry-ignored-channel-overview-item'>
                                                                <div className='create-autoresponder-entry-ignored-channel-overview-item-title'>{ guild_channel.name },&nbsp;</div>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div> : <div></div>
                                        }
                                        <div className='create-autoresponder-entry-allowed-roles-id'>
                                            <h3 className='create-autoresponder-entry-allowed-role-id-label'>
                                                Allowed Roles*
                                            </h3>
                                            <select className='create-autoresponder-entry-allowed-role-id-input' id='create-autoresponder-entry-allowed-role-id-input' name='new-autoresponder-entry-allowed-role-id' onChange={on_field_change} value="">
                                                <option key="no-selection" value="">
                                                    Make selection
                                                </option>
                                                { guildRoles.map(guild_role => 
                                                    <option key={ String(guild_role.id) } value={ String(guild_role.id) }>
                                                        { guild_role.name }
                                                    </option>
                                                )}
                                                <option key="all-role" value="all">
                                                    All roles allowed
                                                </option>
                                                <option key="clear" value="clear">
                                                    Clear roles and restart
                                                </option>
                                            </select>
                                            <div className='create-autoresponder-entry-allowed-role-overview'>
                                                { newAutoresponderEntryAllowedRoles.length === 0 ?
                                                    <div className='create-autoresponder-entry-allowed-role-overview-item'>No allowed roles yet!</div>
                                                    :
                                                    newAutoresponderEntryAllowedRoles.map(guild_role => 
                                                        <div key={ String(guild_role.id) } value={ String(guild_role.id) } className='create-autoresponder-entry-allowed-role-overview-item'>
                                                            <div className='create-autoresponder-entry-allowed-role-overview-item-title'>{ guild_role.name },&nbsp;</div>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                        { newAutoresponderEntryAllowedRoles.length === guildRoles.length ?
                                            <div className='create-autoresponder-entry-ignored-role-id'>
                                                <h3 className='create-autoresponder-entry-ignored-role-id-label'>
                                                    Ignored Roles
                                                </h3>
                                                <select className='create-autoresponder-entry-ignored-role-id-input' id='create-autoresponder-entry-ignored-role-id-input' name='new-autoresponder-entry-ignored-role-id' onChange={on_field_change} value="">
                                                    <option key="no-selection" value="">
                                                        Make selection
                                                    </option>
                                                    { guildRoles.map(guild_role => 
                                                        <option key={ String(guild_role.id) } value={ String(guild_role.id) }>
                                                            { guild_role.name }
                                                        </option>
                                                    )}
                                                    <option key="clear" value="clear">
                                                        Clear roles and restart
                                                    </option>
                                                </select>
                                                <div className='create-autoresponder-entry-ignored-role-overview'>
                                                    { newAutoresponderEntryIgnoredRoles.length === 0 ?
                                                        <div className='create-autoresponder-entry-ignored-role-overview-item'>No ignored roles yet!</div>
                                                        :
                                                        newAutoresponderEntryIgnoredRoles.map(guild_role => 
                                                            <div key={ String(guild_role.id) } value={ String(guild_role.id) } className='create-autoresponder-entry-ignored-role-overview-item'>
                                                                <div className='create-autoresponder-entry-ignored-role-overview-item-title'>{ guild_role.name },&nbsp;</div>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div> : <div></div>
                                        }
                                    </div>
                                    <div className='create-autoresponder-entry-interactions-wrapper'>
                                        <div className='submit'>
                                            <GenericButton button_text="Create entry" on_click_action={create_autoresponder_entry} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <Loading loading_size='s' />
                    :
                    <div className='dashboard-modules-autoresponder-disabled-container'>
                        The Autoresponder module is disabled, click <label onClick={open_enable_autoresponder_module_sidebar} className='dashboard-modules-autoresponder-disabled-enable-button'>here</label> if you want to enabled this module!
                        <div className='enable-autoresponder-module-sidebar' id="enable-autoresponder-module-sidebar">
                            <div className='enable-autoresponder-module-wrapper'>
                                <div className='enable-autoresponder-module-cancel'>
                                    <div className="enable-autoresponder-module-back-button" onClick={close_enable_autoresponder_module_sidebar}>
                                        <div className="enable-autoresponder-module-button-icon"><span className="material-symbols-outlined" id="giveaway-close-icon">close</span></div>
                                        <div className="enable-autoresponder-module-button-text-wrapper"><span className="enable-autoresponder-module-button-text">Cancel & Reset</span></div>
                                    </div>
                                    <h2 className='enable-autoresponder-module-title'>
                                        Enable the Autoresponder Module
                                    </h2>
                                </div>
                                <div className='enable-autoresponder-module-channel-id'>
                                </div>
                            </div>
                            <div className='enable-autoresponder-module-interactions-wrapper'>
                                <div className='submit'>
                                    <GenericButton button_text="Enable" id="enable" on_click_action={set_module_status} />
                                </div>
                            </div>
                        </div>
                    </div>
                :
                <Loading loading_size='s' />
            }
        </div>
    );
};

export default DashboardModulesAutoresponderContent;