import React from 'react';
import { useNavigate } from 'react-router-dom';

import '../../styles/pages/errors/404.css'

const Error404 = () => {
    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1)
    }
    return(
        <div className="error-404-wrapper">
            <div className="error-404-text">
                <h1 className="error-404-text-title">Oh no!</h1>
                <span className="error-404-text-description"> We were unable to locate the requested page!</span>            
            </div>
            <div className="error-404-return-home-box">
                <div className="error-404-return-home-box-button" onClick={goBack}>
                    <span className="error-404-return-home-box-button-text">Return to previous page</span>
                </div>
            </div>
        </div>
    );
};

export default Error404;