var startProductBarPos=-1;
window.addEventListener("scroll", add_default_ticket_type_sidebar_scroll)
window.addEventListener("scroll", add_custom_ticket_type_sidebar_scroll)
window.addEventListener("scroll", enable_tickets_module_sidebar_scroll)

function add_default_ticket_type_sidebar_scroll() {
    var bar = document.getElementById('add-default-ticket-type-sidebar');
    if (bar === null) {
      return
    }
    if(startProductBarPos<0)startProductBarPos=findPosY(bar);

    if(window.scrollY>=startProductBarPos){
      bar.style.position='fixed';
      bar.style.top=0;
    }else{
      bar.style.position='relative';
    }
};

function add_custom_ticket_type_sidebar_scroll() {
  var bar = document.getElementById('add-custom-ticket-type-sidebar');
  if (bar === null) {
    return
  }
  if(startProductBarPos<0)startProductBarPos=findPosY(bar);

  if(window.scrollY>=startProductBarPos){
    bar.style.position='fixed';
    bar.style.top=0;
  }else{
    bar.style.position='relative';
  }
};

function enable_tickets_module_sidebar_scroll() {
  var bar = document.getElementById('enable-tickets-module-sidebar');
  if (bar === null) {
    return
  }
  if(startProductBarPos<0)startProductBarPos=findPosY(bar);

  if(window.scrollY>=startProductBarPos){
    bar.style.position='fixed';
    bar.style.top=0;
  }else{
    bar.style.position='relative';
  }
};

function findPosY(obj) {
    var curtop = 0;
    if (typeof (obj.offsetParent) != 'undefined' && obj.offsetParent) {
      while (obj.offsetParent) {
        curtop += obj.offsetTop;
        obj = obj.offsetParent;
      }
      curtop += obj.offsetTop;
    }
    else if (obj.y)
      curtop += obj.y;
    return curtop;
}