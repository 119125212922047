import '../../styles/components/headers/guild_dashboard_header.css'

function GuildDashboardHeader(props) {
    return(
        <div className="guild-dashboard-header-container">
            <div className='guild-dashboard-header-title'>
                {props.view_title}
            </div>
            <div className='guild-dashboard-header-guild'>
                { props.guild_name !== "" ?
                    <div>{props.guild_name} ({props.guild_id})</div>
                    :
                    <div></div>
                }
                
            </div>
        </div>
    )
}

export default GuildDashboardHeader