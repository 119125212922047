import '../../styles/components/buttons/generic_button.css'

function GenericButton(props) {
    var icon_name = props.icon_name
    var button_text = props.button_text
    var link = props.link
    var on_click_action = props.on_click_action
    var type = props.type
    var action_type = props.action_type
    var id = props.id
    var name = props.name

    if (link !== undefined) {
        return(
            <a className='generic-button-container' href={ link } id={ id }>
                <button className={'generic-button ' + type} name={ name } id={ id } type={ action_type } onClick={ on_click_action }>
                    <span className="material-symbols-outlined" id={ id }>{ icon_name }</span>
                    <span className='generic-button-text' id={ id }>{ button_text }</span>
                </button>
            </a>
        )
    } else {
        return(
            <div className='generic-button-container' id={ id }>
                <button className={'generic-button ' + type} name={ name } id={ id } onClick={ on_click_action } type={ action_type }>
                    <span className="material-symbols-outlined" id={ id }>{ icon_name }</span>
                    <span className='generic-button-text' id={ id }>{ button_text }</span>
                </button>
            </div>
        )
    }

}

export default GenericButton