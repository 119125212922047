import React from 'react';
import { useCookies } from 'react-cookie'
import { useLocation, useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';

import "../../styles/pages/oauth/logout.css";

const Logout = () => {
    const [cookies, setCookie, removeCookie] = useCookies(['access_token'])
    removeCookie('access_token',{path:'/'});

    const location = useLocation()
    if (location.state !== null) {
        if (location.state.alert !== null) {
            if (location.state.alert.alert_type === "error") {
                toast.error(location.state.alert.message)
                location.state.alert = null
            } else if (location.state.alert.alert_type === "warning") {
                toast.warning(location.state.alert.message)
                location.state.alert = null
            } else if (location.state.alert.alert_type === "success") {
                toast.success(location.state.alert.message)
                location.state.alert = null
            } else if (location.state.alert.alert_type === "info") {
                toast.info(location.state.alert.message)
                location.state.alert = null
            }
        }
    }

    return(
        <div className='logout-wrapper'>
            <h1 className='logout-title'>You have been logged out!</h1>
            <h2 className='logout-description'>Thank you for using Husqy!</h2>
            <div className='logout-button-container'>
                <a className='logout-button-wrapper' href='/login'>
                    <button className='loggedout-button'>
                        <span className="loggedout-button-text">Back to login</span>
                    </button>
                </a>
            </div>
            <ToastContainer 
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                pauseOnHover={false}
                newestOnTop={true}
                closeOnClick
                rtl={false}
                draggable
                theme="dark"
                pauseOnFocusLoss={false}
            />
        </div>
    );
};

export default Logout;