import React, { useState } from 'react';
import { useParams } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import { toast } from 'react-toastify'
import axios from 'axios'

import '../../../styles/components/dashboard/functions/GiveawayEntry.css'
import config from '../../../config'

function GiveawayEntry(props) {
    const [cookies] = useCookies(['access_token'])
        
    const [giveaway, setGiveaway] = useState(props.giveaway)
    const [guildSettings, setGuildSettings] = useState(props.guild_settings)

    const params = useParams()

    async function delete_giveaway(event) {
        event.preventDefault()
        var old_giveaway = {}
        old_giveaway.action_type = "delete"
        old_giveaway.giveaway_id = event.target.id

        const options = {
            url: `${config["api_base_url"]}/guilds/${params.guild_id}/functions/giveaways/${event.target.id}`,
            method: 'DELETE',
            headers: {
                access_token: cookies["access_token"]
            },
            data: old_giveaway
          };

          axios(options)
          .then(response => {
              if (response.status === 202) {
                window.location.reload()
              }  
          })
          .catch(error => {
              console.log(error)
              if (error.response.status === 401) {
                toast.error("You can't delete this giveaway because you are not the owner!")
              } else if (error.response.status === 429) {
                toast.error("Rate limit reached! Please slow down!")
            }else {
                toast.error("Something went wrong while deleting the giveaway!")
              }
          });
    }

    async function reroll_giveaway(event) {
        event.preventDefault()
        var rerolled_giveaway = {}
        rerolled_giveaway.action_type = "reroll"
        rerolled_giveaway.giveaway_id = event.target.id
        rerolled_giveaway.guild_id = params.guild_id

        const options = {
            url: `${config["api_base_url"]}/guilds/${params.guild_id}/functions/giveaways/${event.target.id}`,
            method: 'PUT',
            headers: {
                access_token: cookies["access_token"]
            },
            data: rerolled_giveaway
          };

          axios(options)
          .then(response => {
              if (response.status === 202) {
                window.location.reload()
              }  
          })
          .catch(error => {
              console.log(error)
              if (error.response.status === 401) {
                toast.error("You can't reroll this giveaway because you are not the owner!")
              } else if (error.response.status === 425) {
                toast.error("You can't reroll this giveaway because it is still active!")
              } else if (error.response.status === 429) {
                toast.error("Rate limit reached! Please slow down!")
            } else {
                toast.error("Something went wrong while rerolling the giveaway!")
              }
          });
    }
    
    return(
        <div key={giveaway.giveaway_id} className='giveaways-entry-container'>
            <div className='giveaways-entry-field-giveaway-id'>
                <div className='giveaways-entry-field-giveaway-id-wrapper'>
                    <label className='giveaways-entry-field-giveaway-id-title'>Giveaway</label>
                    <p className='giveaways-entry-field-giveaway-id-description'>{giveaway.giveaway_id}</p>
                </div>
                <div className='giveaways-entry-field-giveaway-interactions-wrapper'>
                    { giveaway.winner.length !== 0 ?
                        <div className='giveaways-entry-field-giveaway-reroll-wrapper'>
                            <div className="giveaways-entry-reroll-button"><span className="material-symbols-outlined" id={giveaway.giveaway_id} onClick={reroll_giveaway}>refresh</span></div>
                        </div> : <div></div>
                    }
                    <div className='giveaways-entry-field-giveaway-delete-wrapper'>
                        <div className="giveaways-entry-delete-button"><span className="material-symbols-outlined" id={giveaway.giveaway_id} onClick={delete_giveaway}>delete</span></div>
                    </div>
                </div>
            </div>
            <hr className='giveaways-entry-field-splitter' />
            { giveaway.end_time !== "None" ?
                <div className='giveaways-entry-field-end-time'>
                    <label className='giveaways-entry-field-end-time-title'>End time</label>
                    <p className='giveaways-entry-field-end-time-description'>
                        {new Date(parseInt(giveaway.end_time)*1000).toLocaleString("en-us", {weekday:"long", day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', timeZone: guildSettings.timezone})} {guildSettings.timezone}
                    </p>
                </div> : <div></div>
            }
            { giveaway.delete_at !== "None" ?
                <div className='giveaways-entry-field-delete-time'>
                    <label className='giveaways-entry-field-delete-time-title'>Delete time</label>
                    <p className='giveaways-entry-field-delete-time-description'>
                        {new Date(parseInt(giveaway.delete_at)*1000).toLocaleString("en-us", {weekday:"long", day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', timeZone: guildSettings.timezone})} {guildSettings.timezone}
                    </p>
                </div> : <div></div>
            }
            <div className='giveaways-entry-field-winner-count'>
                <label className='giveaways-entry-field-winner-count-title'>Winner count</label>
                <p className='giveaways-entry-field-winner-count-description'>{giveaway.winner_count}</p>
            </div>
            <div className='giveaways-entry-field-winner'>
                    <label className='giveaways-entry-field-winner-title'>Winner(s)</label>
                    { giveaway.winner.length !== 0 ?
                        <p className='giveaways-entry-field-winner-description'>
                            { giveaway.winner.map(user =>
                                <div key={user.id}>{user.name} ({user.id})</div>
                            )}
                            <a className='giveaways-entry-field-winner-message-link' href={"https://discord.com/channels/"+params.guild_id+"/"+giveaway.channel.id+"/"+giveaway.winner_message_id} target="_blank" rel="noreferrer">
                                View winner message!
                            </a>
                        </p>
                        :
                        <p className='giveaways-entry-field-winner-description'>Giveaway is still active!</p>
                    }
                </div>
            <div className='giveaways-entry-field-channel-id'>
                <label className='giveaways-entry-field-channel-id-title'>Channel</label>
                <p className='giveaways-entry-field-channel-id-description'>{giveaway.channel.name}<br />{giveaway.channel.id}</p>
            </div>
            <div className='giveaways-entry-field-message-id'>
                <label className='giveaways-entry-field-message-id-title'>Message ID</label>
                <p className='giveaways-entry-field-message-id-description'>{giveaway.message_id}</p>
                <a className='giveaways-entry-field-winner-message-link' href={"https://discord.com/channels/"+params.guild_id+"/"+giveaway.channel.id+"/"+giveaway.message_id} target="_blank" rel="noreferrer">
                    View giveaway in Discord!
                </a>
            </div>
            <div className='giveaways-entry-field-giveaway-owner-id'>
                <label className='giveaways-entry-field-giveaway-owner-id-title'>Owner</label>
                <p className='giveaways-entry-field-giveaway-owner-id-description'>{giveaway.owner.name}<br />({giveaway.owner.id})</p>
            </div>
            <div className='giveaways-entry-field-prize'>
                <label className='giveaways-entry-field-prize-title'>Prize</label>
                <p className='giveaways-entry-field-prize-description'>{giveaway.prize}</p>
            </div>
            <div className='giveaways-entry-field-description'>
                <label className='giveaways-entry-field-description-title'>Description</label>
                <p className='giveaways-entry-field-description-description'>{giveaway.description}</p>
            </div>
            <div className='giveaways-entry-field-participants'>
                <label className='giveaways-entry-field-participants-title'>Participants</label>
                <p className='giveaways-entry-field-participants-description'>
                    { giveaway.participants.map(participant =>
                        participant.name + ", "
                    )}
                </p>
            </div>
        </div>
    )
}

export default GiveawayEntry