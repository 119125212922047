import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie'
import { ToastContainer, toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios'

import GuildPreviewBox from '../components/guild_preview_box'
import LogoutButtonFloat from '../components/buttons/logout_button_float'
import Loading from '../components/loading'
import RefreshButton from '../components/buttons/refresh_button'

import '../styles/pages/guilds_overview.css'
import 'react-toastify/dist/ReactToastify.css';
import config from '../config'

const GuildsOverview = () => {
    const [cookies] = useCookies(['access_token'])
    if (cookies["access_token"] === undefined) {
        window.location.replace("/login")
    }
    const navigate = useNavigate();

    const [guilds, setGuilds] = useState([])
    const [guildsLoadingStatus, setguildsLoadingStatus] = useState('loading')
    
    const location = useLocation()
    if (location.state !== null) {
        if (location.state.alert !== null) {
            if (location.state.alert.alert_type === "error") {
                toast.error(location.state.alert.message)
                location.state.alert = null
            } else if (location.state.alert.alert_type === "warning") {
                toast.warning(location.state.alert.message)
                location.state.alert = null
            } else if (location.state.alert.alert_type === "success") {
                toast.success(location.state.alert.message)
                location.state.alert = null
            } else if (location.state.alert.alert_type === "info") {
                toast.info(location.state.alert.message)
                location.state.alert = null
            }
        }
    }

    const api_get_guilds = async () => {
        setguildsLoadingStatus('loading')
        const options = {
            url: `${config["api_base_url"]}/guilds/`,
            method: 'GET',
            headers: {
                access_token: cookies["access_token"]
            }
          };
    
        axios(options)
        .then(response => {
            if (response.status === 200) {
                setGuilds(response["data"]["data"])
                setguildsLoadingStatus('success')
            }
        })
        .catch(error => {
            console.log(error)
            setguildsLoadingStatus('error')
            if ( error.response ) {
                if (error.response.status === 401) {
                    const new_state = { alert: { alert_type: "error", message: "Your session has expired or you are not logged in! Please login again to continue!" }}
                    navigate("/logout", { state: new_state });
                }
                else if (error.response.status === 429) {
                    toast.error("Rate limit reached! Please slow down!")
                    return
                }
            }
            toast.error("Something went wrong while trying to load your servers!")
        });
    }

    useEffect(() => {
        api_get_guilds()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return(
        <div className='guild-overview-guilds-container'>
            <div className="guild-overview-text-wrapper">
                <h1 className="guild-overview-text-title">Your servers</h1>
                <h2 className="guild-overview-text-description">Choose the server you want to configure!</h2>
                <div className="guild-overview-refresh">
                    <RefreshButton on_click_action={api_get_guilds} />
                </div>
            </div>
            <div className='guild-overview-guilds-wrapper'>
                { guildsLoadingStatus === 'error' ?
                    <div className='guild-overview-guilds-error'>Something went wrong, please hit refresh to try again!</div>
                    :
                    guildsLoadingStatus === 'success' ? 
                        guilds.length === 0 ?
                            <div className='guild-overview-guilds-empty'>You do not have any guilds! <br/> Please make sure you are the owner of the server or have administrator rights!</div>
                            :
                            guilds.map(guild => 
                                <GuildPreviewBox key={guild.guild_id} guild_id={guild.guild_id} guild_name={guild.guild_name} guild_icon_url={guild.icon_url} husqy_used={guild.husqy_used} />
                            )
                        :
                        <Loading loading_size='s' />
                }

            </div>
            <LogoutButtonFloat />
            <ToastContainer 
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                pauseOnHover={false}
                newestOnTop={true}
                closeOnClick
                rtl={false}
                draggable
                theme="dark"
                pauseOnFocusLoss={false}
            />
        </div>
    );
};

export default GuildsOverview;