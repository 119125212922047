import '../../styles/components/buttons/refresh_button.css'

function RefreshButton(props) {
    var on_click_action = props.on_click_action

    return(
        <div className='refresh-button-container'>
            <button className='refresh-button' onClick={on_click_action}>
                <span className="material-symbols-outlined" id='refresh-icon'>refresh</span>
                <span className='refresh-button-text'>Refresh</span>
            </button>
        </div>
    )
}

export default RefreshButton