import React, { useState } from 'react';
import { useParams } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import { toast } from 'react-toastify'
import axios from 'axios'

import '../../../styles/components/dashboard/functions/ReminderEntry.css'
import config from '../../../config'

function ReminderEntry(props) {
    const [cookies] = useCookies(['access_token'])
        
    const [reminder, setReminder] = useState(props.reminder)
    const [guildSettings, setGuildSettings] = useState(props.guild_settings)

    const params = useParams()

    async function delete_reminder(event) {
        event.preventDefault()
        var old_reminder = {}
        old_reminder.action_type = "delete"
        old_reminder.reminder_id = event.target.id

        const options = {
            url: `${config["api_base_url"]}/guilds/${params.guild_id}/functions/reminders/${event.target.id}`,
            method: 'DELETE',
            headers: {
                access_token: cookies["access_token"]
            },
            data: old_reminder
          };

          axios(options)
          .then(response => {
              if (response.status === 202) {
                window.location.reload()
              }  
          })
          .catch(error => {
              console.log(error)
              if (error.response.status === 401) {
                toast.error("You can't delete this reminder because you are not the target user!")
              } else if (error.response.status === 429) {
                toast.error("Rate limit reached! Please slow down!")
              } else {
                toast.error("Something went wrong while deleting the reminder!")
              }
          });
    }

    return(
        <div key={reminder.reminder_id} className='reminders-entry-container'>
            <div className='reminders-entry-field-reminder-id'>
                <div className='reminders-entry-field-reminder-id-wrapper'>
                    <label className='reminders-entry-field-reminder-id-title'>Reminder</label>
                    <p className='reminders-entry-field-reminder-id-description'>{reminder.reminder_id}</p>
                </div>
                <div className='reminders-entry-field-reminder-delete-wrapper'>
                    <div className="reminders-entry-delete-button"><span className="material-symbols-outlined" id={reminder.reminder_id} onClick={delete_reminder}>delete</span></div>
                </div>
            </div>
            <hr className='reminders-entry-field-splitter' />
            <div className='reminders-entry-field-end-time'>
                <label className='reminders-entry-field-end-time-title'>End time</label>
                <p className='reminders-entry-field-end-time-description'>
                    {new Date(parseInt(reminder.end_time)*1000).toLocaleString("en-us", {weekday:"long", day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', timeZone: guildSettings.timezone})} {guildSettings.timezone}
                </p>
            </div>
            <div className='reminders-entry-field-destination-id'>
                <label className='reminders-entry-field-destination-id-title'>Destination ({reminder.destination.type})</label>
                <p className='reminders-entry-field-destination-id-description'>{reminder.destination.name}<br />({reminder.destination.id})</p>
                <a className='reminders-entry-field-message-link' href={"https://discord.com/channels/"+params.guild_id+"/"+reminder.destination.id+"/"+reminder.reminder_message_id} target="_blank" rel="noreferrer">
                    View reminder in Discord!
                </a>
            </div>
            <div className='reminders-entry-field-target-user-id'>
                <label className='reminders-entry-field-target-user-id-title'>Target user</label>
                <p className='reminders-entry-field-target-user-id-description'>{reminder.target_user.name}<br />({reminder.target_user.id})</p>
            </div>
            <div className='reminders-entry-field-linked-message-link'>
                <label className='reminders-entry-field-linked-message-link-title'>Linked message</label>
                { reminder.linked_message_link ?
                    <a href={reminder.linked_message_link} rel="noreferrer" target="_blank" className='reminders-entry-field-linked-message-link-description'>Go to message</a>
                    :
                    <p className='reminders-entry-field-linked-message-link-description'>No message linked</p>
                }
            </div>
            <div className='reminders-entry-field-description'>
                <label className='reminders-entry-field-description-title'>Description</label>
                <p className='reminders-entry-field-description-description'>{reminder.description}</p>
            </div>
        </div>
    )
}

export default ReminderEntry