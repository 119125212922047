import React from 'react';
import { useParams } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import { toast } from 'react-toastify'
import axios from 'axios'

import '../../../styles/components/dashboard/modules/ReactionRoleEntryBox.css'
import config from '../../../config'

function ReactionRoleEntryBox(props) {
    const [cookies] = useCookies(['access_token'])
    const entry = props.entry

    const params = useParams()

    const delete_reactionroles_entry = async (event) => {
        event.preventDefault()
        var old_reactionroles_entry = {}
        old_reactionroles_entry.reaction_role_id = event.target.id 
        old_reactionroles_entry.guild_id = params.guild_id
        old_reactionroles_entry.action_type = "delete"

        const options = {
            url: `${config["api_base_url"]}/guilds/${params.guild_id}/modules/reaction_roles/entries/${event.target.id}`,
            method: 'DELETE',
            headers: {
                access_token: cookies["access_token"]
            },
            data: old_reactionroles_entry,
          };

          axios(options)
          .then(response => {
              if (response.status === 202) {
                window.location.reload()
              }  
          })
          .catch(error => {
              console.log(error)
              if (error.response.status === 429) {
                toast.error("Rate limit reached! Please slow down!")
                return
            } 
              toast.error("Something went wrong while deleting the Reaction Role entry!")
          });
    }

    return(
        <div className='reactionrole-entry-wrapper'>
            <div className='reactionrole-entry-inner-wrapper'>
                <div className='reactionrole-entry-inner-wrapper-title'>
                    <div className='reactionrole-entry-inner-wrapper-title-label'>
                        {entry.reaction_role_id}
                    </div>
                    <div className='reactionrole-entry-inner-wrapper-delete-wrapper'>
                        <div className="reactionrole-entry-inner-wrapper-delete-button"><span className="material-symbols-outlined" id={entry.reaction_role_id} onClick={delete_reactionroles_entry}>delete</span></div>
                    </div>
                </div>
                <div className='reactionrole-entry-inner-wrapper-message'>
                    <div className='reactionrole-entry-inner-wrapper-message-label'>Message ID:</div>
                    <div className='reactionrole-entry-inner-wrapper-message-description'>{entry.message_id}</div>
                </div>
                <div className='reactionrole-entry-inner-wrapper-channel'>
                    <div className='reactionrole-entry-inner-wrapper-channel-label'>Channel:</div>
                    <div className='reactionrole-entry-inner-wrapper-channel-description'>{entry.channel.name} ({entry.channel.id})</div>
                </div>
                <div className='reactionrole-entry-inner-wrapper-reaction'>
                    <div className='reactionrole-entry-inner-wrapper-reaction-label'>Reaction:</div>
                    <div className='reactionrole-entry-inner-wrapper-reaction-description'>{entry.reaction}</div>
                </div>
                <div className='reactionrole-entry-inner-wrapper-role'>
                    <div className='reactionrole-entry-inner-wrapper-role-label'>Role:</div>
                    <div className='reactionrole-entry-inner-wrapper-role-description'>{entry.role.name} ({entry.role.id})</div>
                </div>
            </div>
            <div className='reactionrole-entry-inner-wrapper-interactions'>
                <a href={"https://discord.com/channels/"+params.guild_id+"/"+entry.channel.id+"/"+entry.message_id} className='reactionrole-entry-wrapper-link'>
                    View message!
                </a>

            </div>
        </div>
    )
}

export default ReactionRoleEntryBox