import GenericButton from './buttons/generic_button'

import '../styles/components/guild_preview_box.css'

function GuildPreviewBox(props) {
    let interaction_button
    let guild_icon

    const guild_id = props.guild_id
    const guild_name = props.guild_name
    const guild_icon_url = props.guild_icon_url
    const husqy_used = props.husqy_used

    if (husqy_used === true) {
        interaction_button = <GenericButton link={guild_id + '/overview'} button_text="Configure" />
    } else {
        interaction_button = <GenericButton link='https://husqy.xyz/get-started' type="secondary" button_text="Invite Husqy" />
    }

    if (guild_icon_url === null) {
        guild_icon = <span className='guild-overview-guild-card-image-text'>{ guild_name[0] }</span>
    } else {
        guild_icon = <img className='guild-overview-guild-card-image' src={ guild_icon_url } alt={ guild_icon_url } />
    }

    return(
        <div className='guild-card-container'>
            <div className='guild-info-wrapper'>
                <div className='guild-overview-guild-card-image-wrapper'>
                    { guild_icon }
                </div>
                <div className='guild-overview-guild-card-details-wrapper'>
                    <div className='guild-overview-guild-card-details'>
                        <h3 className='guild-overview-guild-card-details-title'>{ guild_name }</h3>
                        <p className='guild-overview-guild-card-details-description'>{ guild_id }</p>
                    </div>
                </div>
            </div>
            <div className='guild-interactions-wrapper'>
                { interaction_button }
            </div>
        </div>
    )
}

export default GuildPreviewBox