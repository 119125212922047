import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import { toast } from 'react-toastify'
import axios from 'axios'
import Loading from '../../../components/loading'
import GenericButton from "../../../components/buttons/generic_button"
import RefreshButton from "../../../components/buttons/refresh_button"
import PollEntry from "../../../components/dashboard/functions/PollEntry"

import '../../../styles/pages/dashboard/functions/pollsContent.css'
import '../../../js/sticky_polls_bars.js'
import config from '../../../config'

const DashboardFunctionsPollsContent = () => {
    const [cookies] = useCookies(['access_token'])

    const [serverPolls, setServerPolls] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [guildChannels, setGuildChannels] = useState([])
    const [guildSettings, setGuildSettings] = useState({})
    const [guildMembers, setGuildMembers] = useState([])

    const [newPollEndtime, setNewPollEndtime] = useState("")
    const [newPollType, setNewPollType] = useState("normal")
    const [newPollAnonymous, setNewPollAnonymous] = useState(false)
    const [newPollMaxChoices, setNewPollMaxChoices] = useState(1)
    const [newPollQuestion, setNewPollQuestion] = useState("")
    const [newPollAnswer1, setNewPollAnswer1] = useState("")
    const [newPollAnswer2, setNewPollAnswer2] = useState("")
    const [newPollAnswer3, setNewPollAnswer3] = useState("")
    const [newPollAnswer4, setNewPollAnswer4] = useState("")
    const [newPollAnswer5, setNewPollAnswer5] = useState("")
    const [newPollOwner, setNewPollOwner] = useState("")
    const [newPollChannel, setNewPollChannel] = useState("")

    const params = useParams()
    const [channelsLoadingStatus, setChannelsLoadingStatus] = useState('loading')
    const [pollsLoadingStatus, setPollsLoadingStatus] = useState('loading')
    const [settingsLoadingStatus, setSettingsLoadingStatus] = useState('loading')
    const [membersLoadingStatus, setMembersLoadingStatus] = useState('loading')

    // Popups
    const open_create_poll_popup = async (event) => {
        event.preventDefault()
        var bar = document.getElementById('create-poll-sidebar')
        bar.style.transform = "translateX(0px)"
    }
    const close_create_poll_popup = async (event) => {
        event.preventDefault()
        setNewPollEndtime("")
        setNewPollType("normal")
        setNewPollAnonymous(false)
        setNewPollMaxChoices(1)
        setNewPollQuestion("")
        setNewPollAnswer1("")
        setNewPollAnswer2("")
        setNewPollAnswer3("")
        setNewPollAnswer4("")
        setNewPollAnswer5("")
        setNewPollOwner("")
        setNewPollChannel("")
        var bar = document.getElementById('create-poll-sidebar')
        bar.style.transform = "translateX(+502px)"
    }

    // FIELD CHANGES
    const on_field_change = async (event) => {
        event.preventDefault();
        if (event.target.name === "new-poll-duration") {
            setNewPollEndtime(event.target.value)
        } else if (event.target.name === "new-poll-type") {
            setNewPollType(event.target.value)
        } else if (event.target.name === "create-poll-anonymous") {
            var create_poll_is_anonymous_input = document.getElementById("create-poll-is-anonymous")
            var is_anonymous = create_poll_is_anonymous_input.checked
            setNewPollAnonymous(is_anonymous)
        } else if (event.target.name === "new-poll-max-choices") {
            setNewPollMaxChoices(event.target.value)
        } else if (event.target.name === "new-poll-question") {
            setNewPollQuestion(event.target.value)
        } else if (event.target.name === "new-poll-answer1") {
            setNewPollAnswer1(event.target.value)
        } else if (event.target.name === "new-poll-answer2") {
            setNewPollAnswer2(event.target.value)
        } else if (event.target.name === "new-poll-answer3") {
            setNewPollAnswer3(event.target.value)
        } else if (event.target.name === "new-poll-answer4") {
            setNewPollAnswer4(event.target.value)
        } else if (event.target.name === "new-poll-answer5") {
            setNewPollAnswer5(event.target.value)
        } else if (event.target.name === "new-poll-owner-id") {
            setNewPollOwner(event.target.value)
        } else if (event.target.name === "new-poll-channel-id") {
            setNewPollChannel(event.target.value)
        }
    }

    // Buttons presses
    const create_poll = async (event) => {
        event.preventDefault()
        var new_poll = {}
        new_poll.action_type = "create"
        new_poll.poll_type = newPollType
        if (newPollType === "timed") {
            if (newPollEndtime === "") {
                toast.error("You need to fill in a wait duration!")
                return
            } else {
                const pollEndtimeRegex = /^(?!.*[dhmsDHMS]{2})\d+[dhmsDHMS]$/;
                const valid = pollEndtimeRegex.test(String(newPollEndtime));
                if (!valid) {
                    toast.error("The provided wait duration is not valid. Please provide in format: {delay}{d/h/m/s}! It does not support 2d3m!")
                    return
                } else {
                    new_poll.endtime = newPollEndtime
                }
            }
        }
        new_poll.anonymous = String(newPollAnonymous)

        if (newPollOwner === "") {
            toast.error("You must select a owner for this poll!")
            return
        }
        new_poll.poll_owner_id = String(newPollOwner)

        if (newPollChannel === "") {
            toast.error("You must select a target channel for this poll!")
            return
        }
        new_poll.channel_id = String(newPollChannel)

        new_poll.question = newPollQuestion
        if (newPollAnswer1 === "" || newPollAnswer2 === "") {
            toast.error("Answer 1 and Answer 2 must be filled in!")
            return
        }

        new_poll.answer1 = newPollAnswer1
        new_poll.answer2 = newPollAnswer2
        if (newPollAnswer1 !== "" && newPollAnswer2 !== "" && newPollAnswer3 !== "") {
            new_poll.answer3 = newPollAnswer3
        } else {
            new_poll.answer3 = ""
        }
        if (newPollAnswer1 !== "" && newPollAnswer2 !== "" && newPollAnswer3 !== "" && newPollAnswer4 !== "") {
            new_poll.answer4 = newPollAnswer4
        } else {
            new_poll.answer4 = ""
        }
        if (newPollAnswer1 !== "" && newPollAnswer2 !== "" && newPollAnswer3 !== "" && newPollAnswer4 !== "" && newPollAnswer5 !== "") {
            new_poll.answer5 = newPollAnswer5
        } else {
            new_poll.answer5 = ""
        }
        
        var max_choices_maximum = 2
        if (newPollAnswer3 === "" && newPollAnswer4 === "" && newPollAnswer5 === "") {
            max_choices_maximum = 2
        }
        if (newPollAnswer3 !== "" && newPollAnswer4 === "" && newPollAnswer5 === "") {
            max_choices_maximum = 3
        }
        if (newPollAnswer3 !== "" && newPollAnswer4 !== "" && newPollAnswer5 === "") {
            max_choices_maximum = 4
        }
        if (newPollAnswer3 !== "" && newPollAnswer4 !== "" && newPollAnswer5 !== "") { 
            max_choices_maximum = 5
        }
        
        if (newPollMaxChoices > max_choices_maximum) {
            toast.error("The provided number of choices is to much for the number of answers!")
            return
        }

        new_poll.max_choices = newPollMaxChoices
        new_poll.guild_id = params.guild_id
        
        const options = {
            url: `${config["api_base_url"]}/guilds/${params.guild_id}/functions/polls`,
            method: 'POST',
            headers: {
                access_token: cookies["access_token"]
            },
            data: new_poll,
          };

          axios(options)
          .then(response => {
              if (response.status === 202) {
                window.location.reload()
              }
          })
          .catch(error => {
              console.log(error)
              if (error.response.status === 429) {
                toast.error("Rate limit reached! Please slow down!")
                return
            }
              toast.error("Something went wrong while creating the poll!")
          });
    }

    // Refresh data
    const refresh_data = async (event) => {
        event.preventDefault();
        setServerPolls([])
        setCurrentPage(1)
        window.location.reload()
    }

    // Get data
    const get_extra_data = async (event) => {
        event.preventDefault();
        get_polls_paged()
    }

    const get_channels = async () => {
        setChannelsLoadingStatus("loading")
        const options = {
            url: `${config["api_base_url"]}/guilds/${params.guild_id}/channels?types=text`,
            method: 'GET',
            headers: {
                access_token: cookies["access_token"]
            },
          };

          axios(options)
          .then(response => {
              if (response.status === 200) {
                  setGuildChannels(response["data"][0]["data"])
                  setChannelsLoadingStatus("success")
              }
          })
          .catch(error => {
              console.log(error)
              setChannelsLoadingStatus("error")
              if (error.response.status === 429) {
                toast.error("Rate limit reached! Please slow down!")
                return
            }
              toast.error("Something went wrong while getting the servers channels!")
          });
    }

    const get_polls_paged = async () => {
        setPollsLoadingStatus("loading")
        const options = {
            url: `${config["api_base_url"]}/guilds/${params.guild_id}/functions/polls?page=${currentPage}`,
            method: 'GET',
            headers: {
                access_token: cookies["access_token"]
            }
          };

        axios(options)
        .then(response => {
            if (response.status === 200) {
                if (response["data"]["data"].length === 0) {
                    setCurrentPage(currentPage)
                } else {
                    setCurrentPage(currentPage+1)
                    if (serverPolls.length === 0 ) {
                        setServerPolls(response["data"]["data"])
                    } else {
                        var newServerPolls = serverPolls.concat(response["data"]["data"])
                        setServerPolls(newServerPolls)
                    }
                }
                setPollsLoadingStatus("success")
            }
        })
        .catch(error => {
            console.log(error)
            setPollsLoadingStatus("error")
            if (error.response.status === 429) {
                toast.error("Rate limit reached! Please slow down!")
                return
            }
            toast.error("Something went wrong while fetching the servers polls!")
        });
    }

    const get_settings = async () => {
        setSettingsLoadingStatus("loading")
        const options = {
            url: `${config["api_base_url"]}/guilds/${params.guild_id}/settings`,
            method: 'GET',
            headers: {
                access_token: cookies["access_token"]
            }
          };

          axios(options)
          .then(response => {
              if (response.status === 200) {
                  setGuildSettings(response["data"]["data"])
                  setSettingsLoadingStatus("success")
              }
          })
          .catch(error => {
              console.log(error)
              setSettingsLoadingStatus("error")
              if (error.response.status === 429) {
                toast.error("Rate limit reached! Please slow down!")
                return
            }
              toast.error("Something went wrong while getting the servers settings!")
          });
    }

    const get_members = async () => {
        setMembersLoadingStatus("loading")
        const options = {
            url: `${config["api_base_url"]}/guilds/${params.guild_id}/members`,
            method: 'GET',
            headers: {
                access_token: cookies["access_token"]
            },
          };

          axios(options)
          .then(response => {
              if (response.status === 200) {
                  setGuildMembers(response["data"]["data"])
                  setMembersLoadingStatus("success")
              }
          })
          .catch(error => {
              console.log(error)
              setMembersLoadingStatus("error")
              if (error.response.status === 429) {
                toast.error("Rate limit reached! Please slow down!")
                return
            }
              toast.error("Something went wrong while getting the servers members!")
          });
    }

    useEffect(() => {
        get_polls_paged()
        get_settings()
        get_channels()
        get_members()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return(
        <div className='dashboard-functions-polls-content-inner-wrapper'>
            { channelsLoadingStatus === 'success' && pollsLoadingStatus === 'success' && settingsLoadingStatus === 'success' && membersLoadingStatus === 'success' ? 
                <div className='dashboard-functions-polls-items-container'>
                    <div className='dashboard-functions-polls-general-interactions-wrapper'>
                        <div className='dashboard-functions-polls-data'>
                            <RefreshButton on_click_action={refresh_data} />
                        </div>
                        <div className='dashboard-functions-polls'>
                            <GenericButton button_text="Create poll" on_click_action={open_create_poll_popup} />
                        </div>
                    </div>
                    <div className='polls-container'>
                        { serverPolls.length === 0 ? 
                            <div className='dashboard-functions-polls-no-entries-wrapper'>
                                This server does not have any active polls yet!
                            </div>
                            :
                            <div className='polls-entry-wrapper'>
                                <div className='polls-entry-inner-wrapper'>
                                    { serverPolls.map(poll =>
                                        <PollEntry key={poll.poll_id} poll={poll} guild_settings={guildSettings}/>
                                    )}
                                </div>
                                <div className='dashboard-functions-polls-load-extra'>
                                    <GenericButton type="secondary" button_text="Load extra polls" on_click_action={get_extra_data} />
                                </div>
                            </div>
                        }
                        <div className='create-poll-sidebar' id="create-poll-sidebar">
                            <div className='create-poll-wrapper'>
                                <div className='create-poll-cancel'>
                                    <div className="create-poll-back-button" onClick={close_create_poll_popup}>
                                        <div className="create-poll-button-icon"><span className="material-symbols-outlined" id="poll-close-icon">close</span></div>
                                        <div className="create-poll-button-text-wrapper"><span className="create-poll-button-text">Cancel & Reset</span></div>
                                    </div>
                                    <h2 className='create-poll-title'>
                                        New poll
                                    </h2>
                                </div>
                                <div className='create-poll-type'>
                                    <h3 className='create-poll-type-label'>
                                        Poll type*
                                    </h3>
                                    <select className='create-poll-type-input' placeholder="normal" id='create-poll-type-input' name='new-poll-type' onChange={on_field_change} value={newPollType}>
                                        <option key="normal" value="normal">
                                            Normal
                                        </option>
                                        <option key="timed" value="timed">
                                            Timed
                                        </option>
                                    </select>
                                </div>
                                { newPollType === 'timed' ?
                                    <div className='create-poll-duration'>
                                        <h3 className='create-poll-duration-label'>
                                            Poll duration (d/h/m/s)*
                                        </h3>
                                        <input className='create-poll-duration-input' id='create-poll-duration-input' name='new-poll-duration' placeholder='2h' onChange={on_field_change} value={newPollEndtime} />
                                    </div> : <div></div>
                                }
                                <div className='create-poll-anonymous-max-choices-wrapper'>
                                    <div key="anonymous" className='create-poll-anonymous'>
                                        <h3 className='create-poll-duration-label'>
                                            Anonymous*
                                        </h3>
                                        { newPollAnonymous ?
                                            <div key="anonymous-yes">
                                                <div className='create-poll-anonymous-wrapper'>
                                                    <label className="toggler-wrapper style-1">
                                                        <input id="create-poll-is-anonymous" type="checkbox" name="create-poll-anonymous" defaultChecked onChange={on_field_change}></input>
                                                        <div className="toggler-slider">
                                                            <div className="toggler-knob"></div>
                                                        </div>
                                                    </label>
                                                </div>
                                            </div> 
                                            :
                                            <div key="anonymous-no">
                                                <div  id="create-poll-anonymous-false" className='create-poll-anonymous-wrapper'>
                                                    <label className="toggler-wrapper style-1">
                                                        <input id="create-poll-is-anonymous" type="checkbox" name="create-poll-anonymous" onChange={on_field_change}></input>
                                                        <div className="toggler-slider">
                                                            <div className="toggler-knob"></div>
                                                        </div>
                                                    </label>
                                                </div> 
                                            </div>
                                        }
                                    </div>
                                    <div className='create-poll-max-choices'>
                                        <h3 className='create-poll-max-choices-label'>
                                            Max choices*
                                        </h3>
                                        <input type="number" className='create-poll-max-choices-input' id='create-poll-max-choices-input' name='new-poll-max-choices' placeholder='1' onChange={on_field_change} value={newPollMaxChoices} />
                                    </div>
                                </div>
                                <div className='create-poll-owner-id'>
                                    <h3 className='create-poll-owner-id-label'>
                                        Owner*
                                    </h3>
                                    <select className='create-poll-owner-id-input' id='create-poll-owner-id-input' name='new-poll-owner-id' onChange={on_field_change} value={newPollOwner}>
                                        { guildMembers.map(guild_member => 
                                            <option key={ String(guild_member.id) } value={ String(guild_member.id) }>
                                                { guild_member.name }
                                            </option>
                                        )}
                                        <option key="no-owner" value="">
                                            No owner selected yet!
                                        </option>
                                    </select>
                                </div>
                                <div className='create-poll-channel-id'>
                                    <h3 className='create-poll-channel-id-label'>
                                        Channel*
                                    </h3>
                                    <select className='create-poll-channel-id-input' id='create-poll-channel-id-input' name='new-poll-channel-id' onChange={on_field_change} value={newPollChannel}>
                                        { guildChannels.map(guild_channel => 
                                            <option key={ String(guild_channel.id) } value={ String(guild_channel.id) }>
                                                { guild_channel.name }
                                            </option>
                                        )}
                                        <option key="no-channel" value="">
                                            No channel selected yet!
                                        </option>
                                    </select>
                                </div>
                                <div className='create-poll-question'>
                                    <h3 className='create-poll-question-label'>
                                        Question*
                                    </h3>
                                    <textarea rows={1} className='create-poll-question-input' id='create-poll-question-input' name='new-poll-question' placeholder='A question' onChange={on_field_change} value={newPollQuestion}/>
                                </div>
                                <div className='create-poll-answer1'>
                                    <h3 className='create-poll-answer1-label'>
                                        Answer1*
                                    </h3>
                                    <textarea rows={1} className='create-poll-answer1-input' id='create-poll-answer1-input' name='new-poll-answer1' placeholder='A' onChange={on_field_change} value={newPollAnswer1}/>
                                </div>
                                <div className='create-poll-answer2'>
                                    <h3 className='create-poll-answer2-label'>
                                        Answer2*
                                    </h3>
                                    <textarea rows={1} className='create-poll-answer2-input' id='create-poll-answer2-input' name='new-poll-answer2' placeholder='B' onChange={on_field_change} value={newPollAnswer2}/>
                                </div>
                                { newPollAnswer1 !== "" && newPollAnswer2 !== "" ?
                                    <div className='create-poll-answer3'>
                                        <h3 className='create-poll-answer3-label'>
                                            Answer3
                                        </h3>
                                        <textarea rows={1} className='create-poll-answer3-input' id='create-poll-answer3-input' name='new-poll-answer3' placeholder='C' onChange={on_field_change} value={newPollAnswer3}/>
                                    </div> : <div></div>
                                }
                                { newPollAnswer1 !== "" && newPollAnswer2 !== "" && newPollAnswer3 !== "" ?
                                    <div className='create-poll-answer4'>
                                        <h3 className='create-poll-answer4-label'>
                                            Answer4
                                        </h3>
                                        <textarea rows={1} className='create-poll-answer4-input' id='create-poll-answer4-input' name='new-poll-answer4' placeholder='D' onChange={on_field_change} value={newPollAnswer4}/>
                                    </div> : <div></div>
                                }
                                { newPollAnswer1 !== "" && newPollAnswer2 !== "" && newPollAnswer3 !== "" && newPollAnswer4 !== ""?
                                    <div className='create-poll-answer5'>
                                        <h3 className='create-poll-answer5-label'>
                                            Answer5
                                        </h3>
                                        <textarea rows={1} className='create-poll-answer5-input' id='create-poll-answer5-input' name='new-poll-answer5' placeholder='E' onChange={on_field_change} value={newPollAnswer5}/>
                                    </div> : <div></div>
                                }
                            </div>
                            <div className='create-poll-interactions-wrapper'>
                                <div className='submit'>
                                    <GenericButton button_text="Create poll" on_click_action={create_poll} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <Loading loading_size='s' />
            }
        </div>
    );
};

export default DashboardFunctionsPollsContent;