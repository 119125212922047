import React, { useState } from 'react';
import { useParams } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import { toast } from 'react-toastify'
import axios from 'axios'

import '../../../styles/components/dashboard/functions/PollEntry.css'
import config from '../../../config'

function PollEntry(props) {
    const [cookies] = useCookies(['access_token'])
        
    const [poll, setPoll] = useState(props.poll)
    const [guildSettings, setGuildSettings] = useState(props.guild_settings)

    const params = useParams()

    async function delete_poll(event) {
        event.preventDefault()
        var old_poll = {}
        old_poll.action_type = "delete"
        old_poll.poll_id = event.target.id

        const options = {
            url: `${config["api_base_url"]}/guilds/${params.guild_id}/functions/polls/${event.target.id}`,
            method: 'DELETE',
            headers: {
                access_token: cookies["access_token"]
            },
            data: old_poll
          };

          axios(options)
          .then(response => {
              if (response.status === 202) {
                window.location.reload()
              }  
          })
          .catch(error => {
              console.log(error)
              if (error.response.status === 401) {
                toast.error("You can't delete this poll because you are not the owner!")
              } else if (error.response.status === 429) {
                toast.error("Rate limit reached! Please slow down!")
            } else {
                toast.error("Something went wrong while deleting the poll!")
              }
          });
    }

    async function close_poll(event) {
        event.preventDefault()
        var closed_poll = {}
        closed_poll.action_type = "close"
        closed_poll.poll_id = event.target.id
        closed_poll.guild_id = params.guild_id

        const options = {
            url: `${config["api_base_url"]}/guilds/${params.guild_id}/functions/polls/${event.target.id}`,
            method: 'PUT',
            headers: {
                access_token: cookies["access_token"]
            },
            data: closed_poll
          };

          axios(options)
          .then(response => {
              if (response.status === 202) {
                window.location.reload()
              }  
          })
          .catch(error => {
              console.log(error)
              if (error.response.status === 401) {
                toast.error("You can't close this poll because you are not the owner!")
              } else if (error.response.status === 429) {
                toast.error("Rate limit reached! Please slow down!")
              } else {
                toast.error("Something went wrong while closing the poll!")
              }
          });
    }

    return(
        <div key={poll.poll_id} className='polls-entry-container'>
            <div className='polls-entry-field-poll-id'>
                <div className='polls-entry-field-poll-id-wrapper'>
                    <label className='polls-entry-field-poll-id-title'>Poll</label>
                    <p className='polls-entry-field-poll-id-description'>{poll.poll_id}</p>
                </div>
                <div className='polls-entry-field-poll-interactions-wrapper'>
                    { !poll.is_finished ?
                        <div className='polls-entry-field-poll-close-wrapper'>
                            <div className="polls-entry-close-button"><span className="material-symbols-outlined" id={poll.poll_id} onClick={close_poll}>stop_circle</span></div>
                        </div> : <div></div>
                    }
                    <div className='polls-entry-field-poll-delete-wrapper'>
                        <div className="polls-entry-delete-button"><span className="material-symbols-outlined" id={poll.poll_id} onClick={delete_poll}>delete</span></div>
                    </div>
                </div>
            </div>
            <hr className='polls-entry-field-splitter' />
            { poll.end_time !== "None" ?
                <div className='polls-entry-field-end-time'>
                    <label className='polls-entry-field-end-time-title'>End time</label>
                    <p className='polls-entry-field-end-time-description'>
                        {new Date(parseInt(poll.end_time)*1000).toLocaleString("en-us", {weekday:"long", day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', timeZone: guildSettings.timezone})} {guildSettings.timezone}
                    </p>
                </div> : <div></div>
            }
            { poll.delete_at !== "None" ?
                <div className='polls-entry-field-delete-time'>
                    <label className='polls-entry-field-delete-time-title'>Delete time</label>
                    <p className='polls-entry-field-delete-time-description'>
                        {new Date(parseInt(poll.delete_at)*1000).toLocaleString("en-us", {weekday:"long", day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', timeZone: guildSettings.timezone})} {guildSettings.timezone}
                    </p>
                </div> : <div></div>
            }
            <div className='polls-entry-field-extra-details'>
                <div className='polls-entry-field-anonymous'>
                    <label className='polls-entry-field-anonymous-title'>Anonymous</label>
                    { poll.anonymous === true ?
                        <p className='polls-entry-field-is-anonymous-description'>Yes</p>
                        :
                        <p className='polls-entry-field-is-anonymous-description'>No</p>
                    }
                </div>
                <div className='polls-entry-field-is-finished'>
                    <label className='polls-entry-field-is-finished-title'>Status</label>
                    { poll.is_finished === true ?
                        <p className='polls-entry-field-is-finished-description'>Finished</p>
                        :
                        <p className='polls-entry-field-is-finished-description'>Active</p>
                    }
                </div>
            </div>
            <div className='polls-entry-field-extra-details'>
                <div className='polls-entry-field-max-choices'>
                    <label className='polls-entry-field-max-choices-title'>Max choices</label>
                    <p className='polls-entry-field-max-choices-description'>{poll.max_choices}</p>
                </div>
                <div className='polls-entry-field-poll-type'>
                    <label className='polls-entry-field-poll-type-title'>Type</label>
                    <p className='polls-entry-field-poll-type-description'>{poll.poll_type}</p>
                </div>
            </div>
            <div className='polls-entry-field-channel-id'>
                <label className='polls-entry-field-channel-id-title'>Channel</label>
                <p className='polls-entry-field-channel-id-description'>{poll.channel.name}<br />({poll.channel.id})</p>
            </div>
            <div className='polls-entry-field-message-id'>
                <label className='polls-entry-field-message-id-title'>Message ID</label>
                <p className='polls-entry-field-message-id-description'>{poll.message_id}</p>
                <a className='polls-entry-field-message-link' href={"https://discord.com/channels/"+params.guild_id+"/"+poll.channel.id+"/"+poll.message_id} target="_blank" rel="noreferrer">
                    View poll in Discord!
                </a>
            </div>
            <div className='polls-entry-field-poll-owner-id'>
                <label className='polls-entry-field-poll-owner-id-title'>Owner</label>
                <p className='polls-entry-field-poll-owner-id-description'>{poll.poll_owner.name}<br />({poll.poll_owner.id})</p>
            </div>
            <div className='polls-entry-field-question'>
                <label className='polls-entry-field-question-title'>Question & Answers</label>
                <p className='polls-entry-field-question-description'>
                    Q: {poll.question}<br /><br />
                    <div>
                        1. {poll.answer1}<br />
                        {poll.answer1_members}<br />
                    </div>
                    <div>
                        <hr className='polls-entry-field-question-description-devider' />
                        2. {poll.answer2}<br />
                        {poll.answer2_members}<br />
                    </div>
                    { poll.answer3 !== "None" ?
                        <div>
                            <hr className='polls-entry-field-question-description-devider' />
                            3. {poll.answer3}<br />
                            {poll.answer3_members}<br />
                        </div> : <div></div>
                    }
                    { poll.answer4 !== "None" ?
                        <div>
                            <hr className='polls-entry-field-question-description-devider' />
                            4. {poll.answer4}<br />
                            {poll.answer4_members}<br />
                        </div> : <div></div>
                    }
                    { poll.answer5 !== "None" ?
                        <div>
                            <hr className='polls-entry-field-question-description-devider' />
                            5. {poll.answer5}<br />
                            {poll.answer5_members}<br />
                        </div> : <div></div>
                    }
                </p>
            </div>
        </div>
    )
}

export default PollEntry