import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import { toast } from 'react-toastify'
import axios from 'axios'
import Loading from '../../../components/loading'
import GenericButton from "../../../components/buttons/generic_button"
import RefreshButton from "../../../components/buttons/refresh_button"

import '../../../styles/pages/dashboard/modules/greetingsContent.css'
import '../../../js/sticky_modules_greetings_bars.js'
import config from '../../../config'

const DashboardModulesGreetingsContent = () => {
    const [cookies] = useCookies(['access_token'])

    const [moduleGreetingsStatus, setModuleGreetingsStatus] = useState(false)
    const [moduleGreetingsSettings, setModuleGreetingsSettings] = useState({})

    const [guildChannels, setGuildChannels] = useState([])
    const [guildRoles, setGuildRoles] = useState([])

    const [newGreetingsInGuild, setNewGreetingsInGuild] = useState(false)
    const [newGreetingsInGuildContent, setNewGreetingsInGuildContent] = useState("")
    const [newGreetingsInGuildType, setNewGreetingsInGuildType] = useState("")
    const [newGreetingsInGuildChannel, setNewGreetingsInGuildChannel] = useState("")
    const [newGreetingsToUser, setNewGreetingsToUser] = useState(false)
    const [newGreetingsToUserContent, setNewGreetingsToUserContent] = useState("")
    const [newGreetingsToUserType, setNewGreetingsToUserType] = useState("")
    const [newGreetingsLeaveTakingInGuild, setNewGreetingsLeaveTakingInGuild] = useState(false)
    const [newGreetingsLeaveTakingInGuildContent, setNewGreetingsLeaveTakingInGuildContent] = useState("")
    const [newGreetingsLeaveTakingInGuildType, setNewGreetingsLeaveTakingInGuildType] = useState("")
    const [newGreetingsLeaveTakingInGuildChannel, setNewGreetingsLeaveTakingInGuildChannel] = useState("")
    const [newGreetingsRoleAdd, setNewGreetingsRoleAdd] = useState(false)
    const [newGreetingsRoleAddRole, setNewGreetingsRoleAddRole] = useState("")

    const params = useParams()
    const [moduleGreetingsLoadingStatus, setModuleGreetingsLoadingStatus] = useState('loading')
    const [channelsLoadingStatus, setChannelsLoadingStatus] = useState('loading')
    const [rolesLoadingStatus, setRolesLoadingStatus] = useState('loading')
    const [moduleGreetingsSettingsLoadingStatus, setModuleGreetingsSettingsLoadingStatus] = useState('loading')

    // FIELD CHANGES
    const on_field_change = async (event) => {
        event.preventDefault();
        if (event.target.name === "new-greetings-module-in-guild-enabled-input") {
            if (event.target.id === "on") {
                setNewGreetingsInGuild(true)
            } else if (event.target.id === "off") {
                setNewGreetingsInGuild(false)
            }
        } else if (event.target.name === "new-dashboard-modules-greetings-in-guild-content") {
            setNewGreetingsInGuildContent(event.target.value)
        } else if (event.target.name === "new-dashboard-modules-greetings-in-guild-type") {
            setNewGreetingsInGuildType(event.target.value)
        } else if (event.target.name === "new-dashboard-modules-greetings-in-guild-channel") {
            setNewGreetingsInGuildChannel(event.target.value)
        } else if (event.target.name === "new-greetings-module-to-user-enabled-input") {
            if (event.target.id === "on") {
                setNewGreetingsToUser(true)
            } else if (event.target.id === "off") {
                setNewGreetingsToUser(false)
            }
        } else if (event.target.name === "new-dashboard-modules-greetings-to-user-content") {
            setNewGreetingsToUserContent(event.target.value)
        } else if (event.target.name === "new-dashboard-modules-greetings-to-user-type") {
            setNewGreetingsToUserType(event.target.value)
        } else if (event.target.name === "new-greetings-module-leave-taking-in-guild-enabled-input") {
            if (event.target.id === "on") {
                setNewGreetingsLeaveTakingInGuild(true)
            } else if (event.target.id === "off") {
                setNewGreetingsLeaveTakingInGuild(false)
            }
        } else if (event.target.name === "new-dashboard-modules-greetings-leave-taking-in-guild-content") {
            setNewGreetingsLeaveTakingInGuildContent(event.target.value)
        } else if (event.target.name === "new-dashboard-modules-greetings-leave-taking-in-guild-type") {
            setNewGreetingsLeaveTakingInGuildType(event.target.value)
        } else if (event.target.name === "new-dashboard-modules-greetings-leave-taking-in-guild-channel") {
            setNewGreetingsLeaveTakingInGuildChannel(event.target.value)
        }  else if (event.target.name === "new-greetings-module-role-add-enabled-input") {
            if (event.target.id === "on") {
                setNewGreetingsRoleAdd(true)
            } else if (event.target.id === "off") {
                setNewGreetingsRoleAdd(false)
            }
        } else if (event.target.name === "new-dashboard-modules-greetings-role-add-role") {
            setNewGreetingsRoleAddRole(event.target.value)
        }
    }

    // Refresh data
    const refresh_data_table = async (event) => {
        event.preventDefault();
        window.location.reload()
    }

    // Module Status
    const open_enable_greetings_module_sidebar = async (event) => {
        event.preventDefault()
        var bar = document.getElementById('enable-greetings-module-sidebar')
        bar.style.transform = "translateX(0px)"
    }
    const close_enable_greetings_module_sidebar = async (event) => {
        event.preventDefault()
        var bar = document.getElementById('enable-greetings-module-sidebar')
        bar.style.transform = "translateX(+502px)"
    }

    const get_module_status = async () => {
        setModuleGreetingsLoadingStatus("loading")
        const options = {
            url: `${config["api_base_url"]}/guilds/${params.guild_id}/modules?module_names=greetings`,
            method: 'GET',
            headers: {
                access_token: cookies["access_token"]
            }
          };
        axios(options)
        .then(response => {
            if (response.status === 200) {
                    setModuleGreetingsStatus(response["data"]["data"]["module_greetings"])
                    if (response["data"]["data"]["module_greetings"] === true) {
                        get_module_settings()
                    }
                }
                setModuleGreetingsLoadingStatus("success")
        })
        .catch(error => {
            console.log(error)
            if (error.response.status === 429) {
                toast.error("Rate limit reached! Please slow down!")
                return
            }
            setModuleGreetingsLoadingStatus("error")
            toast.error("Something went wrong while fetching the module Greetings status!")
        });
    }

    const set_module_status = async (event) => {
        var new_status
        if (event.target.id === "enable") {
            new_status = true
        } else if (event.target.id === "disable") {
            new_status = false
        }

        var data = {}
        data.greetings = {}
        data.greetings.new_status = new_status
        data.greetings.guild_id = params.guild_id
        
        const options = {
            url: `${config["api_base_url"]}/guilds/${params.guild_id}/modules`,
            method: 'POST',
            headers: {
                access_token: cookies["access_token"]
            },
            data: data
          };

        axios(options)
        .then(response => {
            if (response.status === 202) {
                if (response["data"]["data"]["greetings"]["code"] === 200) {
                    window.location.reload()
                }
            } else {
                toast.error("Something went wrong while updating the Greetings module!")
            }
        })
        .catch(error => {
            console.log(error)
            if (error.response.status === 429) {
                toast.error("Rate limit reached! Please slow down!")
                return
            }
            toast.error("Something went wrong while updating the Greetings module!")
        });
    }

    // Get data
    const get_channels = async () => {
        setChannelsLoadingStatus("loading")
        const options = {
            url: `${config["api_base_url"]}/guilds/${params.guild_id}/channels?types=text`,
            method: 'GET',
            headers: {
                access_token: cookies["access_token"]
            },
          };

          axios(options)
          .then(response => {
              if (response.status === 200) {
                  setGuildChannels(response["data"][0]["data"])
                  setChannelsLoadingStatus("success")
              }
          })
          .catch(error => {
              console.log(error)
              setChannelsLoadingStatus("error")
              if (error.response.status === 429) {
                toast.error("Rate limit reached! Please slow down!")
                return
            }
              toast.error("Something went wrong while getting the servers channels!")
          });
    }

    const get_roles = async () => {
        setRolesLoadingStatus("loading")
        const options = {
            url: `${config["api_base_url"]}/guilds/${params.guild_id}/roles`,
            method: 'GET',
            headers: {
                access_token: cookies["access_token"]
            },
          };

        axios(options)
          .then(response => {
              if (response.status === 200) {
                  setGuildRoles(response["data"]["data"])
                  setRolesLoadingStatus("success")
              }
          })
          .catch(error => {
              console.log(error)
              setRolesLoadingStatus("error")
              if (error.response.status === 429) {
                toast.error("Rate limit reached! Please slow down!")
                return
            }
              toast.error("Something went wrong while getting the servers roles!")
          });
    }

    const get_module_settings = async () => {
        setModuleGreetingsSettingsLoadingStatus("loading")
        const options = {
            url: `${config["api_base_url"]}/guilds/${params.guild_id}/modules/greetings`,
            method: 'GET',
            headers: {
                access_token: cookies["access_token"]
            },
          };

          axios(options)
          .then(response => {
              if (response.status === 200) {
                setModuleGreetingsSettings(response["data"]["data"])
                setNewGreetingsInGuild(response["data"]["data"]["greetings_in_guild"])
                setNewGreetingsInGuildContent(response["data"]["data"]["greetings_in_guild_content"])
                setNewGreetingsInGuildType(response["data"]["data"]["greetings_in_guild_type"])
                if (response["data"]["data"]["greetings_in_guild_channel"]["id"] === null) {
                    setNewGreetingsInGuildChannel("none")
                } else {
                    setNewGreetingsInGuildChannel(response["data"]["data"]["greetings_in_guild_channel"]["id"])
                }
                setNewGreetingsToUser(response["data"]["data"]["greetings_to_user"])
                setNewGreetingsToUserContent(response["data"]["data"]["greetings_to_user_content"])
                setNewGreetingsToUserType(response["data"]["data"]["greetings_to_user_type"])
                setNewGreetingsLeaveTakingInGuild(response["data"]["data"]["leave_taking_in_guild"])
                setNewGreetingsLeaveTakingInGuildContent(response["data"]["data"]["leave_taking_in_guild_content"])
                setNewGreetingsLeaveTakingInGuildType(response["data"]["data"]["leave_taking_in_guild_type"])
                if (response["data"]["data"]["greetings_in_guild_channel"]["id"] === null) {
                    setNewGreetingsLeaveTakingInGuildChannel("none")
                } else {
                    setNewGreetingsLeaveTakingInGuildChannel(response["data"]["data"]["leave_taking_in_guild_channel"]["id"])
                }
                setNewGreetingsRoleAdd(response["data"]["data"]["greetings_role_add"])
                if (response["data"]["data"]["greetings_role"]["id"] === null) {
                    setNewGreetingsRoleAddRole("none")
                } else {
                    setNewGreetingsRoleAddRole(response["data"]["data"]["greetings_role"]["id"])
                }
                setModuleGreetingsSettingsLoadingStatus("success")
              }
          })
          .catch(error => {
              console.log(error)
              setModuleGreetingsSettingsLoadingStatus("error")
              if (error.response.status === 429) {
                toast.error("Rate limit reached! Please slow down!")
                return
            }
              toast.error("Something went wrong while getting the settings for the Greetings module!")
          });
    }

    const reset_module_settings = async () => {
        setNewGreetingsInGuild(moduleGreetingsSettings.greetings_in_guild)
        setNewGreetingsInGuildContent(moduleGreetingsSettings.greetings_in_guild_content)
        setNewGreetingsInGuildType(moduleGreetingsSettings.greetings_in_guild_type)
        if (moduleGreetingsSettings.greetings_in_guild_channel.id === null) {
            setNewGreetingsInGuildChannel("none")
        } else {
            setNewGreetingsInGuildChannel(moduleGreetingsSettings.greetings_in_guild_channel.id)
        }
        setNewGreetingsToUser(moduleGreetingsSettings.greetings_to_user)
        setNewGreetingsToUserContent(moduleGreetingsSettings.greetings_to_user_content)
        setNewGreetingsToUserType(moduleGreetingsSettings.greetings_to_user_type)
        setNewGreetingsLeaveTakingInGuild(moduleGreetingsSettings.leave_taking_in_guild)
        setNewGreetingsLeaveTakingInGuildContent(moduleGreetingsSettings.leave_taking_in_guild_content)
        setNewGreetingsLeaveTakingInGuildType(moduleGreetingsSettings.leave_taking_in_guild_type)
        if (moduleGreetingsSettings.leave_taking_in_guild_channel.id === null) {
            setNewGreetingsLeaveTakingInGuildChannel("none")
        } else {
            setNewGreetingsLeaveTakingInGuildChannel(moduleGreetingsSettings.leave_taking_in_guild_channel.id)
        }
        setNewGreetingsRoleAdd(moduleGreetingsSettings.greetings_role_add)
        if (moduleGreetingsSettings.greetings_role.id === null) {
            setNewGreetingsRoleAddRole("none")
        } else {
            setNewGreetingsRoleAddRole(moduleGreetingsSettings.greetings_role.id)
        }
    }

    const set_module_settings = async () => {
        var data = {}
        if (newGreetingsInGuild === true) {
            if (newGreetingsInGuildType === "none") {
                toast.error("You must select the type with which to respond for the greetings in server!")
                return
            }
            if (newGreetingsInGuildContent === "None") {
                toast.error("You need to insert the content for the greetings in server!")
                return
            }
            if (newGreetingsInGuildChannel === "none") {
                toast.error("You must select the channel to which the greetings are posted for the greetings in server!")
                return
            }
        }
        if (newGreetingsToUser === true) {
            if (newGreetingsToUserType === "none") {
                toast.error("You must select the type with which to respond for the greetings to user!")
                return
            }
            if (newGreetingsToUserContent === "None") {
                toast.error("You need to insert the content for the greetings to user!")
                return
            }
        }
        if (newGreetingsLeaveTakingInGuild === true) {
            if (newGreetingsLeaveTakingInGuildType === "none") {
                toast.error("You must select the type with which to respond for the leave taking in server!")
                return
            }
            if (newGreetingsLeaveTakingInGuildContent === "None") {
                toast.error("You need to insert the content for the leave taking in server!")
                return
            }
            if (newGreetingsLeaveTakingInGuildChannel === "none") {
                toast.error("You must select the channel to which the leave taking messages are posted for the leave taking in server!")
                return
            }
        }
        if (newGreetingsRoleAdd === true) {
            if (newGreetingsRoleAddRole === "none") {
                toast.error("You must select the role to assign to users for the greetings role add!")
                return
            }
        }

        data.guild_id = params.guild_id
        data.greetings_in_guild = newGreetingsInGuild
        if (newGreetingsInGuild === false) {
            data.greetings_in_guild_content = null
            data.greetings_in_guild_type = "message"
            data.greetings_in_guild_channel = null
        } else {
            data.greetings_in_guild_content = newGreetingsInGuildContent
            data.greetings_in_guild_type = newGreetingsInGuildType
            data.greetings_in_guild_channel = newGreetingsInGuildChannel
        }
        data.greetings_to_user = newGreetingsToUser
        if (newGreetingsToUser  === false) {
            data.greetings_to_user_content = null
            data.greetings_to_user_type = "message"
        } else {
            data.greetings_to_user_content = newGreetingsToUserContent
            data.greetings_to_user_type = newGreetingsToUserType
        }
        data.leave_taking_in_guild = newGreetingsLeaveTakingInGuild
        if (newGreetingsLeaveTakingInGuild === false) {
            data.leave_taking_in_guild_content = null
            data.leave_taking_in_guild_type = "message"
            data.leave_taking_in_guild_channel = null
        } else {
            data.leave_taking_in_guild_content = newGreetingsLeaveTakingInGuildContent
            data.leave_taking_in_guild_type = newGreetingsLeaveTakingInGuildType
            data.leave_taking_in_guild_channel = newGreetingsLeaveTakingInGuildChannel
        }
        data.greetings_role_add = newGreetingsRoleAdd
        if (newGreetingsRoleAdd === false) {
            data.greetings_role = null
        } else {
            data.greetings_role = newGreetingsRoleAddRole
        }

        const options = {
            url: `${config["api_base_url"]}/guilds/${params.guild_id}/modules/greetings`,
            method: 'PUT',
            headers: {
                access_token: cookies["access_token"]
            },
            data: data,
          };

          axios(options)
          .then(response => {
              if (response.status === 202) {
                window.location.reload()
              }
          })
          .catch(error => {
              console.log(error)
              if (error.response.status === 429) {
                toast.error("Rate limit reached! Please slow down!")
                return
            }
              toast.error("Something went wrong while changing the settings of the Greetings module!")
          });
    }

    useEffect(() => {
        get_module_status()
        get_channels()
        get_roles()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return(
        <div className='dashboard-modules-greetings-content-inner-wrapper'>
            { moduleGreetingsLoadingStatus === 'success' && channelsLoadingStatus === 'success' && rolesLoadingStatus === 'success' ?
                moduleGreetingsStatus === true ?
                    moduleGreetingsSettingsLoadingStatus === 'success' ?  
                        <div className='dashboard-modules-greetings-enabled-container'> 
                            <div className='dashboard-modules-greetings-enabled-disable-wrapper'>
                                Click <label id="disable" onClick={set_module_status} className='dashboard-modules-greetings-enabled-disable-button'>here</label> if you want to disable this module!
                            </div>
                            <div className='dashboard-modules-greetings-enabled-settings-container'>
                                <div className='dashboard-modules-greetings-enabled-settings-reminder'>Don't forget to save your changes!</div>
                                <div className='dashboard-modules-greetings-enabled-setting'>
                                    <div className='dashboard-modules-greetings-enabled-setting-label-container'>
                                        <div className='dashboard-modules-greetings-enabled-setting-label'>
                                            Greetings in Server
                                        </div>
                                        { newGreetingsInGuild ?
                                            <div key="off">
                                                <div className='new-greetings-module-in-guild-enabled-wrapper'>
                                                    <label className="toggler-wrapper style-1">
                                                        <input id="off" type="checkbox" name="new-greetings-module-in-guild-enabled-input" defaultChecked onChange={on_field_change}></input>
                                                        <div className="toggler-slider">
                                                            <div className="toggler-knob"></div>
                                                        </div>
                                                    </label>
                                                </div>
                                            </div> 
                                            :
                                            <div key="on">
                                                <div className='new-greetings-module-in-guild-enabled-wrapper'>
                                                    <label className="toggler-wrapper style-1">
                                                        <input id="on" type="checkbox" name="new-greetings-module-in-guild-enabled-input" onChange={on_field_change}></input>
                                                        <div className="toggler-slider">
                                                            <div className="toggler-knob"></div>
                                                        </div>
                                                    </label>
                                                </div> 
                                            </div>
                                        }
                                    </div>
                                    <div className='dashboard-modules-greetings-enabled-setting-description'>If new users should be greeted in a channel.</div>
                                    { newGreetingsInGuild ?
                                        <div>
                                            <hr  className='dashboard-modules-greetings-enabled-setting-devider' />
                                            <div className='dashboard-modules-greetings-enabled-internal-setting'>
                                                <div className='dashboard-modules-greetings-enabled-internal-setting-label'>Greetings in server type</div>
                                                <select className='dashboard-modules-greetings-in-guild-type-input dashboard-modules-greetings-enabled-internal-setting-input' id='dashboard-modules-greetings-in-guild-type-input' name='new-dashboard-modules-greetings-in-guild-type' onChange={on_field_change} value={newGreetingsInGuildType}>
                                                    <option key="greetings-in-guild-type-none" value="none">
                                                        No type selected!
                                                    </option>
                                                    <option key="greetings-in-guild-type-embed" value="embed">
                                                        Embed
                                                    </option>
                                                    <option key="greetings-in-guild-type-message" value="message">
                                                        Message
                                                    </option>
                                                </select>
                                            </div>
                                            <div className='dashboard-modules-greetings-enabled-internal-setting'>
                                                <div className='dashboard-modules-greetings-enabled-internal-setting-label'>Greetings in server content</div>
                                                <input className='dashboard-modules-greetings-in-guild-content-input dashboard-modules-greetings-enabled-internal-setting-input' id='dashboard-modules-greetings-in-guild-content-input' name='new-dashboard-modules-greetings-in-guild-content' onChange={on_field_change} value={newGreetingsInGuildContent}></input>
                                            </div>
                                            <div className='dashboard-modules-greetings-enabled-internal-setting'>
                                                <div className='dashboard-modules-greetings-enabled-internal-setting-label'>Greetings in server channel</div>
                                                <select className='dashboard-modules-greetings-in-guild-channel-input dashboard-modules-greetings-enabled-internal-setting-input' id='dashboard-modules-greetings-in-guild-channel-input' name='new-dashboard-modules-greetings-in-guild-channel' onChange={on_field_change} value={newGreetingsInGuildChannel}>
                                                    { guildChannels.map(guild_channel => 
                                                        <option key={ String(guild_channel.id) } value={ String(guild_channel.id) }>
                                                            { guild_channel.name }
                                                        </option>
                                                    )}
                                                    <option key="greetings-in-guild-channel-none" value="none">
                                                        No channel selected!
                                                    </option>
                                                </select>
                                            </div>
                                            { moduleGreetingsSettings.greetings_in_guild_channel.id !== null ?
                                                <a className='greetings-entry-field-channel-link' href={"https://discord.com/channels/"+params.guild_id+"/"+moduleGreetingsSettings.greetings_in_guild_channel.id} target="_blank" rel="noreferrer">
                                                    View in Discord!
                                                </a>
                                                :
                                                <div></div>
                                            }
                                        </div>
                                        :
                                        <div></div>
                                    }
                                </div>
                                <div className='dashboard-modules-greetings-enabled-setting'>
                                    <div className='dashboard-modules-greetings-enabled-setting-label-container'>
                                        <div className='dashboard-modules-greetings-enabled-setting-label'>
                                            Greetings to user
                                        </div>
                                        { newGreetingsToUser ?
                                            <div key="off">
                                                <div className='new-greetings-module-to-user-enabled-wrapper'>
                                                    <label className="toggler-wrapper style-1">
                                                        <input id="off" type="checkbox" name="new-greetings-module-to-user-enabled-input" defaultChecked onChange={on_field_change}></input>
                                                        <div className="toggler-slider">
                                                            <div className="toggler-knob"></div>
                                                        </div>
                                                    </label>
                                                </div>
                                            </div> 
                                            :
                                            <div key="on">
                                                <div className='new-greetings-module-to-user-enabled-wrapper'>
                                                    <label className="toggler-wrapper style-1">
                                                        <input id="on" type="checkbox" name="new-greetings-module-to-user-enabled-input" onChange={on_field_change}></input>
                                                        <div className="toggler-slider">
                                                            <div className="toggler-knob"></div>
                                                        </div>
                                                    </label>
                                                </div> 
                                            </div>
                                        }
                                    </div>
                                    <div className='dashboard-modules-greetings-enabled-setting-description'>If new users should be greeted in their DM.</div>
                                    { newGreetingsToUser ?
                                        <div>
                                            <hr  className='dashboard-modules-greetings-enabled-setting-devider' />
                                            <div className='dashboard-modules-greetings-enabled-internal-setting'>
                                                <div className='dashboard-modules-greetings-enabled-internal-setting-label'>Greetings to user type</div>
                                                <select className='dashboard-modules-greetings-to-user-type-input dashboard-modules-greetings-enabled-internal-setting-input' id='dashboard-modules-greetings-to-user-type-input' name='new-dashboard-modules-greetings-to-user-type' onChange={on_field_change} value={newGreetingsToUserType}>
                                                    <option key="greetings-to-user-type-none" value="none">
                                                        No type selected!
                                                    </option>
                                                    <option key="greetings-to-user-type-embed" value="embed">
                                                        Embed
                                                    </option>
                                                    <option key="greetings-to-user-type-message" value="message">
                                                        Message
                                                    </option>
                                                </select>
                                            </div>
                                            <div className='dashboard-modules-greetings-enabled-internal-setting'>
                                                <div className='dashboard-modules-greetings-enabled-internal-setting-label'>Greetings to user content</div>
                                                <input className='dashboard-modules-greetings-to-user-content-input dashboard-modules-greetings-enabled-internal-setting-input' id='dashboard-modules-greetings-to-user-content-input' name='new-dashboard-modules-greetings-to-user-content' onChange={on_field_change} value={newGreetingsToUserContent}></input>
                                            </div>
                                        </div>
                                        :
                                        <div></div>
                                    }
                                </div>
                                <div className='dashboard-modules-greetings-enabled-setting'>
                                    <div className='dashboard-modules-greetings-enabled-setting-label-container'>
                                        <div className='dashboard-modules-greetings-enabled-setting-label'>
                                            Leave taking in Server
                                        </div>
                                        { newGreetingsLeaveTakingInGuild ?
                                            <div key="off">
                                                <div className='new-greetings-module-leave-taking-in-guild-enabled-wrapper'>
                                                    <label className="toggler-wrapper style-1">
                                                        <input id="off" type="checkbox" name="new-greetings-module-leave-taking-in-guild-enabled-input" defaultChecked onChange={on_field_change}></input>
                                                        <div className="toggler-slider">
                                                            <div className="toggler-knob"></div>
                                                        </div>
                                                    </label>
                                                </div>
                                            </div> 
                                            :
                                            <div key="on">
                                                <div className='new-greetings-module-leave-taking-in-guild-enabled-wrapper'>
                                                    <label className="toggler-wrapper style-1">
                                                        <input id="on" type="checkbox" name="new-greetings-module-leave-taking-in-guild-enabled-input" onChange={on_field_change}></input>
                                                        <div className="toggler-slider">
                                                            <div className="toggler-knob"></div>
                                                        </div>
                                                    </label>
                                                </div> 
                                            </div>
                                        }
                                    </div>
                                    <div className='dashboard-modules-greetings-enabled-setting-description'>If left users should be waved goodbye.</div>
                                    { newGreetingsLeaveTakingInGuild ?
                                        <div>
                                            <hr  className='dashboard-modules-greetings-enabled-setting-devider' />
                                            <div className='dashboard-modules-greetings-enabled-internal-setting'>
                                                <div className='dashboard-modules-greetings-enabled-internal-setting-label'>Leave taking type</div>
                                                <select className='dashboard-modules-greetings-leave-taking-in-guild-type-input dashboard-modules-greetings-enabled-internal-setting-input' id='dashboard-modules-greetings-leave-taking-in-guild-type-input' name='new-dashboard-modules-greetings-leave-taking-in-guild-type' onChange={on_field_change} value={newGreetingsLeaveTakingInGuildType}>
                                                    <option key="leave-taking-in-guild-type-none" value="none">
                                                        No type selected!
                                                    </option>
                                                    <option key="leave-taking-in-guild-type-embed" value="embed">
                                                        Embed
                                                    </option>
                                                    <option key="leave-taking-in-guild-type-message" value="message">
                                                        Message
                                                    </option>
                                                </select>
                                            </div>
                                            <div className='dashboard-modules-greetings-enabled-internal-setting'>
                                                <div className='dashboard-modules-greetings-enabled-internal-setting-label'>Leave taking content</div>
                                                <input className='dashboard-modules-greetings-leave-taking-in-guild-content-input dashboard-modules-greetings-enabled-internal-setting-input' id='dashboard-modules-greetings-leave-taking-in-guild-content-input' name='new-dashboard-modules-greetings-leave-taking-in-guild-content' onChange={on_field_change} value={newGreetingsLeaveTakingInGuildContent}></input>
                                            </div>
                                            <div className='dashboard-modules-greetings-enabled-internal-setting'>
                                                <div className='dashboard-modules-greetings-enabled-internal-setting-label'>Leave taking channel</div>
                                                <select className='dashboard-modules-greetings-leave-taking-in-guild-channel-input dashboard-modules-greetings-enabled-internal-setting-input' id='dashboard-modules-greetings-leave-taking-in-guild-channel-input' name='new-dashboard-modules-greetings-leave-taking-in-guild-channel' onChange={on_field_change} value={newGreetingsLeaveTakingInGuildChannel}>
                                                    { guildChannels.map(guild_channel => 
                                                        <option key={ String(guild_channel.id) } value={ String(guild_channel.id) }>
                                                            { guild_channel.name }
                                                        </option>
                                                    )}
                                                    <option key="greetings-leave-taking-in-guild-channel-none" value="none">
                                                        No channel selected!
                                                    </option>
                                                </select>
                                            </div>
                                            { moduleGreetingsSettings.leave_taking_in_guild_channel.id !== null ?
                                                <a className='greetings-entry-field-channel-link' href={"https://discord.com/channels/"+params.guild_id+"/"+moduleGreetingsSettings.leave_taking_in_guild_channel.id} target="_blank" rel="noreferrer">
                                                    View in Discord!
                                                </a>
                                                :
                                                <div></div>
                                            }
                                        </div>
                                        :
                                        <div></div>
                                    }
                                </div>
                                <div className='dashboard-modules-greetings-enabled-setting'>
                                    <div className='dashboard-modules-greetings-enabled-setting-label-container'>
                                        <div className='dashboard-modules-greetings-enabled-setting-label'>
                                            Greetings role add
                                        </div>
                                        { newGreetingsRoleAdd ?
                                            <div key="off">
                                                <div className='new-greetings-module-role-add-enabled-wrapper'>
                                                    <label className="toggler-wrapper style-1">
                                                        <input id="off" type="checkbox" name="new-greetings-module-role-add-enabled-input" defaultChecked onChange={on_field_change}></input>
                                                        <div className="toggler-slider">
                                                            <div className="toggler-knob"></div>
                                                        </div>
                                                    </label>
                                                </div>
                                            </div> 
                                            :
                                            <div key="on">
                                                <div className='new-greetings-module-role-add-enabled-wrapper'>
                                                    <label className="toggler-wrapper style-1">
                                                        <input id="on" type="checkbox" name="new-greetings-module-role-add-enabled-input" onChange={on_field_change}></input>
                                                        <div className="toggler-slider">
                                                            <div className="toggler-knob"></div>
                                                        </div>
                                                    </label>
                                                </div> 
                                            </div>
                                        }
                                    </div>
                                    <div className='dashboard-modules-greetings-enabled-setting-description'>If new users should be given a role on join.</div>
                                    { newGreetingsRoleAdd ?
                                        <div>
                                            <hr  className='dashboard-modules-greetings-enabled-setting-devider' />
                                            <div className='dashboard-modules-greetings-enabled-internal-setting'>
                                                <div className='dashboard-modules-greetings-enabled-internal-setting-label'>Greetings role</div>
                                                <select className='dashboard-modules-greetings-role-add-role-input dashboard-modules-greetings-enabled-internal-setting-input' id='dashboard-modules-greetings-role-add-role-input' name='new-dashboard-modules-greetings-role-add-role' onChange={on_field_change} value={newGreetingsRoleAddRole}>
                                                    { guildRoles.map(guild_role => 
                                                        <option key={ String(guild_role.id) } value={ String(guild_role.id) }>
                                                            { guild_role.name }
                                                        </option>
                                                    )}
                                                    <option key="greetings-role-add-role-none" value="none">
                                                        No role selected!
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        :
                                        <div></div>
                                    }
                                </div>
                                <div className="dashboard-modules-greetings-settings-interactions-wrapper">
                                    <div className="dashboard-modules-greetings-settings-interactions-inner-wrapper">
                                        <GenericButton type="danger" on_click_action={reset_module_settings} button_text="Cancel" />
                                        <GenericButton button_text="Save" on_click_action={set_module_settings} />
                                    </div>
                                    <div className="dashboard-modules-greetings-settings-interactions-refresh">
                                        <RefreshButton on_click_action={refresh_data_table} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <Loading loading_size='s' />
                    :
                    <div className='dashboard-modules-greetings-disabled-container'>
                        The Greetings module is disabled, click <label onClick={open_enable_greetings_module_sidebar} className='dashboard-modules-greetings-disabled-enable-button'>here</label> if you want to enabled this module!
                        <div className='enable-greetings-module-sidebar' id="enable-greetings-module-sidebar">
                            <div className='enable-greetings-module-wrapper'>
                                <div className='enable-greetings-module-cancel'>
                                    <div className="enable-greetings-module-back-button" onClick={close_enable_greetings_module_sidebar}>
                                        <div className="enable-greetings-module-button-icon"><span className="material-symbols-outlined" id="giveaway-close-icon">close</span></div>
                                        <div className="enable-greetings-module-button-text-wrapper"><span className="enable-greetings-module-button-text">Cancel & Reset</span></div>
                                    </div>
                                    <h2 className='enable-greetings-module-title'>
                                        Enable the Greetings Module
                                    </h2>
                                </div>
                                <div className='enable-greetings-module-channel-id'>
                                </div>
                            </div>
                            <div className='enable-greetings-module-interactions-wrapper'>
                                <div className='submit'>
                                    <GenericButton button_text="Enable" id="enable" on_click_action={set_module_status} />
                                </div>
                            </div>
                        </div>
                    </div>
                :
                <Loading loading_size='s' />
            }
        </div>
    );
};

export default DashboardModulesGreetingsContent;