import React, { useState, useEffect, useMemo } from 'react';
import { useCookies } from 'react-cookie'
import { toast } from 'react-toastify'
import axios from 'axios'

import '../styles/components/sidebar.css'
import '../js/sticky_sidebar.js'
import config from '../config'

function Sidebar(props) {
    const [cookies] = useCookies(['access_token'])

    const [moduleStatus, setModuleStatus] = useState({})

    window.addEventListener('resize', function(event){
        var newWidth = window.innerWidth;
        if (newWidth > 768) {
            sidebar_mobile_open()
            var bar_toggle_button_close = document.getElementById('close-sidebar')
            bar_toggle_button_close.style.visibility = "hidden"
        } else if (newWidth < 769) {
            sidebar_mobile_close()
        }
    })

    function sidebar_mobile_open() {
        var bar = document.getElementById('sidebar-container');
        var bar_toggle_button_open = document.getElementById('open-sidebar');
        var bar_toggle_button_close = document.getElementById('close-sidebar');  
        bar.style.transform = "translateX(0px)";
        bar_toggle_button_open.style.visibility = "hidden"
        bar_toggle_button_close.style.transform = "translateX(250px)"
        bar_toggle_button_close.style.visibility = "visible"
    }
      
    function sidebar_mobile_close() {
      var bar = document.getElementById('sidebar-container');
      var bar_toggle_button_open = document.getElementById('open-sidebar');
      var bar_toggle_button_close = document.getElementById('close-sidebar');  
      bar.style.transform = "translateX(-250px)";
      bar_toggle_button_open.style.transform = 0;
      bar_toggle_button_open.style.visibility = "visible"
      bar_toggle_button_close.style.visibility = "hidden"
    }

    const get_module_statusses = async () => {
        const options = {
            url: `${config["api_base_url"]}/guilds/${props.guild_id}/modules`,
            method: 'GET',
            headers: {
                access_token: cookies["access_token"]
            },
          };

          axios(options)
          .then(response => {
              if (response.status === 200) {
                  setModuleStatus(response["data"]["data"])
              }
          })
          .catch(error => {
              console.log(error)
              if (error.response.status === 429) {
                toast.error("Rate limit reached! Please slow down!")
                return
            }
          });
    }

    useEffect(() => {
        get_module_statusses()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return(
        <div className='sidebar-container' id='sidebar-container'>
            <div className="sidebar-menu-open" id="open-sidebar"><div onClick={sidebar_mobile_open}>
                <span className="material-symbols-outlined">arrow_forward</span>
            </div></div>
            <div className="sidebar-menu-close" id="close-sidebar"><div onClick={sidebar_mobile_close}>
                <span className="material-symbols-outlined">arrow_back</span>
            </div></div>
            <div className='sidebar-back-categories-container'>
                <div className='sidebar-back-container'>
                    <a href="/" className="sidebar-content-back-button">
                        <div className="sidebar-back-button-icon"><span className="material-symbols-outlined" id="arrow-back-icon">arrow_back</span></div>
                        <div className="sidebar-back-button-text-wrapper"><span className="sidebar-back-button-text">Back to server overview</span></div>
                    </a>
                </div>
                <div className='sidebar-categories-container'>
                    <div className='sidebar-category-group-container'>
                        <a href={"/"+props.guild_id+"/overview"} className='sidebar-category'>
                            <div className='sidebar-category-wrapper'>
                                <div className="sidebar-category-icon-wrapper"><span className="material-symbols-outlined" id="sidebar-category-icon">grid_view</span></div>
                                <div className="sidebar-category-text-wrapper"><span className="sidebar-category-button-text">Dashboard</span></div>                                
                            </div>
                        </a>
                        <a href={"/"+props.guild_id+"/settings"} className='sidebar-category'>
                            <div className='sidebar-category-wrapper'>
                                <div className="sidebar-category-icon-wrapper"><span className="material-symbols-outlined" id="sidebar-category-icon">settings</span></div>
                                <div className="sidebar-category-text-wrapper"><span className="sidebar-category-button-text">Settings</span></div>
                            </div>
                        </a>
                        <a href={"/"+props.guild_id+"/statistics"} className='sidebar-category'>
                            <div className='sidebar-category-wrapper'>
                                <div className="sidebar-category-icon-wrapper"><span className="material-symbols-outlined" id="sidebar-category-icon">analytics</span></div>
                                <div className="sidebar-category-text-wrapper"><span className="sidebar-category-button-text">Statistics</span></div>
                            </div>
                        </a>
                    </div>
                    <div className='sidebar-category-group-container'>
                        <div className='sidebar-category-group'>
                            <span className='sidebar-category-title'>Functions</span>
                        </div>
                        <a href={"/"+props.guild_id+"/functions/giveaways"} className='sidebar-category'>
                            <div className='sidebar-category-wrapper'>
                                <div className="sidebar-category-icon-wrapper"><span className="material-symbols-outlined" id="sidebar-category-icon">celebration</span></div>
                                <div className="sidebar-category-text-wrapper"><span className="sidebar-category-button-text">Giveaways</span></div>
                            </div>
                        </a>
                        <a href={"/"+props.guild_id+"/functions/polls"} className='sidebar-category'>
                            <div className='sidebar-category-wrapper'>
                                <div className="sidebar-category-icon-wrapper"><span className="material-symbols-outlined" id="sidebar-category-icon">how_to_vote</span></div>
                                <div className="sidebar-category-text-wrapper"><span className="sidebar-category-button-text">Polls</span></div>
                            </div>
                        </a>
                        <a href={"/"+props.guild_id+"/functions/reminders"} className='sidebar-category'>
                            <div className='sidebar-category-wrapper'>
                                <div className="sidebar-category-icon-wrapper"><span className="material-symbols-outlined" id="sidebar-category-icon">notifications_active</span></div>
                                <div className="sidebar-category-text-wrapper"><span className="sidebar-category-button-text">Reminders</span></div>
                            </div>
                        </a>
                        <a href={"/"+props.guild_id+"/functions/tags"} className='sidebar-category'>
                            <div className='sidebar-category-wrapper'>
                                <div className="sidebar-category-icon-wrapper"><span className="material-symbols-outlined" id="sidebar-category-icon">sell</span></div>
                                <div className="sidebar-category-text-wrapper"><span className="sidebar-category-button-text">Tags</span></div>
                            </div>
                        </a>
                    </div>
                    <div className='sidebar-category-group-container'>
                        <div className='sidebar-category-group'>
                            <span className='sidebar-category-title'>Modules</span>
                        </div>
                        <a href={"/"+props.guild_id+"/modules/logging"} className='sidebar-category'>
                            <div className='sidebar-category-wrapper'>
                                <div className="sidebar-category-icon-wrapper"><span className="material-symbols-outlined" id="sidebar-category-icon">exclamation</span></div>
                                <div className="sidebar-category-text-wrapper"><span className="sidebar-category-button-text">Logging</span></div>
                            </div>
                            <div className='sidebar-category-status'>
                                { moduleStatus.length === 0 ?
                                        <div></div>
                                    :
                                    moduleStatus.module_logging === true ?
                                        <div>Enabled</div>
                                    :
                                    moduleStatus.module_logging === false ?
                                        <div>Disabled</div>
                                    :
                                    <div></div>
                                }
                            </div>
                        </a>
                        <a href={"/"+props.guild_id+"/modules/greetings"} className='sidebar-category'>
                            <div className='sidebar-category-wrapper'>
                                <div className="sidebar-category-icon-wrapper"><span className="material-symbols-outlined" id="sidebar-category-icon">handshake</span></div>
                                <div className="sidebar-category-text-wrapper"><span className="sidebar-category-button-text">Greetings</span></div>
                            </div>
                            <div className='sidebar-category-status'>
                                { moduleStatus.length === 0 ?
                                        <div></div>
                                    :
                                    moduleStatus.module_greetings === true ?
                                        <div>Enabled</div>
                                    :
                                    moduleStatus.module_greetings === false ?
                                        <div>Disabled</div>
                                    :
                                    <div></div>
                                }
                            </div>
                        </a>
                        <a href={"/"+props.guild_id+"/modules/tempchannels"} className='sidebar-category'>
                            <div className='sidebar-category-wrapper'>
                                <div className="sidebar-category-icon-wrapper"><span className="material-symbols-outlined" id="sidebar-category-icon">voice_chat</span></div>
                                <div className="sidebar-category-text-wrapper"><span className="sidebar-category-button-text">Tempchannels</span></div>
                            </div>
                            <div className='sidebar-category-status'>
                                { moduleStatus.length === 0 ?
                                        <div></div>
                                    :
                                    moduleStatus.module_tempchannel === true ?
                                        <div>Enabled</div>
                                    :
                                    moduleStatus.module_tempchannel === false ?
                                        <div>Disabled</div>
                                    :
                                    <div></div>
                                }
                            </div>
                        </a>
                        <a href={"/"+props.guild_id+"/modules/socials"} className='sidebar-category'>
                            <div className='sidebar-category-wrapper'>
                                <div className="sidebar-category-icon-wrapper"><span className="material-symbols-outlined" id="sidebar-category-icon">link</span></div>
                                <div className="sidebar-category-text-wrapper"><span className="sidebar-category-button-text">Socials</span></div>
                            </div>
                            <div className='sidebar-category-status'>
                                { moduleStatus.length === 0 ?
                                        <div></div>
                                    :
                                    moduleStatus.module_socials === true ?
                                        <div>Enabled</div>
                                    :
                                    moduleStatus.module_socials === false ?
                                        <div>Disabled</div>
                                    :
                                    <div></div>
                                }
                            </div>
                        </a>
                        <a href={"/"+props.guild_id+"/modules/reaction-roles"} className='sidebar-category'>
                            <div className='sidebar-category-wrapper'>
                                <div className="sidebar-category-icon-wrapper"><span className="material-symbols-outlined" id="sidebar-category-icon">sentiment_satisfied</span></div>
                                <div className="sidebar-category-text-wrapper"><span className="sidebar-category-button-text">Reaction Roles</span></div>
                            </div>
                            <div className='sidebar-category-status'>
                                { moduleStatus.length === 0 ?
                                        <div></div>
                                    :
                                    moduleStatus.module_reaction_roles === true ?
                                        <div>Enabled</div>
                                    :
                                    moduleStatus.module_reaction_roles === false ?
                                        <div>Disabled</div>
                                    :
                                    <div></div>
                                }
                            </div>
                        </a>
                        <a href={"/"+props.guild_id+"/modules/autoresponder"} className='sidebar-category'>
                            <div className='sidebar-category-wrapper'>
                                <div className="sidebar-category-icon-wrapper"><span className="material-symbols-outlined" id="sidebar-category-icon">chat</span></div>
                                <div className="sidebar-category-text-wrapper"><span className="sidebar-category-button-text">Autoresponder</span></div>
                            </div>
                            <div className='sidebar-category-status'>
                                { moduleStatus.length === 0 ?
                                        <div></div>
                                    :
                                    moduleStatus.module_autoresponder === true ?
                                        <div>Enabled</div>
                                    :
                                    moduleStatus.module_autoresponder === false ?
                                        <div>Disabled</div>
                                    :
                                    <div></div>
                                }
                            </div>
                        </a>
                        <a href={"/"+props.guild_id+"/modules/tickets"} className='sidebar-category'>
                            <div className='sidebar-category-wrapper'>
                                <div className="sidebar-category-icon-wrapper"><span className="material-symbols-outlined" id="sidebar-category-icon">confirmation_number</span></div>
                                <div className="sidebar-category-text-wrapper"><span className="sidebar-category-button-text">Tickets</span></div>
                            </div>
                            <div className='sidebar-category-status'>
                                { moduleStatus.length === 0 ?
                                        <div></div>
                                    :
                                    moduleStatus.module_tickets === true ?
                                        <div>Enabled</div>
                                    :
                                    moduleStatus.module_tickets === false ?
                                        <div>Disabled</div>
                                    :
                                    <div></div>
                                }
                            </div>
                        </a>
                        <a href={"/"+props.guild_id+"/modules/serverstats"} className='sidebar-category'>
                            <div className='sidebar-category-wrapper'>
                                <div className="sidebar-category-icon-wrapper"><span className="material-symbols-outlined" id="sidebar-category-icon">monitoring</span></div>
                                <div className="sidebar-category-text-wrapper"><span className="sidebar-category-button-text">ServerStats</span></div>
                            </div>
                            <div className='sidebar-category-status'>
                                { moduleStatus.length === 0 ?
                                        <div></div>
                                    :
                                    moduleStatus.module_serverstats === true ?
                                        <div>Enabled</div>
                                    :
                                    moduleStatus.module_serverstats === false ?
                                        <div>Disabled</div>
                                    :
                                    <div></div>
                                }
                            </div>
                        </a>
                    </div>
                    <div className='sidebar-category-group-container'>
                        <div className='sidebar-category-group'>
                            <span className='sidebar-category-title'>Privacy & Security</span>
                        </div>
                        <a href={"/"+props.guild_id+"/privacy/user-data-collection-overview"} className='sidebar-category'>
                            <div className='sidebar-category-wrapper'>
                                <div className="sidebar-category-icon-wrapper"><span className="material-symbols-outlined" id="sidebar-category-icon">lock</span></div>
                                <div className="sidebar-category-text-wrapper"><span className="sidebar-category-button-text">User data status</span></div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <div className='sidebar-links-container'>
                <div className="sidebar-links-devider-wrapper">
                    <hr className="sidebar-links-devider" />
                </div>
                <a href="https://husqy.xyz/help/documentation" rel="noreferrer" target="_blank" className='sidebar-link'>
                    <div className="sidebar-link-icon-wrapper"><span className="material-symbols-outlined" id="sidebar-link-icon">help</span></div>
                    <div className="sidebar-link-text-wrapper"><span className="sidebar-link-button-text">Documentation</span></div>
                </a>
                <a href="https://husqy.xyz/help/faq" target="_blank" rel="noreferrer" className='sidebar-link'>
                    <div className="sidebar-link-icon-wrapper"><span className="material-symbols-outlined" id="sidebar-link-icon">description</span></div>
                    <div className="sidebar-link-text-wrapper"><span className="sidebar-link-button-text">FAQ</span></div>
                </a>
                <a href="/logout" className='sidebar-link'>
                    <div className="sidebar-link-icon-wrapper"><span className="material-symbols-outlined" id="sidebar-link-icon">Logout</span></div>
                    <div className="sidebar-link-text-wrapper"><span className="sidebar-link-button-text">Logout</span></div>
                </a>
            </div>
        </div>
    )
}

export default Sidebar