import React from 'react';
import { Route, Routes,} from "react-router-dom";

import Error404 from "../pages/errors/404"
import Login from "../pages/oauth/login"
import Logout from "../pages/oauth/logout"
import GuildsOverview from "../pages/guilds_overview"
import DashboardOverview from "../pages/dashboard/overview"
import DashboardSettings from "../pages/dashboard/settings"
import DashboardStatistics from "../pages/dashboard/statistics"
import DashboardFunctionsTags from "../pages/dashboard/functions/tags"
import DashboardFunctionsReminders from "../pages/dashboard/functions/reminders"
import DashboardFunctionsPolls from "../pages/dashboard/functions/polls"
import DashboardFunctionsGiveaways from "../pages/dashboard/functions/giveaways"
import DashboardModulesLogging from "../pages/dashboard/modules/logging"
import DashboardModulesAutoresponder from "../pages/dashboard/modules/autoresponder"
import DashboardModulesReactionroles from "../pages/dashboard/modules/reactionroles"
import DashboardModulesTempchannels from "../pages/dashboard/modules/tempchannels"
import DashboardModulesSocials from "../pages/dashboard/modules/socials"
import DashboardModulesGreetings from "../pages/dashboard/modules/greetings"
import DashboardModulesServerstats from "../pages/dashboard/modules/serverstats"
import DashboardModulesTickets from "../pages/dashboard/modules/tickets"
import DashboardPrivacyUserDataCollectionOverview from '../pages/dashboard/security_privacy/userDataCollectionOverview';

const Webpages = () => {
    return(
        <Routes>
            {/* Auth */}
            <Route path = "/login" element={<Login />} />
            <Route path = "/logout" element={<Logout />} />
            {/* Dashboard General */}
            <Route path = "/" element={<GuildsOverview />} />
            <Route path = "/:guild_id/" element={<DashboardOverview />} />
            <Route path = "/:guild_id/overview" element={<DashboardOverview />} />
            <Route path = "/:guild_id/settings" element={<DashboardSettings />} />
            <Route path = "/:guild_id/statistics" element={<DashboardStatistics />} />
            {/* Functions */}
            <Route path = "/:guild_id/functions/tags" element={<DashboardFunctionsTags />} />
            <Route path = "/:guild_id/functions/reminders" element={<DashboardFunctionsReminders />} />
            <Route path = "/:guild_id/functions/polls" element={<DashboardFunctionsPolls />} />
            <Route path = "/:guild_id/functions/giveaways" element={<DashboardFunctionsGiveaways />} />
            {/* Modules */}
            <Route path = "/:guild_id/modules/logging" element={<DashboardModulesLogging />} />
            <Route path = "/:guild_id/modules/autoresponder" element={<DashboardModulesAutoresponder />} />
            <Route path = "/:guild_id/modules/reaction-roles" element={<DashboardModulesReactionroles />} />
            <Route path = "/:guild_id/modules/tempchannels" element={<DashboardModulesTempchannels />} />
            <Route path = "/:guild_id/modules/socials" element={<DashboardModulesSocials />} />
            <Route path = "/:guild_id/modules/greetings" element={<DashboardModulesGreetings />} />
            <Route path = "/:guild_id/modules/serverstats" element={<DashboardModulesServerstats />} />
            <Route path = "/:guild_id/modules/tickets" element={<DashboardModulesTickets />} />
            {/* Privacy & Security */}
            <Route path = "/:guild_id/privacy/user-data-collection-overview" element={<DashboardPrivacyUserDataCollectionOverview />} />
            {/* Errors */}
            <Route path = "/page-not-found" element={<Error404 />} />
            <Route path = "*" element={<Error404 />} />
        </Routes>
    );
};
export default Webpages;