import React from 'react';

import '../../styles/pages/dashboard/statisticsContent.css'

const DashboardStatisticsContent = () => {
    return(
        <div>
            Statistics can currently only be viewed in Discord but <br/> 
            we are working hard to make the dashboard complete and allow you to view your statistics here. <br/> <br/> 
            Please bear with us, this feature will be added soon!
        </div>
    );
};

export default DashboardStatisticsContent;