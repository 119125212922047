import React, { useState, useEffect } from 'react';
import { useParams,useNavigate } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import { toast } from 'react-toastify'
import axios from 'axios'
import Sidebar from '../../../components/sidebar';
import Loading from '../../../components/loading'
import { ToastContainer } from 'react-toastify';
import DashboardFunctionsPollsContent from './pollsContent';
import GuildDashboardHeader from '../../../components/headers/guild_dashboard_header'

import '../../../styles/pages/dashboard/functions/polls.css'
import config from '../../../config'

const DashboardFunctionsPolls = () => {
    const [cookies] = useCookies(['access_token'])
    if (cookies["access_token"] === undefined) {
        window.location.replace("/login")
    }
    const navigate = useNavigate();

    const [guildName, setGuildName] = useState('')
    
    const params = useParams()
    const [loadingStatus, setLoadingStatus] = useState('loading')
    
    // VALIDATE ACCESS
    const validate_guild_id = async () => {
        BigInt(params.guild_id)
    }

    const validate_access = async () => {
        const options = {
            url: `${config["api_base_url"]}/guilds/${params.guild_id}/validate/access`,
            method: 'GET',
            headers: {
                access_token: cookies["access_token"]
            }
          };

        axios(options)
        .then(response => {
            if (response.status === 200) {
                if (response["data"]["data"]["access"] === false) {
                    const new_state = { alert: { alert_type: "error", message: "You do not have access to that server!" }}
                    navigate("/", { state: new_state });
                }
                setGuildName(response["data"]["data"]["guild_name"])
                setLoadingStatus("success")
            }
        })
        .catch(error => {
            console.log(error)
            try {
                if (error.response.status === 429) {
                    toast.error("Rate limit reached! Please slow down!")
                    return
                }
                const new_state = { alert: { alert_type: "error", message: "Something went wrong while loading the server!" }}
                navigate("/", { state: new_state });
            } catch {
                const new_state = { alert: { alert_type: "error", message: "Something went wrong while loading the server!" }}
                navigate("/", { state: new_state });
            }
        });
    }

    useEffect(() => {
        validate_guild_id()
        validate_access()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return(
        <div className='dashboard-functions-polls-container'>
            <Sidebar guild_id={params.guild_id} />
            <div className='dashboard-functions-polls-content-wrapper'>
                <GuildDashboardHeader view_title="Polls" guild_name={guildName} guild_id={params.guild_id} />
                { loadingStatus === 'success' ? 
                    <DashboardFunctionsPollsContent />
                    :
                    <Loading loading_size='s' />
                }
            </div>
            <ToastContainer 
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                pauseOnHover={false}
                newestOnTop={true}
                closeOnClick
                rtl={false}
                draggable
                theme="dark"
                pauseOnFocusLoss={false}
            />
        </div>
    );
};

export default DashboardFunctionsPolls;