import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import { toast } from 'react-toastify'
import axios from 'axios'
import Loading from '../../../components/loading'
import GenericButton from "../../../components/buttons/generic_button"
import RefreshButton from "../../../components/buttons/refresh_button"
import ReactionRoleEntryBox from "../../../components/dashboard/modules/ReactionRoleEntryBox"

import '../../../styles/pages/dashboard/modules/reactionrolesContent.css'
import '../../../js/sticky_modules_reactionroles_bars.js'
import config from '../../../config'

const DashboardModulesReactionrolesContent = () => {
    const [cookies] = useCookies(['access_token'])

    const [moduleReactionrolesStatus, setModuleReactionrolesStatus] = useState(false)
    const [moduleReactionrolesSettings, setModuleReactionrolesSettings] = useState({})
    const [newModuleReactionrolesSettings, setNewModuleReactionrolesSettings] = useState({})
    const [moduleReactionrolesEntries, setModuleReactionrolesEntries] = useState([])
    const [currentPage, setCurrentPage] = useState(1)

    const [guildChannels, setGuildChannels] = useState([])
    const [guildRoles, setGuildRoles] = useState([])
    const [newReactionRolesEntryChannel, setNewReactionRolesEntryChannel] = useState("")
    const [newReactionRolesEntryRole, setNewReactionRolesEntryRole] = useState("")
    const [newReactionRolesEntryReaction, setNewReactionRolesEntryReaction] = useState("")
    const [newReactionRolesEntryMessage, setNewReactionRolesEntryMessage] = useState("")

    const [enableModuleReactionrolesDeleteUnrelated, setEnableModuleReactionrolesDeleteUnrelated] = useState(true)
    const [enableModuleReactionrolesRemoveRole, setEnableModuleReactionrolesRemoveRole] = useState(true)

    const params = useParams()
    const [moduleReactionrolesLoadingStatus, setModuleReactionrolesLoadingStatus] = useState('loading')
    const [channelsLoadingStatus, setChannelsLoadingStatus] = useState('loading')
    const [rolesLoadingStatus, setRolesLoadingStatus] = useState('loading')
    const [moduleReactionrolesSettingsLoadingStatus, setModuleReactionrolesSettingsLoadingStatus] = useState('loading')
    const [moduleReactionrolesEntriesLoadingStatus, setModuleReactionrolesEntriesLoadingStatus] = useState('success')

    // FIELD CHANGES
    const on_field_change = async (event) => {
        event.preventDefault();
        if (event.target.name === "module-reactionroles-module-delete-unrelated-input") {
            if (event.target.id === "on") {
                setEnableModuleReactionrolesDeleteUnrelated(true)
            } else if (event.target.id === "off") {
                setEnableModuleReactionrolesDeleteUnrelated(false)
            }
        } else if (event.target.name === "module-reactionroles-module-remove-role-from-users-input") {
            if (event.target.id === "on") {
                setEnableModuleReactionrolesRemoveRole(true)
            } else if (event.target.id === "off") {
                setEnableModuleReactionrolesRemoveRole(false)
            }
        } else if (event.target.name === "dashboard-modules-reactionroles-enabled-setting-delete-unrelated-input") {
            if (event.target.id === "on") {
                setNewModuleReactionrolesSettings(prevModuleReactionRolesSettings => ({...prevModuleReactionRolesSettings, "delete_unrelated": true}))
            } else if (event.target.id === "off") {
                setNewModuleReactionrolesSettings(prevModuleReactionRolesSettings => ({...prevModuleReactionRolesSettings, "delete_unrelated": false}))
            }
        } else if (event.target.name === "dashboard-modules-reactionroles-enabled-setting-remove-role-from-users-input") {
            if (event.target.id === "on") {
                setNewModuleReactionrolesSettings(prevModuleReactionRolesSettings => ({...prevModuleReactionRolesSettings, "remove_role_from_users": true}))
            } else if (event.target.id === "off") {
                setNewModuleReactionrolesSettings(prevModuleReactionRolesSettings => ({...prevModuleReactionRolesSettings, "remove_role_from_users": false}))
            }
        } else if (event.target.name === "new-reactionrole-entry-channel") {
            setNewReactionRolesEntryChannel(event.target.value)
        } else if (event.target.name === "new-reactionrole-entry-role") {
            setNewReactionRolesEntryRole(event.target.value)
        } else if (event.target.name === "new-reactionrole-entry-message") {
            setNewReactionRolesEntryMessage(event.target.value)
        } else if (event.target.name === "new-reactionrole-entry-reaction") {
            setNewReactionRolesEntryReaction(event.target.value)
        }
    }
    
    const open_create_reactionroles_entry_popup = async (event) => {
        event.preventDefault()
        var bar = document.getElementById('create-reactionroles-module-sidebar')
        bar.style.transform = "translateX(0px)"
    }
    const close_create_reactionroles_entry_popup = async (event) => {
        event.preventDefault()
        setNewReactionRolesEntryChannel("")
        setNewReactionRolesEntryRole("")
        setNewReactionRolesEntryReaction("")
        setNewReactionRolesEntryMessage("")
        var bar = document.getElementById('create-reactionroles-module-sidebar')
        bar.style.transform = "translateX(+502px)"
    }

    const create_reactionroles_entry = async (event) => {
        event.preventDefault()
        var new_reactionroles_entry = {}
        if (newReactionRolesEntryChannel === "") {
            toast.error("You need to select the channel where the message is located!")
            return
        }
        if (newReactionRolesEntryMessage === "") {
            toast.error("You need to enter the message which you want to use as the Reaction Role message!")
            return  
        }
        if (newReactionRolesEntryReaction === "") {
            toast.error("You need to enter the reaction you want to use!")
            return
        }
        if (newReactionRolesEntryRole === "") {
            toast.error("You need to select the role corresponding to the reaction!")
            return
        }

        new_reactionroles_entry.channel_id = newReactionRolesEntryChannel
        new_reactionroles_entry.message_id = newReactionRolesEntryMessage
        new_reactionroles_entry.reaction = newReactionRolesEntryReaction
        new_reactionroles_entry.role_id = newReactionRolesEntryRole
        new_reactionroles_entry.guild_id = params.guild_id
        new_reactionroles_entry.action_type = "create"

        const options = {
            url: `${config["api_base_url"]}/guilds/${params.guild_id}/modules/reaction_roles/entries`,
            method: 'POST',
            headers: {
                access_token: cookies["access_token"]
            },
            data: new_reactionroles_entry,
          };

          axios(options)
          .then(response => {
              if (response.status === 202) {
                window.location.reload()
              }
          })
          .catch(error => {
              console.log(error)
              if (error.response.status === 429) {
                toast.error("Rate limit reached! Please slow down!")
                return
            }
              toast.error("Something went wrong while creating the Reaction Role entry!")
          });
    }

    // Refresh data
    const refresh_data_table = async (event) => {
        event.preventDefault();
        setCurrentPage(1)
        setModuleReactionrolesEntries([])
        window.location.reload()
    }

    // Module Status
    const open_enable_reactionroles_module_sidebar = async (event) => {
        event.preventDefault()
        var bar = document.getElementById('enable-reactionroles-module-sidebar')
        bar.style.transform = "translateX(0px)"
    }
    const close_enable_reactionroles_module_sidebar = async (event) => {
        event.preventDefault()
        var bar = document.getElementById('enable-reactionroles-module-sidebar')
        bar.style.transform = "translateX(+502px)"
    }

    const get_module_status = async () => {
        setModuleReactionrolesLoadingStatus("loading")
        const options = {
            url: `${config["api_base_url"]}/guilds/${params.guild_id}/modules?module_names=reaction_roles`,
            method: 'GET',
            headers: {
                access_token: cookies["access_token"]
            }
          };
        axios(options)
        .then(response => {
            if (response.status === 200) {
                    setModuleReactionrolesStatus(response["data"]["data"]["module_reaction_roles"])
                    if (response["data"]["data"]["module_reaction_roles"] === true) {
                        get_module_settings()
                        get_module_entries()
                    }
                }
                setModuleReactionrolesLoadingStatus("success")
        })
        .catch(error => {
            console.log(error)
            setModuleReactionrolesLoadingStatus("error")
            if (error.response.status === 429) {
                toast.error("Rate limit reached! Please slow down!")
                return
            }
            toast.error("Something went wrong while fetching the module Reaction Roles status!")
        });
    }

    const set_module_status = async (event) => {
        var new_status
        if (event.target.id === "enable") {
            new_status = true
        } else if (event.target.id === "disable") {
            new_status = false
        }

        var data = {}
        data.reaction_roles = {}
        data.reaction_roles.new_status = new_status
        data.reaction_roles.guild_id = params.guild_id
        if (new_status === true) {
            data.reaction_roles.delete_unrelated = enableModuleReactionrolesDeleteUnrelated
            data.reaction_roles.remove_role_from_users = enableModuleReactionrolesRemoveRole
        }

        const options = {
            url: `${config["api_base_url"]}/guilds/${params.guild_id}/modules`,
            method: 'POST',
            headers: {
                access_token: cookies["access_token"]
            },
            data: data
          };

        axios(options)
        .then(response => {
            console.log(response)
            if (response.status === 202) {
                if (response["data"]["data"]["reaction_roles"]["code"] === 200) {
                    window.location.reload()
                }
            } else {
                toast.error("Something went wrong while updating the Reaction Roles module!")
            }
        })
        .catch(error => {
            console.log(error)
            if (error.response.status === 429) {
                toast.error("Rate limit reached! Please slow down!")
                return
            }
            toast.error("Something went wrong while updating the Reactionroles module!")
        });
    }

    // Get data
    const get_channels = async () => {
        setChannelsLoadingStatus("loading")
        const options = {
            url: `${config["api_base_url"]}/guilds/${params.guild_id}/channels?types=text`,
            method: 'GET',
            headers: {
                access_token: cookies["access_token"]
            },
          };

          axios(options)
          .then(response => {
              if (response.status === 200) {
                  setGuildChannels(response["data"][0]["data"])
                  setChannelsLoadingStatus("success")
              }
          })
          .catch(error => {
              console.log(error)
              setChannelsLoadingStatus("error")
              if (error.response.status === 429) {
                toast.error("Rate limit reached! Please slow down!")
                return
            }
              toast.error("Something went wrong while getting the servers channels!")
          });
    }

    const get_roles = async () => {
        setRolesLoadingStatus("loading")
        const options = {
            url: `${config["api_base_url"]}/guilds/${params.guild_id}/roles`,
            method: 'GET',
            headers: {
                access_token: cookies["access_token"]
            },
          };

        axios(options)
          .then(response => {
              if (response.status === 200) {
                  setGuildRoles(response["data"]["data"])
                  setRolesLoadingStatus("success")
              }
          })
          .catch(error => {
              console.log(error)
              setRolesLoadingStatus("error")
              if (error.response.status === 429) {
                toast.error("Rate limit reached! Please slow down!")
                return
            }
              toast.error("Something went wrong while getting the servers roles!")
          });
    }

    const get_module_settings = async () => {
        setModuleReactionrolesSettingsLoadingStatus("loading")
        const options = {
            url: `${config["api_base_url"]}/guilds/${params.guild_id}/modules/reaction_roles`,
            method: 'GET',
            headers: {
                access_token: cookies["access_token"]
            },
          };

          axios(options)
          .then(response => {
              if (response.status === 200) {
                setModuleReactionrolesSettings(response["data"]["data"])
                setNewModuleReactionrolesSettings(response["data"]["data"])
                setModuleReactionrolesSettingsLoadingStatus("success")
              }
          })
          .catch(error => {
              console.log(error)
              setModuleReactionrolesSettingsLoadingStatus("error")
              if (error.response.status === 429) {
                toast.error("Rate limit reached! Please slow down!")
                return
            }
              toast.error("Something went wrong while getting the settings for the Reaction Roles module!")
          });
    }

    const set_module_settings = async (event) => {
        event.preventDefault()
        newModuleReactionrolesSettings.guild_id = params.guild_id
        const options = {
            url: `${config["api_base_url"]}/guilds/${params.guild_id}/modules/reaction_roles`,
            method: 'PUT',
            headers: {
                access_token: cookies["access_token"]
            },
            data: newModuleReactionrolesSettings
          };

          axios(options)
          .then(response => {
              console.log(response.status)
              if (response.status === 202) {
                window.location.reload()
              }
          })
          .catch(error => {
              console.log(error)
              if (error.response.status === 429) {
                toast.error("Rate limit reached! Please slow down!")
                return
            }
              toast.error("Something went wrong while changing the settings for the Reaction Roles module!")
          });
    }

    const reset_module_settings = async (event) => {
        event.preventDefault()
        setNewModuleReactionrolesSettings(moduleReactionrolesSettings)
    }

    const get_extra_data = async (event) => {
        event.preventDefault();
        get_module_entries()
    }

    const get_module_entries = async () => {
        setModuleReactionrolesEntriesLoadingStatus("loading")
        const options = {
            url: `${config["api_base_url"]}/guilds/${params.guild_id}/modules/reaction_roles/entries?page=${currentPage}`,
            method: 'GET',
            headers: {
                access_token: cookies["access_token"]
            }
          };

        axios(options)
        .then(response => {
            if (response.status === 200) {
                if (response["data"]["data"].length === 0) {
                    setCurrentPage(currentPage)
                } else {
                    setCurrentPage(currentPage+1)
                    if (moduleReactionrolesEntries.length === 0 ) {
                        setModuleReactionrolesEntries(response["data"]["data"])
                    } else {
                        var newModuleReactionrolesEntries = moduleReactionrolesEntries.concat(response["data"]["data"])
                        setModuleReactionrolesEntries(newModuleReactionrolesEntries)
                    }
                }
                setModuleReactionrolesEntriesLoadingStatus("success")
            }
        })
        .catch(error => {
            console.log(error)
            setModuleReactionrolesEntriesLoadingStatus("error")
            if (error.response.status === 429) {
                toast.error("Rate limit reached! Please slow down!")
                return
            }
            toast.error("Something went wrong while fetching the servers Reaction Role entries!")
        });
    }

    useEffect(() => {
        get_module_status()
        get_channels()
        get_roles()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return(
        <div className='dashboard-modules-reactionroles-content-inner-wrapper'>
            { moduleReactionrolesLoadingStatus === 'success' && channelsLoadingStatus === 'success' && rolesLoadingStatus === 'success' ?
                moduleReactionrolesStatus === true ?
                    moduleReactionrolesSettingsLoadingStatus === 'success' && moduleReactionrolesEntriesLoadingStatus === 'success' ?  
                        <div className='dashboard-modules-reactionroles-enabled-container'> 
                            <div className='dashboard-modules-reactionroles-enabled-disable-wrapper'>
                                Click <label id="disable" onClick={set_module_status} className='dashboard-modules-reactionroles-enabled-disable-button'>here</label> if you want to disable this module!
                            </div>
                            <div className='dashboard-modules-reactionroles-enabled-settings-container'>
                                <div className='dashboard-modules-reactionroles-enabled-settings-reminder'>Don't forget to save your changes!</div>
                                <div className='dashboard-modules-reactionroles-enabled-settings-wrapper'>
                                    <div className='dashboard-modules-reactionroles-enabled-setting'>
                                        <div className='dashboard-modules-reactionroles-enabled-setting-label'>
                                            Delete unrelated
                                        </div>
                                        <div className='dashboard-modules-reactionroles-enabled-setting-description'>If added reactions, which do not correspond with a role, should be removed from the message.</div>
                                        <hr  className='dashboard-modules-reactionroles-enabled-setting-devider' />
                                        { newModuleReactionrolesSettings.delete_unrelated ?
                                            <div key="off">
                                                <div className='dashboard-modules-reactionroles-enabled-setting-wrapper'>
                                                    <label className="toggler-wrapper style-1">
                                                        <input id="off" type="checkbox" name="dashboard-modules-reactionroles-enabled-setting-delete-unrelated-input" defaultChecked onChange={on_field_change}></input>
                                                        <div className="toggler-slider">
                                                            <div className="toggler-knob"></div>
                                                        </div>
                                                    </label>
                                                </div>
                                            </div> 
                                            :
                                            <div key="on">
                                                <div className='dashboard-modules-reactionroles-enabled-setting-wrapper'>
                                                    <label className="toggler-wrapper style-1">
                                                        <input id="on" type="checkbox" name="dashboard-modules-reactionroles-enabled-setting-delete-unrelated-input" onChange={on_field_change}></input>
                                                        <div className="toggler-slider">
                                                            <div className="toggler-knob"></div>
                                                        </div>
                                                    </label>
                                                </div> 
                                            </div>
                                        }
                                    </div>
                                    <div className='dashboard-modules-reactionroles-enabled-setting'>
                                        <div className='dashboard-modules-reactionroles-enabled-setting-label'>
                                            Remove role on reaction role delete
                                        </div>
                                        <div className='dashboard-modules-reactionroles-enabled-setting-description'>If the role of a reaction role entry should be removed from all users when this entry is deleted.</div>
                                        <hr  className='dashboard-modules-reactionroles-enabled-setting-devider' />
                                        { newModuleReactionrolesSettings.remove_role_from_users ?
                                            <div key="off">
                                                <div className='dashboard-modules-reactionroles-enabled-setting-wrapper'>
                                                    <label className="toggler-wrapper style-1">
                                                        <input id="off" type="checkbox" name="dashboard-modules-reactionroles-enabled-setting-remove-role-from-users-input" defaultChecked onChange={on_field_change}></input>
                                                        <div className="toggler-slider">
                                                            <div className="toggler-knob"></div>
                                                        </div>
                                                    </label>
                                                </div>
                                            </div> 
                                            :
                                            <div key="on">
                                                <div className='dashboard-modules-reactionroles-enabled-setting-wrapper'>
                                                    <label className="toggler-wrapper style-1">
                                                        <input id="on" type="checkbox" name="dashboard-modules-reactionroles-enabled-setting-remove-role-from-users-input" onChange={on_field_change}></input>
                                                        <div className="toggler-slider">
                                                            <div className="toggler-knob"></div>
                                                        </div>
                                                    </label>
                                                </div> 
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className='dashboard-modules-reactionroles-settings-interactions-wrapper'>
                                    <div className='dashboard-modules-reactionroles-settings-cancel-changes'>
                                        <GenericButton button_text="Cancel" type="danger" on_click_action={reset_module_settings} />
                                    </div>
                                    <div className='dashboard-modules-reactionroles-settings-save-changes'>
                                        <GenericButton button_text="Save" on_click_action={set_module_settings} />
                                    </div>
                                </div>
                                <hr className='dashboard-modules-reactionroles-enabled-settings-devider-bottom' />
                            </div>
                            <div className='dashboard-modules-reactionroles-entries-container'>
                                <div className='dashboard-modules-reactionroles-general-interactions-wrapper'>
                                    <div className='dashboard-modules-reactionroles-data'>
                                        <RefreshButton on_click_action={refresh_data_table} />
                                    </div>
                                    <div className='dashboard-modules-reactionroles-entry'>
                                        <GenericButton button_text="Create entry" on_click_action={open_create_reactionroles_entry_popup} />
                                    </div>
                                </div>
                                <div className='dashboard-modules-reactionroles-entry-wrapper'>
                                    <div className='dashboard-modules-reactionroles-entry-container'>
                                        { moduleReactionrolesEntries.length === 0 ?
                                            <div className='dashboard-modules-reactionroles-no-entries-wrapper'>
                                                This server does not have any active Reaction Role entries yet!
                                            </div>
                                            :
                                            <div className='dashboard-modules-reactionroles-entries-wrapper'>
                                                <div className='dashboard-modules-reactionroles-inner-wrapper'>
                                                    { moduleReactionrolesEntries.map(entry =>
                                                        <ReactionRoleEntryBox entry={entry} />
                                                    )}
                                                </div>
                                                <div className='dashboard-modules-reactionroles-load-extra'>
                                                    <GenericButton type="secondary" button_text="Load extra entries" on_click_action={get_extra_data} />
                                                </div>
                                            </div>
                                        }
                                    </div>
 
                                </div>
                            </div>
                            <div className='create-reactionroles-module-sidebar' id="create-reactionroles-module-sidebar">
                                <div className='create-reactionroles-module-wrapper'>
                                    <div className='create-reactionroles-module-cancel'>
                                        <div className="create-reactionroles-module-back-button" onClick={close_create_reactionroles_entry_popup}>
                                            <div className="create-reactionroles-module-button-icon"><span className="material-symbols-outlined" id="reactionroles-close-icon">close</span></div>
                                            <div className="create-reactionroles-module-button-text-wrapper"><span className="create-reactionroles-module-button-text">Cancel & Reset</span></div>
                                        </div>
                                        <h2 className='create-reactionroles-module-title'>
                                            Create a Reaction Roles entry
                                        </h2>
                                    </div>
                                    <div className='create-reactionroles-module-channel'>
                                        <h3 className='create-reactionroles-module-channel-label'>
                                            Reaction Role channel*
                                        </h3>
                                        <select className='create-reactionroles-module-channel-input' id='create-reactionroles-module-channel-input' name='new-reactionrole-entry-channel' onChange={on_field_change} value={newReactionRolesEntryChannel}>
                                            { guildChannels.map(channel => 
                                                <option key={ String(channel.id) } value={ String(channel.id) }>
                                                    { channel.name }
                                                </option>
                                            )}
                                            <option key="no-channel" value="">
                                                No channel selected yet!
                                            </option>
                                        </select>
                                    </div>
                                    <div className='create-reactionroles-module-message'>
                                        <h3 className='create-reactionroles-module-message-label'>
                                            Message ID*
                                        </h3>
                                        <input type='number' className='create-reactionroles-module-message-input' id='create-reactionroles-module-message-input' name='new-reactionrole-entry-message' placeholder='1234567890' onChange={on_field_change} value={newReactionRolesEntryMessage} />
                                    </div>
                                    <div className='create-reactionroles-module-reaction'>
                                        <h3 className='create-reactionroles-module-reaction-label'>
                                            Reaction*
                                        </h3>
                                        <input className='create-reactionroles-module-reaction-input' id='create-reactionroles-module-reaction-input' name='new-reactionrole-entry-reaction' placeholder='😃' onChange={on_field_change} value={newReactionRolesEntryReaction} />
                                    </div>
                                    <div className='create-reactionroles-module-role'>
                                        <h3 className='create-reactionroles-module-role-label'>
                                            Role*
                                        </h3>
                                        <select className='create-reactionroles-module-role-input' id='create-reactionroles-module-role-input' name='new-reactionrole-entry-role' onChange={on_field_change} value={newReactionRolesEntryRole}>
                                            { guildRoles.map(role => 
                                                <option key={ String(role.id) } value={ String(role.id) }>
                                                    { role.name }
                                                </option>
                                            )}
                                            <option key="no-role" value="">
                                                No role selected yet!
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div className='create-reactionroles-module-interactions-wrapper'>
                                    <div className='submit'>
                                        <GenericButton button_text="Create entry" on_click_action={create_reactionroles_entry} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <Loading loading_size='s' />
                    :
                    <div className='dashboard-modules-reactionroles-disabled-container'>
                        The Reaction Roles module is disabled, click <label onClick={open_enable_reactionroles_module_sidebar} className='dashboard-modules-reactionroles-disabled-enable-button'>here</label> if you want to enabled this module!
                        <div className='enable-reactionroles-module-sidebar' id="enable-reactionroles-module-sidebar">
                            <div className='enable-reactionroles-module-wrapper'>
                                <div className='enable-reactionroles-module-cancel'>
                                    <div className="enable-reactionroles-module-back-button" onClick={close_enable_reactionroles_module_sidebar}>
                                        <div className="enable-reactionroles-module-button-icon"><span className="material-symbols-outlined" id="reactionroles-close-icon">close</span></div>
                                        <div className="enable-reactionroles-module-button-text-wrapper"><span className="enable-reactionroles-module-button-text">Cancel & Reset</span></div>
                                    </div>
                                    <h2 className='enable-reactionroles-module-title'>
                                        Enable the Reaction Roles Module
                                    </h2>
                                </div>
                                <div className='enable-reactionroles-module-delete-unrelated'>
                                    <h3 className='enable-reactionroles-module-delete-unrelated-label'>
                                        Delete unrelated reactions*
                                    </h3>
                                    { enableModuleReactionrolesDeleteUnrelated ?
                                        <div key="off">
                                            <div className='module-reactionroles-module-delete-unrelated-wrapper'>
                                                <label className="toggler-wrapper style-1">
                                                    <input id="off" type="checkbox" name="module-reactionroles-module-delete-unrelated-input" defaultChecked onChange={on_field_change}></input>
                                                    <div className="toggler-slider">
                                                        <div className="toggler-knob"></div>
                                                    </div>
                                                </label>
                                            </div>
                                        </div> 
                                        :
                                        <div key="on">
                                            <div className='module-reactionroles-module-delete-unrelated-wrapper'>
                                                <label className="toggler-wrapper style-1">
                                                    <input id="on" type="checkbox" name="module-reactionroles-module-delete-unrelated-input" onChange={on_field_change}></input>
                                                    <div className="toggler-slider">
                                                        <div className="toggler-knob"></div>
                                                    </div>
                                                </label>
                                            </div> 
                                        </div>
                                    }
                                </div>
                                <div className='enable-reactionroles-module-remove-role-from-users'>
                                    <h3 className='enable-reactionroles-module-remove-role-from-users-label'>
                                        Remove role from users when deleted*
                                    </h3>
                                    { enableModuleReactionrolesRemoveRole ?
                                        <div key="off">
                                            <div className='module-reactionroles-module-remove-role-from-users-wrapper'>
                                                <label className="toggler-wrapper style-1">
                                                    <input id="off" type="checkbox" name="module-reactionroles-module-remove-role-from-users-input" defaultChecked onChange={on_field_change}></input>
                                                    <div className="toggler-slider">
                                                        <div className="toggler-knob"></div>
                                                    </div>
                                                </label>
                                            </div>
                                        </div> 
                                        :
                                        <div key="on">
                                            <div className='module-reactionroles-module-remove-role-from-users-wrapper'>
                                                <label className="toggler-wrapper style-1">
                                                    <input id="on" type="checkbox" name="module-reactionroles-module-remove-role-from-users-input" onChange={on_field_change}></input>
                                                    <div className="toggler-slider">
                                                        <div className="toggler-knob"></div>
                                                    </div>
                                                </label>
                                            </div> 
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className='enable-reactionroles-module-interactions-wrapper'>
                                <div className='submit'>
                                    <GenericButton button_text="Enable" id="enable" on_click_action={set_module_status} />
                                </div>
                            </div>
                        </div>
                    </div>
                :
                <Loading loading_size='s' />
            }
        </div>
    );
};

export default DashboardModulesReactionrolesContent;