import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import { toast } from 'react-toastify'
import axios from 'axios'
import Loading from '../../../components/loading'
import GenericButton from "../../../components/buttons/generic_button"
import LoggingEventForm from "../../../components/dashboard/modules/LoggingEventForm"

import '../../../styles/pages/dashboard/modules/loggingContent.css'
import '../../../js/sticky_modules_logging_bars.js'
import config from '../../../config'

const DashboardModulesLoggingContent = () => {
    const [cookies] = useCookies(['access_token'])

    const [moduleLoggingStatus, setModuleLoggingStatus] = useState(false)
    const [moduleLoggingLogsChannel, setModuleLogsChannel] = useState("")
    const [moduleLoggingGeneralEvents, setModuleGeneralEvents] = useState({})
    const [moduleLoggingChannelEvents, setModuleChannelEvents] = useState({})
    const [moduleLoggingRoleEvents, setModuleRoleEvents] = useState({})
    const [moduleLoggingUserEvents, setModuleUserEvents] = useState({})
    const [moduleLoggingMiscEvents, setModuleMiscEvents] = useState({})
    const [moduleLoggingRemindersEvents, setModuleRemindersEvents] = useState({})
    const [moduleLoggingAudioEvents, setModuleAudioEvents] = useState({})
    const [moduleLoggingUtilsEvents, setModuleUtilsEvents] = useState({})
    const [moduleLoggingSocialsEvents, setModuleSocialsEvents] = useState({})
    const [moduleLoggingReactionRolesEvents, setModuleReactionRolesEvents] = useState({})
    const [moduleLoggingTagEvents, setModuleTagEvents] = useState({})
    const [moduleLoggingPollEvents, setModulePollEvents] = useState({})
    const [moduleLoggingAutoresponderEvents, setModuleAutoresponderEvents] = useState({})
    const [moduleLoggingGiveawayEvents, setModuleGiveawayEvents] = useState({})

    const [guildChannels, setGuildChannels] = useState([])
    const [activePage, setActivePage] = useState("")

    const [newLogsChannel, setNewLogsChannel] = useState("")

    const params = useParams()
    const [moduleLoggingLoadingStatus, setModuleLoggingLoadingStatus] = useState('loading')
    const [moduleLoggingSettingsLoadingStatus, setModuleLoggingSettingsLoadingStatus] = useState('loading')

    const [channelsLoadingStatus, setChannelsLoadingStatus] = useState('loading')

    // FIELD CHANGES
    const on_field_change = async (event) => {
        event.preventDefault();
        if (event.target.name === "new-logs-channel-id") {
            setNewLogsChannel(event.target.value)
        }
    }

    const active_page_changer = async (event) => {
        event.preventDefault()
        setActivePage(event.target.id)
    }

    // Module Status
    const open_enable_logging_module_sidebar = async (event) => {
        event.preventDefault()
        var bar = document.getElementById('enable-logging-module-sidebar')
        bar.style.transform = "translateX(0px)"
    }
    const close_enable_logging_module_sidebar = async (event) => {
        event.preventDefault()
        setNewLogsChannel("")
        var bar = document.getElementById('enable-logging-module-sidebar')
        bar.style.transform = "translateX(+502px)"
    }

    const get_module_status = async () => {
        setModuleLoggingLoadingStatus("loading")
        const options = {
            url: `${config["api_base_url"]}/guilds/${params.guild_id}/modules?module_names=logging`,
            method: 'GET',
            headers: {
                access_token: cookies["access_token"]
            }
          };

        axios(options)
        .then(response => {
            if (response.status === 200) {
                    setModuleLoggingStatus(response["data"]["data"]["module_logging"])
                    if (response["data"]["data"]["module_logging"] === true) {
                        get_module_settings()
                    }
                }
                setModuleLoggingLoadingStatus("success")
        })
        .catch(error => {
            console.log(error)
            if (error.response.status === 429) {
                toast.error("Rate limit reached! Please slow down!")
                return
            }
            setModuleLoggingLoadingStatus("error")
            toast.error("Something went wrong while fetching the module Logging status!")
        });
    }

    const set_module_status = async (event) => {
        var new_status
        if (event.target.id === "enable") {
            new_status = true
        } else if (event.target.id === "disable") {
            new_status = false
        }
        
        if (new_status === true ) {
            if (newLogsChannel === "") {
                toast.error("You need to select a logs channel!")
                return
            }
        }

        var data = {}
        data.logging = {}
        data.logging.new_status = new_status
        data.logging.guild_id = params.guild_id
        if (new_status === true ) {
            data.logging.new_logs_channel = newLogsChannel
        }
        
        const options = {
            url: `${config["api_base_url"]}/guilds/${params.guild_id}/modules`,
            method: 'POST',
            headers: {
                access_token: cookies["access_token"]
            },
            data: data
          };

        axios(options)
        .then(response => {
            if (response.status === 202) {
                if (response["data"]["data"]["logging"]["code"] === 200) {
                    window.location.reload()
                }
            } else {
                toast.error("Something went wrong while updating the Logging module!")
            }
        })
        .catch(error => {
            console.log(error)
            if (error.response.status === 429) {
                toast.error("Rate limit reached! Please slow down!")
                return
            }
            toast.error("Something went wrong while updating the Logging module!")
        });
    }

    const set_module_logs_channel = async (event) => {
        event.preventDefault()
        var new_logs_channel = {
            "logs_channel": String(newLogsChannel)
        }
        new_logs_channel.guild_id = params.guild_id

        const options = {
            url: `${config["api_base_url"]}/guilds/${params.guild_id}/modules/logging`,
            method: 'PUT',
            headers: {
                access_token: cookies["access_token"]
            },
            data: new_logs_channel
          };

        axios(options)
        .then(response => {
            if (response.status === 202) {
                window.location.reload()
            } else {
                toast.error("Something went wrong while updating the Logs channel!")
            }
        })
        .catch(error => {
            console.log(error)
            if (error.response.status === 429) {
                toast.error("Rate limit reached! Please slow down!")
                return
            }
            toast.error("Something went wrong while updating the Logs channel!")
        });
    }

    const reset_module_logs_channel = async (event) => {
        event.preventDefault()
        setNewLogsChannel(moduleLoggingLogsChannel)
    }

    // Get data
    const get_channels = async () => {
        setChannelsLoadingStatus("loading")
        const options = {
            url: `${config["api_base_url"]}/guilds/${params.guild_id}/channels?types=text`,
            method: 'GET',
            headers: {
                access_token: cookies["access_token"]
            },
          };

          axios(options)
          .then(response => {
              if (response.status === 200) {
                  setGuildChannels(response["data"][0]["data"])
                  setChannelsLoadingStatus("success")
              }
          })
          .catch(error => {
              console.log(error)
              setChannelsLoadingStatus("error")
              if (error.response.status === 429) {
                toast.error("Rate limit reached! Please slow down!")
                return
            }
              toast.error("Something went wrong while getting the s channels!")
          });
    }

    const get_module_settings = async () => {
        setModuleLoggingSettingsLoadingStatus("loading")
        const options = {
            url: `${config["api_base_url"]}/guilds/${params.guild_id}/modules/logging`,
            method: 'GET',
            headers: {
                access_token: cookies["access_token"]
            },
          };

          axios(options)
          .then(response => {
              if (response.status === 200) {
                setModuleLogsChannel(response["data"]["data"]["logs_channel"]["id"])
                setNewLogsChannel(response["data"]["data"]["logs_channel"]["id"])
                setModuleGeneralEvents(response["data"]["data"]["general_events"])
                setModuleChannelEvents(response["data"]["data"]["channel_events"])
                setModuleRoleEvents(response["data"]["data"]["role_events"])
                setModuleUserEvents(response["data"]["data"]["user_events"])
                setModuleMiscEvents(response["data"]["data"]["misc_events"])
                setModuleRemindersEvents(response["data"]["data"]["reminder_events"])
                setModuleAudioEvents(response["data"]["data"]["audio_events"])
                setModuleUtilsEvents(response["data"]["data"]["utils_events"])
                setModuleSocialsEvents(response["data"]["data"]["socials_events"])
                setModuleReactionRolesEvents(response["data"]["data"]["reaction_roles_events"])
                setModuleTagEvents(response["data"]["data"]["tag_events"])
                setModulePollEvents(response["data"]["data"]["poll_events"])
                setModuleAutoresponderEvents(response["data"]["data"]["autoresponder_events"])
                setModuleGiveawayEvents(response["data"]["data"]["giveaway_events"])
                setModuleLoggingSettingsLoadingStatus("success")
              }
          })
          .catch(error => {
              console.log(error)
              setModuleLoggingSettingsLoadingStatus("error")
              if (error.response.status === 429) {
                toast.error("Rate limit reached! Please slow down!")
                return
            }
              toast.error("Something went wrong while getting the settings for the Logging module!")
          });
    }

    useEffect(() => {
        get_module_status()
        get_channels()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return(
        <div className='dashboard-modules-logging-content-inner-wrapper'>
            { moduleLoggingLoadingStatus === 'success' && channelsLoadingStatus === 'success' ?
                moduleLoggingStatus === true ?
                    moduleLoggingSettingsLoadingStatus === 'success' ?
                        <div className='dashboard-modules-logging-enabled-container'>
                            <div className='dashboard-modules-logging-enabled-disable-wrapper'>
                                Click <label id="disable" onClick={set_module_status} className='dashboard-modules-logging-enabled-disable-button'>here</label> if you want to disable this module!
                            </div>
                            <div className='dashboard-modules-logging-active-page-changer-container'>
                                <hr className='dashboard-modules-logging-active-page-changer-devider' />
                                <div className='dashboard-modules-logging-active-page-changer-pages-container'>
                                    <div className='dashboard-modules-logging-active-page-changer-page' id="logs_channel" onClick={active_page_changer}>Logs channel</div>
                                    <div className='dashboard-modules-logging-active-page-changer-page' id="general_events" onClick={active_page_changer}>General Events</div>
                                    <div className='dashboard-modules-logging-active-page-changer-page' id="channel_events" onClick={active_page_changer}>Channel Events</div>
                                    <div className='dashboard-modules-logging-active-page-changer-page' id="role_events" onClick={active_page_changer}>Role Events</div>
                                    <div className='dashboard-modules-logging-active-page-changer-page' id="user_events" onClick={active_page_changer}>User Events</div>
                                    <div className='dashboard-modules-logging-active-page-changer-page' id="misc_events" onClick={active_page_changer}>Misc Events</div>
                                    <div className='dashboard-modules-logging-active-page-changer-page' id="reminder_events" onClick={active_page_changer}>Reminder Events</div>
                                    <div className='dashboard-modules-logging-active-page-changer-page' id="audio_events" onClick={active_page_changer}>Audio Events</div>
                                    <div className='dashboard-modules-logging-active-page-changer-page' id="utils_events" onClick={active_page_changer}>Utils Events</div>
                                    <div className='dashboard-modules-logging-active-page-changer-page' id="socials_events" onClick={active_page_changer}>Socials Events</div>
                                    <div className='dashboard-modules-logging-active-page-changer-page' id="reaction_roles_events" onClick={active_page_changer}>Reaction Roles Events</div>
                                    <div className='dashboard-modules-logging-active-page-changer-page' id="tag_events" onClick={active_page_changer}>Tag Events</div>
                                    <div className='dashboard-modules-logging-active-page-changer-page' id="poll_events" onClick={active_page_changer}>Poll Events</div>
                                    <div className='dashboard-modules-logging-active-page-changer-page' id="autoresponder_events" onClick={active_page_changer}>Autoresponder Events</div>
                                    <div className='dashboard-modules-logging-active-page-changer-page' id="giveaway_events" onClick={active_page_changer}>Giveaway Events</div>
                                </div>
                                <hr className='dashboard-modules-logging-active-page-changer-devider' />
                            </div>
                            <div>
                                { activePage === 'logs_channel' ?
                                        <div className='dashboard-modules-logging-logs-channel-changer-container'>
                                        <div className='dashboard-modules-logging-logs-channel-changer-wrapper'>
                                            <div className='dashboard-modules-logging-logs-channel-info'>
                                                <div className='dashboard-modules-logging-logs-channel-group-title'>
                                                    Logs channel
                                                </div>
                                                <div className='dashboard-modules-logging-logs-channel-group-description'>
                                                    The channel to which log messages are sent.&nbsp;
                                                    <a className='logging-entry-field-channel-link' href={"https://discord.com/channels/"+params.guild_id+"/"+moduleLoggingLogsChannel} target="_blank" rel="noreferrer">
                                                        View logs in Discord!
                                                    </a>
                                                </div>
                                            </div>
                                            <select className='dashboard-modules-logging-logs-channel-changer-input' id='dashboard-modules-logging-logs-channel-changer-input' name='new-logs-channel-id' onChange={on_field_change} value={newLogsChannel}>
                                                { guildChannels.map(guild_channel => 
                                                    <option key={ String(guild_channel.id) } value={ String(guild_channel.id) }>
                                                        { guild_channel.name }
                                                    </option>
                                                )}
                                            </select>
                                        </div>
                                        <div className='module-logging-logs-channel-changer-interactions-container'>
                                            <GenericButton button_text="Reset" type="danger" action_type="reset" on_click_action={reset_module_logs_channel} />
                                            <GenericButton button_text="Save" on_click_action={set_module_logs_channel} />
                                        </div>
                                    </div>
                                    :
                                    activePage === 'general_events' ?
                                        <LoggingEventForm key="general_events" eventData={ moduleLoggingGeneralEvents } title="General Events" description="General Husqy events" />
                                    :
                                    activePage === 'channel_events' ?
                                        <LoggingEventForm key="channel_events" eventData={ moduleLoggingChannelEvents } title="Channel Events" description="Events related to channels" />
                                    :
                                    activePage === 'role_events' ?
                                        <LoggingEventForm key="role_events" eventData={ moduleLoggingRoleEvents } title="Role Events" description="Events related to roles" />
                                    :
                                    activePage === 'user_events' ?
                                        <LoggingEventForm key="user_events" eventData={ moduleLoggingUserEvents } title="User Events" description="Events related to users" />
                                    :
                                    activePage === 'misc_events' ?
                                        <LoggingEventForm key="misc_events" eventData={ moduleLoggingMiscEvents } title="Misc Events" description="Miscellaneous events" />
                                    :
                                    activePage === 'reminder_events' ?
                                        <LoggingEventForm key="reminder_events" eventData={ moduleLoggingRemindersEvents } title="Reminder Events" description="Events related to Husqy reminders" />
                                    :
                                    activePage === 'audio_events' ?
                                        <LoggingEventForm key="audio_events" eventData={ moduleLoggingAudioEvents } title="Audio Events" description="Events related to audio" />
                                    :
                                    activePage === 'utils_events' ?
                                        <LoggingEventForm key="utils_events" eventData={ moduleLoggingUtilsEvents } title="Utils Events" description="Events related to Husqy utilities" />
                                    :
                                    activePage === 'socials_events' ?
                                        <LoggingEventForm key="socials_events" eventData={ moduleLoggingSocialsEvents } title="Socials Events" description="Events related to the socials module" />
                                    :
                                    activePage === 'reaction_roles_events' ?
                                        <LoggingEventForm key="reaction_roles_events" eventData={ moduleLoggingReactionRolesEvents } title="Reaction Roles Events" description="Events related to the Reaction Role module" />
                                    :
                                    activePage === 'tag_events' ?
                                        <LoggingEventForm key="tag_events" eventData={ moduleLoggingTagEvents } title="Tag Events" description="Events related to Husqy tags" />
                                    :
                                    activePage === 'poll_events' ?
                                        <LoggingEventForm key="poll_events" eventData={ moduleLoggingPollEvents } title="Poll Events" description="Events related to Husqy Polls" />
                                    :
                                    activePage === 'autoresponder_events' ?
                                        <LoggingEventForm key="autoresponder_events" eventData={ moduleLoggingAutoresponderEvents } title="Autoresponder Events" description="Events related to the Autoresponder module" />
                                    :
                                    activePage === 'giveaway_events' ?
                                        <LoggingEventForm key="giveaway_events" eventData={ moduleLoggingGiveawayEvents } title="Giveaway Events" description="Events related to Husqy giveaways" />
                                    :
                                        <div className='dashboard-modules-logging-no-page-selected-container'>
                                            Please choose a page to further configure the Logging module!
                                        </div>
                                }
                                </div>
                            </div>
                        :
                        <Loading loading_size='s' />
                    :
                    <div className='dashboard-modules-logging-disabled-container'>
                        The Logging module is disabled, click <label onClick={open_enable_logging_module_sidebar} className='dashboard-modules-logging-disabled-enable-button'>here</label> if you want to enabled this module!
                        <div className='enable-logging-module-sidebar' id="enable-logging-module-sidebar">
                            <div className='enable-logging-module-wrapper'>
                                <div className='enable-logging-module-cancel'>
                                    <div className="enable-logging-module-back-button" onClick={close_enable_logging_module_sidebar}>
                                        <div className="enable-logging-module-button-icon"><span className="material-symbols-outlined" id="giveaway-close-icon">close</span></div>
                                        <div className="enable-logging-module-button-text-wrapper"><span className="enable-logging-module-button-text">Cancel & Reset</span></div>
                                    </div>
                                    <h2 className='enable-logging-module-title'>
                                        Enable the Logging Module
                                    </h2>
                                </div>
                                <div className='enable-logging-module-channel-id'>
                                    <h3 className='enable-logging-module-channel-id-label'>
                                        Logs channel*
                                    </h3>
                                    <select className='enable-logging-module-channel-id-input' id='enable-logging-module-channel-id-input' name='new-logs-channel-id' onChange={on_field_change} value={newLogsChannel}>
                                        { guildChannels.map(guild_channel => 
                                            <option key={ String(guild_channel.id) } value={ String(guild_channel.id) }>
                                                { guild_channel.name }
                                            </option>
                                        )}
                                        <option key="no-channel" value="">
                                            No channel selected yet!
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div className='enable-logging-module-interactions-wrapper'>
                                <div className='submit'>
                                    <GenericButton button_text="Enable" id="enable" on_click_action={set_module_status} />
                                </div>
                            </div>
                        </div>
                    </div>
                :
                <Loading loading_size='s' />
            }
        </div>
    );
};

export default DashboardModulesLoggingContent;