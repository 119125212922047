import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import axios from 'axios'
import Loading from '../../components/loading'
import { toast } from 'react-toastify'
import GenericButton from "../../components/buttons/generic_button"
import RefreshButton from "../../components/buttons/refresh_button"

import '../../styles/pages/dashboard/settingsContent.css'
import config from '../../config'

const DashboardSettingsContent = () => {
    const [cookies] = useCookies(['access_token'])

    const params = useParams()
    const [settingsLoadingStatus, setSettingsLoadingStatus] = useState('loading')
    const [rolesLoadingStatus, setRolesLoadingStatus] = useState('loading')
    const [supportedVariablesLoadingStatus, setSupportedVariablesLoadingStatus] = useState('loading')

    const [supportedTimezones, setSupportedTimezones] = useState([])
    const [supportedLanguages, setSupportedLanguages] = useState([])
    const [supportedUnitSystems, setSupportedUnitSystems] = useState([])
    const [guildSettings, setGuildSettings] = useState({})

    const [newLanguage, setNewLanguage] = useState("")
    const [newTimezone, setNewTimezone] = useState("")
    const [newUnitSystem, setNewUnitSystem] = useState("")
    const [newAutoKickRoleId, setNewAutoKickRoleId] = useState("")
    const [newAutodelete, setNewAutoDelete] = useState("")
    const [newMaxWarns, setNewMaxWarns] = useState("")
    const [newInviteLink, setNewInviteLink] = useState("")
    const [newPollSaveTimeout, setNewPollSaveTimeout] = useState("")
    const [newGiveawaySaveTimeout, setNewGiveawaySaveTimeout] = useState("")

    const [guildRoles, setGuildRoles] = useState([])

    // FORM SUBMIT
    const on_form_submit = async (event) => {
        event.preventDefault();
        var data = {}
        data.guild_id = params.guild_id
        if ( newLanguage !== guildSettings.language.full ) {
            data.language = newLanguage
        } else {
            data.language = guildSettings.language.full
        }

        if ( newTimezone !== guildSettings.timezone ) {
            data.timezone = newTimezone
        } else {
            data.timezone = guildSettings.timezone
        }

        if ( newUnitSystem !== guildSettings.unit_system ) {
            data.unit_system = newUnitSystem
        } else {
            data.unit_system = guildSettings.unit_system
        }
    
        if ( newAutodelete !== guildSettings.auto_delete ) {
            if ( newAutodelete !== ""){
                data.auto_delete = newAutodelete
            } else {
                data.auto_delete = guildSettings.auto_delete
            }
        } else {
            data.auto_delete = guildSettings.auto_delete
        }

        if ( newMaxWarns !== guildSettings.max_warns ) {
            if ( newMaxWarns !== ""){
                data.max_warns = newMaxWarns
            } else {
                data.max_warns = guildSettings.max_warns
            }
        } else {
            data.max_warns = guildSettings.max_warns
        }
        
        if ( newAutoKickRoleId !== guildSettings.auto_kick_role_id ) {
            data.auto_kick_role_id = newAutoKickRoleId
        } else {
            data.auto_kick_role_id = guildSettings.auto_kick_role_id
        }

        var auto_kick_input = document.getElementById("guild-settings-item-checkbox-auto-kick")
        var auto_kick_enabled = auto_kick_input.checked
        if ( auto_kick_enabled !== guildSettings.auto_kick_enabled ) {
            data.auto_kick_enabled = auto_kick_enabled
        } else {
            data.auto_kick_enabled = auto_kick_enabled
        }
    
        if ( newInviteLink !== guildSettings.invite_link ) {
            if ( newInviteLink === "None") {
                data.invite_link = null
            } else {
                data.invite_link = newInviteLink
            }
        }else {
            data.invite_link = guildSettings.invite_link
        }
    
        var send_poll_results_in_chat_input = document.getElementById("guild-settings-item-checkbox-send-poll-results-in-chat")
        var send_poll_results_in_chat = send_poll_results_in_chat_input.checked
        if ( send_poll_results_in_chat !== guildSettings.send_poll_results_in_chat ) {
            data.send_poll_results_in_chat = send_poll_results_in_chat
        } else {
            data.send_poll_results_in_chat = send_poll_results_in_chat
        }
    
        if ( newPollSaveTimeout !== guildSettings.poll_save_timeout ) {
            if ( newPollSaveTimeout !== ""){
                data.poll_save_timeout = newPollSaveTimeout
            } else {
                data.poll_save_timeout = guildSettings.poll_save_timeout
            }
        } else {
            data.poll_save_timeout = guildSettings.poll_save_timeout
        }
    
        if ( newGiveawaySaveTimeout !== guildSettings.giveaway_save_timeout ) {
            if ( newGiveawaySaveTimeout !== ""){
                data.giveaway_save_timeout = newGiveawaySaveTimeout
            } else {
                data.giveaway_save_timeout = guildSettings.giveaway_save_timeout
            }
        } else {
            data.giveaway_save_timeout = guildSettings.giveaway_save_timeout
        }

        const options = {
            url: `${config["api_base_url"]}/guilds/${params.guild_id}/settings`,
            method: 'PUT',
            headers: {
                access_token: cookies["access_token"]
            },
            data: data
          };

          axios(options)
          .then(response => {
              if (response.status === 202) {
                  window.location.reload()
              }
          })
          .catch(error => {
              console.log(error)
              setSettingsLoadingStatus("error")
              if (error.response.status === 429) {
                toast.error("Rate limit reached! Please slow down!")
                return
              }
              toast.error("Something went wrong while updating the servers settings!")
          });

        on_form_reset(event)
        refresh()
    }

    // FORM RESET
    const on_form_reset = async (event) => {
        event.preventDefault();
        setNewLanguage(guildSettings["language"]["full"])
        setNewTimezone(guildSettings.timezone)
        setNewUnitSystem(guildSettings.unit_system)
        setNewAutoDelete("")
        setNewMaxWarns("")
        setNewAutoKickRoleId(guildSettings.auto_kick_role_id)
        setNewInviteLink("")
        setNewPollSaveTimeout("")
        setNewGiveawaySaveTimeout("")
        var auto_kick_input = document.getElementById("guild-settings-item-checkbox-auto-kick")
        auto_kick_input.checked = guildSettings.auto_kick_enabled
        var send_poll_results_in_chat_input = document.getElementById("guild-settings-item-checkbox-send-poll-results-in-chat")
        send_poll_results_in_chat_input.checked = guildSettings.send_poll_results_in_chat
    }

    // FIELD CHANGES
    const on_field_change = async (event) => {
        event.preventDefault();
        if (event.target.name === "language") {
            setNewLanguage(event.target.value)
        } else if (event.target.name === "timezone") {
            setNewTimezone(event.target.value)
        } else if (event.target.name === "unit_system") {
            setNewUnitSystem(event.target.value)
        } else if (event.target.name === "auto_delete") {
            setNewAutoDelete(event.target.value)
        } else if (event.target.name === "max_warns") {
            setNewMaxWarns(event.target.value)
        } else if (event.target.name === "auto_kick_role_id") {
            setNewAutoKickRoleId(event.target.value)
        } else if (event.target.name === "invite_link") {
            setNewInviteLink(event.target.value)
        } else if (event.target.name === "poll_save_timeout") {
            setNewPollSaveTimeout(event.target.value)
        } else if (event.target.name === "giveaway_save_timeout") {
            setNewGiveawaySaveTimeout(event.target.value)
        }
    }

    // GET SETTINGS FOR GUILD
    const refresh = async () => {
        get_settings()
        get_roles()
        get_supported_variables()
    }

    const get_settings = async () => {
        setSettingsLoadingStatus("loading")
        const options = {
            url: `${config["api_base_url"]}/guilds/${params.guild_id}/settings`,
            method: 'GET',
            headers: {
                access_token: cookies["access_token"]
            }
          };

          axios(options)
          .then(response => {
              if (response.status === 200) {
                  setGuildSettings(response["data"]["data"])
                  setNewLanguage(response["data"]["data"]["language"]["full"])
                  setNewTimezone(response["data"]["data"]["timezone"])
                  setNewUnitSystem(response["data"]["data"]["unit_system"])
                  setNewAutoKickRoleId(response["data"]["data"]["auto_kick_role_id"]["id"])
                  setSettingsLoadingStatus("success")
              }
          })
          .catch(error => {
              console.log(error)
              setSettingsLoadingStatus("error")
              if (error.response.status === 429) {
                toast.error("Rate limit reached! Please slow down!")
                return
            }
              toast.error("Something went wrong while getting the servers settings!")
          });
    }

    const get_roles = async () => {
        setRolesLoadingStatus("loading")
        const options = {
            url: `${config["api_base_url"]}/guilds/${params.guild_id}/roles`,
            method: 'GET',
            headers: {
                access_token: cookies["access_token"]
            },
          };

          axios(options)
          .then(response => {
              if (response.status === 200) {
                  setGuildRoles(response["data"]["data"])
                  setRolesLoadingStatus("success")
              }
          })
          .catch(error => {
              console.log(error)
              if (error.response.status === 429) {
                toast.error("Rate limit reached! Please slow down!")
                return
            }
              setRolesLoadingStatus("error")
              toast.error("Something went wrong while getting the servers roles!")
          });
    }

    const get_supported_variables = async () => {
        setSupportedVariablesLoadingStatus("loading")
        const options = {
            url: `${config["api_base_url"]}/guilds/supported_variables`,
            method: 'GET',
            headers: {
                access_token: cookies["access_token"]
            },
          };

          axios(options)
          .then(response => {
              if (response.status === 200) {
                  setSupportedLanguages(response["data"]["data"]["languages"])
                  setSupportedUnitSystems(response["data"]["data"]["unit_systems"])
                  setSupportedTimezones(response["data"]["data"]["timezones"])
                  setSupportedVariablesLoadingStatus("success")
              }
          })
          .catch(error => {
              console.log(error)
              setSupportedVariablesLoadingStatus("error")
              if (error.response.status === 429) {
                toast.error("Rate limit reached! Please slow down!")
                return
            }
              toast.error("Something went wrong while getting the servers roles!")
          });
    }

    useEffect(() => {
        refresh()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return(
        <div className='dashboard-settings-content-inner-wrapper'>
            { settingsLoadingStatus === 'success' && rolesLoadingStatus === 'success' && supportedVariablesLoadingStatus === 'success' ? 
                 <form className='dashboard-settings-form-container' onSubmit={on_form_submit} onReset={on_form_reset}>
                    <div className='dashboard-settings-form-reminder'>Don't forget to save your changes below!</div>
                    <div className='dashboard-settings-form-wrapper-top'>
                        <div className='dashboard-settings-container'>
                            <h3 className='dashboard-settings-title'>Language</h3>
                            <div className='dashboard-settings-description'>The language Husqy will use when responding to commands or sending different messages.</div>
                            <hr  className='dashboard-settings-devider' />
                            <select className='dashboard-settings-dropdown' name='language' onChange={on_field_change} value={newLanguage}>
                                { supportedLanguages.map(supported_language => 
                                    <option key={ supported_language } value={ supported_language } >
                                        { supported_language }
                                    </option>
                                )}
                            </select>
                        </div>
                        <div className='dashboard-settings-container'>
                            <h3 className='dashboard-settings-title'>Timezone</h3>
                            <div className='dashboard-settings-description'>The desired timezone of the Discord Server. This timezone can be used in different functionalities.</div>
                            <hr  className='dashboard-settings-devider' />
                            <select className='dashboard-settings-dropdown' name='timezone' onChange={on_field_change} value={newTimezone}>
                                { supportedTimezones.map(supported_timezone => 
                                    <option key={ supported_timezone } value={ supported_timezone }>
                                        { supported_timezone }
                                    </option>
                                )}
                            </select>
                        </div>
                        <div className='dashboard-settings-container'>
                            <h3 className='dashboard-settings-title'>Unit System</h3>
                            <div className='dashboard-settings-description'>The desired Unit System of the Discord Server. This timezone can be used in different functionalities.</div>
                            <hr  className='dashboard-settings-devider' />
                            <select className='dashboard-settings-dropdown' name='unit_system' onChange={on_field_change} value={newUnitSystem}>
                                { supportedUnitSystems.map(supported_unit_system => 
                                    <option key={ supported_unit_system } value={ supported_unit_system }>
                                        { supported_unit_system }
                                    </option>
                                )}
                            </select>
                        </div>
                    </div>
                    <div className='dashboard-settings-form-wrapper-bottom'>
                        <div className='dashboard-settings-container'>
                            <h3 className='dashboard-settings-title'>Auto Delete</h3>
                            <div className='dashboard-settings-description'>The time in seconds it takes for Husqy to delete some responses. Messages like, lists or info's will remain visible for a minimum amount of seconds regardless of the set auto delete. If the auto delete is set longer than this minimum, the auto delete time will be used.</div>
                            <hr  className='dashboard-settings-devider' />
                            <input className='dashboard-settings-input' type='text' value={newAutodelete} name='auto_delete' placeholder={guildSettings.auto_delete} onChange={on_field_change}></input>
                        </div>
                        <div className='dashboard-settings-container'>
                            <h3 className='dashboard-settings-title'>Maximum warnings</h3>
                            <div className='dashboard-settings-description'>The maximum amount of warnings a user is allowed to get before automatically getting kicked (if auto kick is enabled!).</div>
                            <hr  className='dashboard-settings-devider' />
                            <input className='dashboard-settings-input' type='text' value={newMaxWarns} placeholder={guildSettings.max_warns} name='max_warns' onChange={on_field_change}></input>
                        </div>
                        <div className='dashboard-settings-container'>
                            <h3 className='dashboard-settings-title'>Auto Kick role</h3>
                            <div className='dashboard-settings-description'>The role which will be used to detect if a user needs to be auto kicked.</div>
                            <hr  className='dashboard-settings-devider' />
                            <select className='dashboard-settings-dropdown' name='auto_kick_role_id' onChange={on_field_change} value={newAutoKickRoleId ? String(newAutoKickRoleId) : "None"}>
                                { guildRoles.map(guild_role => 
                                    <option key={ String(guild_role.id) } value={ String(guild_role.id) }>
                                        { guild_role.name }
                                    </option>
                                )}
                                <option key="None" value="None">
                                    No auto kick role
                                </option>
                            </select>
                        </div>
                        <div className='dashboard-settings-container'>
                            <h3 className='dashboard-settings-title'>Auto Kick</h3>
                            <div className='dashboard-settings-description'>Enable or disable the Husqy Auto Kick System. This will automatically kick a user based on their warnings.</div>
                            <hr  className='dashboard-settings-devider' />
                            { guildSettings.auto_kick_enabled ? 
                                <label className="toggler-wrapper style-1">
                                    <input id="guild-settings-item-checkbox-auto-kick" type="checkbox" name="auto_kick" defaultChecked></input>
                                    <div className="toggler-slider">
                                    <div className="toggler-knob"></div>
                                    </div>
                                </label> :
                                <label className="toggler-wrapper style-1">
                                    <input id="guild-settings-item-checkbox-auto-kick" type="checkbox" name="auto_kick"></input>
                                    <div className="toggler-slider">
                                    <div className="toggler-knob"></div>
                                    </div>
                                </label>    
                            }
                        </div>
                        <div className='dashboard-settings-container'>
                            <h3 className='dashboard-settings-title'>Invite Link</h3>
                            <div className='dashboard-settings-description'>The set invite link for the Discord Servers users can request. Use *None* to remove the invite link!</div>
                            <hr  className='dashboard-settings-devider' />
                            <input className='dashboard-settings-input' type='text' value={newInviteLink} name='invite_link' placeholder={guildSettings.invite_link ? guildSettings.invite_link : "Not in use!"} onChange={on_field_change}></input>
                        </div>
                        <div className='dashboard-settings-container'>
                            <h3 className='dashboard-settings-title'>Send poll results in chat</h3>
                            <div className='dashboard-settings-description'>Enable or disable the functionality to send the results of a poll in the chat before permanently deleting the poll.</div>
                            <hr  className='dashboard-settings-devider' />
                            { guildSettings.send_poll_results_in_chat ? 
                                <label className="toggler-wrapper style-1">
                                    <input id="guild-settings-item-checkbox-send-poll-results-in-chat" type="checkbox" name="send_poll_results_in_chat" defaultChecked></input>
                                    <div className="toggler-slider">
                                    <div className="toggler-knob"></div>
                                    </div>
                                </label> :
                                <label className="toggler-wrapper style-1">
                                    <input id="guild-settings-item-checkbox-send-poll-results-in-chat" type="checkbox" name="send_poll_results_in_chat"></input>
                                    <div className="toggler-slider">
                                    <div className="toggler-knob"></div>
                                    </div>
                                </label>    
                            }
                        </div>
                        <div className='dashboard-settings-container'>
                            <h3 className='dashboard-settings-title'>Poll save timeout</h3>
                            <div className='dashboard-settings-description'>The time in seconds to save a finished poll for before it is permanently deleted. Changing this does not effect the currently saved polls!</div>
                            <hr  className='dashboard-settings-devider' />
                            <input className='dashboard-settings-input' type='text' value={newPollSaveTimeout} placeholder={guildSettings.poll_save_timeout} name='poll_save_timeout' onChange={on_field_change}></input>
                        </div>
                        <div className='dashboard-settings-container'>
                            <h3 className='dashboard-settings-title'>Giveaway save timeout</h3>
                            <div className='dashboard-settings-description'>The time in seconds to save a finished giveaway for before it is permanently deleted. Changing this does not effect the currently saved giveaways!</div>
                            <hr  className='dashboard-settings-devider' />
                            <input className='dashboard-settings-input' type='text' value={newGiveawaySaveTimeout} placeholder={guildSettings.giveaway_save_timeout} name='giveaway_save_timeout' onChange={on_field_change}></input>
                        </div>
                        <div className="dashboard-settings-button-wrapper">
                            <div className="dashboard-settings-button-actions">
                                <GenericButton type="danger" action_type="reset" button_text="Cancel" />
                                <GenericButton button_text="Save" action_type="submit" />
                            </div>
                            <div className="dashboard-settings-button-refresh">
                                <RefreshButton on_click_action={refresh} />
                            </div>
                        </div>
                    </div>
                 </form>
                 :
                 <Loading loading_size='s' />
            }
        </div>
    );
};

export default DashboardSettingsContent;