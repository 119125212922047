import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import { toast } from 'react-toastify'
import axios from 'axios'
import Loading from '../../../components/loading'
import GenericButton from "../../../components/buttons/generic_button"
import RefreshButton from "../../../components/buttons/refresh_button"

import '../../../styles/pages/dashboard/modules/socialsContent.css'
import '../../../js/sticky_modules_socials_bars.js'
import config from '../../../config'

const DashboardModulesSocialsContent = () => {
    const [cookies] = useCookies(['access_token'])

    const [moduleSocialsStatus, setModuleSocialsStatus] = useState(false)
    const [moduleSocialsSettings, setModuleSocialsSettings] = useState({})
    const [activePage, setActivePage] = useState("")

    const [currentRedditPage, setCurrentRedditPage] = useState(1)
    const [redditListEntries, setRedditListEntries] = useState([])
    const [newRedditEntry, setNewRedditEntry] = useState("")
    const [currentRssPage, setCurrentRssPage] = useState(1)
    const [rssListEntries, setRssListEntries] = useState([])
    const [newRssEntry, setNewRssEntry] = useState("")
    const [currentTwitchPage, setCurrentTwitchPage] = useState(1)
    const [twitchListEntries, setTwitchListEntries] = useState([])
    const [newTwitchEntry, setNewTwitchEntry] = useState("")

    const [guildChannels, setGuildChannels] = useState([])

    const [currentRedditUpdatesChannel, setCurrentRedditUpdatesChannel] = useState("None")
    const [currentRssUpdatesChannel, setCurrentRssUpdatesChannel] = useState("None")
    const [currentTwitchUpdatesChannel, setCurrentTwitchUpdatesChannel] = useState("None")

    const [newRedditUpdatesChannel, setNewRedditUpdatesChannel] = useState("")
    const [newRedditMonitorEnabled, setNewRedditMonitorEnabled] = useState(false)
    const [newRedditMentionEveryone, setNewRedditMentionEveryone] = useState(false)
    const [newRssUpdatesChannel, setNewRssUpdatesChannel] = useState("")
    const [newRssMonitorEnabled, setNewRssMonitorEnabled] = useState(false)
    const [newRssMentionEveryone, setNewRssMentionEveryone] = useState(false)
    const [newTwitchUpdatesChannel, setNewTwitchUpdatesChannel] = useState("")
    const [newTwitchMonitorEnabled, setNewTwitchMonitorEnabled] = useState(false)
    const [newTwitchMentionEveryone, setNewTwitchMentionEveryone] = useState(false)

    const params = useParams()
    const [moduleSocialsLoadingStatus, setModuleSocialsLoadingStatus] = useState('loading')
    const [channelsLoadingStatus, setChannelsLoadingStatus] = useState('loading')
    const [moduleSocialsSettingsLoadingStatus, setModuleSocialsSettingsLoadingStatus] = useState('loading')

    // FIELD CHANGES
    const active_page_changer = async (event) => {
        event.preventDefault()
        setActivePage(event.target.id)
    }

    const on_field_change = async (event) => {
        event.preventDefault();
        if (event.target.name === "new-socials-module-reddit-enabled-input") {
            if (event.target.id === "on") {
                setNewRedditMonitorEnabled(true)
            } else if (event.target.id === "off") {
                setNewRedditMonitorEnabled(false)
            }
        } else if (event.target.name === "new-socials-module-rss-enabled-input") {
            if (event.target.id === "on") {
                setNewRssMonitorEnabled(true)
            } else if (event.target.id === "off") {
                setNewRssMonitorEnabled(false)
            }
        } else if (event.target.name === "new-socials-module-twitch-enabled-input") {
            if (event.target.id === "on") {
                setNewTwitchMonitorEnabled(true)
            } else if (event.target.id === "off") {
                setNewTwitchMonitorEnabled(false)
            }
        } else if (event.target.name === "new-socials-module-reddit-mention-everyone-input") {
            if (event.target.id === "on") {
                setNewRedditMentionEveryone(true)
            } else if (event.target.id === "off") {
                setNewRedditMentionEveryone(false)
            }
        } else if (event.target.name === "new-socials-module-rss-mention-everyone-input") {
            if (event.target.id === "on") {
                setNewRssMentionEveryone(true)
            } else if (event.target.id === "off") {
                setNewRssMentionEveryone(false)
            }
        } else if (event.target.name === "new-socials-module-twitch-mention-everyone-input") {
            if (event.target.id === "on") {
                setNewTwitchMentionEveryone(true)
            } else if (event.target.id === "off") {
                setNewTwitchMentionEveryone(false)
            }
        } else if (event.target.name === "new-socials-reddit-updates-channel-id") {
            setNewRedditUpdatesChannel(event.target.value)
        } else if (event.target.name === "new-socials-rss-updates-channel-id") {
            setNewRssUpdatesChannel(event.target.value)
        } else if (event.target.name === "new-socials-twitch-updates-channel-id") {
            setNewTwitchUpdatesChannel(event.target.value)
        } else if (event.target.name === "new-reddit-entry-account") {
            setNewRedditEntry(event.target.value)
        } else if (event.target.name === "new-rss-entry-account") {
            setNewRssEntry(event.target.value)
        }  else if (event.target.name === "new-twitch-entry-account") {
            setNewTwitchEntry(event.target.value)
        } 
    }
    
    const reset_module_settings = async () => {
        setNewRedditUpdatesChannel("None")
        setNewRedditMonitorEnabled(false)
        setNewRedditMentionEveryone(false)
        setNewRssUpdatesChannel("None")
        setNewRssMonitorEnabled(false)
        setNewRssMentionEveryone(false)
        setNewTwitchUpdatesChannel("None")
        setNewTwitchMonitorEnabled(false)
        setNewTwitchMentionEveryone(false)
    }

    // Sidebars
    const open_create_reddit_entry_popup = async (event) => {
        event.preventDefault()
        var bar = document.getElementById('create-reddit-entry-sidebar')
        bar.style.transform = "translateX(0px)"
    }
    const close_create_reddit_entry_popup = async (event) => {
        event.preventDefault()
        setNewRedditEntry("")
        var bar = document.getElementById('create-reddit-entry-sidebar')
        bar.style.transform = "translateX(+502px)"
    }
    const open_create_rss_entry_popup = async (event) => {
        event.preventDefault()
        var bar = document.getElementById('create-rss-entry-sidebar')
        bar.style.transform = "translateX(0px)"
    }
    const close_create_rss_entry_popup = async (event) => {
        event.preventDefault()
        setNewRssEntry("")
        var bar = document.getElementById('create-rss-entry-sidebar')
        bar.style.transform = "translateX(+502px)"
    }
    const open_create_twitch_entry_popup = async (event) => {
        event.preventDefault()
        var bar = document.getElementById('create-twitch-entry-sidebar')
        bar.style.transform = "translateX(0px)"
    }
    const close_create_twitch_entry_popup = async (event) => {
        event.preventDefault()
        setNewTwitchEntry("")
        var bar = document.getElementById('create-twitch-entry-sidebar')
        bar.style.transform = "translateX(+502px)"
    }

    // Refresh data
    const refresh_data_table = async (event) => {
        event.preventDefault();
        window.location.reload()
    }

    // Module Status
    const open_enable_socials_module_sidebar = async (event) => {
        event.preventDefault()
        var bar = document.getElementById('enable-socials-module-sidebar')
        bar.style.transform = "translateX(0px)"
    }
    const close_enable_socials_module_sidebar = async (event) => {
        event.preventDefault()
        var bar = document.getElementById('enable-socials-module-sidebar')
        bar.style.transform = "translateX(+502px)"
    }

    const get_module_status = async () => {
        setModuleSocialsLoadingStatus("loading")
        const options = {
            url: `${config["api_base_url"]}/guilds/${params.guild_id}/modules?module_names=socials`,
            method: 'GET',
            headers: {
                access_token: cookies["access_token"]
            }
          };
        axios(options)
        .then(response => {
            if (response.status === 200) {
                    setModuleSocialsStatus(response["data"]["data"]["module_socials"])
                    if (response["data"]["data"]["module_socials"] === true) {
                        get_module_settings()
                        get_data_reddit_paged()
                        get_data_rss_paged()
                        get_data_twitch_paged()
                    }
                }
                setModuleSocialsLoadingStatus("success")
        })
        .catch(error => {
            console.log(error)
            setModuleSocialsLoadingStatus("error")
            if (error.response.status === 429) {
                toast.error("Rate limit reached! Please slow down!")
                return
            }
            toast.error("Something went wrong while fetching the module Socials status!")
        });
    }

    const set_module_status = async (event) => {
        var new_status
        if (event.target.id === "enable") {
            new_status = true
        } else if (event.target.id === "disable") {
            new_status = false
        }
        
        var data = {}
        data.socials = {}
        data.socials.new_status = new_status
        data.socials.guild_id = params.guild_id
   
        const options = {
            url: `${config["api_base_url"]}/guilds/${params.guild_id}/modules`,
            method: 'POST',
            headers: {
                access_token: cookies["access_token"]
            },
            data: data
          };

        axios(options)
        .then(response => {
            if (response.status === 202) {
                if (response["data"]["data"]["socials"]["code"] === 200) {
                    window.location.reload()
                }
            } else {
                toast.error("Something went wrong while updating the Socials module!")
            }
        })
        .catch(error => {
            console.log(error)
            if (error.response.status === 429) {
                toast.error("Rate limit reached! Please slow down!")
                return
            }
            toast.error("Something went wrong while updating the Socials module!")
        });
    }

    // Get data
    const get_channels = async () => {
        setChannelsLoadingStatus("loading")
        const options = {
            url: `${config["api_base_url"]}/guilds/${params.guild_id}/channels?types=text`,
            method: 'GET',
            headers: {
                access_token: cookies["access_token"]
            },
          };

          axios(options)
          .then(response => {
              if (response.status === 200) {
                  setGuildChannels(response["data"][0]["data"])
                  setChannelsLoadingStatus("success")
              }
          })
          .catch(error => {
              console.log(error)
              setChannelsLoadingStatus("error")
              if (error.response.status === 429) {
                toast.error("Rate limit reached! Please slow down!")
                return
            }
              toast.error("Something went wrong while getting the servers channels!")
          });
    }

    const get_module_settings = async () => {
        setModuleSocialsSettingsLoadingStatus("loading")
        const options = {
            url: `${config["api_base_url"]}/guilds/${params.guild_id}/modules/socials`,
            method: 'GET',
            headers: {
                access_token: cookies["access_token"]
            },
          };

          axios(options)
          .then(response => {
              if (response.status === 200) {
                  setModuleSocialsSettings(response["data"]["data"])
                  if (response["data"]["data"]["reddit_updates_channel_id"]["id"] === null) {
                    setNewRedditUpdatesChannel("None")
                    setCurrentRedditUpdatesChannel("None")
                  } else {
                    setNewRedditUpdatesChannel(response["data"]["data"]["reddit_updates_channel_id"]["id"])
                    setCurrentRedditUpdatesChannel(response["data"]["data"]["reddit_updates_channel_id"]["id"])
                  }
                  setNewRedditMonitorEnabled(response["data"]["data"]["monitor_reddit"])
                  setNewRedditMentionEveryone(response["data"]["data"]["reddit_mention_everyone"])
                  if (response["data"]["data"]["rss_updates_channel_id"]["id"] === null) {
                    setNewRssUpdatesChannel("None")
                    setCurrentRssUpdatesChannel("None")
                  } else {
                    setNewRssUpdatesChannel(response["data"]["data"]["rss_updates_channel_id"]["id"])
                    setCurrentRssUpdatesChannel(response["data"]["data"]["rss_updates_channel_id"]["id"])
                  }
                  setNewRssMonitorEnabled(response["data"]["data"]["monitor_rss"])
                  setNewRssMentionEveryone(response["data"]["data"]["rss_mention_everyone"])
                  if (response["data"]["data"]["twitch_updates_channel_id"]["id"] === null) {
                    setNewTwitchUpdatesChannel("None")
                    setCurrentTwitchUpdatesChannel("None")
                  } else {
                    setNewTwitchUpdatesChannel(response["data"]["data"]["twitch_updates_channel_id"]["id"])
                    setCurrentTwitchUpdatesChannel(response["data"]["data"]["twitch_updates_channel_id"]["id"])
                  }
                  setNewTwitchMonitorEnabled(response["data"]["data"]["monitor_twitch"])
                  setNewTwitchMentionEveryone(response["data"]["data"]["twitch_mention_everyone"])
                  setModuleSocialsSettingsLoadingStatus("success")
              }
          })
          .catch(error => {
              console.log(error)
              setModuleSocialsSettingsLoadingStatus("error")
              if (error.response.status === 429) {
                toast.error("Rate limit reached! Please slow down!")
                return
            }
              toast.error("Something went wrong while getting the settings of the Socials module!")
          });
    }

    const set_module_settings = async () => {
        var data = {}
        data.guild_id = params.guild_id
        data.new_monitor_reddit = newRedditMonitorEnabled
        data.new_monitor_rss = newRssMonitorEnabled
        data.new_monitor_twitch = newTwitchMonitorEnabled
        if (newRedditMonitorEnabled === true) {
            if (newRedditUpdatesChannel === "None") {
                toast.error("You must select a channel where the Reddit updates will be send!")
                return
            }
            data.new_reddit_updates_channel_id = newRedditUpdatesChannel
        } else {
            data.new_reddit_updates_channel_id = "None"
        }
        if (newRssMonitorEnabled === true) {
            if (newRssUpdatesChannel === "None") {
                toast.error("You must select a channel where the RSS updates will be send!")
                return
            }
            data.new_rss_updates_channel_id = newRssUpdatesChannel
        } else {
            data.new_rss_updates_channel_id = "None"
        }
        if (newTwitchMonitorEnabled === true) {
            if (newTwitchUpdatesChannel === "None") {
                toast.error("You must select a channel where the Twitch updates will be send!")
                return
            }
            data.new_twitch_updates_channel_id = newTwitchUpdatesChannel
        } else {
            data.new_twitch_updates_channel_id = "None"
        }

        data.new_reddit_mention_everyone = newRedditMentionEveryone
        data.new_rss_mention_everyone = newRssMentionEveryone
        data.new_twitch_mention_everyone = newTwitchMentionEveryone

        const options = {
            url: `${config["api_base_url"]}/guilds/${params.guild_id}/modules/socials`,
            method: 'PUT',
            headers: {
                access_token: cookies["access_token"]
            },
            data: data,
          };

          axios(options)
          .then(response => {
              if (response.status === 202) {
                    window.location.reload()
              }
          })
          .catch(error => {
              console.log(error)
              if (error.response.status === 429) {
                toast.error("Rate limit reached! Please slow down!")
                return
            }
              toast.error("Something went wrong while getting the settings of the Socials module!")
          });
    }

    // Reddit
    const get_extra_data_reddit = async (event) => {
        event.preventDefault();
        get_data_reddit_paged()
    }

    const delete_reddit_entry = async (event) => {
        event.preventDefault();
        var data = {}
        data.subreddit = event.target.id

        const options = {
            url: `${config["api_base_url"]}/guilds/${params.guild_id}/modules/socials/reddit/entries`,
            method: 'DELETE',
            headers: {
                access_token: cookies["access_token"]
            }, 
            data: data
          };

        axios(options)
        .then(response => {
            if (response.status === 202) {
                window.location.reload()
            }
        })
        .catch(error => {
            console.log(error)
            if (error.response.status === 429) {
                toast.error("Rate limit reached! Please slow down!")
                return
            }
            toast.error("Something went wrong while removing the Subreddit from the list!")
        });
    }

    const add_reddit_entry = async (event) => {
        event.preventDefault();
        if (newRedditEntry === "") {
            toast.error("You need to insert a Reddit account to monitor!")
            return
        }

        var data = {}
        data.new_subreddit = newRedditEntry

        const options = {
            url: `${config["api_base_url"]}/guilds/${params.guild_id}/modules/socials/reddit/entries`,
            method: 'POST',
            headers: {
                access_token: cookies["access_token"]
            }, 
            data: data
          };

        axios(options)
        .then(response => {
            if (response.status === 202) {
                window.location.reload()
            }
        })
        .catch(error => {
            console.log(error)
            if (error.response.status === 422) {
                toast.error("You have already reached the maximumm amount of allowed Subreddits!")
            } else if (error.response.status === 409) {
                toast.error("The given Subreddit is already in the list!")
            } else if (error.response.status === 429) {
                toast.error("Rate limit reached! Please slow down!")
            }else {
                toast.error("Something went wrong while adding the Subreddit to the list!")
            }
        });
    }

    const get_data_reddit_paged = async () => {
        setModuleSocialsSettingsLoadingStatus("loading")
        const options = {
            url: `${config["api_base_url"]}/guilds/${params.guild_id}/modules/socials/reddit/entries?page=${currentRedditPage}`,
            method: 'GET',
            headers: {
                access_token: cookies["access_token"]
            }
          };

        axios(options)
        .then(response => {
            if (response.status === 200) {
                if (response["data"]["data"].length === 0) {
                    setCurrentRedditPage(currentRedditPage)
                } else {
                    setCurrentRedditPage(currentRedditPage+1)
                    if (redditListEntries.length === 0 ) {
                        setRedditListEntries(response["data"]["data"])
                    } else {
                        var newRedditListEntries = redditListEntries.concat(response["data"]["data"])
                        setRedditListEntries(newRedditListEntries)
                    }
                }
                setModuleSocialsSettingsLoadingStatus("success")
            }
        })
        .catch(error => {
            console.log(error)
            setModuleSocialsSettingsLoadingStatus("error")
            if (error.response.status === 429) {
                toast.error("Rate limit reached! Please slow down!")
                return
            }
            toast.error("Something went wrong while fetching the servers Reddit entries!")
        });
    }

    // RSS
    const get_extra_data_rss = async (event) => {
        event.preventDefault();
        get_data_rss_paged()
    }

    const delete_rss_entry = async (event) => {
        event.preventDefault();
        var data = {}
        data.rss_feed = event.target.id
        data.guild_id = params.guild_id
        data.action_type = "delete"

        const options = {
            url: `${config["api_base_url"]}/guilds/${params.guild_id}/modules/socials/rss/entries`,
            method: 'DELETE',
            headers: {
                access_token: cookies["access_token"]
            }, 
            data: data
          };

        axios(options)
        .then(response => {
            if (response.status === 202) {
                window.location.reload()
            }
        })
        .catch(error => {
            console.log(error)
            if (error.response.status === 429) {
                toast.error("Rate limit reached! Please slow down!")
                return
            }
            toast.error("Something went wrong while removing the RSS feed from the list!")
        });
    }

    const add_rss_entry = async (event) => {
        event.preventDefault();
        if (newRssEntry === "") {
            toast.error("You need to insert a RSS feed to monitor!")
            return
        }

        var data = {}
        data.new_rss_feed = newRssEntry
        data.guild_id = params.guild_id
        data.action_type = "add"

        const options = {
            url: `${config["api_base_url"]}/guilds/${params.guild_id}/modules/socials/rss/entries`,
            method: 'POST',
            headers: {
                access_token: cookies["access_token"]
            }, 
            data: data
          };

        axios(options)
        .then(response => {
            if (response.status === 202) {
                window.location.reload()
            }
        })
        .catch(error => {
            console.log(error)
            if (error.response.status === 422) {
                toast.error("You have already reached the maximumm amount of allowed RSS Feeds!")
            } else if (error.response.status === 409) {
                toast.error("The given RSS Feed is already in the list!")
            } else if (error.response.status === 429) {
                toast.error("Rate limit reached! Please slow down!")
            } else {
                toast.error("Something went wrong while adding the RSS feed to the list!")
            }
        });
    }

    const get_data_rss_paged = async () => {
        setModuleSocialsSettingsLoadingStatus("loading")
        const options = {
            url: `${config["api_base_url"]}/guilds/${params.guild_id}/modules/socials/rss/entries?page=${currentRssPage}`,
            method: 'GET',
            headers: {
                access_token: cookies["access_token"]
            }
          };

        axios(options)
        .then(response => {
            if (response.status === 200) {
                if (response["data"]["data"].length === 0) {
                    setCurrentRssPage(currentRssPage)
                } else {
                    setCurrentRssPage(currentRssPage+1)
                    if (rssListEntries.length === 0 ) {
                        setRssListEntries(response["data"]["data"])
                    } else {
                        var newRssListEntries = rssListEntries.concat(response["data"]["data"])
                        setRssListEntries(newRssListEntries)
                    }
                }
                setModuleSocialsSettingsLoadingStatus("success")
            }
        })
        .catch(error => {
            console.log(error)
            setModuleSocialsSettingsLoadingStatus("error")
            if (error.response.status === 429) {
                toast.error("Rate limit reached! Please slow down!")
                return
            }
            toast.error("Something went wrong while fetching the servers Rss entries!")
        });
    }

    // Twitch
    const get_extra_data_twitch = async (event) => {
        event.preventDefault();
        get_data_twitch_paged()
    }

    const delete_twitch_entry = async (event) => {
        event.preventDefault();
        var data = {}
        data.twitch_accounts = event.target.id

        const options = {
            url: `${config["api_base_url"]}/guilds/${params.guild_id}/modules/socials/twitch/entries`,
            method: 'DELETE',
            headers: {
                access_token: cookies["access_token"]
            }, 
            data: data
          };

        axios(options)
        .then(response => {
            if (response.status === 202) {
                window.location.reload()
            }
        })
        .catch(error => {
            console.log(error)
            if (error.response.status === 429) {
                toast.error("Rate limit reached! Please slow down!")
                return
            }
            toast.error("Something went wrong while removing the Twitch account from the list!")
        });
    }

    const add_twitch_entry = async (event) => {
        event.preventDefault();
        if (newTwitchEntry === "") {
            toast.error("You need to insert a Twitch to monitor!")
            return
        }

        var data = {}
        data.new_twitch_accounts = newTwitchEntry

        const options = {
            url: `${config["api_base_url"]}/guilds/${params.guild_id}/modules/socials/twitch/entries`,
            method: 'POST',
            headers: {
                access_token: cookies["access_token"]
            }, 
            data: data
          };

        axios(options)
        .then(response => {
            if (response.status === 202) {
                window.location.reload()
            }
        })
        .catch(error => {
            console.log(error)
            if (error.response.status === 422) {
                toast.error("You have already reached the maximumm amount of allowed Twitch accounts!")
            } else if (error.response.status === 409) {
                toast.error("The given Twitch account is already in the list!")
            } else if (error.response.status === 429) {
                toast.error("Rate limit reached! Please slow down!")
            } else {
                toast.error("Something went wrong while adding the Twitch account to the list!")
            }
        });
    }

    const get_data_twitch_paged = async () => {
        setModuleSocialsSettingsLoadingStatus("loading")
        const options = {
            url: `${config["api_base_url"]}/guilds/${params.guild_id}/modules/socials/twitch/entries?page=${currentTwitchPage}`,
            method: 'GET',
            headers: {
                access_token: cookies["access_token"]
            }
          };

        axios(options)
        .then(response => {
            if (response.status === 200) {
                if (response["data"]["data"].length === 0) {
                    setCurrentTwitchPage(currentTwitchPage)
                } else {
                    setCurrentTwitchPage(currentTwitchPage+1)
                    if (twitchListEntries.length === 0 ) {
                        setTwitchListEntries(response["data"]["data"])
                    } else {
                        var newTwitchListEntries = twitchListEntries.concat(response["data"]["data"])
                        setTwitchListEntries(newTwitchListEntries)
                    }
                }
                setModuleSocialsSettingsLoadingStatus("success")
            }
        })
        .catch(error => {
            console.log(error)
            setModuleSocialsSettingsLoadingStatus("error")
            if (error.response.status === 429) {
                toast.error("Rate limit reached! Please slow down!")
                return
            }
            toast.error("Something went wrong while fetching the servers Twitch entries!")
        });
    }

    useEffect(() => {
        get_module_status()
        get_channels()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return(
        <div className='dashboard-modules-socials-content-inner-wrapper'>
            { moduleSocialsLoadingStatus === 'success' && channelsLoadingStatus === 'success' ?
                moduleSocialsStatus === true ?
                    moduleSocialsSettingsLoadingStatus === 'success' ?  
                        <div className='dashboard-modules-socials-enabled-container'> 
                            <div className='dashboard-modules-socials-enabled-disable-wrapper'>
                                Click <label id="disable" onClick={set_module_status} className='dashboard-modules-socials-enabled-disable-button'>here</label> if you want to disable this module!
                            </div>
                            <div className='dashboard-modules-socials-enabled-settings-reminder'>Don't forget to save your changes! The components are scrollable!</div>
                            <div className='dashboard-modules-socials-enabled-settings-container'>
                                <div key="reddit" className='dashboard-modules-socials-enabled-setting'>
                                    <div className='dashboard-modules-socials-enabled-setting-label-wrapper'>
                                        <div className='dashboard-modules-socials-enabled-setting-label'>Reddit component</div>
                                        { newRedditMonitorEnabled ?
                                            <div key="off">
                                                <div className='new-socials-module-reddit-enabled-wrapper'>
                                                    <label className="toggler-wrapper style-1">
                                                        <input id="off" type="checkbox" name="new-socials-module-reddit-enabled-input" defaultChecked onChange={on_field_change}></input>
                                                        <div className="toggler-slider">
                                                            <div className="toggler-knob"></div>
                                                        </div>
                                                    </label>
                                                </div>
                                            </div> 
                                            :
                                            <div key="on">
                                                <div className='new-socials-module-reddit-enabled-wrapper'>
                                                    <label className="toggler-wrapper style-1">
                                                        <input id="on" type="checkbox" name="new-socials-module-reddit-enabled-input" onChange={on_field_change}></input>
                                                        <div className="toggler-slider">
                                                            <div className="toggler-knob"></div>
                                                        </div>
                                                    </label>
                                                </div> 
                                            </div>
                                        }
                                    </div>
                                    { currentRedditUpdatesChannel !== "None" ?
                                        <a className='socials-entry-field-channel-link' href={"https://discord.com/channels/"+params.guild_id+"/"+moduleSocialsSettings.reddit_updates_channel_id.id} target="_blank" rel="noreferrer">
                                            View Reddit updates!
                                        </a>
                                        :
                                        <div></div>
                                    }
                                    <div className='dashboard-modules-socials-enabled-setting-container'>
                                        { newRedditMonitorEnabled ? 
                                            <div className='dashboard-modules-socials-enabled-setting-wrapper'>
                                                <hr  className='dashboard-modules-socials-enabled-setting-devider' />
                                                <div className='dashboard-modules-socials-enabled-setting-inner-wrapper'>
                                                    <div className='dashboard-modules-socials-enabled-setting-inner-container'>
                                                        <div className='dashboard-modules-socials-enabled-setting-wrapper-updates-channel-title'>
                                                            Updates channel
                                                        </div>
                                                        <select className='dashboard-modules-socials-reddit-updates-channel-input' id='dashboard-modules-socials-reddit-updates-channel-input' name='new-socials-reddit-updates-channel-id' onChange={on_field_change} value={newRedditUpdatesChannel}>
                                                            { guildChannels.map(guild_channel => 
                                                                <option key={ String(guild_channel.id) } value={ String(guild_channel.id) }>
                                                                    { guild_channel.name }
                                                                </option>
                                                            )}
                                                            <option key="no-voice-category" value="None">
                                                                No channel selected yet!
                                                            </option>
                                                        </select>
                                                    </div>
                                                    <div className='dashboard-modules-socials-enabled-setting-inner-container'>
                                                        <div className='dashboard-modules-socials-enabled-setting-wrapper-mention-everyone-title'>
                                                            Mention everyone
                                                        </div>
                                                        { newRedditMentionEveryone ?
                                                            <div key="off">
                                                                <div className='new-socials-module-reddit-mention-everyone-wrapper'>
                                                                    <label className="toggler-wrapper style-1">
                                                                        <input id="off" type="checkbox" name="new-socials-module-reddit-mention-everyone-input" defaultChecked onChange={on_field_change}></input>
                                                                        <div className="toggler-slider">
                                                                            <div className="toggler-knob"></div>
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                            </div> 
                                                            :
                                                            <div key="on">
                                                                <div className='new-socials-module-reddit-mention-everyone-wrapper'>
                                                                    <label className="toggler-wrapper style-1">
                                                                        <input id="on" type="checkbox" name="new-socials-module-reddit-mention-everyone-input" onChange={on_field_change}></input>
                                                                        <div className="toggler-slider">
                                                                            <div className="toggler-knob"></div>
                                                                        </div>
                                                                    </label>
                                                                </div> 
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div></div>
                                        }
                                    </div>
                                </div>
                                <div key="rss" className='dashboard-modules-socials-enabled-setting'>
                                    <div className='dashboard-modules-socials-enabled-setting-label-wrapper'>
                                        <div className='dashboard-modules-socials-enabled-setting-label'>RSS component</div>
                                        { newRssMonitorEnabled ?
                                            <div key="off">
                                                <div className='new-socials-module-rss-enabled-wrapper'>
                                                    <label className="toggler-wrapper style-1">
                                                        <input id="off" type="checkbox" name="new-socials-module-rss-enabled-input" defaultChecked onChange={on_field_change}></input>
                                                        <div className="toggler-slider">
                                                            <div className="toggler-knob"></div>
                                                        </div>
                                                    </label>
                                                </div>
                                            </div> 
                                            :
                                            <div key="on">
                                                <div className='new-socials-module-rss-enabled-wrapper'>
                                                    <label className="toggler-wrapper style-1">
                                                        <input id="on" type="checkbox" name="new-socials-module-rss-enabled-input" onChange={on_field_change}></input>
                                                        <div className="toggler-slider">
                                                            <div className="toggler-knob"></div>
                                                        </div>
                                                    </label>
                                                </div> 
                                            </div>
                                        }
                                    </div>
                                    { currentRssUpdatesChannel !== "None" ?
                                        <a className='socials-entry-field-channel-link' href={"https://discord.com/channels/"+params.guild_id+"/"+moduleSocialsSettings.rss_updates_channel_id.id} target="_blank" rel="noreferrer">
                                            View RSS updates!
                                        </a>
                                        :
                                        <div></div>
                                    }
                                    <div className='dashboard-modules-socials-enabled-setting-container'>
                                        { newRssMonitorEnabled ? 
                                            <div className='dashboard-modules-socials-enabled-setting-wrapper'>
                                                <hr  className='dashboard-modules-socials-enabled-setting-devider' />
                                                <div className='dashboard-modules-socials-enabled-setting-inner-wrapper'>
                                                    <div className='dashboard-modules-socials-enabled-setting-inner-container'>
                                                        <div className='dashboard-modules-socials-enabled-setting-wrapper-updates-channel-title'>
                                                            Updates channel
                                                        </div>
                                                        <select className='dashboard-modules-socials-rss-updates-channel-input' id='dashboard-modules-socials-rss-updates-channel-input' name='new-socials-rss-updates-channel-id' onChange={on_field_change} value={newRssUpdatesChannel}>
                                                            { guildChannels.map(guild_channel => 
                                                                <option key={ String(guild_channel.id) } value={ String(guild_channel.id) }>
                                                                    { guild_channel.name }
                                                                </option>
                                                            )}
                                                            <option key="no-voice-category" value="None">
                                                                No channel selected yet!
                                                            </option>
                                                        </select>
                                                    </div>
                                                    <div className='dashboard-modules-socials-enabled-setting-inner-container'>
                                                        <div className='dashboard-modules-socials-enabled-setting-wrapper-mention-everyone-title'>
                                                            Mention everyone
                                                        </div>
                                                        { newRssMentionEveryone ?
                                                            <div key="off">
                                                                <div className='new-socials-module-rss-mention-everyone-wrapper'>
                                                                    <label className="toggler-wrapper style-1">
                                                                        <input id="off" type="checkbox" name="new-socials-module-rss-mention-everyone-input" defaultChecked onChange={on_field_change}></input>
                                                                        <div className="toggler-slider">
                                                                            <div className="toggler-knob"></div>
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                            </div> 
                                                            :
                                                            <div key="on">
                                                                <div className='new-socials-module-rss-mention-everyone-wrapper'>
                                                                    <label className="toggler-wrapper style-1">
                                                                        <input id="on" type="checkbox" name="new-socials-module-rss-mention-everyone-input" onChange={on_field_change}></input>
                                                                        <div className="toggler-slider">
                                                                            <div className="toggler-knob"></div>
                                                                        </div>
                                                                    </label>
                                                                </div> 
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div></div>
                                        }
                                    </div>
                                </div>
                                <div key="twitch" className='dashboard-modules-socials-enabled-setting'>
                                    <div className='dashboard-modules-socials-enabled-setting-label-wrapper'>
                                        <div className='dashboard-modules-socials-enabled-setting-label'>Twitch component</div>
                                        { newTwitchMonitorEnabled ?
                                            <div key="off">
                                                <div className='new-socials-module-twitch-enabled-wrapper'>
                                                    <label className="toggler-wrapper style-1">
                                                        <input id="off" type="checkbox" name="new-socials-module-twitch-enabled-input" defaultChecked onChange={on_field_change}></input>
                                                        <div className="toggler-slider">
                                                            <div className="toggler-knob"></div>
                                                        </div>
                                                    </label>
                                                </div>
                                            </div> 
                                            :
                                            <div key="on">
                                                <div className='new-socials-module-twitch-enabled-wrapper'>
                                                    <label className="toggler-wrapper style-1">
                                                        <input id="on" type="checkbox" name="new-socials-module-twitch-enabled-input" onChange={on_field_change}></input>
                                                        <div className="toggler-slider">
                                                            <div className="toggler-knob"></div>
                                                        </div>
                                                    </label>
                                                </div> 
                                            </div>
                                        }
                                    </div>
                                    { currentTwitchUpdatesChannel !== "None" ?
                                        <a className='socials-entry-field-channel-link' href={"https://discord.com/channels/"+params.guild_id+"/"+moduleSocialsSettings.twitch_updates_channel_id.id} target="_blank" rel="noreferrer">
                                            View Twitch updates!
                                        </a>
                                        :
                                        <div></div>
                                    }
                                    <div className='dashboard-modules-socials-enabled-setting-container'>
                                        { newTwitchMonitorEnabled ? 
                                            <div className='dashboard-modules-socials-enabled-setting-wrapper'>
                                                <hr  className='dashboard-modules-socials-enabled-setting-devider' />
                                                <div className='dashboard-modules-socials-enabled-setting-inner-wrapper'>
                                                    <div className='dashboard-modules-socials-enabled-setting-inner-container'>
                                                        <div className='dashboard-modules-socials-enabled-setting-wrapper-updates-channel-title'>
                                                            Updates channel
                                                        </div>
                                                        <select className='dashboard-modules-socials-twitch-updates-channel-input' id='dashboard-modules-socials-twitch-updates-channel-input' name='new-socials-twitch-updates-channel-id' onChange={on_field_change} value={newTwitchUpdatesChannel}>
                                                            { guildChannels.map(guild_channel => 
                                                                <option key={ String(guild_channel.id) } value={ String(guild_channel.id) }>
                                                                    { guild_channel.name }
                                                                </option>
                                                            )}
                                                            <option key="no-voice-category" value="None">
                                                                No channel selected yet!
                                                            </option>
                                                        </select>
                                                    </div>
                                                    <div className='dashboard-modules-socials-enabled-setting-inner-container'>
                                                        <div className='dashboard-modules-socials-enabled-setting-wrapper-mention-everyone-title'>
                                                            Mention everyone
                                                        </div>
                                                        { newTwitchMentionEveryone ?
                                                            <div key="off">
                                                                <div className='new-socials-module-twitch-mention-everyone-wrapper'>
                                                                    <label className="toggler-wrapper style-1">
                                                                        <input id="off" type="checkbox" name="new-socials-module-twitch-mention-everyone-input" defaultChecked onChange={on_field_change}></input>
                                                                        <div className="toggler-slider">
                                                                            <div className="toggler-knob"></div>
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                            </div> 
                                                            :
                                                            <div key="on">
                                                                <div className='new-socials-module-twitch-mention-everyone-wrapper'>
                                                                    <label className="toggler-wrapper style-1">
                                                                        <input id="on" type="checkbox" name="new-socials-module-twitch-mention-everyone-input" onChange={on_field_change}></input>
                                                                        <div className="toggler-slider">
                                                                            <div className="toggler-knob"></div>
                                                                        </div>
                                                                    </label>
                                                                </div> 
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div></div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="dashboard-modules-socials-settings-interactions-wrapper">
                                <div className="dashboard-modules-socials-settings-interactions-inner-wrapper">
                                <GenericButton button_text="Cancel" type="danger" on_click_action={reset_module_settings} />
                                    <GenericButton button_text="Save" on_click_action={set_module_settings} />
                                </div>
                                <div className="dashboard-modules-socials-settings-interactions-refresh">
                                    <RefreshButton on_click_action={refresh_data_table} />
                                </div>
                            </div>
                            <hr  className='dashboard-modules-socials-enabled-setting-devider' />
                            <div className='dashboard-modules-socials-active-page-changer-container'>
                                <div className='dashboard-modules-socials-active-page-changer-pages-container'>
                                    <div className='dashboard-modules-socials-active-page-changer-page' id="reddit" onClick={active_page_changer}>Reddit list</div>
                                    <div className='dashboard-modules-socials-active-page-changer-page' id="rss" onClick={active_page_changer}>RSS list</div>
                                    <div className='dashboard-modules-socials-active-page-changer-page' id="twitch" onClick={active_page_changer}>Twitch list</div>
                                </div>
                            </div>
                            { activePage === "reddit" ?
                                        <div className='dashboard-modules-socials-table-container'>
                                            <div className='dashboard-modules-socials-table-container-interactions'>
                                                <div className='dashboard-functions-tags-table-data'>
                                                    <RefreshButton on_click_action={refresh_data_table} />
                                                </div>
                                                { newRedditMonitorEnabled === true ?
                                                    <div className='dashboard-modules-socials-settings-save-changes'>
                                                        <GenericButton button_text="Add Subreddit" on_click_action={open_create_reddit_entry_popup} />
                                                    </div>
                                                    :
                                                    <div></div>
                                                }
                                            </div>
                                            { newRedditMonitorEnabled === false ?
                                                <div className='dashboard-modules-socials-table-container-component-disabled'>
                                                    The Reddit component is disabled, please enable it to view your Reddit list!
                                                </div>
                                                :
                                                redditListEntries.length === 0 ?
                                                <div className='dashboard-modules-socials-table-container-component-list-empty'>
                                                    The Reddit list is empty!
                                                </div>
                                                :
                                                <div className='dashboard-modules-socials-table-container-table'>
                                                    <div className="dashboard-modules-socials-table-titles">
                                                        <div className="dashboard-modules-socials-table-titles-wrapper">
                                                            <div className="dashboard-modules-socials-table-titles-name-wrapper">
                                                                <p className="dashboard-modules-socials-table-titles-name">Subreddit</p>
                                                            </div>
                                                            <div className="dashboard-modules-socials-table-titles-interactions-wrapper">
                                                                <p className="dashboard-modules-socials-table-titles-interactions">Interactions</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="dashboard-modules-socials-table-content">
                                                        { redditListEntries.map(account => 
                                                            <div className="socials-entry-wrapper" key={account.name}>
                                                                <div className="socials-entry-name-wrapper">
                                                                    <p className="socials-entry-name">{ account.name }</p>
                                                                </div>
                                                                <div className="socials-entry-interactions-wrapper">
                                                                    <div className="socials-entry-interactions-inner-wrapper">
                                                                        <div className="socials-entry-interactions-form-delete">
                                                                            <span className="socials-entry-interactions-delete-text" id={account.name} onClick={delete_reddit_entry}>Delete</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            }
                                            <div className='dashboard-modules-socials-table-load-extra'>
                                                <GenericButton button_text="Load extra Reddit entries" on_click_action={get_extra_data_reddit} />
                                            </div>
                                            <div className='create-reddit-entry-sidebar' id="create-reddit-entry-sidebar">
                                                <div className='create-reddit-entry-wrapper'>
                                                    <div className='create-reddit-entry-cancel'>
                                                        <div className="create-reddit-entry-back-button" onClick={close_create_reddit_entry_popup}>
                                                            <div className="create-reddit-entry-button-icon"><span className="material-symbols-outlined" id="reddit-entry-close-icon">close</span></div>
                                                            <div className="create-reddit-entry-button-text-wrapper"><span className="create-reddit-entry-button-text">Cancel & Reset</span></div>
                                                        </div>
                                                        <h2 className='create-reddit-entry-title'>
                                                            New Subreddit entry
                                                        </h2>
                                                    </div>
                                                    <div className='create-reddit-entry-account'>
                                                        <h3 className='create-reddit-entry-account-label'>
                                                            Subreddit*
                                                        </h3>
                                                        <input className='create-reddit-entry-account-input' id='create-reddit-entry-account-input' name='new-reddit-entry-account' placeholder='homeassistant' onChange={on_field_change} value={newRedditEntry} />
                                                    </div>
                                                </div>
                                                <div className='create-reddit-entry-interactions-wrapper'>
                                                    <div className='submit'>
                                                        <GenericButton button_text="Create entry" on_click_action={add_reddit_entry} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                :
                                activePage === "rss" ?
                                        <div className='dashboard-modules-socials-table-container'>
                                            <div className='dashboard-modules-socials-table-container-interactions'>
                                                <div className='dashboard-functions-tags-table-data'>
                                                    <RefreshButton on_click_action={refresh_data_table} />
                                                </div>
                                                { newRssMonitorEnabled === true ?
                                                    <div className='dashboard-modules-socials-settings-save-changes'>
                                                        <GenericButton button_text="Add Feed" on_click_action={open_create_rss_entry_popup} />
                                                    </div>
                                                    :
                                                    <div></div>
                                                }
                                            </div>
                                            { newRssMonitorEnabled === false ?
                                                <div className='dashboard-modules-socials-table-container-component-disabled'>
                                                    The RSS component is disabled, please enable it to view your RSS list!
                                                </div>
                                                :
                                                rssListEntries.length === 0 ?
                                                <div className='dashboard-modules-socials-table-container-component-list-empty'>
                                                    The RSS list is empty!
                                                </div>
                                                :
                                                <div className='dashboard-modules-socials-table-container-table'>
                                                    <div className="dashboard-modules-socials-table-titles">
                                                        <div className="dashboard-modules-socials-table-titles-wrapper">
                                                            <div className="dashboard-modules-socials-table-titles-name-wrapper">
                                                                <p className="dashboard-modules-socials-table-titles-name">RSS Feed</p>
                                                            </div>
                                                            <div className="dashboard-modules-socials-table-titles-interactions-wrapper">
                                                                <p className="dashboard-modules-socials-table-titles-interactions">Interactions</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="dashboard-modules-socials-table-content">
                                                        { rssListEntries.map(account => 
                                                            <div className="socials-entry-wrapper" key={account.url}>
                                                                <div className="socials-entry-name-wrapper">
                                                                    <p className="socials-entry-name">{ account.url }</p>
                                                                </div>
                                                                <div className="socials-entry-interactions-wrapper">
                                                                    <div className="socials-entry-interactions-inner-wrapper">
                                                                        <div className="socials-entry-interactions-form-delete">
                                                                            <span className="socials-entry-interactions-delete-text" id={account.url} onClick={delete_rss_entry}>Delete</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            }
                                            <div className='dashboard-modules-socials-table-load-extra'>
                                                <GenericButton button_text="Load extra RSS entries" on_click_action={get_extra_data_rss} />
                                            </div>
                                            <div className='create-rss-entry-sidebar' id="create-rss-entry-sidebar">
                                                <div className='create-rss-entry-wrapper'>
                                                    <div className='create-rss-entry-cancel'>
                                                        <div className="create-rss-entry-back-button" onClick={close_create_rss_entry_popup}>
                                                            <div className="create-rss-entry-button-icon"><span className="material-symbols-outlined" id="rss-entry-close-icon">close</span></div>
                                                            <div className="create-rss-entry-button-text-wrapper"><span className="create-rss-entry-button-text">Cancel & Reset</span></div>
                                                        </div>
                                                        <h2 className='create-rss-entry-title'>
                                                            New RSS Feed entry
                                                        </h2>
                                                    </div>
                                                    <div className='create-rss-entry-account'>
                                                        <h3 className='create-rss-entry-account-label'>
                                                            RSS Feed*
                                                        </h3>
                                                        <input className='create-rss-entry-account-input' id='create-rss-entry-account-input' name='new-rss-entry-account' placeholder='https://example.com' onChange={on_field_change} value={newRssEntry} />
                                                    </div>
                                                </div>
                                                <div className='create-rss-entry-interactions-wrapper'>
                                                    <div className='submit'>
                                                        <GenericButton button_text="Create entry" on_click_action={add_rss_entry} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                :
                                activePage === "twitch" ?
                                        <div className='dashboard-modules-socials-table-container'>
                                            <div className='dashboard-modules-socials-table-container-interactions'>
                                                <div className='dashboard-functions-tags-table-data'>
                                                    <RefreshButton on_click_action={refresh_data_table} />
                                                </div>
                                                { newTwitchMonitorEnabled === true ?
                                                    <div className='dashboard-modules-socials-settings-save-changes'>
                                                        <GenericButton button_text="Add account" on_click_action={open_create_twitch_entry_popup} />
                                                    </div>
                                                    :
                                                    <div></div>
                                                }
                                            </div>
                                            { newTwitchMonitorEnabled === false ?
                                                <div className='dashboard-modules-socials-table-container-component-disabled'>
                                                    The Twitch component is disabled, please enable it to view your Twitch list!
                                                </div>
                                                :
                                                twitchListEntries.length === 0 ?
                                                <div className='dashboard-modules-socials-table-container-component-list-empty'>
                                                    The Twitch list is empty!
                                                </div>
                                                :
                                                <div className='dashboard-modules-socials-table-container-table'>
                                                    <div className="dashboard-modules-socials-table-titles">
                                                        <div className="dashboard-modules-socials-table-titles-wrapper">
                                                            <div className="dashboard-modules-socials-table-titles-name-wrapper">
                                                                <p className="dashboard-modules-socials-table-titles-name">Twitch account</p>
                                                            </div>
                                                            <div className="dashboard-modules-socials-table-titles-interactions-wrapper">
                                                                <p className="dashboard-modules-socials-table-titles-interactions">Interactions</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="dashboard-modules-socials-table-content">
                                                        { twitchListEntries.map(account => 
                                                            <div className="socials-entry-wrapper" key={account.name}>
                                                                <div className="socials-entry-name-wrapper">
                                                                    <p className="socials-entry-name">{ account.name }</p>
                                                                </div>
                                                                <div className="socials-entry-interactions-wrapper">
                                                                    <div className="socials-entry-interactions-inner-wrapper">
                                                                        <div className="socials-entry-interactions-form-delete">
                                                                            <span className="socials-entry-interactions-delete-text" id={account.name} onClick={delete_twitch_entry}>Delete</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            }
                                            <div className='dashboard-modules-socials-table-load-extra'>
                                                <GenericButton button_text="Load extra Twitch entries" on_click_action={get_extra_data_twitch} />
                                            </div>
                                            <div className='create-twitch-entry-sidebar' id="create-twitch-entry-sidebar">
                                                <div className='create-twitch-entry-wrapper'>
                                                    <div className='create-twitch-entry-cancel'>
                                                        <div className="create-twitch-entry-back-button" onClick={close_create_twitch_entry_popup}>
                                                            <div className="create-twitch-entry-button-icon"><span className="material-symbols-outlined" id="twitch-entry-close-icon">close</span></div>
                                                            <div className="create-twitch-entry-button-text-wrapper"><span className="create-twitch-entry-button-text">Cancel & Reset</span></div>
                                                        </div>
                                                        <h2 className='create-twitch-entry-title'>
                                                            New Twitch account entry
                                                        </h2>
                                                    </div>
                                                    <div className='create-twitch-entry-account'>
                                                        <h3 className='create-twitch-entry-account-label'>
                                                            Twitch account*
                                                        </h3>
                                                        <input className='create-twitch-entry-account-input' id='create-twitch-entry-account-input' name='new-twitch-entry-account' placeholder='husqy_bot' onChange={on_field_change} value={newTwitchEntry} />
                                                    </div>
                                                </div>
                                                <div className='create-twitch-entry-interactions-wrapper'>
                                                    <div className='submit'>
                                                        <GenericButton button_text="Create entry" on_click_action={add_twitch_entry} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                :
                                <div className='dashboard-modules-socials-no-page-selected-container'>
                                    Please choose a list to view the entries!
                                </div>
                            }
                        </div>
                        :
                        <Loading loading_size='s' />
                    :
                    <div className='dashboard-modules-socials-disabled-container'>
                        The Socials module is disabled, click <label onClick={open_enable_socials_module_sidebar} className='dashboard-modules-socials-disabled-enable-button'>here</label> if you want to enabled this module!
                        <div className='enable-socials-module-sidebar' id="enable-socials-module-sidebar">
                            <div className='enable-socials-module-wrapper'>
                                <div className='enable-socials-module-cancel'>
                                    <div className="enable-socials-module-back-button" onClick={close_enable_socials_module_sidebar}>
                                        <div className="enable-socials-module-button-icon"><span className="material-symbols-outlined" id="socials-close-icon">close</span></div>
                                        <div className="enable-socials-module-button-text-wrapper"><span className="enable-socials-module-button-text">Cancel & Reset</span></div>
                                    </div>
                                    <h2 className='enable-socials-module-title'>
                                        Enable the Socials Module
                                    </h2>
                                </div>
                            </div>
                            <div className='enable-socials-module-interactions-wrapper'>
                                <div className='submit'>
                                    <GenericButton button_text="Enable" id="enable" on_click_action={set_module_status} />
                                </div>
                            </div>
                        </div>
                    </div>
                :
                <Loading loading_size='s' />
            }
        </div>
    );
};

export default DashboardModulesSocialsContent;