var startProductBarPos=-1;
window.addEventListener("scroll", create_reactionroles_module_scroll)
window.addEventListener("scroll", enable_reactionroles_module_scroll)

function create_reactionroles_module_scroll() {
    var bar = document.getElementById('create-reactionroles-module-sidebar');
    if (bar === null) {
      return
    }
    if(startProductBarPos<0)startProductBarPos=findPosY(bar);

    if(window.scrollY>=startProductBarPos){
      bar.style.position='fixed';
      bar.style.top=0;
    }else{
      bar.style.position='relative';
    }
};

function enable_reactionroles_module_scroll() {
  var bar = document.getElementById('enable-reactionroles-module-sidebar');
  if (bar === null) {
    return
  }
  if(startProductBarPos<0)startProductBarPos=findPosY(bar);

  if(window.scrollY>=startProductBarPos){
    bar.style.position='fixed';
    bar.style.top=0;
  }else{
    bar.style.position='relative';
  }
};

function findPosY(obj) {
    var curtop = 0;
    if (typeof (obj.offsetParent) != 'undefined' && obj.offsetParent) {
      while (obj.offsetParent) {
        curtop += obj.offsetTop;
        obj = obj.offsetParent;
      }
      curtop += obj.offsetTop;
    }
    else if (obj.y)
      curtop += obj.y;
    return curtop;
}