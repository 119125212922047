import React, { useEffect } from 'react';
import { useSearchParams } from "react-router-dom";
import { useCookies } from 'react-cookie'
import axios from 'axios'

import "../../styles/pages/oauth/login.css";
import config from '../../config'

const Login = () => {
    function HandleOauthCallback(discord_access_code) {
        const options = {
            url: `${config["api_base_url"]}/oauth/authorize?code=${discord_access_code}`,
            method: 'GET',
          };
          
          axios(options)
            .then(response => {
                if (response.status === 200) {
                    const cookieOptions = {
                        path: '/',
                        secure: true, // Cookie will only be sent over HTTPS
                        sameSite: 'none', // SameSite attribute set to None
                      };
                    setCookie("access_token", response["data"]["data"]["access_token"], cookieOptions)
                    window.location.replace("/");
                }
            })
            .catch(error => {});
    }
    const [cookies, setCookie] = useCookies(['access_token'])

    const [searchParams] = useSearchParams();
    var oauth_code = searchParams.get("code")

    useEffect(() => {
        if (oauth_code != null) {
            HandleOauthCallback(oauth_code)
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return(
        <div className='login-wrapper'>
            <h1 className='login-title'>Welcome to the Husqy Dashboard</h1>
            <h2 className='login-description'>Please login with Discord to manage your servers!</h2>
            <div className='login-button-container'>
                <a className='login-button-wrapper' href='https://discord.com/api/oauth2/authorize?client_id=868946730878632047&redirect_uri=https%3A%2F%2Fdashboard.husqy.xyz%2Flogin&response_type=code&scope=guilds%20identify'>
                    <button className='login-button'>
                        <svg className='login-button-svg' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 127.14 96.36'>
                            <defs>
                                <style>{'.cls-1{fill:#fff;}'}</style>
                            </defs>
                            <g className='图层_2' data-name='图层 2'>
                                <g className='Discord_Logos' data-name='Discord Logos'>
                                    <g className='Discord_Logo_-_Large_-_White' data-name='Discord Logo - Large - White'>
                                        <path className='cls-1' d='M107.7,8.07A105.15,105.15,0,0,0,81.47,0a72.06,72.06,0,0,0-3.36,6.83A97.68,97.68,0,0,0,49,6.83,72.37,72.37,0,0,0,45.64,0,105.89,105.89,0,0,0,19.39,8.09C2.79,32.65-1.71,56.6.54,80.21h0A105.73,105.73,0,0,0,32.71,96.36,77.7,77.7,0,0,0,39.6,85.25a68.42,68.42,0,0,1-10.85-5.18c.91-.66,1.8-1.34,2.66-2a75.57,75.57,0,0,0,64.32,0c.87.71,1.76,1.39,2.66,2a68.68,68.68,0,0,1-10.87,5.19,77,77,0,0,0,6.89,11.1A105.25,105.25,0,0,0,126.6,80.22h0C129.24,52.84,122.09,29.11,107.7,8.07ZM42.45,65.69C36.18,65.69,31,60,31,53s5-12.74,11.43-12.74S54,46,53.89,53,48.84,65.69,42.45,65.69Zm42.24,0C78.41,65.69,73.25,60,73.25,53s5-12.74,11.44-12.74S96.23,46,96.12,53,91.08,65.69,84.69,65.69Z'/>
                                    </g>
                                </g>
                            </g>
                        </svg>
                        <span className="login-button-text">Login with Discord</span>
                    </button>
                </a>
            </div>
        </div>
    );
};

export default Login;